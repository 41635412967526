import { PersistedSettings } from '@/core/utils/PersistedSettings'
import type { ParsedError } from '@/backend/error'
import {
  ANNOTATION_ITEM_DEFAULT_DURATION,
  HARDWARE_CONCURRENCY,
  NEIGHBOUR_TILES,
} from '@/utils/localStorageKeys'

import { VIDEO_ANNOTATION_DURATION_DEFAULT } from './constants'
// we will move it to workview
// eslint-disable-next-line boundaries/element-types
import type { ClassMapping } from '@/modules/Workview/classMappingTypes'
import type { RunningSessionPayload } from '@/backend/wind/types'

const persistedSettings = new PersistedSettings('localStorage')

export type WorkviewState = {
  classMapping: { [runningSessionId: string]: ClassMapping }
  clickerEpsilon: number
  currentTool: null | string

  instructionsIsOpen: boolean
  autoAnnotateModels: RunningSessionPayload[]
  preselectedAnnotationClassId: null | number
  preselectedModelId: null | string
  videoAnnotationDuration: number
  neighbourTiles: boolean
  hardwareConcurrency: number
  error: ParsedError['error'] | null
}

export const getInitialState = (): WorkviewState => ({
  clickerEpsilon: 0.5,
  currentTool: null,
  instructionsIsOpen: false,
  autoAnnotateModels: [],
  classMapping: JSON.parse(localStorage.getItem('class-mapping') || '{}'),
  preselectedAnnotationClassId: null,
  preselectedModelId: null,

  videoAnnotationDuration: persistedSettings.loadValue(
    ANNOTATION_ITEM_DEFAULT_DURATION,
    VIDEO_ANNOTATION_DURATION_DEFAULT,
  ),
  neighbourTiles: persistedSettings.loadValue(NEIGHBOUR_TILES, false),
  hardwareConcurrency: persistedSettings.loadValue(
    HARDWARE_CONCURRENCY,
    navigator.hardwareConcurrency - 1 || 2,
  ),
  error: null,
})

export const state: WorkviewState = getInitialState()
