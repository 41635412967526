import { v4 as uuidv4 } from 'uuid'

import type { WorkflowMutation } from '@/store/modules/workflow/types'

export type Payload = {
  sourceStageId: string
  targetStageId: string
  name?: string
}

export const CREATE_EDGE: WorkflowMutation<Payload> = (
  state,
  { sourceStageId, targetStageId, name },
) => {
  const workflow = state.editedWorkflow

  if (!workflow || !sourceStageId || !name) {
    return
  }

  const stage = workflow.stages.find((s) => s.id === sourceStageId)

  if (!stage) {
    console.warn(`CREATE_EDGE: no stage with stageId ${sourceStageId}`)
    return
  }

  const newEdge = {
    id: uuidv4(),
    name: name ?? 'default',
    source_stage_id: sourceStageId,
    target_stage_id: targetStageId,
  }
  stage.edges = [...stage.edges, newEdge]
}
