import { defineStore } from 'pinia'
import type { LayoutConfig, LayoutSlotChannel } from '@/modules/Editor/layout'
import { LayoutConfigType, type ViewConfig } from '@/modules/Editor/layout'
import type {
  V2DatasetItemPayload,
  V2DatasetItemWithChannelsPayload,
} from '@/store/types/V2DatasetItemPayload'
import { isLayoutV3ItemPayload } from '@/store/types/V2DatasetItemPayload'
import { useWorkviewSettingsStore } from '@/pinia/useWorkviewSettingsStore'
import { isLayoutV3 } from '@/modules/Workview/isLayoutVersion'
import type { components } from '@/backend/darwin/api'
import type { V2DatasetItemSlot } from '@/store/types/V2DatasetItemSlot'
import { computed, ref, watch } from 'vue'
import { useEditorV2 } from '@/composables/useEditorV2/useEditorV2'
import { useDatasetItemsStore } from '@/modules/Datasets/useDatasetItemsStore'
import { useFeatureFlagsStore } from '@/pinia/useFeatureFlagsStore'
import { injectVirtualSlotsForDicomMPR } from '@/modules/Workview/injectVirtualSlotsForDicomMPR'

export const useLayoutStore = defineStore('layout', () => {
  const datasetItemsStore = useDatasetItemsStore()
  const workviewSettings = useWorkviewSettingsStore()
  const featuresStore = useFeatureFlagsStore()
  const { editor } = useEditorV2()

  const itemSlots = ref<V2DatasetItemSlot[]>([])
  const layoutConfig = ref<LayoutConfig | undefined>()
  // Holds the activable channels for the current item
  const availableChannels = ref<Record<string, LayoutSlotChannel>>({})
  // available channels by slot name
  const availableSlotChannels = ref<Record<string, LayoutSlotChannel[]>>({})
  // Reference the active channels for the current item
  // Note: at the moment only 1 active channel per time is supported
  const visibleChannels = ref<LayoutSlotChannel[]>([])

  const channelsCount = computed(() => Object.entries(availableChannels.value).length)

  /*
   * This is used by layout V3 where we don't have a layout_grid from the BE.
   * It might be redundant if the BE will provide it in the future.
   */
  const getViewGridBySlotSize = (
    grid: components['schemas']['DatasetsV2.Common.ItemLayoutV3']['slots_grid'],
  ): [number, number] => {
    const rows = grid.length
    const columns = Math.max(...grid.map((row) => row.length))
    return [rows, columns]
  }

  /**
   * Returns all slots that are not base slots.
   * This is only useful for channel layouts (v3) and it's used to filter out non-base slots
   */
  const getChannelsNestedSlots = (item: V2DatasetItemPayload): string[] => {
    if (!isLayoutV3(item.layout)) {
      throw new Error('channel slots can only be computed from v3 layouts')
    }

    let nestedChannelSlots: string[] = []
    item.layout.slots_grid.forEach((slotGridRow) =>
      slotGridRow.forEach((slotGridItem) => {
        if (Array.isArray(slotGridItem)) {
          nestedChannelSlots = [...nestedChannelSlots, ...slotGridItem.slice(1)]
        }
      }),
    )

    return nestedChannelSlots
  }

  /**
   * This is used by layout V3 where we only need to consider base slots, without nested channel
   * slots.
   */
  const getItemSlotsForLayoutV3 = (item: V2DatasetItemPayload): V2DatasetItemSlot[] => {
    const nonBaseChannels = getChannelsNestedSlots(item)

    return item.slots.filter(({ slot_name }) => !nonBaseChannels.includes(slot_name))
  }

  const getItemLayoutV2Config = (item: V2DatasetItemPayload): LayoutConfig => {
    if (isLayoutV3(item.layout)) {
      throw new Error('tried to get layout config from a version 3 layout')
    }

    injectVirtualSlotsForDicomMPR(item, featuresStore.featureFlags)

    const layout:
      | components['schemas']['DatasetsV2.Common.ItemLayoutV1']
      | components['schemas']['DatasetsV2.Common.ItemLayoutV2'] = item.layout

    const views = item.slots
      .toSorted((a, b) => {
        const indexA = layout?.slots?.indexOf(a.slot_name) || 0
        const indexB = layout?.slots?.indexOf(b.slot_name) || 0
        return indexA - indexB
      })
      .map<ViewConfig>((file) => ({
        file,
        item,
        initialFrameIndex: workviewSettings.multiSlotFrameIndexes[file.slot_name],
      }))

    return {
      defaultSlot: workviewSettings.activeSlotName || undefined,
      type: item.layout?.type || LayoutConfigType.SIMPLE,
      layout_shape: item.layout?.version === 2 ? item.layout?.layout_shape : undefined,
      slots: item.layout?.version === 2 ? item.layout?.slots : undefined,
      views,
    }
  }

  /**
   * Return true if the channel id is currently visible
   */
  const isChannelVisible = (channelId: string): boolean =>
    !!visibleChannels.value.some((c) => c.id === channelId)

  /**
   * Shows a channel which will become visible on the channel overlay
   *
   * Note: only one channel can be visible at a time
   */
  const showChannel = (channelId: string): void => {
    const channel = availableChannels.value[channelId]
    if (!channel) {
      return
    }
    // removes all other visible channels with the same slotName first
    visibleChannels.value = visibleChannels.value.filter((c) => c.slotName !== channel.slotName)
    // then push the visible channel. Although we are toggling, so just 1 is fine
    visibleChannels.value.push(channel)
  }

  /**
   * Hides a channel which won't be visible anymore on the channel overlay
   */
  const hideChannel = (channelId: string): void => {
    visibleChannels.value = visibleChannels.value.filter((c) => c.id !== channelId)
  }

  /**
   * Hides all channel which won't be visible anymore on the channel overlay
   */
  const hideAllChannels = (): void => {
    visibleChannels.value = []
  }

  /**
   * Toggles a channel visibility on the channel overlay
   * This method is also hiding the view file, since all channels can be toggled, otherwise
   * we would have seen the base view file rendered in the background.
   * As we expand the channels functionality, we will introduce a "frozen"/"readonly" state
   * for the base view, so that it doesn't render nor deal with any logic around it
   */
  const toggleChannel = (channelId: string): void => {
    editor.value?.activeView.hideFile()
    isChannelVisible(channelId) ? hideChannel(channelId) : showChannel(channelId)
  }

  const initChannels = (item: V2DatasetItemPayload, slotGridItem: string | string[]): void => {
    if (typeof slotGridItem === 'string') {
      // When the slot is of type string, then it has no channels
      return
    }

    // Add all channels
    slotGridItem.map((channelName: string, index: number) => {
      const channelSlot = item.slots.find((s) => s.slot_name === channelName)
      if (!channelSlot) {
        throw new Error(`file ${channelName} not found`)
      }

      const slotName = slotGridItem[0]
      const channel: LayoutSlotChannel = {
        id: channelName,
        index,
        file: channelSlot,
        slotName,
        initialFrameIndex: workviewSettings.multiSlotFrameIndexes[slotName],
      }

      availableChannels.value[channelName] = channel
      if (!availableSlotChannels.value[slotName]) {
        availableSlotChannels.value[slotName] = []
      }
      availableSlotChannels.value[slotName].push(channel)

      return channel
    })
  }

  /*
   * Layout v3 is quite different from v1/2 as it supports three dimensional slots.
   * Here is an example:
   * ```
   * slots_grid: [
   *   ['slot1', 'slot2', 'slot3'],
   *   ['slot4', ['slot5', 'slot5-channel1', 'slot5-channel2', 'slot5-channel3'], 'slot6'],
   * ]
   * ```
   * The above example will produce a layout 2 x 3, the first row has 3 slots with no channels,
   * the second row has again 3 slots, but the second has 3 channels (on top of the base slot).
   *
   * Note: we initialize the availableChannels ref as a side-effect of this metho to optimize performance
   */
  const getItemLayoutV3Config = (item: V2DatasetItemWithChannelsPayload): LayoutConfig => {
    if (!isLayoutV3(item.layout)) {
      throw new Error('tried to get layout 3 config from a version 1/2 layout')
    }

    const layout = item.layout

    // Initialise channels for each layout slot
    item.layout.slots_grid.forEach((slotGridRow: (string | string[])[]): ViewConfig | void => {
      slotGridRow.forEach((slotGridItem) => initChannels(item, slotGridItem))
    })

    const views: LayoutConfig['views'] = []
    const channelSlots = getChannelsNestedSlots(item)
    // Create a list of base slot views, including those not directly specified in the layout
    item.slots.map((slot) => {
      if (channelSlots.includes(slot.slot_name)) {
        return
      }
      views.push({
        file: slot,
        item,
        initialFrameIndex: workviewSettings.multiSlotFrameIndexes[slot.slot_name],
      })
    })

    return {
      defaultSlot: workviewSettings.activeSlotName || undefined,
      type: LayoutConfigType.GRID,
      layout_shape: getViewGridBySlotSize(layout.slots_grid),
      slots: itemSlots.value.map(({ slot_name }) => slot_name),
      views,
    }
  }

  /** The layout config and item slots are re-calculated on item id and views list change */
  watch(
    () => [datasetItemsStore.currentItem, editor.value?.viewsList],
    () => {
      const item = datasetItemsStore.currentItem
      if (!item) {
        return
      }

      // clear available channels
      availableChannels.value = {}
      availableSlotChannels.value = {}

      // important to initialize itemSlots before as these will be used
      // to calculate the layout config as well
      itemSlots.value = isLayoutV3ItemPayload(item) ? getItemSlotsForLayoutV3(item) : item.slots

      layoutConfig.value = isLayoutV3ItemPayload(item)
        ? getItemLayoutV3Config(item)
        : getItemLayoutV2Config(item)
    },
    { immediate: true },
  )

  return {
    itemSlots,
    layoutConfig,
    availableChannels,
    availableSlotChannels,
    visibleChannels,
    channelsCount,

    isChannelVisible,
    showChannel,
    hideChannel,
    hideAllChannels,
    toggleChannel,
  }
})
