import type { WorkflowMutation } from '@/store/modules/workflow/types'
import type { V2WorkflowEdgePayload } from '@/store/types/V2WorkflowEdgePayload'

export type Payload = {
  name: string
  sourceStageId: string
  targetStageId: string
  updatedEdge: V2WorkflowEdgePayload
}

export const UPDATE_EDGE: WorkflowMutation<Payload> = (
  state,
  { name, sourceStageId, targetStageId, updatedEdge },
) => {
  if (!state.editedWorkflow || !name || !sourceStageId || !targetStageId) {
    return
  }

  const stage = state.editedWorkflow.stages.find((s) =>
    s.edges.some((e) => {
      const matchesSource = e.source_stage_id === sourceStageId
      const matchesTarget = e.target_stage_id === targetStageId

      return matchesSource && matchesTarget
    }),
  )

  if (!stage) {
    console.warn(`UPDATE_EDGE: no connected stage found on edge named ${name}`)
    return
  }

  const edgeIdx = stage.edges.findIndex((e) => {
    const matchesName = e.name === name
    const matchesSource = e.source_stage_id === sourceStageId
    const matchesTarget = e.target_stage_id === targetStageId

    return matchesName && matchesSource && matchesTarget
  })

  if (edgeIdx < 0) {
    console.warn(`UPDATE_EDGE: no connected edge found on edge named ${name}`)
    return
  }

  stage.edges.splice(edgeIdx, 1, updatedEdge)
}
