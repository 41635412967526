import type { NeuralModelAction } from '@/store/modules/neuralModel/types'
import { loadTrainedModels as windRequest } from '@/backend/wind'
import type { TrainedModelPayload } from '@/backend/wind/types'

export type LoadTrainedModels = NeuralModelAction<number, TrainedModelPayload[]>

export const loadTrainedModels: LoadTrainedModels = async ({ commit }, teamId) => {
  const response = await windRequest({ teamId })

  if ('data' in response) {
    commit('SET_TRAINED_MODELS', response.data)
    return response
  }

  return response
}
