import { EventEmitter } from 'events'

import type { PartialRecord } from '@/core/helperTypes'
import { LayoutEvents } from '@/modules/Editor/eventBus'
import type { Editor } from '@/modules/Editor/editor'
import type { View } from '@/modules/Editor/views/view'
import { ViewCreator } from '@/modules/Editor/views/viewCreator'
// eslint-disable-next-line boundaries/element-types
import type { V2DatasetItemPayload } from '@/store/types/V2DatasetItemPayload'
// eslint-disable-next-line boundaries/element-types
import type { V2DatasetItemSlot } from '@/store/types/V2DatasetItemSlot'
import { getPrimaryViewFromView } from '@/modules/Editor/plugins/mask/utils/shared/getPrimaryViewFromView'

type ViewsMap = Map<string, View>

export type LayoutSlotChannel = {
  id: string
  index: number
  file: V2DatasetItemSlot
  slotName: string
  initialFrameIndex?: number
}
export type ViewConfig = {
  file: V2DatasetItemSlot
  item: V2DatasetItemPayload
  initialFrameIndex?: number
}

export type ViewPosition = {
  row: number
  column: number
}

export const LayoutConfigType = {
  VERTICAL: 'vertical',
  HORIZONTAL: 'horizontal',
  GRID: 'grid',
  SIMPLE: 'simple',
} as const

export type LayoutConfigType = (typeof LayoutConfigType)[keyof typeof LayoutConfigType]

export type LayoutConfig = {
  type: LayoutConfigType
  layout_shape?: number[] | null
  slots?: string[] | null
  defaultSlot?: string
  views: ViewConfig[]
}

const getInitialLayoutShape = (layout: LayoutConfig): [number, number] => {
  const { layout_shape: layoutShape, views, type } = layout

  if (layoutShape) {
    return [layoutShape[0], layoutShape[1]]
  }

  const numViews = views.length

  if (type === LayoutConfigType.GRID) {
    if (numViews > 9) {
      // We have a maximum number of 16 viewports that will defaultly
      // show on the frontend, even if we have lots of files in an item.
      return [4, 4]
    }

    if (numViews > 4) {
      return [3, 3]
    }

    if (numViews > 1) {
      return [2, 2]
    }

    // Likely to be uploaded as a LayoutConfigType.SIMPLE layout, but technicaly possible.
    return [1, 1]
  }

  if (type === LayoutConfigType.VERTICAL) {
    return [1, numViews]
  }

  if (type === LayoutConfigType.HORIZONTAL) {
    return [numViews, 1]
  }

  if (type === LayoutConfigType.SIMPLE) {
    if (numViews > 1) {
      throw new Error('Invalid layout, simple only supports one view')
    }

    return [1, 1]
  }

  throw new Error('Unknwon LayoutConfigType')
}

// Store item reference here.

/**
 * @event activeView:changed
 */
export class Layout extends EventEmitter {
  private _activeView!: View
  private _visibleView!: View
  private _views: Map<string, View> = new Map()
  private viewCreator: ViewCreator
  private _viewPositions: PartialRecord<string, ViewPosition> = {}
  private _availableViewConfigurations: ViewConfig[]

  private _isFullScreen: boolean = false
  private _fullScreenSlotName: string | null = null
  private _isMultiPlanarMode: boolean = true

  /**
   * The current layout, used for working out what viewports
   * to keep when changing the layout when using a RadView layout.
   */
  private _layoutShape: [number, number]

  public layoutConfig: LayoutConfig

  constructor(editor: Editor, layout: LayoutConfig) {
    super()

    this.viewCreator = new ViewCreator(editor)
    this._layoutShape = getInitialLayoutShape(layout)
    this.layoutConfig = layout
    this.setupInitialViews(this.layoutConfig)
    this._availableViewConfigurations = layout.views
  }

  public enterFullScreen(slotName: string): void {
    this._fullScreenSlotName = slotName
    this._isFullScreen = true
  }

  public exitFullScreen(): void {
    this._fullScreenSlotName = null
    this._isFullScreen = false
  }

  public enterMultiPlanarMode(): void {
    this._isMultiPlanarMode = true
    this.exitFullScreen()
  }

  public exitMultiPlanarMode(): void {
    this._isMultiPlanarMode = false
    this.enterFullScreen(this.multiPlanarPrimarySlotName)
  }

  public setViewport(slot_name: string, column: number, row: number): void {
    const layoutShape = this._layoutShape

    if (column < 0 || column >= layoutShape[0] || row < 0 || row >= layoutShape[1]) {
      throw new Error('Setting viewport out of grid bounds')
    }

    // If there is another view in the viewport, destroy it.
    const viewPositions = this._viewPositions

    const viewIdInViewport = Object.keys(viewPositions).find(
      (viewId: string) =>
        viewPositions[viewId]?.row === row && viewPositions[viewId]?.column === column,
    )
    const viewInViewport = viewIdInViewport ? this._views.get(viewIdInViewport) : undefined

    if (viewInViewport?.fileManager.file.slot_name === slot_name) {
      // Nothing to do here, the view is already in the correct column and row.
      return
    }

    if (viewIdInViewport && viewInViewport) {
      this._views.delete(viewIdInViewport)
      delete this._viewPositions[viewIdInViewport]
      viewInViewport.removeListeners()
      viewInViewport.destroy()
    }

    // Check if there is a viewport with this slot_name is already present in the viewport.
    const slotAlreadyInView = this.viewsList.find(
      (view) => view.fileManager.file.slot_name === slot_name,
    )

    if (slotAlreadyInView) {
      // Move the slot's position in the grid.
      this._viewPositions[slotAlreadyInView.id] = { row, column }
      return
    }

    // Otherwise build a new view
    const viewConfig = this._availableViewConfigurations.find(
      (viewConfig) => viewConfig.file.slot_name === slot_name,
    )

    if (!viewConfig) {
      throw new Error('No view config for slot_name')
    }

    const view = this.createView(viewConfig)
    this._views.set(view.id, view)
    this._viewPositions[view.id] = { row, column }
  }

  public setLayout(columns: number, rows: number): void {
    if (columns > 8 || rows > 4 || rows * columns > 16) {
      throw new Error('Unsupported layout, we should not hit a layout this big')
    }

    const exisitngViewPositions = this._viewPositions
    const newLayoutShape: [number, number] = [columns, rows]
    const newViewsMap: Map<string, View> = new Map()
    const newViewsPosition: PartialRecord<string, ViewPosition> = {}

    // Pull out views to delete.

    const viewsToRemove: View[] = []

    const currentViews = this.viewsList

    let topLeftView: View | undefined

    currentViews.forEach((view) => {
      // Check if view position is within new range
      const viewPositon = exisitngViewPositions[view.id]

      if (!viewPositon || viewPositon.row >= rows || viewPositon.column >= columns) {
        // View needs to be removed.
        viewsToRemove.push(view)

        return
      }

      newViewsMap.set(view.id, view)
      newViewsPosition[view.id] = viewPositon

      if (viewPositon.row === 0 && viewPositon.column === 0) {
        topLeftView = view
      }
    })

    if (currentViews.length === viewsToRemove.length) {
      // We're about to remove all the views, this is an edge case that can happen
      // after resizing down the grid layout. We need to keep at least one view in the grid.
      const lastRemainingView = viewsToRemove.shift()
      if (lastRemainingView) {
        newViewsMap.set(lastRemainingView.id, lastRemainingView)
        newViewsPosition[lastRemainingView.id] = { row: 0, column: 0 }
        topLeftView = lastRemainingView
      }
    }

    this._layoutShape = newLayoutShape
    this._views = newViewsMap
    this._viewPositions = newViewsPosition

    viewsToRemove.forEach((view) => {
      view.removeListeners()
      view.destroy()
    })

    if (topLeftView) {
      this.setActiveView(topLeftView.id)
    }
  }

  public getViewByName(name: string): View | undefined {
    return this.viewsList.find((view) => view.name === name)
  }

  public get views(): ViewsMap {
    return this._views
  }

  public get viewsList(): View[] {
    return [...this._views.values()]
  }

  public get viewPositions(): PartialRecord<string, ViewPosition> {
    return this._viewPositions
  }

  public get isFullScreen(): boolean {
    return this._isFullScreen
  }

  public get isMultiPlanarMode(): boolean {
    return this._isMultiPlanarMode
  }

  public get fullScreenSlotName(): string | null {
    return this._fullScreenSlotName
  }

  public setFullScreenSlotName(slotName: string): void {
    this._fullScreenSlotName = slotName
  }

  public get fullScreenSlotPositionInGrid(): ViewPosition {
    const fullScreenView = this.viewsList.find(
      (view) => view.fileManager.file.slot_name === this._fullScreenSlotName,
    )

    return this._viewPositions[fullScreenView?.id || ''] ?? { row: 0, column: 0 }
  }

  public get multiPlanarPrimarySlotName(): string {
    const primaryView = getPrimaryViewFromView(this.activeView)
    if (!primaryView) {
      throw new Error('Primary view not found')
    }

    return primaryView.name
  }

  public get activeView(): View {
    return this._activeView
  }

  public get visibleView(): View {
    return this._visibleView
  }

  public get layoutShape(): [number, number] {
    return [...this._layoutShape]
  }

  public setActiveView(viewId: string): void {
    if (this.activeView?.id === viewId) {
      return
    }

    const oldViewId = this.activeView?.id
    this.activeView?.allLayersChanged()
    this.activeView?.removeListeners()

    const view = this.views.get(viewId)
    if (!view) {
      throw new Error('View not found')
    }
    this._activeView = view
    this._visibleView = view
    LayoutEvents.activeViewChanged.emit({
      newViewId: this._activeView.id,
      oldViewId,
    })
    view.addListeners()
  }

  public setVisibleView(view: View): void {
    this._visibleView = view
    LayoutEvents.visibleViewChanged.emit({
      visibleViewId: view.id,
      activeViewId: this.activeView.id,
    })
  }

  public updateViewsCameraDimensions(width?: number, height?: number): void {
    this.viewsList.forEach((view) => {
      view.updateCameraDimensions(width, height)
    })
  }

  private cleanupView(key: string): void {
    const view = this.views.get(key)
    if (!view) {
      return
    }
    view.removeListeners()
    view.destroy()
    this._views.delete(key)
  }

  public cleanup(): void {
    this.viewsList.forEach((view) => {
      this.cleanupView(view.id)
    })
    this._views.clear()
  }

  /*
   * This function will setup the initial views based on the layout configuration.
   * When no slotNames are passed, then we try to set the views based on the `layout.views` array
   * When slotNames are passed (through the API), we try to set the views based on the
   * slot names, respecting the order.
   * If a slot name is non-existent or repeated, then we skip that slot.
   */
  private setupInitialViews(layout: LayoutConfig): void {
    const { layoutShape } = this
    const [columns, rows] = layoutShape
    const { slots: slotNames } = layout

    // Fill slots from top left to bottom right
    for (let row = 0; row < rows; row++) {
      for (let column = 0; column < columns; column++) {
        const slotIndex = row * columns + column
        let viewConfig: ViewConfig | undefined = layout.views[slotIndex]

        // When slot names are passed, then we use the slot names array to order the slots
        if (slotNames?.[slotIndex] !== undefined) {
          const namedSlotViewConfig = layout.views.find(
            (viewConfig) => viewConfig.file.slot_name === slotNames[slotIndex],
          )
          if (namedSlotViewConfig) {
            viewConfig = namedSlotViewConfig
          }
        }

        if (!viewConfig) {
          continue
        }

        const newView = this.createView(viewConfig)
        const newViewSlotName = newView.fileManager.file?.slot_name
        const slotNameAlreadySet = Array.from(this._views.values()).some(
          (view) => view.fileManager.file.slot_name === newViewSlotName,
        )

        if (slotNameAlreadySet) {
          // A view with the same slot name was already added, skip
          continue
        }
        this._views.set(newView.id, newView)
        this._viewPositions[newView.id] = { row, column }
      }
    }

    if (layout.defaultSlot) {
      const defaultView = this.getViewByName(layout.defaultSlot)
      this.setActiveView(defaultView?.id || this.viewsList[0]?.id)
    } else {
      this.setActiveView(this.viewsList[0]?.id)
    }
  }

  private createView(viewConfig: ViewConfig): View {
    const DEFAULT_FRAME_INDEX = -1

    const view = this.viewCreator.createForType(
      viewConfig.file,
      viewConfig.item,
      viewConfig.initialFrameIndex ?? DEFAULT_FRAME_INDEX,
    )

    return view
  }
}
