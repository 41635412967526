import { CallbackStatus } from '@/modules/Editor/callbackHandler'
import { EditorCursor, selectCursor } from '@/modules/Editor/editorCursor'
import { isLeftMouseButton } from '@/modules/Editor/mouse'
import type { PointerEvent } from '@/core/utils/touch'
import { resolveEventPoint } from '@/core/utils/touch'
import type { Tool, ToolContext } from '@/modules/Editor/managers/toolManager'
import { setupMouseButtonLoadout } from '@/modules/Editor/plugins/mixins/loadouts'
import { DicomView } from '@/modules/Editor/views/dicomView'
import { VtkLayer } from '@/modules/Editor/models/layers/vtkLayer'

import { initCrosshairsState } from './helpers/initCrosshairsState'
import { queueJumpToPosition } from './helpers/queueJumpToPosition'
import type { CrosshairsState } from './types'

interface CrosshairsTool extends Tool {
  onStart(context: ToolContext, event: PointerEvent): void
  onMove(context: ToolContext, event: PointerEvent): void
  onEnd(context: ToolContext, event: PointerEvent): void
}

let crosshairsState: CrosshairsState | null = null

function getVtkLayersFromContext(context: ToolContext): VtkLayer[] | null {
  const featureFlags = context.editor.featureFlags
  if (!featureFlags.MED_2D_VIEWER || !featureFlags.OBLIQUE_PLANES) {
    return null
  }
  const vtkLayers = []
  for (const view of context.editor.viewsList) {
    if (view instanceof DicomView && view.mainLayer instanceof VtkLayer) {
      vtkLayers.push(view.mainLayer)
    }
  }
  return vtkLayers
}

/**
 * Crosshairs mouse Tool for left click.
 */
export const crosshairsTool: CrosshairsTool = {
  onStart(context: ToolContext, event: PointerEvent) {
    const editor = context.editor

    crosshairsState = initCrosshairsState(editor)

    const cursorPoint = resolveEventPoint(event)

    if (!cursorPoint) {
      return
    }

    const view = editor.activeView
    const { camera } = view
    const imagePoint = camera.canvasViewToImageView(cursorPoint)

    const flooredImagePoint = { x: Math.floor(imagePoint.x), y: Math.floor(imagePoint.y) }

    queueJumpToPosition(crosshairsState, flooredImagePoint)
  },

  onMove(context: ToolContext, event: PointerEvent) {
    if (crosshairsState === null) {
      return
    }

    const cursorPoint = resolveEventPoint(event)

    if (!cursorPoint) {
      return CallbackStatus.Stop
    }
    const editor = context.editor
    const view = editor.activeView
    const { camera } = view
    const imagePoint = camera.canvasViewToImageView(cursorPoint)

    const flooredImagePoint = { x: Math.floor(imagePoint.x), y: Math.floor(imagePoint.y) }

    if (!crosshairsState.previousImagePoint) {
      crosshairsState.previousImagePoint = flooredImagePoint
      return CallbackStatus.Stop
    }

    const { previousImagePoint } = crosshairsState

    if (
      flooredImagePoint.x - previousImagePoint.x !== 0 ||
      flooredImagePoint.y - previousImagePoint.y !== 0
    ) {
      queueJumpToPosition(crosshairsState, flooredImagePoint)
    }

    crosshairsState.previousImagePoint = flooredImagePoint
    return CallbackStatus.Stop
  },

  onEnd() {
    crosshairsState = null
  },

  activate(context: ToolContext) {
    // Custom crosshair tool when there are vtk layers
    const vtkLayers = getVtkLayersFromContext(context)
    if (vtkLayers?.length) {
      for (const vtkLayer of vtkLayers) {
        vtkLayer.vtk2DContext?.enablePicking()
      }

      selectCursor(EditorCursor.BBox)
      return
    }

    setupMouseButtonLoadout(context, { middle: true })

    selectCursor(EditorCursor.BBox)

    context.handles.push(
      ...context.editor.onMouseDown((e) => {
        if (!isLeftMouseButton(e)) {
          return CallbackStatus.Continue
        }
        return this.onStart(context, e)
      }),
    )

    context.handles.push(...context.editor.onMouseMove((event) => this.onMove(context, event)))
    context.handles.push(...context.editor.onMouseUp((event) => this.onEnd(context, event)))

    context.handles.push(...context.editor.onTouchStart((event) => this.onStart(context, event)))
    context.handles.push(...context.editor.onTouchMove((event) => this.onMove(context, event)))
    context.handles.push(...context.editor.onTouchEnd((event) => this.onEnd(context, event)))
  },

  deactivate(context: ToolContext): void {
    // Custom crosshair tool when there are vtk layers
    const vtkLayers = getVtkLayersFromContext(context)
    if (vtkLayers?.length) {
      for (const vtkLayer of vtkLayers) {
        vtkLayer.vtk2DContext?.disablePicking()
      }
      return
    }

    this.reset(context)
  },

  reset(): void {
    crosshairsState = null
  },
}
