import type { NeuralModelAction } from '@/store/modules/neuralModel/types'
import { loadRunningSessions as windRequest } from '@/backend/wind'
import type { RunningSessionPayload } from '@/backend/wind/types'

export type Action = NeuralModelAction<number, RunningSessionPayload[]>

export const loadRunningSessions: Action = async ({ commit }, teamId) => {
  const response = await windRequest({
    expand: ['meta.classes', 'meta.num_instances_available', 'meta.num_instances_starting'],
    teamId,
    includePublic: true,
  })

  if ('data' in response) {
    commit('SET_RUNNING_SESSIONS', response.data)
    return response
  }

  return response
}
