import { useToast } from '@/uiKit/Toast/useToast'
import type { NeuralModelAction } from '@/store/modules/neuralModel/types'
import { updateRunningSession as windRequest } from '@/backend/wind'
import type { RunningSessionPayload } from '@/backend/wind/types'

export type Payload = {
  autoStart?: boolean
  autoStop?: boolean
  maximumInstances: number
  minimumInstances: number
  runningSession: RunningSessionPayload
  teamId: number
}

export type UpdateModel = NeuralModelAction<Payload, RunningSessionPayload>

export const updateModel: UpdateModel = async ({ commit }, payload) => {
  const { autoStart, autoStop, maximumInstances, minimumInstances, runningSession, teamId } =
    payload

  const response = await windRequest({
    autoStart,
    autoStop,
    expand: ['meta.classes', 'meta.num_instances_available', 'meta.num_instances_starting'],
    max: maximumInstances,
    min: minimumInstances,
    runningSessionId: runningSession.id,
    teamId,
  })

  if ('data' in response) {
    commit('PUSH_RUNNING_SESSION', response.data)
  }

  if ('error' in response && typeof response.error.message === 'string') {
    useToast().warning({ meta: { title: response.error.message } })
  }

  return response
}
