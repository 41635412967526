import { PersistedSettings } from '@/core/utils/PersistedSettings'
import { copyAttributes } from '@/utils/array'
import {
  ANNOTATION_ITEM_DEFAULT_DURATION,
  HARDWARE_CONCURRENCY,
  NEIGHBOUR_TILES,
} from '@/utils/localStorageKeys'

import { SET_AUTO_ANNOTATE_CLASS_MAPPING } from './mutations/SET_AUTO_ANNOTATE_CLASS_MAPPING'
import { SET_ERROR } from './mutations/SET_ERROR'
import { getInitialState } from './state'
import type { WorkflowMutation } from './types'

const persistedSettings = new PersistedSettings()

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const mutations: { [k: string]: WorkflowMutation<any> } = {
  // instructions
  CLOSE_INSTRUCTIONS(state) {
    state.instructionsIsOpen = false
  },

  OPEN_INSTRUCTIONS(state) {
    state.instructionsIsOpen = true
  },

  // tools

  /**
   * @param payload: { preselectedClassId: number, skipAutoSelectTool: boolean }
   *
   * NOTE: skipAutoSelectTool - each PRESELECT_CLASS_ID call triggers
   * the toolManager.autoActivateTool from the useLinkTooling, we don't need it sometimes
   * since it reselect the tool.
   */
  // NOTE: In use by V1 only
  PRESELECT_CLASS_ID(state, payload) {
    if (!payload) {
      return
    }
    const { preselectedClassId } = payload

    state.preselectedAnnotationClassId = preselectedClassId
  },

  SET_CURRENT_TOOL_PRESELECTED_MODEL_ID(state, preselectedModelId = null) {
    state.preselectedModelId = preselectedModelId
  },

  // annotation selection/visibility/highlight

  RESET_ALL(state) {
    copyAttributes(state, getInitialState())
  },

  SET_ERROR,

  SET_CLICKER_EPSILON(state, epsilon: number) {
    state.clickerEpsilon = epsilon
  },

  SET_VIDEO_ANNOTATION_DURATION(state, duration: number) {
    persistedSettings.saveValue(ANNOTATION_ITEM_DEFAULT_DURATION, duration)
    state.videoAnnotationDuration = duration
  },

  SET_HARDWARE_CONCURRENCY(state, hardwareConcurrency: number) {
    persistedSettings.saveValue(HARDWARE_CONCURRENCY, hardwareConcurrency)
    state.hardwareConcurrency = hardwareConcurrency
  },

  SET_NEIGHBOUR_TILES(state, neighourTiles: boolean) {
    persistedSettings.saveValue(NEIGHBOUR_TILES, neighourTiles)
    state.neighbourTiles = neighourTiles
  },
  SET_AUTO_ANNOTATE_CLASS_MAPPING,
}
