import type { NeuralModelAction } from '@/store/modules/neuralModel/types'
import { loadInferenceRequests as windRequest } from '@/backend/wind'
import type { InferenceRequestCountPayload, RunningSessionPayload } from '@/backend/wind/types'

type RequestParams = Parameters<typeof windRequest>[0]
type Payload = Pick<RequestParams, 'from' | 'granularity'> & {
  runningSession: RunningSessionPayload
  teamId: number
}

export type LoadInferenceRequests = NeuralModelAction<Payload, InferenceRequestCountPayload[]>

export const loadInferenceRequests: LoadInferenceRequests = async ({ commit }, payload) => {
  const { from, granularity, runningSession, teamId } = payload

  const response = await windRequest({
    from,
    granularity,
    runningSessionId: runningSession.id,
    teamId,
  })

  if ('data' in response) {
    commit('SET_RUNNING_SESSION_REQUEST_COUNTS', { runningSession, data: response.data })
  }

  return response
}
