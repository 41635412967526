import { v4 } from 'uuid'

import { buildV2WorkflowStagePayload } from '@/store/modules/workflow/actions/buildV2WorkflowStagePayload'
import type { WorkflowAction } from '@/store/modules/workflow/types'
import { StageType } from '@/store/types/StageType'
import type { BaseStageConfig, V2WorkflowStagePayload } from '@/store/types/V2WorkflowStagePayload'
import { appendNumber } from '@/core/utils/string'

type StageTypes =
  | typeof StageType.Review
  | typeof StageType.Annotate
  | typeof StageType.Discard
  | typeof StageType.Complete
  | typeof StageType.Model
  | typeof StageType.Logic

export type Payload = {
  type: StageTypes
  id?: string
} & BaseStageConfig

export const createStage: WorkflowAction<Payload, V2WorkflowStagePayload> = (
  { commit, state },
  { type, x, y, id },
) => {
  const uuid = v4()
  const typeToDefaultNameMap = {
    [StageType.Annotate]: 'Annotate',
    [StageType.Archive]: 'Archive',
    [StageType.Review]: 'Review',
    [StageType.Complete]: 'Complete',
    [StageType.ConsensusEntrypoint]: 'Consensus',
    [StageType.Discard]: 'Discard',
    [StageType.Logic]: 'Logic',
    [StageType.Model]: 'AI Model',
    [StageType.Dataset]: 'Dataset',
    [StageType.Webhook]: 'Webhook',
    [StageType.Sampling]: 'Sampling',
  }

  const stage = buildV2WorkflowStagePayload({
    id: id ?? uuid,
    type,
    config: {
      x,
      y,
    },
    name: appendNumber(
      typeToDefaultNameMap[type],
      state.editedWorkflow?.stages.filter((s) => s.type === type).map((s) => s.name) ?? [],
    ),
  })

  // Be sure that a Logic stage has a default first rule
  // when created
  if (stage.type === StageType.Logic) {
    stage.config.rules.push({
      name: 'Rule 1',
      seq: 1,
      conditions: [
        {
          seq: 1,
          subject: 'Annotation',
          operand: 'eq',
          value: {
            class: 'annotation_class_id',
            value: [],
          },
        },
      ],
    })
  }

  if (stage.type === StageType.ConsensusEntrypoint) {
    const consensusStage = stage
    const testStage = buildV2WorkflowStagePayload({
      id: consensusStage.config.test_stage_id,
      type: StageType.ConsensusTest,
      config: {
        x: consensusStage.config.x,
        y: consensusStage.config.y,
      },
      name: `${consensusStage.name} - test`,
    })

    commit('CREATE_CONSENSUS_STAGE', [consensusStage, testStage])
    return
  }

  commit('CREATE_STAGE', stage)
}
