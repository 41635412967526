/**
 * This utility contains api-related functions that deals with tokens
 * and requests to the backend.
 * It also manages the localStorage and sessionStorage to manage the tokens
 */
import axios from 'axios'
import type { AxiosError, AxiosResponse, Method, ResponseType } from 'axios'

import { type ErrorWithMessage } from '@/backend/error'
import type { Ability, TeamPayload } from '@/store/types'

import { client, refreshClient } from './apiClient'
import qs from 'qs'

// Primary client public interface

export type AdditionalRequestOptions = {
  params?: unknown
  responseType?: ResponseType
  /**
   * We default to encoding arrays with comma, as `?foo=bar,baz`.
   * Use 'brackets' to encode arrays with brackets, as `?foo[]=bar&foo[]=baz`.
   */
  arrayFormat?: 'comma' | 'brackets'
  /**
   * Pass an optional signal (from new AbortController.signal) to cancel the request
   */
  signal?: AbortSignal
}

/**
 * Main request method for use with the primary backend client
 */
export const request = <T>(
  url: string,
  method: Method,
  data: unknown,
  options: AdditionalRequestOptions = {},
): Promise<AxiosResponse<T>> => {
  const { arrayFormat, ...rest } = options
  return client.request({
    method,
    url,
    data,
    ...rest,
    paramsSerializer: {
      serialize: (params) => qs.stringify(params, { arrayFormat: options.arrayFormat || 'comma' }),
    },
  })
}

/**
 * Utility method for downloading files from backend.
 *
 * Defaults to arraybuffer as response type.
 */
export const download = <T>(
  url: string,
  options: AdditionalRequestOptions = { responseType: 'arraybuffer' },
): Promise<AxiosResponse<T>> => request<T>(url, 'get', null, options)

/**
 * Utility method for GET requests to the backend
 */
export const get = <T = unknown>(
  url: string,
  params?: object,
  options?: AdditionalRequestOptions,
): Promise<AxiosResponse<T>> => request<T>(url, 'get', null, { params, ...options })

/**
 * Utility method for POST requests to the backend
 */
export const post = <T = unknown>(
  url: string,
  data?: object,
  options?: AdditionalRequestOptions,
): Promise<AxiosResponse<T>> => request<T>(url, 'post', data, options)

/**
 * Utility method for PUT requests to the backend
 */
export const put = <T = unknown>(
  url: string,
  data?: object,
  options?: AdditionalRequestOptions,
): Promise<AxiosResponse<T>> => request<T>(url, 'put', data, options)

/**
 * Utility method for DELETE rquests to the backend
 */
export const remove = <T = unknown>(
  url: string,
  data?: object,
  options?: AdditionalRequestOptions,
): Promise<AxiosResponse<T>> => request<T>(url, 'delete', data, options)

/**
 * Utility method to send a logout request to a fixed backend endpoint
 */
export const logout = (): Promise<AxiosResponse<{ success: { detail: string } }>> =>
  refreshClient.get('users/logout')

export type SelectTeamResponse = {
  selected_team_abilities: Ability[]
  selected_team: TeamPayload
  teams: TeamPayload[]
  refresh_token: string
  token_expiration: number
  token: string
}
/**
 * Utility method to send a team selection request to a fixed backend endpoint.
 */
export const selectTeam = <T = SelectTeamResponse>(params: object): Promise<AxiosResponse<T>> =>
  refreshClient.post<T>('users/select_team', params)

// MISC

/**
 * General axios helper method to upload data to S3, using a signed S3 upload url
 */
export const uploadToS3 = (
  uploadUrl: string,
  data: Blob,
  type: string | null,
): Promise<AxiosResponse<unknown>> =>
  axios({
    method: 'PUT',
    url: uploadUrl,
    data,
    headers: type ? { 'Content-Type': type } : {},
  })

export const isApiError = (error: unknown): error is AxiosError<ErrorWithMessage> =>
  axios.isAxiosError(error) && error.response?.data.errors && 'code' in error.response.data.errors
