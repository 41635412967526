import { FFmpeg } from '@ffmpeg/ffmpeg'
import { toBlobURL } from '@ffmpeg/util'

import { checkBrowserSupportsMultithreading } from '@/core/utils/browser'
import type { ViewEvent } from '@/modules/Editor/eventBus'
import { EditorEvents } from '@/modules/Editor/eventBus'
import { FrameManagerEvents, StreamViewEvents } from '@/modules/Editor/eventBus'
import measureRunningTime from '@/modules/Editor/utils/measureRunningTime'
import { setContext } from '@/services/sentry'
import { AsyncOperationQueue } from '@/modules/Editor/utils/frameExtractorQueue'
import type { FSNode } from '@ffmpeg/ffmpeg/dist/esm/types'

const FORCE_RELOAD = true

type FFMPEGError = {
  name: string
  message: string
}

export enum State {
  INIT = 'INIT',
  LOADING = 'LOADING',
  RUNNING = 'RUNNING',
  IDLE = 'IDLE',
}

/*
 * These are errors that we expect to happen when using FFMPEG.
 *
 * 'FFmpeg.terminate': ffmpeg can run only one command at a time, so we terminate it before
 * running a new one. FFMpeg throws this error internally, we don't want to throw an error here.
 *
 * 'ffmpeg is not loaded': this is an unpredictable error as it can happen when ffmpeg is
 * not loaded, but all commands we run we check if it's loaded first. It seems to be an issue
 * with ffmpeg and it's internal `loaded` state
 *
 * 'ErrnoError: FS error': this is an error that happens when we try to read files in ffmpeg
 * file system but they can't be found. We have no control over this as this can happen
 * when moving between items even if we flush the memory (as we do)
 *
 * 'RuntimeError' and 'TypeError': these are errors that happen mainly when ffmpeg is terminated,
 * from what the community online says there isn't much we can do to avoid them, and again
 * they are internal in ffmpeg workers
 */
export const EXPECTED_FFMPEG_INTERNAL_ERRORS = [
  'FFmpeg.terminate',
  'ffmpeg is not loaded',
  'ErrnoError: FS error',
  'RuntimeError: ',
  'TypeError: ',
]

export interface FrameSpec {
  segmentFileUrl: string
  indexInSegment: number
}

// FFMPEG's "-threads" option by default uses the maximum number of threads available.
// On Apple M1 Pro, running FFMPEG with "-threads 0" (the default) configures it to 6 threads.
// Unfortunately this causes frame extraction on Chrome to freeze (works fine in Firefox).
// Running FFMPEG with "-threads 6" works. "-threads 7" freezes again, so 6 was indeed the limit.
// This is the closest we can get to the maximum number of threads (results in 5 on Apple M1 Pro).
const MAX_THREADS = Math.floor(navigator.hardwareConcurrency / 2)

export const FIRST_SEGMENT_INDEX = '000000000'
const SEGMENT_NAME_LENGTH = FIRST_SEGMENT_INDEX.length
const SEGMENT_WAIT_TIMEOUT = 30000

export class FrameExtractionError extends Error {
  segmentIndex: string
  frameNumber: number
  viewId: string
  contextId?: string

  constructor(
    frameNumber: number,
    segmentIndex: string,
    message: string,
    viewId: string,
    contextId?: string,
  ) {
    super(message)
    this.name = 'FrameExtractionError'
    this.segmentIndex = segmentIndex
    this.frameNumber = frameNumber
    this.viewId = viewId
    this.contextId = contextId
  }
}

/**
 * `ffmpeg.wasm` only uses local packages when running in a node environment.
 * When running in the browser, it fetches from CDN.
 *
 * `ffmpeg.wasm` always loads the multithreaded version of ffmpeg.
 * This causes browsers that don't support it to throw errors.
 *
 * Here we use the same URL that `ffmpeg.wasm` uses for multithreaded mode
 * with added logic to also use the single threading version if the browser needs it.
 */
const supportsMultithreading = checkBrowserSupportsMultithreading()

export type Segment = {
  binaryData: Uint8Array
  index: string
  segmentFileUrl: string
  start: number
  end: number
}

export interface SegmentInMemory extends Segment {
  viewId: string
  loaded: boolean
  loadingPromise?: Promise<void>
}

class FrameExtractor {
  private ffmpeg = new FFmpeg()

  // This is referring to the state of the FFMPEG instance
  public state: State = State.INIT
  private loadFFMPEGPromise?: Promise<void> = Promise.resolve()
  private segmentsInMemory: SegmentInMemory[] = []
  private extractPromise?: Promise<string | undefined> = Promise.resolve(undefined)
  private framesCache = new Map<string, string>()
  /** this queue will hold ffmpeg async operations so we can serialise more than 1 **/
  private queue: AsyncOperationQueue = new AsyncOperationQueue()

  /**
   * `frameExtractor` is a singleton class, so there are few things to keep in mind:
   * 1. we don't need to unsubscribe from events
   * 2. cleanup needs to be called manually, but keep in mind it will clear data for any view
   * 3. it will receive requests from potentially multiple views, it is critical to always refer
   * to the view id for operations
   */
  constructor() {
    FrameManagerEvents.clearFrameExtractionQueue.on(({ viewId }: ViewEvent) => {
      this.queue.clearByBroupId(viewId)
    })
    // Editor cleaning up means we have new file/s, so we can cleanup current cache
    EditorEvents.cleanup.on(this.cleanup.bind(this))
  }

  // eslint-disable-next-line @typescript-eslint/no-unsafe-function-type, @typescript-eslint/no-explicit-any
  private async runFFmpegMethod(fn: Function, ...args: unknown[]): Promise<any> {
    try {
      if (!this.ffmpeg.loaded && this.state !== State.LOADING) {
        await this.loadFFMPEG(FORCE_RELOAD).catch((err) => {
          setContext('ffmpeg load error', {
            ...args,
            error: err,
          })
        })
      }
      if (this.state === State.LOADING) {
        await this.loadFFMPEGPromise
      }
      return fn.apply(this, args)
    } catch (err: unknown) {
      this.handleFFMPEGError(err as FFMPEGError)
    }
  }

  // This is called to release the memory used by the frames cache.
  // It's managed automatically when the document unloads but this helps to improve performance
  private resetFramesCache(): void {
    // TODO: ANN-2712 We need to clear this more often,
    // and introduce a limit to not allow users to overload it
    for (const objectURL of this.framesCache.values()) {
      URL.revokeObjectURL(objectURL)
    }
    this.framesCache.clear()
  }

  /**
   * Asynchronously loads the FFMPEG library.
   *
   * This function initiates the loading of the FFMPEG library, ensuring that it is only
   * loaded once even if called multiple times. It sets the internal state to LOADING during
   * the load process and IDLE once loading is complete.
   *
   * @public
   * @param {boolean} [force=false] - If true, the FFMPEG library will be reloaded
   * regardless of the current status
   * @returns {Promise<void>} A promise that resolves once the FFMPEG library has finished loading.
   */
  public async loadFFMPEG(force?: boolean): Promise<void> {
    if (this.state === State.INIT || force) {
      // eslint-disable-next-line no-async-promise-executor
      this.loadFFMPEGPromise = new Promise(async (resolve) => {
        this.state = State.LOADING
        const baseURL = `https://cdn.jsdelivr.net/npm/@ffmpeg/${
          supportsMultithreading ? 'core-mt' : 'core'
        }@0.12.4/dist/esm`

        await measureRunningTime('ffmpeg-load', async () =>
          this.ffmpeg
            .load({
              coreURL: await toBlobURL(`${baseURL}/ffmpeg-core.js`, 'text/javascript'),
              wasmURL: await toBlobURL(`${baseURL}/ffmpeg-core.wasm`, 'application/wasm'),
              workerURL: supportsMultithreading
                ? await toBlobURL(`${baseURL}/ffmpeg-core.worker.js`, 'text/javascript')
                : undefined,
            })
            .catch(() => {
              // We expect ffpmeg to throw an error when reloading it after termination.
              // We don't care about this error as we terminated it ourserlves.
            }),
        )
        this.state = State.IDLE
        resolve()
      })
    }

    await this.loadFFMPEGPromise
    this.loadFFMPEGPromise = undefined
  }

  /** Loads a video segment into the FFMPEG file system memory. */
  public async loadSegmentInMemory(
    viewId: string,
    index: string,
    binaryData: Uint8Array,
    range: [number, number],
    segmentFileUrl: string,
  ): Promise<void> {
    if (this.state === State.INIT) {
      throw new Error('Frame Extractor: Trying to use FFMPEG without loading it first.')
    }

    let segmentToLoad: SegmentInMemory
    /**
     * it is important to target only segments for the current view, to ensure multi slots
     * work well
     **/
    const existingSegment = this.segmentsInMemory.find(
      (segment) => segment.index === index && segment.viewId === viewId,
    )
    if (existingSegment) {
      if (existingSegment.loaded || existingSegment.loadingPromise) {
        return
      }
      segmentToLoad = existingSegment
    } else {
      const newSegment: SegmentInMemory = {
        viewId,
        index,
        /** copy of the binary data to save it from being released from memory too early */
        binaryData: new Uint8Array(binaryData),
        segmentFileUrl: `${segmentFileUrl}`,
        start: range[0],
        end: range[1],
        loaded: false,
      }
      this.segmentsInMemory.push(newSegment)
      segmentToLoad = newSegment
    }

    // eslint-disable-next-line no-async-promise-executor
    segmentToLoad.loadingPromise = new Promise(async (resolve) => {
      try {
        /** find previously queued segments for the same view **/
        const previouslyQueuedSegments = this.segmentsInMemory.filter(
          (seg) => seg.index !== index && seg.loadingPromise && seg.viewId === viewId,
        )
        await this.loadFFMPEGPromise
        await Promise.all(previouslyQueuedSegments.map((seg) => seg.loadingPromise))
        this.state = State.LOADING
        await this.runFFmpegMethod(
          this.ffmpeg.writeFile,
          `${viewId}-${index}.ts`,
          new Uint8Array(binaryData),
        )

        const memorySegment = this.segmentsInMemory.find(
          (segment) => segment.index === index && segment.viewId === viewId,
        )
        if (memorySegment) {
          // Since the segment data is now on ffmpeg FS, we can remove it from the segments in
          // memory, to free space
          memorySegment.binaryData = new Uint8Array([])
        }
        segmentToLoad.loaded = true
        segmentToLoad.loadingPromise = undefined
        this.state = State.IDLE
        resolve()
      } catch (err: unknown) {
        segmentToLoad.loadingPromise = undefined
        this.handleFFMPEGError(err)
      }
    })

    await segmentToLoad.loadingPromise
  }

  /**
   * Flush segments withing the range, for the given view.
   * NOTE: We sync with HLS.js to flush the segments.
   */
  public async flushRange(start: number, end: number, viewId: string): Promise<void> {
    const res = []
    for (let i = 0; i < this.segmentsInMemory.length; i++) {
      const segment = this.segmentsInMemory[i]
      // This range was flushed by HLS, so we can dump the data on ffmpeg FS and memory for now,
      // it will be loaded again if the app navigates to that range again
      if (segment.viewId === viewId && segment.start >= start && segment.end <= end) {
        try {
          if (!(await this.findSegmentInFFmpeg(segment.viewId, segment.index))) {
            continue
          }
          await this.runFFmpegMethod(
            this.ffmpeg.deleteFile,
            `${segment.viewId}-${segment.index}.ts`,
          )
        } catch {
          // This is not a critical error and it's not preventing the frameExtractor from
          // working properly, plus it's on ffmpeg side, so we don't want to throw an error
          // and we just want to log it.
        }
        continue
      }
      res.push(segment)
    }
    this.segmentsInMemory = res
  }

  /** Hard-resets the class properties (except state) to initial values. */
  public reset(): void {
    this.segmentsInMemory = []
    this.resetFramesCache()
  }

  /**
   * To be called when switching the active file.
   * Cleans up any memory used up by the file's frames/segments and stops the running command.
   */
  public async cleanup(): Promise<void> {
    this.queue.clear()
    if (this.state === State.RUNNING) {
      this.ffmpeg.terminate()
      this.state = State.INIT
    } else if (this.segmentsInMemory.length > 0) {
      for (const segment of this.segmentsInMemory) {
        await this.runFFmpegMethod(this.ffmpeg.deleteFile, `${segment.viewId}-${segment.index}.ts`)
      }
    }
    this.reset()
    await this.loadFFMPEG()
  }

  private isFFmpegInternalError(errorMessage: string): boolean {
    return EXPECTED_FFMPEG_INTERNAL_ERRORS.some((error) => errorMessage.includes(error))
  }

  private handleFFMPEGError(err: FFMPEGError | unknown): void {
    let errorMessage: string
    if (typeof err === 'string') {
      errorMessage = err
    } else if (typeof err === 'object' && err !== null && 'message' in err) {
      errorMessage = (err as FFMPEGError).message
    } else {
      errorMessage = 'Unknown error'
    }

    this.extractPromise = undefined
    setContext('ffmpeg error', {
      error: errorMessage,
    })

    if (this.isFFmpegInternalError(errorMessage)) {
      // FFMpeg internal error, we don't want to throw an error here, just logging it
      return
    }
    // Unexpected error
    throw new Error('Frame Extractor ffmpeg error: ' + errorMessage)
  }

  /** helper to generate the segment file name given the segment index and the view id **/
  getViewSegmentFileName(viewId: string, segmentIndex: string): string {
    const segmentName = segmentIndex.padStart(SEGMENT_NAME_LENGTH, '0')
    return `${viewId}-${segmentName}.ts`
  }

  /** returns the file in FS for ffmpeg if available **/
  async findSegmentInFFmpeg(viewId: string, segmentIndex: string): Promise<FSNode | undefined> {
    await this.loadFFMPEGPromise
    const list = await this.ffmpeg.listDir('./')
    const fileName = this.getViewSegmentFileName(viewId, segmentIndex)
    return list.find((file) => file.name === fileName)
  }

  /**
   * Extracts a frame from a loaded video.
   *
   * __NOTE__: need to have called `loadSegmentInMemory` before calling this function.
   */
  public async extractFrame(
    viewId: string,
    frameNumber: number,
    segmentIndex: string,
    lossless = false,
    contextId?: string,
  ): Promise<string | undefined> {
    const segmentName = segmentIndex.padStart(SEGMENT_NAME_LENGTH, '0')

    // serve from cache when available
    const frameFileName = this.getFrameFileName(viewId, segmentIndex, frameNumber, lossless)
    if (this.framesCache.has(frameFileName)) {
      const url = this.framesCache.get(frameFileName)
      return url
    }

    /**
     * if the file is already in ffmpeg FF, then we don't need to wait for all other segments
     * to beloaded, as the extraction can run
     */
    const hasFileInFFMpeg = await this.findSegmentInFFmpeg(viewId, segmentName)
    if (!hasFileInFFMpeg) {
      await this.waitForSegmentLoad(segmentName, viewId, contextId)
    }

    if (this.state === State.LOADING) {
      await this.loadFFMPEGPromise
      const segment = this.segmentsInMemory.find(
        (segment) => segment.index === segmentName && segment.viewId === viewId,
      )
      await segment?.loadingPromise
    }

    // stop the running command early and reload FFmpeg
    // This ticket is to investigate if State.RUNNING can be removed completely
    // https://linear.app/v7labs/issue/DAR-3318/long-videos-cleanup-frame-extractor-state-logic
    // if (this.state === State.RUNNING) {
    //   await this.reload()
    // }

    // Add to the FFMpeg queue to serialise after any current task
    return await this.queue.enqueue(
      viewId,
      async (viewId, frameNumber, segmentIndex, lossles, contextId) => {
        try {
          this.state = State.RUNNING
          this.extractPromise = this._extractFrame(
            viewId,
            frameNumber,
            segmentIndex,
            lossless,
            contextId,
          )
          const url = await this.extractPromise
          this.state = State.IDLE
          this.extractPromise = undefined
          return url
        } catch (err) {
          this.handleFFMPEGError(err as FFMPEGError)
        }
      },
      viewId,
      frameNumber,
      segmentIndex,
      lossless,
      contextId,
    )
  }

  getFrameFileName(
    viewId: string,
    segmentIndex: string,
    frameNumber: number,
    lossless: boolean,
  ): string {
    const frameFileExt = lossless ? 'png' : 'jpg'
    return `frame-${lossless && 'hq'}-${viewId}-${frameNumber}-${segmentIndex}.${frameFileExt}`
  }

  private async _extractFrame(
    viewId: string,
    frameNumber: number,
    segmentIndex: string,
    lossless: boolean,
    contextId?: string,
  ): Promise<string | undefined> {
    const frameFileExt = lossless ? 'png' : 'jpg'
    const frameFileName = this.getFrameFileName(viewId, segmentIndex, frameNumber, lossless)

    // althogh the caller already checks the cache, it might have become available while waiting
    // for the queue, so worth checking again
    if (this.framesCache.has(frameFileName)) {
      const url = this.framesCache.get(frameFileName)
      return url
    }
    const args = [
      '-i', // input file
      this.getViewSegmentFileName(viewId, segmentIndex),
      '-threads', // number of threads to use
      `${MAX_THREADS}`,
      '-vf', // video filter
      `select='eq(n,${frameNumber})'`, // select the frame at the given frame number
      '-fps_mode', // means all frames in the input file are processed
      'passthrough',
      '-vframes', // number of frames to output
      '1',
      '-update', // update the output file as it is being written
      'true',
      '-y', // automatically overwrite any existing file
    ]
    if (!lossless) {
      // video quality scale, usually is 2-31 (lower is better)
      // some videos don't work with quality better than 8
      args.push('-qscale:v', '8')
    }
    args.push(frameFileName)

    await measureRunningTime(
      `${contextId} ffmpeg-extract`,
      async () => await this.runFFmpegMethod(this.ffmpeg.exec, args),
    )

    const frameData = await this.runFFmpegMethod(this.ffmpeg.readFile, frameFileName)

    const url = URL.createObjectURL(new Blob([frameData], { type: `image/${frameFileExt}` }))
    this.framesCache.set(frameFileName, url)
    await this.runFFmpegMethod(this.ffmpeg.deleteFile, frameFileName)
    return url
  }

  private async waitForSegmentLoad(
    segmentName: string,
    viewId: string,
    contextId?: string,
  ): Promise<void> {
    const existingSegment = this.segmentsInMemory.find(
      (segment) => segment.index === segmentName && segment.viewId === viewId,
    )

    if (existingSegment) {
      await existingSegment.loadingPromise
    } else {
      await new Promise<void>((resolve, reject) => {
        let timeout: number | undefined = undefined
        const onSegmentLoad = ({ viewId: segmentViewId }: ViewEvent, segment: Segment): void => {
          if (segment.index === segmentName && segmentViewId === viewId) {
            StreamViewEvents.segmentLoaded.off(onSegmentLoad)
            clearTimeout(timeout)
            resolve()
          }
        }

        StreamViewEvents.segmentLoaded.on(onSegmentLoad)
        timeout = window.setTimeout(() => {
          StreamViewEvents.segmentLoaded.off(onSegmentLoad)
          reject(
            new FrameExtractionError(-1, segmentName, 'segment load timed out', viewId, contextId),
          )
        }, SEGMENT_WAIT_TIMEOUT)
      })
    }
  }

  /**
   * Get information about a specific frame index, namely the segment file URL and the
   * index within that segment.
   *
   * @param frameNumber The index of the frame within the given segment
   * @param segmentIndex The index of the segment
   * @returns A frame spec containing the segment file URL and index within that segment
   */
  public async getFrameSpec(
    frameNumber: number,
    segmentIndex: string,
    viewId: string,
  ): Promise<{
    segmentFileUrl: string
    indexInSegment: number
  }> {
    if (this.state === State.LOADING) {
      await this.loadFFMPEGPromise
      await Promise.all(
        this.segmentsInMemory
          .filter((segment) => segment.viewId === viewId)
          .map((segment) => segment.loadingPromise),
      )
    }

    const segment = this.segmentsInMemory.find(
      (segment) =>
        segment.index === segmentIndex.padStart(segment.index.length, '0') &&
        segment.viewId === viewId,
    )
    if (!segment) {
      throw new Error("Frame Extractor: Trying to get frame spec from a segment that's not loaded.")
    }

    return {
      segmentFileUrl: segment.segmentFileUrl,
      indexInSegment: frameNumber,
    }
  }
}

/** Singleton that keeps a reference to FFMPEG and uses it to extract frames */
const frameExtractor = new FrameExtractor()

export default frameExtractor
