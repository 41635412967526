import type { ApiResponse, V2WorkflowPayload } from '@/store/types'
import { remove } from '@/backend/api'
import type { ParsedError } from '@/backend/error'
import { errorMessages, isErrorResponse, parseError } from '@/backend/error'

type Params = {
  workflowId: string
  teamSlug: string
}

type Response = Promise<ApiResponse<V2WorkflowPayload> | ParsedError>

/**
 * Sends a request to backend, to delete a v2 workflow
 */
export const deleteV2Workflow = async (params: Params): Response => {
  const { teamSlug, workflowId } = params
  const path = `v2/teams/${teamSlug}/workflows/${workflowId}`

  try {
    const response = await remove<V2WorkflowPayload>(path)
    return response
  } catch (error) {
    if (!isErrorResponse(error)) {
      throw error
    }
    return parseError(error, errorMessages.WORKFLOW_DELETE)
  }
}
