import { v4 } from 'uuid'

import { buildV2WorkflowStagePayload } from '@/store/modules/workflow/actions/buildV2WorkflowStagePayload'
import type { Payload as CreateEdgePayload } from '@/store/modules/workflow/mutations/CREATE_EDGE'
import type { UPDATE_STAGE_CONFIG } from '@/store/modules/workflow/mutations/UPDATE_STAGE_CONFIG'
import type { WorkflowAction } from '@/store/modules/workflow/types'
import type { StoreMutationPayload } from '@/store/types'
import { StageType } from '@/store/types/StageType'
import type {
  BaseStageConfig,
  V2AnnotateStagePayload,
  V2ConsensusStagePayload,
  V2ConsensusTestStagePayload,
  V2WorkflowStagePayload,
} from '@/store/types/V2WorkflowStagePayload'
import { appendNumber } from '@/core/utils/string'

type StageTypes = typeof StageType.Annotate | typeof StageType.Model

export type Payload = {
  consensusId: string
  type: StageTypes
} & BaseStageConfig

/** Balys's ASCII art explains what we are doing here when adding a parallel stage:
 *
 *               ____________________________________________________________________________
 *              |   /---parallel--->"Annotate st"----default---\                 /---fail---|-->
 *"Consensus"-->|--/                                            \---"Test st"---/           |
 *              |  \---parallel--->"Model st"-------default----/                \----pass---|-->
 *              |___________________________________________________________________________|
 */
export const createConsensusChildStage: WorkflowAction<Payload, V2WorkflowStagePayload> = (
  { commit, state },
  { consensusId, type },
) => {
  const childStageId = v4()
  const typeToDefaultNameMap = {
    [StageType.Annotate]: 'Annotate',
    [StageType.Model]: 'Model',
  }

  const consensusStage: V2ConsensusStagePayload | undefined = state.editedWorkflow?.stages.find(
    (s): s is V2ConsensusStagePayload => s.id === consensusId,
  )
  if (!consensusStage) {
    throw new Error('Consensus stage not found')
  }
  const testStage = state.editedWorkflow?.stages.find(
    (s): s is V2ConsensusTestStagePayload => s.id === consensusStage.config.test_stage_id,
  )
  if (!testStage) {
    throw new Error("Consensus' Test stage not found")
  }

  const childStage = buildV2WorkflowStagePayload({
    id: childStageId,
    type,
    config: {
      x: consensusStage.config.x + 10,
      y: consensusStage.config.y + 10,
    },
    edges: [],
    name: appendNumber(
      typeToDefaultNameMap[type],
      state.editedWorkflow?.stages.filter((s) => s.type === type).map((s) => s.name) ?? [],
    ),
  })

  // need to copy selected set of classes from peer parallel Annotate stages
  // to the new parallel Annotate stage
  if (childStage.type === StageType.Annotate) {
    const parallelAnnotateStage = consensusStage.config.parallel_stage_ids
      .map((id) => state.editedWorkflow?.stages.find((s) => s.id === id))
      .find((s): s is V2AnnotateStagePayload => s?.type === StageType.Annotate)

    if (parallelAnnotateStage) {
      childStage.config.allowed_class_ids = parallelAnnotateStage.config.allowed_class_ids?.slice()
    }
  }

  const testHasValidChampionSet = consensusStage.config.parallel_stage_ids.includes(
    testStage.config.champion_stage_id ?? '',
  )

  const payload: StoreMutationPayload<typeof UPDATE_STAGE_CONFIG> = {
    stageId: consensusStage.id,
    config: {
      parallel_stage_ids: [...consensusStage.config.parallel_stage_ids, childStageId],
    },
  }

  if (!testHasValidChampionSet) {
    commit('UPDATE_STAGE_CONFIG', {
      stageId: testStage.id,
      config: {
        champion_stage_id: childStageId,
      },
    })
  }

  if (childStage.type === StageType.Annotate) {
    childStage.assignable_users = []
    childStage.config.assignable_to = 'anyone'
  }

  commit('UPDATE_STAGE_CONFIG', payload)

  commit('CREATE_EDGE', <CreateEdgePayload>{
    sourceStageId: consensusId,
    targetStageId: childStageId,
    stageId: consensusId,
    name: 'parallel',
  })

  // order of the following two commits is important
  commit('CREATE_STAGE', childStage)
  commit('CREATE_EDGE', <CreateEdgePayload>{
    sourceStageId: childStageId,
    targetStageId: testStage.id,
    stageId: childStageId,
    name: 'default',
  })
}
