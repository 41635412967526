import type { MutationTree } from 'vuex'

import type { V2Workflows } from '@/store/types/V2WorkflowPayload'

import { CLONE_WORKFLOW_TO_EDITED } from './CLONE_WORKFLOW_TO_EDITED'
import { CREATE_CONSENSUS_STAGE } from './CREATE_CONSENSUS_STAGE'
import { CREATE_EDGE } from './CREATE_EDGE'
import { CREATE_STAGE } from './CREATE_STAGE'
import { INITIALIZE_WORKFLOW } from './INITIALIZE_WORKFLOW'
import { PUSH_WEBHOOK_RESPONSE } from './PUSH_WEBHOOK_RESPONSE'
import { REMOVE_EDGE } from './REMOVE_EDGE'
import { REMOVE_STAGE } from './REMOVE_STAGE'
import { REMOVE_WORKFLOW } from './REMOVE_WORKFLOW'
import { RENAME_STAGE } from './RENAME_STAGE'
import { SET_EDITED_WORKFLOW } from './SET_EDITED_WORKFLOW'
import { SET_EDITED_WORKFLOW_NAME } from './SET_EDITED_WORKFLOW_NAME'
import { SET_WEBHOOK_RESPONSES } from './SET_WEBHOOK_RESPONSES'
import { SET_WORKFLOW } from './SET_WORKFLOW'
import { SET_WORKFLOWS } from './SET_WORKFLOWS'
import { SET_WORKFLOWS_LOADING } from './SET_WORKFLOWS_LOADING'
import { SET_WORKFLOW_ASSIGNED_ITEMS } from './SET_WORKFLOW_ASSIGNED_ITEMS'
import { UNPLUG_STAGE } from './UNPLUG_STAGE'
import { UPDATE_ASSIGNABLE_USERS } from './UPDATE_ASSIGNABLE_USERS'
import { UPDATE_CONSENSUS_TEST_CLASS_IOU } from './UPDATE_CONSENSUS_TEST_CLASS_IOU'
import { UPDATE_CONSENSUS_TEST_TYPE_IOU } from './UPDATE_CONSENSUS_TEST_TYPE_IOU'
import { UPDATE_EDGE } from './UPDATE_EDGE'
import { UPDATE_STAGE_CONFIG } from './UPDATE_STAGE_CONFIG'

export const mutations: MutationTree<V2Workflows> = {
  CLONE_WORKFLOW_TO_EDITED,
  CREATE_CONSENSUS_STAGE,
  CREATE_EDGE,
  CREATE_STAGE,
  INITIALIZE_WORKFLOW,
  PUSH_WEBHOOK_RESPONSE,
  REMOVE_EDGE,
  REMOVE_STAGE,
  REMOVE_WORKFLOW,
  RENAME_STAGE,
  SET_EDITED_WORKFLOW,
  SET_EDITED_WORKFLOW_NAME,
  SET_WEBHOOK_RESPONSES,
  SET_WORKFLOW,
  SET_WORKFLOWS,
  SET_WORKFLOWS_LOADING,
  SET_WORKFLOW_ASSIGNED_ITEMS,
  UNPLUG_STAGE,
  UPDATE_ASSIGNABLE_USERS,
  UPDATE_CONSENSUS_TEST_CLASS_IOU,
  UPDATE_CONSENSUS_TEST_TYPE_IOU,
  UPDATE_EDGE,
  UPDATE_STAGE_CONFIG,
}
