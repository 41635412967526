import type { WorkflowMutation } from '@/store/modules/workflow/types'
import type { WebhookTestPayload } from '@/store/types'

type Payload = WebhookTestPayload

/**
 * Set webhook responses from api response
 *
 * @param state Current V2 workflows state (mutable).
 * @param webhookResponse Webhook responses payload returned by the API.
 */
export const SET_WEBHOOK_RESPONSES: WorkflowMutation<Payload> = (state, webhookResponse) => {
  state.webhookSuccessResponses = webhookResponse.succeeded || []
  state.webhookFailResponses = webhookResponse.failed || []
}
