import { ModelType } from '@/core/annotations'
import type { Editor } from '@/modules/Editor/editor'
import type { AutoAnnotateModel } from '@/modules/Editor/types'
// eslint-disable-next-line boundaries/element-types
import type { ClassMapping } from '@/modules/Workview/classMappingTypes'

export class AutoAnnotateManager {
  private editor: Editor

  constructor(editor: Editor) {
    this.editor = editor
  }

  get autoAnnotateModels(): AutoAnnotateModel[] {
    return this.editor.autoAnnotateModels
  }

  public get preselectedAutoAnnotateModel(): AutoAnnotateModel | null {
    // Here we mutate in the getter if it is not initialised. We should
    // find a cleaner way to do this.
    // https://linear.app/v7labs/issue/ANN-460/harden-auto-annotate-preselect
    let { preselectedModelId: id } = this.editor.store.state.workview
    const { autoAnnotateModels } = this

    if (id === null) {
      // Preselect the id and optimisatically get the value to return
      id = this.setPreselectedAutoAnnotateModel()
    }

    return autoAnnotateModels.find((m) => m.id === id) || null
  }

  public get clickerEpsilon(): number {
    return this.editor.store.state.workview.clickerEpsilon
  }

  /**
   * Automatically preselects a neural model if one isn't already selected.
   *
   * If a model of type `ModelType.AutoAnnotation` exists, select that, otherwise
   * select the first model among the available ones.
   */
  private setPreselectedAutoAnnotateModel(): string | null {
    const { preselectedModelId } = this.editor.store.state.workview
    if (preselectedModelId) {
      return preselectedModelId
    }

    const { autoAnnotateModels } = this
    if (autoAnnotateModels.length === 0) {
      return null
    }

    const { trainedModels } = this.editor.store.state.neuralModel
    const autoAnnotate = autoAnnotateModels.find((autoAnnotateModel) => {
      const trainedModel = trainedModels.find((tm) => tm.id === autoAnnotateModel.id)
      if (!trainedModel) {
        return false
      }
      return trainedModel.model_template.type === ModelType.AUTO_ANNOTATE
    })

    const preselectedModel = autoAnnotate || autoAnnotateModels[0]
    this.editor.store.commit('workview/SET_CURRENT_TOOL_PRESELECTED_MODEL_ID', preselectedModel.id)

    return preselectedModel.id
  }

  get preselectedModelClassMapping(): ClassMapping {
    const { preselectedAutoAnnotateModel } = this
    if (!preselectedAutoAnnotateModel) {
      return []
    }

    const autoAnnotateClassMapping = this.editor.store.state.workview.classMapping
    if (!(preselectedAutoAnnotateModel.id in autoAnnotateClassMapping)) {
      return []
    }

    return autoAnnotateClassMapping[preselectedAutoAnnotateModel.id]
  }
}
