import { EventEmitter } from 'events'
import clamp from 'lodash/clamp'
import cloneDeep from 'lodash/cloneDeep'
import isEmpty from 'lodash/isEmpty'

import { mark } from '@/performance/mark'
import { ANNOTATION_CREATE_FUNCTION_CALL } from '@/performance/keys'
import type { AnnotationType, MainAnnotationType, SubAnnotationType } from '@/core/annotationTypes'
import { getMainAnnotationType, getSubAnnotationTypes } from '@/core/annotationTypes'
import type { Optional, PartialRecord } from '@/core/helperTypes'
import { getAnnotationClassColor, type AnnotationClass } from '@/modules/Editor/AnnotationClass'
import type {
  AnnotationData,
  AutoAnnotateData,
  SubAnnotationData,
} from '@/modules/Editor/AnnotationData'
import type { VideoAnnotationData } from '@/modules/Editor/AnnotationData'
import { isEye } from '@/modules/Editor/annotationTypes/eye'
import { isSkeleton } from '@/modules/Editor/annotationTypes/skeleton'
import type { ViewEvent } from '@/modules/Editor/eventBus'
import { AnnotationManagerEvents, ClassEvents, EditorExceptions } from '@/modules/Editor/eventBus'
import { getPreviousFrameIndex } from '@/modules/Editor/getPreviousFrameIndex'
import { hasSegmentContainingIndex } from '@/modules/Editor/helpers/segments'
import type { EditablePoint, IPoint } from '@/modules/Editor/point'
import { isPointOnPath } from '@/modules/Editor/point'
import { pointIsVertexOfPath } from '@/modules/Editor/point'
import { autoAnnotateSerializer } from '@/modules/Editor/serialization/autoAnnotateSerializer'
import { instanceIdSerializer } from '@/modules/Editor/serialization/instanceIdSerializer'
import { measuresSerializer } from '@/modules/Editor/serialization/measuresSerializer'
import { ToolName } from '@/modules/Editor/tools/types'
import { frameDataIsLoaded } from '@/modules/Editor/utils/pagination'
import { updateAnnotationData } from '@/modules/Editor/actions'
import { addAnnotationsAction } from '@/modules/Editor/actions/addAnnotationsAction'
import { AnnotationCreationError } from '@/modules/Editor/errors'
import { getAllAnnotationVertices } from '@/modules/Editor/getAllAnnotationVertices'
import { getAnnotationCompoundPathAtFrame } from '@/modules/Editor/getAnnotationCompoundPath'
import type { Annotation } from '@/modules/Editor/models/annotation/Annotation'
import type { CreateAnnotationParams } from '@/modules/Editor/models/annotation/annotationFactories'
import type { ReturnType as ParsedData } from '@/modules/Editor/models/layers/optimisedLayer/dataParser/parseAnnotationsData'
import {
  createAnnotationFromInstanceParams,
  createMaskAnnotationOrAnnotationFromDeserializable,
  createSubAnnotation,
} from '@/modules/Editor/models/annotation/annotationFactories'
import {
  isVideoAnnotation,
  isImageAnnotation,
  isVideoSubAnnotations,
} from '@/modules/Editor/models/annotation/annotationKindValidator'
import {
  clearPath2DCache,
  getPath2D,
  clearAnnotationRenderingCache,
} from '@/modules/Editor/models/annotation/annotationRenderingCache'
import { annotationToRenderableItem } from '@/modules/Editor/models/annotation/annotationToRenderableItem'
import { buildInitialVideoAnnotationSegments } from '@/modules/Editor/models/annotation/buildInitialVideoAnnotationSegments'
import { shallowCloneAnnotation } from '@/modules/Editor/models/annotation/cloneAnnotation'
import { inferVideoData } from '@/modules/Editor/models/annotation/inferVideoData'
import type {
  VideoSubAnnotations,
  ImageAnnotation,
  ImageSubAnnotation,
  VideoAnnotation,
} from '@/modules/Editor/models/annotation/types'
import type { RenderableItem } from '@/modules/Editor/models/layers/object2D'
import { translateVertex, translatePath } from '@/modules/Editor/plugins/edit/utils'
import translateEyeVertex from '@/modules/Editor/plugins/eye/utils/translateVertex'
import { getEdgesAsPaths } from '@/modules/Editor/plugins/skeleton/getEdgesAsPaths'
import { serializeAnnotation } from '@/modules/Editor/serialization/annotationSerializer'
import type { UpdateMeta, UpdatedAnnotation } from '@/modules/Editor/serialization/types'
import type { VideoSubAnnotationDataPayload } from '@/modules/Editor/types'
import { isAutotrackFrame } from '@/modules/Editor/utils/autotrackUtils'
import { getFramesWithData } from '@/modules/Editor/utils/pagination'
import type { View } from '@/modules/Editor/views/view'
// eslint-disable-next-line boundaries/element-types
import { useV2AnnotationsStore } from '@/modules/Workview/useV2AnnotationsStore'
import { addBreadcrumb, setContext } from '@/services/sentry'
// eslint-disable-next-line boundaries/element-types
import { rgbaString } from '@/uiKit/colorPalette'
import { isAnnotationOutOfView } from '@/modules/Editor/utils/outOfViewUtils'

export enum Events {
  ANNOTATIONS_CHANGED = 'annotations:changed',
  ANNOTATION_REORDER = 'annotation:reorder',
  ANNOTATION_HIGHLIGHT = 'annotation:highlight',
  ANNOTATION_UNHIGHLIGHT = 'annotation:unhighlight',
  ANNOTATION_UNHIGHLIGHTALL = 'annotation:unhighlightAll',
}

export class AnnotationManager extends EventEmitter {
  private view: View

  private readonly viewEvent: ViewEvent

  private selectedAnnotationIds: Annotation['id'][] = []
  private _highlightedAnnotationIds: Annotation['id'][] = []

  public inferenceData: Annotation[] = []
  private annotationMoving: Annotation | undefined
  private initialAnnotationData: AnnotationData | VideoAnnotationData | undefined
  // stores class ids of classes that are included in the SSC filter
  //
  // read

  // https://www.notion.so/v7labs/Stage-Specific-Classes-Technical-e3f4ec044e6448a99cff5f5e118b14d8?pvs=4
  // for more info on Stage Specific Classes
  //
  // if a class is present in the filter a user can perform CRUD operations on it
  // if a class is absent that class' existing annotations are in read-only mode
  // if the entire filter is empty then the default behavior is to
  // allow CRUD operations on all classes
  private classFilterClassIds = new Set<number>()

  /**
   * Current image annotations
   *
   * Include both tag and non-tag annotations.
   *
   * Normalized from stage or master annotations, ready to be rendered
   */
  annotationsMap: PartialRecord<string, Annotation> = {}

  annotationsReadonlyMap: Record<string, boolean> = {}

  annotationsLockedMap: Set<string> = new Set()

  /**
   * Keeps annotation ids as an Array to support reactivity
   *
   * Should always be sorted in descending order of z_index, with nulls (tags) last
   */
  annotationsIds: Annotation['id'][] = []

  /** Defines the frame that annotations are set for */
  private _frameIndex: number | null = null
  public get frameIndex(): number | null {
    return this._frameIndex
  }

  constructor(view: View) {
    super()
    this.view = view

    this.viewEvent = { viewId: this.view.id, slotName: this.view.name }
  }

  private memoGetAnnotations: { key: string; result: Annotation[] } = {
    key: '',
    result: [],
  }

  // START: Select annotation

  public isSelected(annotationId: Annotation['id']): boolean {
    return this.selectedAnnotationIds.includes(annotationId)
  }

  public isReadonly(annotationId?: Annotation['id']): boolean {
    if (annotationId === undefined) {
      return false
    }
    return this.annotationsReadonlyMap[annotationId]
  }

  public selectAnnotation(annotationId: Annotation['id']): void {
    if (this.isSelected(annotationId)) {
      return
    }
    if (this.annotationsLockedMap.has(annotationId)) {
      return
    }

    this.deselectAllAnnotations()

    const annotation = this.getAnnotation(annotationId)
    if (!annotation) {
      return
    }

    if (!this.isClassCompatibleWithClassFilter(annotation.classId)) {
      return
    }

    this.initialAnnotationData = undefined
    this.selectedAnnotationIds.push(annotation.id)
    this._selectedVertexIndex = null
    this.clearMemo()

    this.view.annotationsLayer.activate(annotation.id, { isSelected: true })
    AnnotationManagerEvents.annotationSelect.emit(this.viewEvent, annotationId)
  }

  public deselectAnnotation(annotationId: string): void {
    if (!this.isSelected(annotationId)) {
      return
    }

    const annotation = this.getAnnotation(annotationId)

    if (!annotation) {
      return
    }

    this.deselectVertex()

    const index = this.selectedAnnotationIds.indexOf(annotationId)
    if (index !== -1) {
      this.selectedAnnotationIds.splice(index, 1)
    }

    this.clearMemo()

    this.view.annotationsLayer.deactivate(annotationId)
    AnnotationManagerEvents.annotationDeselect.emit(this.viewEvent, annotationId)
  }

  public toggleSelectAnnotation(annotationId: Annotation['id']): void {
    const annotation = this.getAnnotation(annotationId)

    if (!annotation) {
      return
    }

    if (this.isSelected(annotationId)) {
      this.deselectAnnotation(annotationId)
    } else {
      this.selectAnnotation(annotationId)
    }
  }

  /**
   * Deselect all annotations.
   */
  public cachedDeselectAllAnnotations(): void {
    this.selectedAnnotationIds.forEach((id) => {
      const annotation = this.getAnnotation(id)
      if (!annotation) {
        return
      }

      this.deselectVertex()
      this.view.annotationsLayer.deactivate(annotation.id)
    })

    this.selectedAnnotationIds = []
    AnnotationManagerEvents.annotationDeselectAll.emit(this.viewEvent)
  }

  /**
   * Deselect all annotations and clear all memos.
   */
  public deselectAllAnnotations(): void {
    this.cachedDeselectAllAnnotations()
    this.clearMemo()
  }

  // END: Select annotation

  // START: Highlight annotation

  public isHighlighted(annotationId: string): boolean {
    return this._highlightedAnnotationIds.includes(annotationId)
  }

  public highlightAnnotation(annotationId: string): void {
    this.unhighlightAllAnnotations()

    const annotation = this.getAnnotation(annotationId)
    if (!annotation) {
      return
    }

    if (!this.isClassCompatibleWithClassFilter(annotation.classId)) {
      return
    }

    if (this.isLocked(annotationId)) {
      return
    }

    this._highlightedAnnotationIds.push(annotation.id)
    this.clearMemo()

    this.view.annotationsLayer.activate(annotationId, {
      isHighlighted: true,
      isSelected: this.isSelected(annotation.id),
    })
    this.emit(Events.ANNOTATION_HIGHLIGHT, annotationId)
    AnnotationManagerEvents.annotationHighlight.emit(this.viewEvent, annotationId)
  }

  public unhighlightAnnotation(annotationId: Annotation['id']): void {
    this.unhighlightVertex()

    const annotation = this.getAnnotation(annotationId)

    if (!annotation) {
      return
    }

    const index = this._highlightedAnnotationIds.indexOf(annotationId)
    this._highlightedAnnotationIds.splice(index, 1)
    this.clearMemo()

    if (!this.isSelected(annotationId)) {
      this.view.annotationsLayer.deactivate(annotationId)
    }
    this.emit(Events.ANNOTATION_UNHIGHLIGHT, annotationId)
    AnnotationManagerEvents.annotationUnhighlight.emit(this.viewEvent, annotationId)
  }

  /**
   * Unhighlight all annotations.
   */
  public cachedUnhighlightAllAnnotations(): void {
    this.unhighlightVertex()

    this._highlightedAnnotationIds.forEach((id) => {
      const annotation = this.getAnnotation(id)
      if (!annotation) {
        return
      }

      if (!this.isSelected(id)) {
        this.view.annotationsLayer.deactivate(annotation.id)
      }
    })

    this._highlightedAnnotationIds = []
    this.emit(Events.ANNOTATION_UNHIGHLIGHTALL)
    AnnotationManagerEvents.annotationUnhighlightAll.emit(this.viewEvent)
  }

  /**
   * Unhighlight all annotations and clear all memos.
   */
  public unhighlightAllAnnotations(): void {
    this.cachedUnhighlightAllAnnotations()
    this.clearMemo()
  }

  // END: Highlight annotation

  /**
   * Returns sorted by zIndex Annotations array
   */
  get annotations(): Annotation[] {
    const stringifyArr = `${JSON.stringify(this.annotationsIds)}_${this.view.name}`

    if (!stringifyArr || stringifyArr !== this.memoGetAnnotations.key) {
      this.memoGetAnnotations.key = stringifyArr
      this.memoGetAnnotations.result = this.annotationsIds.map(
        (id) => this.getAnnotation(id) as Annotation,
      )
    }

    return this.memoGetAnnotations.result
  }

  private memoGetFrameAnnotations: { key: string; result: Annotation[] } = {
    key: '',
    result: [],
  }

  /**
   * Returns sorted by zIndex Annotations array consistent with the active frames
   */
  get frameAnnotations(): Annotation[] {
    if (!this.view.fileManager.isProcessedAsVideo) {
      return this.annotations
    }

    const stringifyArr = `${JSON.stringify(this.annotationsIds)}_${this.view.name}_${this.view.currentFrameIndex}`
    if (!stringifyArr || stringifyArr !== this.memoGetFrameAnnotations.key) {
      this.memoGetFrameAnnotations.key = stringifyArr
      this.memoGetFrameAnnotations.result = this.annotations.filter(
        ({ data }: Annotation) =>
          'segments' in data &&
          hasSegmentContainingIndex(data?.segments, this.view.currentFrameIndex),
      )
    }

    return this.memoGetFrameAnnotations.result
  }

  public hasAnnotation(id: Annotation['id']): boolean {
    return this.annotationsIds.includes(id)
  }

  public getAnnotation(id: Annotation['id']): Annotation | undefined {
    return this.annotationsMap[id]
  }

  // START: Annotation create

  private _unshiftAnnotation(payload: Annotation): void {
    if (this.annotationsIds.includes(payload.id)) {
      return
    }
    this.annotationsIds.unshift(payload.id)
    this.annotationsMap[payload.id] = payload
    this.clearMemo()
  }

  private _pushAnnotation(payload: Annotation): void {
    if (this.annotationsIds.includes(payload.id)) {
      return
    }
    this.annotationsIds.push(payload.id)
    this.annotationsMap[payload.id] = payload
    this.clearMemo()
  }

  public pushAnnotation(payload: Annotation): void {
    this._pushAnnotation(payload)
    AnnotationManagerEvents.annotationPushed.emit(this.viewEvent, payload)
  }

  public duplicateAnnotation({
    sourceAnnotationId,
    newAnnotation,
  }: {
    sourceAnnotationId: string
    newAnnotation: Annotation
  }): Annotation {
    this._unshiftAnnotation(newAnnotation)
    AnnotationManagerEvents.annotationDuplicate.emit(this.viewEvent, {
      sourceAnnotationId,
      newAnnotation,
    })

    return newAnnotation
  }

  /**
   * Called from the addAnnotationAction
   * Handle annotation creation
   */
  public createAnnotation<T extends VideoAnnotation | Annotation>(annotation: T): T {
    mark(ANNOTATION_CREATE_FUNCTION_CALL)
    if (this.hasAnnotation(annotation.id)) {
      this.emitError()
      throw new AnnotationCreationError(`Annotation with id ${annotation.id} already exists!`)
    }

    // createAnnotation is called when restoring a deleted annotation. When using paginated
    // annotations we might not have all frame data for the annotation, so we should only
    // serialise the frames that have data.
    const framesToSerialize =
      this.view.editor.featureFlags.ANNOTATIONS_PAGINATION && isVideoAnnotation(annotation)
        ? getFramesWithData(annotation.data.frames)
        : undefined

    // Because of bad Annotation data type
    // we need to serialize and create new Annotation
    const payload = serializeAnnotation(
      annotation,
      {
        slotName: this.view.name,
        isProcessedAsVideo: this.view.fileManager.isProcessedAsVideo,
        videoAnnotationDuration: this.view.editor.videoAnnotationDuration,
        frameIndex: this.view.currentFrameIndex,
        totalFrames: this.view.totalFrames,
      },
      { framesToSerialize },
    )

    if (payload === null) {
      throw new Error('Failure during annotation serialization')
    }

    const newAnnotation = createMaskAnnotationOrAnnotationFromDeserializable(
      this.view,
      annotation.annotationClass,
      payload,
    )

    if (!newAnnotation) {
      this.emitError()
      throw new AnnotationCreationError('Failed to create annotation from deserializable')
    }

    this._unshiftAnnotation(newAnnotation)
    AnnotationManagerEvents.annotationCreate.emit(this.viewEvent, newAnnotation)

    return annotation
  }

  /**
   * Multiple annotations creation.
   * using Annotation instance as a parameter to create new annotation.
   */
  public async createAnnotations(annotationsList: Annotation[]): Promise<Annotation[]> {
    const annotations = []
    for (let i = 0; i < annotationsList.length; i++) {
      const params = annotationsList[i]

      const annotation = await this.prepareAnnotationForCreation(params)
      if (!annotation) {
        return []
      }

      annotations.push(annotation)
    }

    const actor = this.view.actionManager
    await actor.do(addAnnotationsAction(this.view, annotations))

    return annotations
  }

  // END: Annotation create

  // START: Annotation delete

  private _deleteAnnotation(annId: string): void {
    const idx = this.annotationsIds.indexOf(annId)
    this.annotationsIds.splice(idx, 1)
    delete this.annotationsMap[annId]
    this.clearMemo()
  }

  /**
   * Check if the passed annotation is in readonly state
   * If it does, emot an exception
   * @param annotation
   * @returns
   */
  private isAnnotationReadonly(annotation: Annotation): boolean {
    const result = !!this.annotationsReadonlyMap[annotation?.id]

    if (result) {
      EditorExceptions.cannotUpdateReadonlyAnnotation.emit({
        annotationId: annotation.id,
        action: 'delete',
      })
    }

    return result
  }

  /**
   * Delete annotation by id from the manager
   */
  public deleteAnnotation(annotationId: string): Annotation | null {
    const annotation = this.getAnnotation(annotationId)
    if (!annotation) {
      return null
    }

    if (this.isAnnotationReadonly(annotation)) {
      return null
    }

    this.deselectAnnotation(annotationId)
    this.unhighlightAnnotation(annotationId)

    this._deleteAnnotation(annotation.id)
    AnnotationManagerEvents.annotationDelete.emit(this.viewEvent, annotationId)

    this.resetState()

    return annotation
  }

  public deleteAnnotations(annotationIds: string[]): void {
    const nonReadonlyAnnotations: Annotation[] = []
    const nonReadonlyAnnotationsIdx: string[] = []

    annotationIds.forEach((id: string) => {
      const annotation = this.getAnnotation(id)
      if (!annotation) {
        return null
      }

      if (this.isAnnotationReadonly(annotation)) {
        return
      }

      this._deleteAnnotation(annotation.id)
      nonReadonlyAnnotations.push(annotation)
      nonReadonlyAnnotationsIdx.push(annotation.id)
    })

    if (nonReadonlyAnnotationsIdx.length === 0) {
      return
    }

    AnnotationManagerEvents.annotationsDelete.emit(this.viewEvent, nonReadonlyAnnotationsIdx)

    this.resetState()
  }

  // END: Annotation delete

  // START Annotation update

  private _setAnnotation(payload: Annotation): void {
    clearAnnotationRenderingCache(payload.id)
    if (!this.annotationsIds.includes(payload.id)) {
      this.annotationsIds.push(payload.id)
    }
    this.annotationsMap[payload.id] = payload

    this.clearMemo()
  }

  /**
   * Method triggers the update for annotation to update zIndex property.
   * And emits an event of reorder with moved annotations and direction of the movement.
   * @param annotationToReorder
   * @param referenceAnnotation
   * @param direction
   */
  public reorderAnnotation(
    toReorder: { id: string },
    reference: { id: string },
    /** needed to determine if you're moving `annotationToReorder`
     * "above" or "below" `referenceAnnotation` */
    direction: 'up' | 'down',
  ): void {
    // This event triggers useLinkAnnotationState
    // to call the annotationManager.setAnnotations method
    this.emit(Events.ANNOTATION_REORDER, toReorder, reference, direction)
    AnnotationManagerEvents.annotationReorder.emit(this.viewEvent, {
      toReorder,
      reference,
      direction,
    })
  }

  /**
   * Called when current annotation update must be persisted
   *
   * IMPORTANT!
   * Accepts updateMeta as second arguments which contains lists of indices of modified frames.
   * You must ALWAYS pass index of video annotation frame or sub_frame in updateMeta if it is
   * created, updated or deleted. Otherwise changes will no be persisted on the BE.
   */
  public updateAnnotation(annotation: Annotation, updateMeta?: UpdateMeta): Annotation | null {
    const isUpdatingKeyframe =
      updateMeta?.updatedFramesIndices && updateMeta.updatedFramesIndices.length > 0

    const oldAnnotation = this.getAnnotation(annotation.id)

    const cannotUpdateBecauseReadonly =
      this.isReadonly(oldAnnotation?.id) && (isUpdatingKeyframe || updateMeta?.hasChangedLength)
    if (cannotUpdateBecauseReadonly) {
      EditorExceptions.cannotUpdateReadonlyAnnotation.emit({
        annotationId: annotation.id,
        action: 'update',
      })
      return null
    }

    addBreadcrumb({ message: 'AnnotationManager updateAnnotation', data: { annotation } })

    if (this.hasAnnotation(annotation.id)) {
      if (!oldAnnotation) {
        return null
      }

      if (isVideoAnnotation(annotation)) {
        // If any of the updated frames were created by a backend model, then
        // delete the inference data for that frame so that they are now marked
        // as user-created frames
        updateMeta?.updatedFramesIndices?.forEach((frameIndex) => {
          const frame = annotation.data.frames[frameIndex]
          if (frame && isAutotrackFrame(frame)) {
            delete frame.inference
          }
        })
      }

      this._setAnnotation(shallowCloneAnnotation(annotation))
      const updatedAnnotation: UpdatedAnnotation = { annotation, updateMeta }
      AnnotationManagerEvents.annotationUpdate.emit(this.viewEvent, updatedAnnotation)
    } else {
      this._pushAnnotation(annotation)
      AnnotationManagerEvents.annotationCreate.emit(this.viewEvent, annotation)
    }

    this.resetState()

    return annotation
  }

  // END: Annotation update

  private _annotationsParsedData: ParsedData | null = null
  public get annotationsParsedData(): ParsedData | null {
    return this._annotationsParsedData
  }

  public setParsedData(parsedData: ParsedData): void {
    this._annotationsParsedData = {
      itemsMap: parsedData.itemsMap,
      zIndexesList: parsedData.zIndexesList,
      itemsBBoxMap: parsedData.itemsBBoxMap,
      rTreeItems: parsedData.rTreeItems,
    }

    AnnotationManagerEvents.setParsedData.emit(this.viewEvent)
  }

  /**
   * Completely replaces annotations handled by annotation manager
   *
   * This should be called when annotations on the outside change as a whole
   * The annotations passed into this function should be pre-sorted by render order
   *
   * NOTE: Long term, this should be receiving a list of editor,
   * rather than store annotations, to enfore the boundary between editor
   * and surrounding code
   */
  public setAnnotations(
    sortedAnnotationIds: string[],
    annotationsMap: PartialRecord<string, Annotation>,
    frameIndex: number,
  ): void {
    this.resetState()
    this.annotationsIds = sortedAnnotationIds
    this.annotationsMap = annotationsMap
    this.annotationsReadonlyMap = {}
    this._frameIndex = frameIndex

    this.clearMemo()

    AnnotationManagerEvents.annotationsSet.emit(this.viewEvent, this.annotationsIds)
  }

  /**
   * Completely replaces a single annotation. This should be called when a change to
   * an annotation is triggered from outside of the store, e.g. when an auto-track
   * model generates keyframes for an annotation.
   */
  public setAnnotation(annotation: Annotation): void {
    this._setAnnotation(annotation)

    AnnotationManagerEvents.annotationSet.emit(this.viewEvent, { annotation })
  }

  /** Set a single annotation as being readonly/editable */
  public setReadonly(annotationId: string, readonly: boolean): void {
    this.annotationsReadonlyMap[annotationId] = readonly
    AnnotationManagerEvents.annotationReadonlyChange.emit(this.viewEvent, {
      annotationId,
      readonly,
    })
  }

  /**
   * Set a single annotation as locked.
   * When locked, the annotation can't be selected so you can't perform any action on it
   */
  public setLocked(annotationId: string, locked: boolean): void {
    if (!locked) {
      this.annotationsLockedMap.delete(annotationId)
    } else {
      this.annotationsLockedMap.add(annotationId)
    }
  }

  public isLocked(annotationId: string): boolean {
    return this.annotationsLockedMap.has(annotationId)
  }

  // START: Annotation Visibility

  private hiddenAnnotationIds: Set<Annotation['id']> = new Set()

  public setHiddenAnnotations(ids: Annotation['id'][]): void {
    this.hiddenAnnotationIds = new Set(ids)
    AnnotationManagerEvents.setHiddenAnnotations.emit(this.viewEvent, ids)
  }

  public isHidden(annotationId: Annotation['id'], frameIndex?: number): boolean {
    if (this.hiddenAnnotationIds.has(annotationId)) {
      return true
    }

    const annotation = this.getAnnotation(annotationId)
    // if the annotation is NOT in memory then we'll consider it to be NOT hidden
    // as conceptually it's not there in the first place
    if (!annotation) {
      return false
    }

    return isAnnotationOutOfView(
      annotation.data.hidden_areas,
      frameIndex || this.view.currentFrameIndex,
    )
  }

  // END: Annotation Visibility
  public initializeSubAnnotation(
    type: AnnotationType,
    parent: Annotation,
    data: SubAnnotationData,
  ): Annotation {
    return createSubAnnotation({ parent, data, type })
  }

  public updateAnnotationData(annotation: Annotation, data: AnnotationData): void {
    annotation.data = data
  }

  /**
   * Delegate to a store getter which infers the main annotation type of a class
   */
  public getMainAnnotationTypeForClass(aClass: AnnotationClass): MainAnnotationType {
    const type = getMainAnnotationType(aClass.annotation_types)
    if (!type) {
      throw new Error('Class in annotation manager has no main type')
    }
    return type
  }

  /**
   * Delegate to a store getter which infers the sub annotation types of a class
   */
  public getSubAnnotationTypesForClass(aClass: AnnotationClass): SubAnnotationType[] {
    return getSubAnnotationTypes(aClass.annotation_types)
  }

  /**
   * Changes the class of the currently selected annotation, if allowed
   *
   * Class change can only happen if the new class is of the same main type as the old class
   */
  public maybeChanceClassOfSelectedAnnotation(newClass: AnnotationClass): boolean {
    const { selectedAnnotation } = this
    if (!selectedAnnotation) {
      return false
    }

    const currentClass = selectedAnnotation.annotationClass
    if (!currentClass || currentClass.id === newClass.id) {
      return false
    }

    const currentMainType = this.getMainAnnotationTypeForClass(currentClass)
    const newMainType = this.getMainAnnotationTypeForClass(newClass)

    // We can't change class to tags or masks
    if (['tag', 'mask'].includes(newMainType)) {
      return false
    }

    if (currentMainType !== newMainType) {
      return false
    }

    ClassEvents.changeAnnotationClass.emit({
      annotationId: selectedAnnotation.id,
      newClassId: newClass.id,
    })

    return true
  }

  /**
   * Only one annotation can be selected
   */
  get selectedAnnotation(): Annotation | undefined {
    if (this.selectedAnnotationIds[0]) {
      return this.getAnnotation(this.selectedAnnotationIds[0])
    }
  }

  get mainAnnotations(): Annotation[] {
    return this.annotations.filter((annotation) => annotation.parentId === undefined)
  }

  get visibleAnnotations(): Annotation[] {
    const { annotations } = this

    const visibleAnnotations = annotations.filter((annotation) => !this.isHidden(annotation.id))

    // If the brush tool is active and editing a selected annotation
    // we hide that annotation so the overlays and it are not rendered, and the brush tool
    // renders it's own dotted annotation area
    if (
      this.view.toolManager?.currentTool?.name === ToolName.Brush &&
      this.selectedAnnotation?.id
    ) {
      return visibleAnnotations.filter((a) => a.id !== this.selectedAnnotation?.id)
    }

    return visibleAnnotations
  }

  get visibleMainAnnotations(): Annotation[] {
    return this.visibleAnnotations.filter((annotation) => annotation.parentId === undefined)
  }

  public get highlightedAnnotationIds(): string[] {
    return this._highlightedAnnotationIds
  }

  get highlightedAnnotations(): Annotation[] {
    const highlightedAnnotationIds = this._highlightedAnnotationIds

    const highlightedAnnotations: Annotation[] = []

    highlightedAnnotationIds.forEach((annotationId: string) => {
      const annotation = this.getAnnotation(annotationId)

      if (annotation) {
        highlightedAnnotations.push(annotation)
      }
    })

    return highlightedAnnotations
  }

  get highlightedVertices(): EditablePoint[] {
    let vertices: EditablePoint[] = []
    for (const annotation of this.highlightedAnnotations) {
      if (!isVideoAnnotation(annotation)) {
        vertices = [
          ...vertices,
          ...getAllAnnotationVertices(annotation, this.view).filter((v) => v.isHighlighted),
        ]
        continue
      }

      const { data: annotationData } = inferVideoData(annotation, this.view.currentFrameIndex)
      if (Object.keys(annotationData).length === 0) {
        continue
      }
      const actualAnnotation = shallowCloneAnnotation(annotation, { data: annotationData })
      vertices = [
        ...vertices,
        ...getAllAnnotationVertices(actualAnnotation, this.view).filter((v) => v.isHighlighted),
      ]
    }
    return vertices
  }

  public isVideoAnnotationAtPoint(annotation: VideoAnnotation, point: IPoint): boolean {
    const { type } = annotation
    const path2D = getPath2D(annotation.id)

    const inferredVideoAnnotationData = inferVideoData(annotation, this.view.currentFrameIndex)
    const { data: annotationData } = inferredVideoAnnotationData

    if (Object.keys(annotationData).length === 0) {
      return false
    }

    const actualAnnotation: Annotation = shallowCloneAnnotation(annotation, {
      data: annotationData,
    })

    if (type === 'line' || type === 'keypoint') {
      const isVertex = pointIsVertexOfPath(
        point,
        getAllAnnotationVertices(actualAnnotation, this.view),
        5 / this.view.cameraScale,
      )

      const { path } = getAnnotationCompoundPathAtFrame(
        actualAnnotation,
        this.view.currentFrameIndex,
      )

      return isVertex || isPointOnPath(point, path, this.view.cameraScale)
    }

    if (type === 'skeleton' || type === 'eye') {
      const isVertex = pointIsVertexOfPath(
        point,
        getAllAnnotationVertices(actualAnnotation, this.view),
        5 / this.view.cameraScale,
      )
      if (
        !annotation.annotationClass ||
        (!annotation.annotationClass.skeletonMetaData && !annotation.annotationClass.eyeMetaData)
      ) {
        return false
      }
      if (!isSkeleton(annotationData) && !isEye(annotationData)) {
        throw new Error('editor: expected annotation of skeleton or eye type')
      }
      const metadata = isEye(annotationData)
        ? annotation.annotationClass.eyeMetaData
        : annotation.annotationClass.skeletonMetaData
      const edges = metadata?.edges
      if (!edges) {
        throw new Error('Annotation type metadata is missing list of edges')
      }

      const { nodes } = annotationData
      const paths = getEdgesAsPaths(nodes, edges)
      return isVertex || paths.some((path) => isPointOnPath(point, path, this.view.cameraScale))
    }
    if (path2D) {
      // only check vertex if selected
      const isVertex =
        this.isSelected(annotation.id) &&
        pointIsVertexOfPath(
          point,
          getAllAnnotationVertices(actualAnnotation, this.view),
          5 / this.view.cameraScale,
        )
      return isVertex || this.view.isPointInPath2D(path2D, point)
    }

    const { path, additionalPaths } = getAnnotationCompoundPathAtFrame(
      actualAnnotation,
      this.view.currentFrameIndex,
    )

    return (
      this.view.isPointInPath(point, path) ||
      additionalPaths.some((p) => this.view.isPointInPath(point, p))
    )
  }

  public isImageAnnotationAtPoint(annotation: ImageAnnotation, point: IPoint): boolean {
    const { type } = annotation
    const path2D = getPath2D(annotation.id)

    if (type === 'line' || type === 'keypoint') {
      const isVertex = pointIsVertexOfPath(
        point,
        getAllAnnotationVertices(annotation, this.view),
        5 / this.view.cameraScale,
      )

      const { path } = getAnnotationCompoundPathAtFrame(annotation, this.view.currentFrameIndex)
      return isVertex || isPointOnPath(point, path, this.view.cameraScale)
    }
    if (type === 'skeleton' || type === 'eye') {
      const isVertex = pointIsVertexOfPath(
        point,
        getAllAnnotationVertices(annotation, this.view),
        5 / this.view.cameraScale,
      )
      if (
        !annotation.annotationClass ||
        (!annotation.annotationClass.skeletonMetaData && !annotation.annotationClass.eyeMetaData)
      ) {
        return false
      }
      if (!isSkeleton(annotation.data) && !isEye(annotation.data)) {
        throw new Error('editor: expected annotation of skeleton or eye type')
      }

      const metadata = isEye(annotation.data)
        ? annotation.annotationClass.eyeMetaData
        : annotation.annotationClass.skeletonMetaData
      const edges = metadata?.edges
      if (!edges) {
        throw new Error('Annotation type metadata is missing list of edges')
      }
      const { nodes } = annotation.data
      const paths = []
      for (const edge of edges) {
        const fromNode = nodes.find((node) => node.name === edge.from)
        const toNode = nodes.find((node) => node.name === edge.to)
        if (!fromNode || !toNode) {
          return false
        }
        paths.push([fromNode.point, toNode.point])
      }
      return isVertex || paths.some((path) => isPointOnPath(point, path, this.view.cameraScale))
    }
    if (path2D) {
      // only check vertex if selected
      const isVertex =
        this.isSelected(annotation.id) &&
        pointIsVertexOfPath(
          point,
          getAllAnnotationVertices(annotation, this.view),
          5 / this.view.cameraScale,
        )
      return isVertex || this.view.isPointInPath2D(path2D, point)
    }

    const { path, additionalPaths } = getAnnotationCompoundPathAtFrame(
      annotation,
      this.view.currentFrameIndex,
    )
    return (
      this.view.isPointInPath(point, path) ||
      additionalPaths.some((p) => this.view.isPointInPath(point, p))
    )
  }

  findAnnotationAtIndex(index: number): Annotation | null {
    this.annotationMoving = undefined
    this.initialAnnotationData = undefined

    const { frameAnnotations } = this
    let annotation: Annotation | null = null

    if (frameAnnotations.length === 0) {
      annotation = null
    } else {
      let clampedIndex = index % frameAnnotations.length
      clampedIndex = clampedIndex >= 0 ? clampedIndex : frameAnnotations.length + clampedIndex
      annotation = frameAnnotations[clampedIndex]
    }

    return annotation
  }

  moveSelectedAnnotation(offset: IPoint): void {
    const { selectedAnnotation, selectedVertexRef } = this
    if (!selectedAnnotation) {
      return
    }

    this.annotationMoving = this.visibleMainAnnotations.find((a) => a.id === selectedAnnotation.id)

    if (!this.annotationMoving) {
      return
    }

    if (this.isLocked(this.annotationMoving.id)) {
      return
    }

    if (!this.initialAnnotationData) {
      this.initialAnnotationData = cloneDeep(this.annotationMoving.data)
    }

    if (selectedVertexRef) {
      if (this.annotationMoving.type === 'eye') {
        translateEyeVertex(
          this.view.editor,
          this.selectedAnnotationVertices.findIndex((vertex) => vertex.isSelected),
          offset,
          new MouseEvent('mock'),
        )
      } else {
        translateVertex(this.view.editor, selectedVertexRef, offset, {}, new MouseEvent('mock'))
      }
    } else {
      translatePath(this.view.editor, this.annotationMoving, offset)
    }

    clearPath2DCache(this.annotationMoving.id)
    this.annotationMoving.centroid = undefined

    this.view.updateRenderedAnnotation(this.annotationMoving.id)
  }

  async performMoveAction(): Promise<void> {
    // We can perform move action only for selected annotation
    if (!this.selectedAnnotation) {
      return
    }
    if (!this.annotationMoving || !this.initialAnnotationData) {
      return
    }
    if (this.isLocked(this.annotationMoving.id)) {
      return
    }
    const action = updateAnnotationData(
      this.view,
      this.annotationMoving,
      this.initialAnnotationData,
      this.annotationMoving.data,
    )
    await this.view.actionManager.do(action)
  }

  inferVideoSubAnnotations(annotation: VideoAnnotation): Annotation[] {
    const { sub_frames: subFrames = {}, segments } = annotation.data

    if (this.view.isLoading) {
      return []
    }
    if (!isVideoSubAnnotations(annotation.subAnnotations)) {
      return []
    }

    // Figure out if the video annotation is visible
    if (!hasSegmentContainingIndex(segments, this.view.currentFrameIndex)) {
      return []
    }

    const subAnnotationFrames = annotation.subAnnotations.frames
    const subKeyframe = subFrames && this.view.currentFrameIndex in subFrames

    // Find the closest keyframe to the left of the current index
    const prevSubIdx = subKeyframe
      ? this.view.currentFrameIndex
      : getPreviousFrameIndex(subFrames, this.view.currentFrameIndex)

    return prevSubIdx === null
      ? []
      : // `subAnnotation.frames` is actually a partial record,
        // so we need an `|| []` here
        subAnnotationFrames[prevSubIdx] || []
  }

  getVideoSubAnnotationData(annotation: VideoAnnotation): VideoSubAnnotationDataPayload {
    const emptyPayload: VideoSubAnnotationDataPayload = {
      subs: [],
      subkeyframe: false,
    }
    const { sub_frames: subFrames, segments } = annotation.data
    if (this.view.isLoading) {
      return emptyPayload
    }
    if (!isVideoSubAnnotations(annotation.subAnnotations)) {
      return emptyPayload
    }

    // Figure out if the video annotation is visible
    if (!hasSegmentContainingIndex(segments, this.view.currentFrameIndex)) {
      return emptyPayload
    }

    if (subFrames && this.view.currentFrameIndex in subFrames) {
      return {
        subs: annotation.subAnnotations.frames[this.view.currentFrameIndex] || [],
        subkeyframe: true,
      }
    }

    return emptyPayload
  }

  preselectedAnnotationClassColor(alpha: number = 1.0): string {
    alpha = clamp(alpha, 0, 1)

    const { preselectedAnnotationClass } = this
    if (!preselectedAnnotationClass) {
      return rgbaString({ r: 94, g: 235, b: 220, a: 1.0 }, alpha)
    }

    const { color } = preselectedAnnotationClass
    return rgbaString(color, alpha)
  }

  selectedAnnotationClassColor(alpha: number = 1.0): string {
    alpha = clamp(alpha, 0, 1)

    const { selectedAnnotation } = this
    if (!selectedAnnotation) {
      return rgbaString({ r: 227, g: 234, b: 242, a: 1.0 }, alpha)
    }

    const { color } = selectedAnnotation
    return rgbaString(color, alpha)
  }

  // Invalidates any cached data such as path2d or centroid for all annotations
  public invalidateAnnotationCache(): void {
    this.annotations.forEach((annotation) => {
      clearPath2DCache(annotation.id)
      annotation.centroid = undefined
    })
  }

  /**
   * The annotation class (in editor format) currently selected in the class selection dropdown.
   *
   * Uses memoisation under the hood because it get's called quite frequently in several cases.
   *
   * Long term, editor will not contain classes, so we should not over-rely on this.
   *
   * Note that it's also still quite expensive in teams with a large number of classes.
   *
   * @deprecated Use it if you absolutely have to,
   * but classes will be moved out of the editor,
   * so make all reasonable attempts not to rely on it.
   */
  public get preselectedAnnotationClass(): AnnotationClass | null {
    const preselectedAnnotationClassId = this.view.editor.preselectedAnnotationClassId

    if (!preselectedAnnotationClassId) {
      return null
    }

    return this.view.editor.getClassById(preselectedAnnotationClassId) || null
  }

  public setClassFilter(classIds: Set<number>): void {
    this.classFilterClassIds = classIds
  }

  public isClassCompatibleWithClassFilter(classId: number): boolean {
    // empty list denotes default mode - all classes are editable
    if (this.classFilterClassIds.size === 0) {
      return true
    }

    return this.classFilterClassIds.has(classId)
  }

  /**
   * Instantiates fully "filled in" annotation which is ready to be saved to
   * the backend.
   *
   * Use this function when an annotation is needed, to be passed into a custom
   * action to save it.
   *
   * This consists of
   *
   * - checking all the required data is loaded
   * - resolving actors for the annotation
   * - resolving the class by taking the preselected class, or having the user select a class
   * - resolving full annotation data
   */
  public async prepareAnnotationForCreation<T extends AnnotationData | VideoAnnotationData>(
    params: Optional<
      Pick<CreateAnnotationParams<T>, 'id' | 'annotationClass' | 'type' | 'data'>,
      'annotationClass'
    >,
    subAnnotationParams?: unknown,
  ): Promise<(T extends VideoAnnotationData ? VideoAnnotation : Annotation) | void> {
    const { isProcessedAsVideo } = this.view.fileManager

    const annotationClass = params.annotationClass || this.preselectedAnnotationClass
    if (!annotationClass) {
      return
    }

    if (isProcessedAsVideo) {
      const data = params.data as VideoAnnotationData
      data.interpolate_algorithm = 'linear-1.1'
      data.segments = buildInitialVideoAnnotationSegments(this.view.currentFrameIndex)
    }

    const annotation = createAnnotationFromInstanceParams({
      id: params.id,
      type: params.type,
      annotationClass,
      classId: annotationClass.id,
      label: annotationClass.name,
      color: getAnnotationClassColor(annotationClass),
      data: params.data,
    })

    // subAnnotations is always default SubAnnotation when creating a new annotation
    const subAnnotations = annotation.subAnnotations as ImageSubAnnotation[]
    for (const subAnnotationType of this.getSubAnnotationTypesForClass(annotationClass)) {
      let data

      if (subAnnotationType === 'measures') {
        data = measuresSerializer.defaultData(this.view.measureManager.measureRegion)
      }

      if (subAnnotationType === 'instance_id') {
        // We must not use store in annotation manager, but right now it's unavoidable.
        // Need to potentially move prepareAnnotationForCreation function out from here.
        const store = useV2AnnotationsStore()
        const response = await store.getInstanceId()
        if ('data' in response) {
          data = instanceIdSerializer.defaultData({ instance_id: response.data.instance_id })
        } else {
          console.error('prepareAnnotationForCreation failed to fetch instance_id')
          return
        }
      }

      if (subAnnotationType === 'auto_annotate') {
        data = autoAnnotateSerializer.defaultData(
          subAnnotationParams as { clicker?: AutoAnnotateData },
        )
      }

      if (!data) {
        continue
      }

      const subAnnotation = this.initializeSubAnnotation(subAnnotationType, annotation, data)
      if (!subAnnotation) {
        continue
      }
      subAnnotations.push(subAnnotation)
    }

    return annotation
  }

  public updateAnnotationSubs(annotation: Annotation, videoSubs: VideoSubAnnotations): void {
    annotation.subAnnotations = videoSubs
  }

  public updateAnnotationFrame(
    annotation: Annotation,
    frame: AnnotationData,
    subs: Annotation[],
    currentFrameIndex: number,
  ): void {
    if (!isVideoAnnotation(annotation)) {
      return
    }

    const { frames: dataFrames } = cloneDeep(annotation.data)
    const { frames: subsFrames } = annotation.subAnnotations
    if (dataFrames === undefined || subsFrames === undefined) {
      return
    }

    // Make sure that updating the annotation creates a new keyframe
    // if none already exists but makes sure the current frame index
    // intersects the annotation, to avoid appending an empty object
    const updatedDataFrames =
      typeof frame === 'object' && isEmpty(frame)
        ? dataFrames
        : { ...dataFrames, [currentFrameIndex]: frame }

    this.updateAnnotationData(annotation, { ...annotation.data, frames: updatedDataFrames })
    this.updateAnnotationSubs(annotation, { ...annotation.subAnnotations })
  }

  public highlightVertexIndex(index: number): void {
    if (!this.selectedAnnotation && !this.highlightedAnnotationIds.length) {
      return
    }

    const annId = this.selectedAnnotation?.id || this.highlightedAnnotations[0]?.id

    if (!annId) {
      return
    }

    this.view.annotationsLayer.activateVertexWithState(annId, index, {
      isHighlighted: true,
    })
  }

  public unhighlightVertex(): void {
    this.view.annotationsLayer.unhighlightAllVertices()
  }

  private _selectedVertexIndex: number | null = null

  public get selectedVertexIndex(): number | null {
    return this._selectedVertexIndex
  }

  private get selectedVertexRef(): EditablePoint | undefined {
    if (this._selectedVertexIndex === null) {
      return
    }

    return this.selectedAnnotationVertices[this._selectedVertexIndex]
  }

  /**
   * For now we allow only one vertex to be selected at a time
   */
  public selectVertexIndex(index: number): void {
    if (!this.selectedAnnotation) {
      return
    }

    this._selectedVertexIndex = index

    this.view.annotationsLayer.activateVertexWithState(this.selectedAnnotation.id, index, {
      isSelected: true,
    })
  }

  public deselectVertex(): void {
    this.view.annotationsLayer.deactivateVertex()
  }

  get selectedAnnotationVertices(): EditablePoint[] {
    if (!this.selectedAnnotation) {
      return []
    }

    return getAllAnnotationVertices(this.selectedAnnotation, this.view)
  }

  selectPreviousVertex(): void {
    if (!this.selectedAnnotation) {
      return
    }

    const vertexIndex =
      this._selectedVertexIndex === null || this._selectedVertexIndex <= 0
        ? this.selectedAnnotationVertices.length - 1
        : this._selectedVertexIndex - 1

    this.selectVertexIndex(vertexIndex)

    this.view.annotationsLayer.activateVertexWithState(this.selectedAnnotation.id, vertexIndex, {
      isSelected: true,
    })
  }

  selectNextVertex(): void {
    if (!this.selectedAnnotation) {
      return
    }

    const vertexIndex =
      this._selectedVertexIndex === null ||
      this._selectedVertexIndex >= this.selectedAnnotationVertices.length - 1
        ? 0
        : this._selectedVertexIndex + 1

    this.selectVertexIndex(vertexIndex)

    this.view.annotationsLayer.activateVertexWithState(this.selectedAnnotation.id, vertexIndex, {
      isSelected: true,
    })
  }

  findTopAnnotationAt(
    point: IPoint,
    filter?: (annotation: Annotation) => boolean,
  ): Annotation | undefined {
    const visibleMainAnnotations = filter
      ? this.visibleMainAnnotations.filter(filter)
      : this.visibleMainAnnotations

    return visibleMainAnnotations
      .filter((ann) => !this.isHidden(ann.id))
      .find((annotation) => {
        if (isImageAnnotation(annotation)) {
          return this.isImageAnnotationAtPoint(annotation, point)
        }

        if (isVideoAnnotation(annotation)) {
          return this.isVideoAnnotationAtPoint(annotation, point)
        }

        return false
      })
  }

  /**
   * Instantiates basic annotation from given params, not resolving any data internally
   */
  public initializeAnnotation(
    params: Pick<CreateAnnotationParams, 'id' | 'type' | 'data' | 'annotationClass' | 'properties'>,
  ): Annotation | null {
    return createAnnotationFromInstanceParams({
      ...params,
      id: params.id,
      annotationClass: params.annotationClass,
      classId: params.annotationClass.id,
      label: params.annotationClass.name,
      color: getAnnotationClassColor(params.annotationClass),
      data: params.data,
      type: params.type,
    })
  }

  /**
   * @deprecated
   * Finds the annotation vertex that matches the position of a given point with
   * a given threshold.
   *
   * All the highlighted annotations will be looped over until a match is found.
   *
   * @param point The position the vertex needs to match
   * @param threshold The position tolerance between the potential vertex and given point
   */
  public findAnnotationVertexAt(point: IPoint, threshold?: number): EditablePoint | undefined {
    return this.findAnnotationWithVertexAt(point, threshold)?.vertex
  }

  /**
   * Finds the annotation vertex that matches the position of a given point with
   * a given threshold.
   *
   * All the highlighted annotations will be looped over until a match is found.
   *
   * @param point The position the vertex needs to match
   * @param threshold The position tolerance between the potential vertex and given point
   *
   * Returns matched annotation with vertex
   */
  public findAnnotationWithVertexAt(
    point: IPoint,
    threshold?: number,
  ):
    | {
        vertex: EditablePoint
        annotation: Annotation
      }
    | undefined {
    for (const annotationId of [...this._highlightedAnnotationIds, ...this.selectedAnnotationIds]) {
      const res = this.findVertexAt(point, annotationId, threshold)
      if (!res) {
        continue
      }

      const annotation = this.getAnnotation(annotationId)
      if (!annotation) {
        continue
      }
      return {
        vertex: res,
        annotation,
      }
    }
  }

  public findVertexAt(
    point: IPoint,
    forAnnotationId: string,
    threshold?: number,
  ): EditablePoint | undefined {
    const annotation = this.getAnnotation(forAnnotationId)
    if (!annotation) {
      return
    }
    const vertex = this.doFindAnnotationVertexAt(annotation, point, threshold)
    if (!vertex) {
      return
    }
    return vertex
  }

  private doFindAnnotationVertexAt(
    annotation: Annotation,
    point: IPoint,
    threshold?: number,
  ): EditablePoint | undefined {
    const vertices = getAllAnnotationVertices(annotation, this.view)
    return this.view.findVertexAtPath([vertices], point, threshold)
  }

  /**
   * Returns the list of Renderable Items used by the OptimisedLayer
   * to draw annotations on the canvas.
   */
  public getRenderableAnnotations(frameIndex: number): RenderableItem[] {
    const res = []
    for (const annotation of this.frameAnnotations) {
      if (
        isAnnotationOutOfView(
          annotation.data.hidden_areas,
          frameIndex || this.view.currentFrameIndex,
        )
      ) {
        continue
      }

      if (annotation.data.frames && frameIndex !== undefined) {
        const frame = annotation.data.frames[frameIndex]
        // Pagination is enabled and frame is empty so we skip this frame
        if (!frameDataIsLoaded(frame)) {
          continue
        }
      }

      try {
        const renderableItem = annotationToRenderableItem(annotation, {
          frameIndex: this.view.currentFrameIndex,
          totalFrames: this.view.totalFrames,
          slotName: this.view.name,
          isProcessedAsVideo: this.view.fileManager.isProcessedAsVideo,
          videoAnnotationDuration: this.view.editor.videoAnnotationDuration,
        })

        // Skip un-renderable annotations (like Tags)
        if (!renderableItem) {
          continue
        }

        res.push(renderableItem)
      } catch (error: unknown) {
        setContext('Get renderable annotation', {
          annotationId: annotation.id,
          annotationType: annotation.type,
          video: !!this.view.fileManager.isProcessedAsVideo,
          frameIndex,
          isUsingPagination: this.view.editor.featureFlags.ANNOTATIONS_PAGINATION,
          error,
        })
        console.error('Annotation manager: failed to get renderable annotation')
      }
    }

    return res
  }

  // START: Cleanup

  private resetState(): void {
    this.clearMemo()
  }

  /**
   * Clear all memos.
   *
   * Next call of memo getter will re-fill memo object.
   */
  private clearMemo(): void {
    this.memoGetAnnotations = {
      key: '',
      result: [],
    }
    this.memoGetFrameAnnotations = {
      key: '',
      result: [],
    }
  }

  public cleanup(): void {
    this.annotations.forEach((a) => clearAnnotationRenderingCache(a.id))
    this.annotationsIds = []
    this.annotationsMap = {}
    this.annotationsReadonlyMap = {}
    this.selectedAnnotationIds = []
    this._highlightedAnnotationIds = []
    this.classFilterClassIds = new Set()
    this.resetState()
    this.emit(Events.ANNOTATION_UNHIGHLIGHTALL)
    AnnotationManagerEvents.annotationDeselectAll.emit(this.viewEvent)
    AnnotationManagerEvents.annotationUnhighlightAll.emit(this.viewEvent)
  }

  // END: Cleanup

  private emitError(): void {
    AnnotationManagerEvents.annotationError.emit(this.viewEvent)
  }
}
