import type { WorkflowAction } from '@/store/modules/workflow/types'
import type { ApiResponse, V2DatasetItemPayload } from '@/store/types'
import { requestWorkBatchInWorkflow as request } from '@/backend/darwin'

export type Payload = {
  teamSlug: string
  workflowId: string
}

/**
 * Request a work batch for the current user.
 *
 * The expected response is a list of assigned dataset items.
 */
export const requestWorkBatchInWorkflow: WorkflowAction<Payload, V2DatasetItemPayload[]> = async (
  { commit },
  { teamSlug, workflowId },
) => {
  const response = <ApiResponse<V2DatasetItemPayload[]>>await request({ teamSlug, workflowId })

  if ('data' in response) {
    commit('SET_WORKFLOW_ASSIGNED_ITEMS', { workflowId, assignedItems: response.data.length })
  }

  return response
}
