import { Object2D } from '@/modules/Editor/models/layers/object2D'
import { renderImageOnCanvas } from '@/modules/Editor/renderImageOnCanvas'
import { renderMeasureRegion } from '@/modules/Editor/renderMeasureRegion'
import { View } from '@/modules/Editor/views/view'

import { ViewTypes } from './viewTypes'

export class ImageView extends View {
  readonly type = ViewTypes.IMAGE

  async init(): Promise<void> {
    this.fileManager.loadFrames()

    this.showFramesTool = false

    this.mainLayer.clear()
    this.mainLayer.add(
      new Object2D(ViewTypes.IMAGE, (ctx, canvas) => {
        if (this.currentFrame) {
          renderImageOnCanvas(this, canvas, this.currentFrame)
          if (this.editor.renderMeasures) {
            renderMeasureRegion(this)
          }
        }
      }),
    )

    // Reset the current image filter's window level
    this.setImageFilter({
      ...this.imageFilter,
      windowLevels: this.defaultWindowLevels,
    })

    // Reset tool
    const { currentTool } = this.toolManager
    if (currentTool) {
      currentTool.tool.reset(currentTool.context)
    }

    await this.jumpToFrame(0)
    this.initCamera()
  }
}
