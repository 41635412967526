import type { InferenceResult } from '@/modules/Editor/backend'
import { useTeamStore } from '@/pinia/useTeamStore'
import type { NeuralModelAction } from '@/store/modules/neuralModel/types'
import { runModelInference as windRunInference } from '@/backend/wind'
import type { RunningSessionPayload, RunInferenceResponse } from '@/backend/wind/types'

export type RunInference = NeuralModelAction<
  { runningSession: RunningSessionPayload; image: { base64: string } },
  RunInferenceResponse<InferenceResult[]>
>
export const runInference: RunInference = async (inferenceData, payload) => {
  const teamStore = useTeamStore()
  const currentTeam = teamStore.currentTeam
  if (!currentTeam) {
    throw new Error('[neuralModel/runInference]: Current team not set')
  }

  const { runningSession, image } = payload

  const response = await windRunInference<InferenceResult[]>({
    image,
    modelId: runningSession.id,
    teamId: currentTeam.id,
  })

  return response
}
