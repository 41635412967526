import { v4 as uuidv4 } from 'uuid'

import type {
  DatasetStageConfigPayload,
  ModelStageConfigPayload,
  V2ArchiveStagePayload,
  V2AnnotateStagePayload,
  V2CompleteStagePayload,
  V2ConsensusStagePayload,
  V2ConsensusTestStagePayload,
  V2DatasetStagePayload,
  V2DiscardStagePayload,
  V2ModelStagePayload,
  V2ReviewStagePayload,
  V2LogicStagePayload,
  V2WorkflowEdgePayload,
  V2WorkflowStagePayload,
  V2WebhookStagePayload,
  V2SamplingStagePayload,
} from '@/store/types'
import { StageType } from '@/store/types'
import type {
  ArchiveStageConfigPayload,
  AnnotateStageConfigPayload,
  CompleteStageConfigPayload,
  ConsensusStageConfigPayload,
  ConsensusTestStageConfigPayload,
  DiscardStageConfigPayload,
  LogicStageConfigPayload,
  ReviewStageConfigPayload,
  WebhookStageConfigPayload,
  SamplingStageConfigPayload,
} from '@/store/types/V2WorkflowStageConfigPayload'

type ArchiveStageParams = Omit<Partial<V2WorkflowStagePayload>, 'config'> & {
  config?: Partial<ArchiveStageConfigPayload>
}

type AnnotateStageParams = Omit<Partial<V2WorkflowStagePayload>, 'config'> & {
  assignable_users?: { user_id: number }[]
  config?: Partial<AnnotateStageConfigPayload>
}

type ReviewStageParams = Omit<Partial<V2WorkflowStagePayload>, 'config'> & {
  assignable_users?: { user_id: number }[]
  config?: Partial<ReviewStageConfigPayload>
}

type CompleteStageParams = Omit<Partial<V2WorkflowStagePayload>, 'config'> & {
  config?: Partial<CompleteStageConfigPayload>
}

type ConsensusStageParams = Omit<Partial<V2WorkflowStagePayload>, 'config'> & {
  config?: Partial<ConsensusStageConfigPayload>
}

type ConsensusTestStageParams = Omit<Partial<V2WorkflowStagePayload>, 'config'> & {
  config?: Partial<ConsensusTestStageConfigPayload>
}

type DiscardStageParams = Omit<Partial<V2WorkflowStagePayload>, 'config'> & {
  config?: Partial<DiscardStageConfigPayload>
}

type ModelStageParams = Omit<Partial<V2WorkflowStagePayload>, 'config'> & {
  config?: Partial<ModelStageConfigPayload>
}

type DatasetStageParams = Omit<Partial<V2DatasetStagePayload>, 'config'> & {
  config?: Partial<DatasetStageConfigPayload>
}

type LogicStageParams = Omit<Partial<V2DatasetStagePayload>, 'config'> & {
  config?: Partial<LogicStageConfigPayload>
}

type WebhookStageParams = Omit<Partial<V2WebhookStagePayload>, 'config'> & {
  config?: Partial<WebhookStageConfigPayload>
}

type SamplingStageParams = Omit<Partial<V2SamplingStagePayload>, 'config'> & {
  config?: Partial<SamplingStageConfigPayload>
}

type Params =
  | ArchiveStageParams
  | AnnotateStageParams
  | ReviewStageParams
  | CompleteStageParams
  | ConsensusStageParams
  | DatasetStageParams
  | SamplingStageParams
  | LogicStageParams

const buildArchiveConfig = (
  params: Partial<ArchiveStageConfigPayload>,
): ArchiveStageConfigPayload => ({
  x: 0,
  y: 0,
  ...params,
})

const buildAnnotateConfig = (
  params: Partial<AnnotateStageConfigPayload>,
): AnnotateStageConfigPayload => {
  const config: AnnotateStageConfigPayload = {
    assignable_to: 'anyone',
    initial: false,
    x: 0,
    y: 0,
    ...params,
  }
  return config
}

const buildReviewConfig = (params: Partial<ReviewStageConfigPayload>): ReviewStageConfigPayload => {
  const config: ReviewStageConfigPayload = {
    assignable_to: 'anyone',
    initial: false,
    readonly: false,
    x: 0,
    y: 0,
    ...params,
  }

  return config
}

const buildLogicConfig = (params: Partial<LogicStageConfigPayload>): LogicStageConfigPayload => ({
  x: 0,
  y: 0,
  rules: [],
  ...params,
})

const buildCompleteConfig = (
  params: Partial<CompleteStageConfigPayload>,
): CompleteStageConfigPayload => ({
  x: 0,
  y: 0,
  ...params,
})

const buildConsensusConfig = (
  params: Partial<ConsensusStageConfigPayload>,
): ConsensusStageConfigPayload => ({
  x: 0,
  y: 0,
  parallel_stage_ids: [],
  test_stage_id: uuidv4(),
  ...params,
})

const buildConsensusTestConfig = (
  params: Partial<ConsensusTestStageConfigPayload>,
): ConsensusTestStageConfigPayload => ({
  x: 0,
  y: 0,
  iou_thresholds: {
    general_threshold: 0.8,
    annotation_classes: [],
    annotation_types: [],
  },
  ...params,
})

const buildDiscardConfig = (
  params: Partial<DiscardStageConfigPayload>,
): DiscardStageConfigPayload => ({
  x: 0,
  y: 0,
  ...params,
})

const buildModelConfig = (params: Partial<ModelStageConfigPayload>): ModelStageConfigPayload => ({
  auto_instantiate: false,
  model_confidence_threshold: 0.5,
  class_mapping: [],
  model_id: '',
  x: 0,
  y: 0,
  ...params,
})

const buildDatasetConfig = (
  params: Partial<DatasetStageConfigPayload>,
): DatasetStageConfigPayload => ({
  initial: true,
  dataset_id: null,
  x: 0,
  y: 0,
  ...params,
})

const buildWebhookConfig = (
  params: Partial<WebhookStageConfigPayload>,
): WebhookStageConfigPayload => {
  const config: WebhookStageConfigPayload = {
    readonly: true,
    url: '',
    authorization_header_name: 'Authorization',
    authorization_header: '',
    retry_if_fails: true,
    include_annotations: true,
    x: 0,
    y: 0,
    ...params,
  }

  return config
}

const buildEdgePayload = (params: Partial<V2WorkflowEdgePayload>): V2WorkflowEdgePayload => ({
  id: 'default',
  source_stage_id: 'default',
  target_stage_id: 'target-stage-foo',
  name: 'default',
  ...params,
})

const buildV2ArchiveStagePayload = (params: ArchiveStageParams): V2ArchiveStagePayload => {
  // discard original config, edges, type, so they don't override
  const { config, edges, type, ...rest } = params

  const stage: V2ArchiveStagePayload = {
    config: buildArchiveConfig(params.config || {}),
    edges: (params.edges || []).map(buildEdgePayload),
    id: 'fake-stage-id',
    name: 'Archive',
    type: StageType.Archive,
    ...rest,
  }

  return stage
}

const buildV2AnnotateStagePayload = (params: AnnotateStageParams): V2AnnotateStagePayload => {
  // discard original config, edges, type, so they don't override
  const { config, edges, type, ...rest } = params

  const stage: V2AnnotateStagePayload = {
    assignable_users: [],
    config: buildAnnotateConfig(params.config || {}),
    edges: (params.edges || []).map(buildEdgePayload),
    id: 'fake-stage-id',
    name: 'Annotate',
    type: StageType.Annotate,
    ...rest,
  }
  return stage
}

const buildV2ReviewStagePayload = (params: ReviewStageParams): V2ReviewStagePayload => {
  // discard original config, edges, type, so they don't override
  const { config, edges, type, ...rest } = params

  const stage: V2ReviewStagePayload = {
    assignable_users: [],
    config: buildReviewConfig(params.config || {}),
    edges: (params.edges || []).map(buildEdgePayload),
    id: 'fake-stage-id',
    name: 'Review',
    type: StageType.Review,
    ...rest,
  }
  return stage
}

const buildV2LogicStagePayload = (params: LogicStageParams): V2LogicStagePayload => {
  // discard original config, edges, type, so they don't override
  const { config, edges, type, ...rest } = params

  const stage: V2LogicStagePayload = {
    config: buildLogicConfig(params.config || {}),
    edges: (params.edges || []).map(buildEdgePayload),
    id: 'fake-stage-id',
    name: 'Logic',
    type: StageType.Logic,
    ...rest,
  }
  return stage
}

const buildV2CompleteStagePayload = (params: CompleteStageParams): V2CompleteStagePayload => {
  // discard original config, edges, type, so they don't override
  const { config, edges, type, ...rest } = params

  const stage: V2CompleteStagePayload = {
    config: buildCompleteConfig(params.config || {}),
    edges: (params.edges || []).map(buildEdgePayload),
    id: 'fake-stage-id',
    name: 'Complete',
    type: StageType.Complete,
    ...rest,
  }

  return stage
}

const buildV2ConsensusStagePayload = (params: ConsensusStageParams): V2ConsensusStagePayload => {
  // discard original config, edges, type, so they don't override
  const { config, edges, type, ...rest } = params

  const stage: V2ConsensusStagePayload = {
    config: buildConsensusConfig(params.config || {}),
    edges: (params.edges || []).map(buildEdgePayload),
    id: 'fake-stage-id',
    name: 'Consensus',
    type: StageType.ConsensusEntrypoint,
    ...rest,
  }

  return stage
}

const buildV2ConsensusTestStagePayload = (
  params: ConsensusTestStageParams,
): V2ConsensusTestStagePayload => {
  // discard original config, edges, type, so they don't override
  const { config, edges, type, ...rest } = params

  const stage: V2ConsensusTestStagePayload = {
    config: buildConsensusTestConfig(params.config || {}),
    edges: (params.edges || []).map(buildEdgePayload),
    id: 'fake-stage-id',
    name: 'Test',
    type: StageType.ConsensusTest,
    ...rest,
  }

  return stage
}

const buildV2DiscardStagePayload = (params: DiscardStageParams): V2DiscardStagePayload => {
  // discard original config, edges, type, so they don't override
  const { config, edges, type, ...rest } = params

  const stage: V2DiscardStagePayload = {
    config: buildDiscardConfig(params.config || {}),
    edges: (params.edges || []).map(buildEdgePayload),
    id: 'fake-stage-id',
    name: 'Discard',
    type: StageType.Discard,
    ...rest,
  }

  return stage
}

const buildV2ModelStagePayload = (params: ModelStageParams): V2ModelStagePayload => {
  // discard original config, edges, type, so they don't override
  const { config, edges, type, ...rest } = params

  const stage: V2ModelStagePayload = {
    config: buildModelConfig(params.config || {}),
    edges: (params.edges || []).map(buildEdgePayload),
    id: 'fake-stage-id',
    name: 'AI Model',
    type: StageType.Model,
    ...rest,
  }

  return stage
}

const buildV2WebhookStagePayload = (params: WebhookStageParams): V2WebhookStagePayload => {
  // discard original config, edges, type, so they don't override
  const { config, edges, type, ...rest } = params

  const stage: V2WebhookStagePayload = {
    config: buildWebhookConfig(params.config || {}),
    edges: (params.edges || []).map(buildEdgePayload),
    id: 'fake-stage-id',
    name: 'Webhook',
    type: StageType.Webhook,
    ...rest,
  }

  return stage
}

const buildV2DatasetStagePayload = (params: DatasetStageParams): V2DatasetStagePayload => {
  const { config, edges, type, ...rest } = params

  const stage: V2DatasetStagePayload = {
    config: buildDatasetConfig(params.config || {}),
    edges: (params.edges || []).map(buildEdgePayload),
    id: 'fake-stage-id',
    name: 'Dataset',
    type: StageType.Dataset,
    ...rest,
  }

  return stage
}

const buildV2SamplingStagePayload = (params: SamplingStageParams): V2SamplingStagePayload => {
  const { config, edges, type, ...rest } = params

  const stage: V2SamplingStagePayload = {
    config: {
      x: 0,
      y: 0,
      threshold: 50,
      ...config,
    },
    edges: (edges ?? []).map(buildEdgePayload),
    id: 'fake-stage-id',
    name: 'Sampling',
    type: StageType.Sampling,
    ...rest,
  }

  return stage
}

const isArchiveStage = (params: Params): params is ArchiveStageParams =>
  (params.type || StageType.Archive) === StageType.Archive

const isAnnotateStage = (params: Params): params is AnnotateStageParams =>
  (params.type || StageType.Annotate) === StageType.Annotate

const isReviewStage = (params: Params): params is ReviewStageParams =>
  params.type === StageType.Review

const isLogicStage = (params: Params): params is LogicStageParams => params.type === StageType.Logic

const isCompleteStage = (params: Params): params is CompleteStageParams =>
  params.type === StageType.Complete

const isConsensusStage = (params: Params): params is ConsensusStageParams =>
  params.type === StageType.ConsensusEntrypoint

const isConsensusTestStage = (params: Params): params is ConsensusTestStageParams =>
  params.type === StageType.ConsensusTest

const isDiscardStage = (params: Params): params is DiscardStageParams =>
  params.type === StageType.Discard

const isModelStage = (params: Params): params is ModelStageParams => params.type === StageType.Model

const isDatasetStage = (params: Params): params is DatasetStageParams =>
  params.type === StageType.Dataset

const isWebhookStage = (params: Params): params is WebhookStageParams =>
  params.type === StageType.Webhook

const isSamplingStage = (params: Params): params is SamplingStageParams =>
  params.type === StageType.Sampling

export const buildV2WorkflowStagePayload = (
  params: Params = {},
  /*
   * Needs to be seperate due to id type variation.
   * Weird and hard to fix this string | number stuff
   * */
): V2WorkflowStagePayload | V2DatasetStagePayload => {
  if (isArchiveStage(params)) {
    return buildV2ArchiveStagePayload(params)
  }

  if (isAnnotateStage(params)) {
    return buildV2AnnotateStagePayload(params)
  }

  if (isReviewStage(params)) {
    return buildV2ReviewStagePayload(params)
  }

  if (isLogicStage(params)) {
    return buildV2LogicStagePayload(params)
  }

  if (isCompleteStage(params)) {
    return buildV2CompleteStagePayload(params)
  }

  if (isConsensusStage(params)) {
    return buildV2ConsensusStagePayload(params)
  }

  if (isConsensusTestStage(params)) {
    return buildV2ConsensusTestStagePayload(params)
  }

  if (isDiscardStage(params)) {
    return buildV2DiscardStagePayload(params)
  }

  if (isModelStage(params)) {
    return buildV2ModelStagePayload(params)
  }

  if (isDatasetStage(params)) {
    return buildV2DatasetStagePayload(params)
  }

  if (isWebhookStage(params)) {
    return buildV2WebhookStagePayload(params)
  }

  if (isSamplingStage(params)) {
    return buildV2SamplingStagePayload(params)
  }

  throw new Error('Invalid stage given')
}
