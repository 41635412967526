import type { FrameData } from '@/core/annotations'
import type { Rectangle } from '@/modules/Editor/rectangle'

export type ModelMetadata = NonNullable<FrameData['inference']>['model']

export type InferenceMetadata = {
  confidence: number
  model: ModelMetadata
}

export const isInferenceMetadata = (data: unknown): data is InferenceMetadata =>
  typeof data === 'object' &&
  data !== null &&
  'confidence' in data &&
  typeof data.confidence === 'number' &&
  'model' in data &&
  typeof data.model === 'object' &&
  data.model !== null &&
  'name' in data.model &&
  typeof data.model.name === 'string' &&
  'id' in data.model &&
  typeof data.model.id === 'string'

export type InferenceResult = {
  id?: string
  path?: { x: number; y: number }[]
  /**
   * both label and name are currently used for class names
   * different models will have different outputs
   */
  label?: string
  /**
   * both label and name are currently used for class names
   * different models will have different outputs
   */
  name?: string
  complex_polygon?: { x: number; y: number }[][]
  bounding_box?: { x: number; y: number; w: number; h: number }
  inference?: InferenceMetadata
  text?: { text: string }
  polygon?: {
    path: { x: number; y: number }[]
    additional_paths?: { x: number; y: number }[][]
  }
  keypoint?: { x: number; y: number }
  line?: { path: { x: number; y: number }[] }
}

type Crop = Rectangle

export interface Click {
  x: number
  y: number
  type: 'add' | 'remove'
}

export type ImagePayload =
  | { url: string }
  | { base64: string }
  | { segment_file_url: string; index_in_segment: number }

export interface AutoAnnotateInferencePayload {
  image: ImagePayload
  bbox: Crop
  dicom?: { window_low: number; window_high: number }
  threshold?: number
  original_bbox?: Crop
  item_metadata?: { item_id: string; slot_name: string; section_index: number }
}

export interface InferenceData {
  image: ImagePayload
  data: {
    bbox: Crop
    clicks: Click[]
    dicom?: { window_low: number; window_high: number }
    threshold?: number
  }
}

export type ParsedInferenceData = {
  image: ImagePayload
  data?: {
    bbox: { x: number; y: number; w: number; h: number }
    clicks?: { x: number; y: number; type: 'add' | 'remove' }[]
    dicom?: { window_low: number; window_high: number }
    threshold?: number
  }
  bbox?: { x: number; y: number; w: number; h: number }
  dicom?: { window_low: number; window_high: number }
  threshold?: number
  original_bbox?: { x: number; y: number; w: number; h: number }
  item_metadata?: { item_id: string; slot_name: string; section_index: number }
}

const parseBbox = (bbox: Crop) => ({
  x: bbox.left,
  y: bbox.top,
  w: bbox.width,
  h: bbox.height,
})

const parseClickerData = (inferenceData: InferenceData): ParsedInferenceData => {
  const data: ParsedInferenceData['data'] = {
    bbox: parseBbox(inferenceData.data.bbox),
    threshold: inferenceData.data.threshold,
  }

  if (inferenceData.data.clicks) {
    data.clicks = inferenceData.data.clicks.map((click) => ({
      x: click.x,
      y: click.y,
      type: click.type,
    }))
  }

  if (inferenceData.data.dicom) {
    data.dicom = {
      window_low: inferenceData.data.dicom.window_low,
      window_high: inferenceData.data.dicom.window_high,
    }
  }

  return { image: inferenceData.image, data }
}

const parseNonClickerData = (inferenceData: AutoAnnotateInferencePayload): ParsedInferenceData => {
  const data = {
    image: inferenceData.image,
    bbox: parseBbox(inferenceData.bbox),
    dicom: inferenceData.dicom,
    threshold: inferenceData.threshold,
    original_bbox: inferenceData.original_bbox ? parseBbox(inferenceData.original_bbox) : undefined,
    item_metadata: inferenceData.item_metadata,
  }

  if (inferenceData.dicom) {
    data.dicom = inferenceData.dicom
  }

  return data
}

export const isClickerData = (
  inferenceData: InferenceData | AutoAnnotateInferencePayload,
): inferenceData is InferenceData => 'data' in inferenceData

export const parseInferenceData = (
  inferenceData: InferenceData | AutoAnnotateInferencePayload,
): ParsedInferenceData => {
  if (isClickerData(inferenceData)) {
    return parseClickerData(inferenceData)
  }

  return parseNonClickerData(inferenceData)
}
