import type { WorkflowMutation } from '@/store/modules/workflow/types'
import type { V2WorkflowPayload } from '@/store/types/V2WorkflowPayload'

export const SET_EDITED_WORKFLOW: WorkflowMutation<V2WorkflowPayload | undefined> = (
  state,
  payload,
) => {
  if (!payload) {
    return
  }

  state.editedWorkflow = payload
}
