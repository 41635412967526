import type { AxiosResponse } from 'axios'
import type { ActionTree } from 'vuex'

import type { AnnotationDataGranularity } from '@/modules/ReportsLegacy/types'
import { downloadFile } from '@/core/utils/file'
import type { ApiResponse, DatasetPayload, RootState, TypedAction } from '@/store/types'
import * as api from '@/backend/api'
import { parseError, errorMessages, isErrorResponse } from '@/backend/error'

import type { AnnotatorsState, AnnotationReportParams, AnnotationReport } from './types'

type AnnotatorStatsAction<P, T> = TypedAction<AnnotatorsState, P, T>

type GetAnnotationReportParams = {
  dataset: DatasetPayload
  granularity: AnnotationDataGranularity
  groupBy: string
  from: string
}

type GetAnnotationReportAction = AnnotatorStatsAction<GetAnnotationReportParams, AnnotationReport>

/**
 * Get an annotation report for a dataset as JSON
 */
const getAnnotationReport: GetAnnotationReportAction = async ({ commit, rootState }, params) => {
  const { currentTeam } = rootState.team
  if (!currentTeam) {
    throw Error('Attempted to fetch annotation report without a selected team')
  }

  const queryParams = {
    group_by: params.groupBy || 'dataset',
    dataset_ids: params.dataset.id,
    granularity: params.granularity || 'hour',
    ...(params.from && { from: params.from }),
  }

  let response

  try {
    response = await api.get<AnnotationReport>(`reports/${currentTeam.id}/annotation`, queryParams)
  } catch (error) {
    if (!isErrorResponse(error)) {
      throw error
    }
    // backend will return a JSON payload in case of an error,
    // so we need to convert the arraybuffer to json
    return parseError(error, errorMessages.REPORT_LOAD)
  }

  const mutationParams: AnnotationReportParams = {
    groupBy: queryParams.group_by,
    datasetId: queryParams.dataset_ids,
    granularity: params.granularity || 'hour',
    from: params.from ? params.from : null,
  }

  commit('PUSH_ANNOTATION_REPORT', { data: response.data, params: mutationParams })

  return response
}

type DownloadAnnotationReportParams = {
  dataset: DatasetPayload
  granularity: AnnotationDataGranularity
  groupBy: string
  from: string
}

type DownloadAnnotationReportAction = AnnotatorStatsAction<
  DownloadAnnotationReportParams,
  ApiResponse<AnnotationReport>
>

/**
 * Download an annotation report as CSV
 */
const downloadAnnotationReport: DownloadAnnotationReportAction = async ({ rootState }, params) => {
  const { dataset, granularity } = params
  const { currentTeam } = rootState.team
  if (!currentTeam) {
    throw Error('Attempted to download annotation report without a selected team.')
  }

  const queryParams = {
    group_by: 'dataset,user',
    dataset_ids: dataset.id,
    granularity,
    format: 'csv',
    include: 'dataset.name,user.first_name,user.last_name,user.email',
  }

  let response: AxiosResponse
  const options = { params: queryParams }

  try {
    response = await api.download<AnnotationReport>(`reports/${currentTeam.id}/annotation`, options)
  } catch (error) {
    if (!isErrorResponse(error)) {
      throw error
    }
    // backend will return a JSON payload in case of an error,
    // so we need to convert the arraybuffer to json
    return parseError(error, errorMessages.REPORT_DOWNLOAD)
  }

  const filename = `${currentTeam.name}_${dataset.name}_by_${granularity}.csv`
  downloadFile(response, filename)

  return { data: response }
}

export const actions: ActionTree<AnnotatorsState, RootState> = {
  getAnnotationReport,
  downloadAnnotationReport,
}
