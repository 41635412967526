import type { NeuralModelAction } from '@/store/modules/neuralModel/types'
import { loadPublishedModelTemplates as windRequest } from '@/backend/wind'
import type { ModelTemplatePayload } from '@/backend/wind/types'

export type Action = NeuralModelAction<number, ModelTemplatePayload[]>

export const loadPublishedModelTemplates: Action = async ({ commit }, teamId) => {
  const response = await windRequest({ teamId: teamId })

  if ('data' in response) {
    commit('SET_MODEL_TEMPLATES', response.data)
    return response
  }

  return response
}
