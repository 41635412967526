import type { Editor } from '@/modules/Editor/editor'
import type { Annotation } from '@/modules/Editor/models/annotation/Annotation'
import type { VideoAnnotationDataPayload } from '@/modules/Editor/types'

import { clipboardCommandAltPaste } from './clipboardCommandAltPaste'
import { clipboardCommandCopy, clipboardCommandCut } from './clipboardCommandCopy'
import { clipboardCommandPaste } from './clipboardCommandPaste'
import { clipboardCommandAltShiftPaste } from './clipboardCommandAltShiftPaste'
import { clipboardCommandShiftPaste } from './clipboardCommandShiftPaste'

export interface ClipboardData {
  clipboardAnnotation: Annotation | undefined
  sourceAnnotationId: string | undefined
  videoAnnotationData: VideoAnnotationDataPayload
  inferredVideoAnnotationData: VideoAnnotationDataPayload
}

const createClipboardData = (): ClipboardData => ({
  clipboardAnnotation: undefined,
  sourceAnnotationId: undefined,
  videoAnnotationData: {
    data: {},
    subs: [],
    keyframe: false,
    subkeyframe: false,
    interpolateAlgorithm: 'linear-1.1',
  },
  inferredVideoAnnotationData: {
    data: {},
    subs: [],
    keyframe: false,
    subkeyframe: false,
    interpolateAlgorithm: 'linear-1.1',
  },
})

/**
 * Data is global, in order to share clipboard between items,
 * even if the plugin manager gets recreated
 */
const data = createClipboardData()

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export const useClipboardInteractions = (editor: Editor) => {
  const copy = (): void => {
    const {
      clipboardAnnotation,
      videoAnnotationData,
      inferredVideoAnnotationData,
      sourceAnnotationId,
    } = clipboardCommandCopy(editor.activeView) || {}

    if (videoAnnotationData && inferredVideoAnnotationData) {
      data.videoAnnotationData = videoAnnotationData
      data.inferredVideoAnnotationData = inferredVideoAnnotationData
    }

    data.sourceAnnotationId = sourceAnnotationId
    data.clipboardAnnotation = clipboardAnnotation
  }

  const cut = (): void => {
    const { clipboardAnnotation, videoAnnotationData } =
      clipboardCommandCut(editor.activeView) || {}

    if (videoAnnotationData) {
      data.videoAnnotationData = videoAnnotationData
    }

    data.clipboardAnnotation = clipboardAnnotation
  }

  const paste = (): void => clipboardCommandPaste(editor, data)

  const shiftPaste = (): void => clipboardCommandShiftPaste(editor, data)

  const altPaste = (): void => clipboardCommandAltPaste(editor, data)

  const altShiftPaste = (): void => clipboardCommandAltShiftPaste(editor, data)

  return { copy, cut, paste, shiftPaste, altPaste, altShiftPaste }
}
