import type { WorkflowAction } from '@/store/modules/workflow/types'
import { loadV2Workflows } from '@/backend/darwin'

export const loadWorkflows: WorkflowAction<string, void> = async ({ commit }, teamSlug) => {
  const response = await loadV2Workflows({ teamSlug })

  if ('data' in response) {
    commit('SET_WORKFLOWS', response.data)
    return { data: undefined }
  }

  return response
}
