import type { WorkflowMutation } from '@/store/modules/workflow/types'

export type Payload = {
  sourceStageId?: string
  targetStageId?: string
  name: string
}

export const REMOVE_EDGE: WorkflowMutation<Payload> = (
  state,
  { sourceStageId, targetStageId, name },
) => {
  const workflow = state.editedWorkflow

  if (!workflow || !name) {
    return
  }

  const stage = workflow.stages.find((s) =>
    s.edges.some((e) => {
      const matchesSource = e.source_stage_id === sourceStageId
      const matchesTarget = e.target_stage_id === targetStageId

      if (sourceStageId && targetStageId) {
        return matchesSource && matchesTarget
      }
      if (sourceStageId) {
        return matchesSource
      }
      return matchesTarget
    }),
  )

  if (!stage) {
    console.warn(`REMOVE_EDGE: no connected stage found on edge named ${name}`)
    return
  }

  const edge = stage.edges.find((e) => {
    const matchesName = e.name === name
    const matchesSource = e.source_stage_id === sourceStageId
    const matchesTarget = e.target_stage_id === targetStageId

    if (sourceStageId && targetStageId) {
      return matchesName && matchesSource && matchesTarget
    }
    if (sourceStageId) {
      return matchesName && matchesSource
    }
    return matchesTarget
  })

  if (!edge) {
    console.warn(`REMOVE_EDGE: no connected edge found on edge named ${name}`)
    return
  }

  stage.edges = stage.edges.filter((e) => e.id !== edge.id)
}
