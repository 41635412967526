import type { PolygonData } from '@/core/annotations'
import type { Annotation } from '@/modules/Editor/models/annotation/Annotation'
import type { Raster, Bounds } from '@/modules/Editor/models/raster/Raster'
import type { RasterBufferAccessor } from '@/modules/Editor/models/raster/RasterBufferAccessor'
import type { IPoint } from '@/modules/Editor/point'
import { VoxelRaster } from '@/modules/Editor/models/raster/VoxelRaster'
import { assertVoxelRaster } from '@/modules/Editor/models/raster/assertVoxelRaster'
import { checkAndRemoveEmptyMasks } from '@/modules/Editor/plugins/mask/utils/shared/checkAndRemoveEmptyMasks'
import { invalidate3D } from '@/modules/Editor/plugins/mask/utils/shared/invalidate3D'
import { updateMaskAnnotation } from '@/modules/Editor/plugins/mask/utils/shared/updateMaskAnnotation'
import { clipRangeToRasterBounds } from '@/modules/Editor/plugins/mask/utils/shared/clipRangeToRasterBounds'
import { shouldApplyRasterScaling } from '@/modules/Editor/plugins/mask/utils/shared/shouldApplyRasterScaling'
import { getVoxelRasterDimensions } from '@/modules/Editor/plugins/brush/utils/getVoxelRasterDimensions'
import { getXYVoxelScaling } from '@/modules/Editor/plugins/brush/utils/getXYVoxelScaling'
import { cleanupPotentiallyEmptyMask } from '@/modules/Editor/plugins/mask/utils/shared/cleanupPotentiallyEmptyMask'
import cloneDeep from 'lodash/cloneDeep'

import { Plane } from '@/modules/Editor/utils/raster/Plane'
import type { View } from '@/modules/Editor/views/view'

import type { PolygonToRasterResult } from './polygonToRaster'
import { polygonToRaster } from './polygonToRaster'
import { getOrCreateRasterForView } from './shared/getOrCreateRasterForView'
import { getAnnotationForClassIdOnRaster } from './shared/getAnnotationForClassIdOnRaster'
import { createMaskAnnotation } from './shared/createMaskAnnotation'
import { createMaskAnnotationOnReformat } from './shared/createMaskAnnotationOnReformat'
import { getPrimaryViewFromView } from './shared/getPrimaryViewFromView'
import { getRasterBufferAccessorForView } from './shared/getRasterBufferAccessorForView'
import { getUpdatedMaskAnnotationBounds } from './shared/getUpdatedMaskAnnotationBounds'
import { updateMaskAnnotationOnReformat } from './shared/updateMaskAnnotationOnReformat'

/**
 * Finds the label index of the mask annotation on the raster,
 * or returns the next available label index if the annotation is new
 * @param raster The raster object
 * @param annotation The annotation to query.
 * @returns The appropriate label index for the raster.
 */
function getLabelIndex(raster: Raster, annotation?: Annotation): number {
  if (annotation !== undefined) {
    const labelIndex = raster.getLabelIndexForAnnotationId(annotation.id)

    if (labelIndex === undefined) {
      throw new Error('Exisitng annotation has no mapping to raster.')
    }

    return labelIndex
  }

  return raster.getNextAvailableLabelIndex()
}

/**
 * Paints the polygon to the given raster object.
 *
 * @param raster The raster object
 * @param polygon The polygon layer.
 * @param labelIndex The labelIndex to paint to the raster object.
 *
 * @returns A promise resolving to the rasterized polygon's extent
 * (painted labelmap indicies) and labels overwritten by the new shape.
 */
async function updateRasterMask(
  view: View,
  raster: Raster,
  rasterBufferAccessor: RasterBufferAccessor,
  polygon: PolygonData,
  labelIndex: number,
  frameIndex?: number,
): Promise<{
  rasterizedPolygon: PolygonToRasterResult
  labelsBeingOverwrittenArray: number[]
  isRasterBufferModified: boolean
}> {
  const { get, set, width, height } = rasterBufferAccessor

  // Only rely on the web worker for long polygons with multiple frames
  const rasterizedPolygon =
    frameIndex !== undefined
      ? await view.rasterWorker.polygonToRaster(polygon.path)
      : polygonToRaster(polygon.path)

  const labelsBeingOverwritten: Set<number> = new Set()
  let isRasterBufferModified = false

  // Update mask
  rasterizedPolygon.data.forEach(([x, y]): void => {
    if (x < 0 || x >= width || y < 0 || y >= height) {
      // Don't include pixel outside the image.
      return
    }

    const currentLabelIndex = get(x, y)
    if (currentLabelIndex !== labelIndex && currentLabelIndex !== 0) {
      labelsBeingOverwritten.add(currentLabelIndex)
    }

    set(x, y, labelIndex)
    isRasterBufferModified = true
  })

  // Clip coords/bounds to the raster size
  rasterizedPolygon.coords = clipRangeToRasterBounds(rasterizedPolygon.coords, width, height)
  rasterizedPolygon.bounds = {
    topLeft: {
      x: rasterizedPolygon.coords.minX,
      y: rasterizedPolygon.coords.minY,
    },
    bottomRight: {
      x: rasterizedPolygon.coords.maxX,
      y: rasterizedPolygon.coords.maxY,
    },
  }

  const labelsBeingOverwrittenArray = Array.from(labelsBeingOverwritten)

  return { rasterizedPolygon, labelsBeingOverwrittenArray, isRasterBufferModified }
}

const createAnnotation = (
  view: View,
  primaryView: View, // Same as view if this is not an MPR reformat.
  raster: Raster,
  bounds: Bounds,
  labelIndex: number,
  classId: number,
  frameIndex?: number,
  plane?: Plane,
): Promise<void> => {
  const frameIndexModified = frameIndex ?? view.currentFrameIndex

  if (plane && plane !== Plane.Z) {
    return createMaskAnnotationOnReformat(
      primaryView,
      assertVoxelRaster(raster),
      bounds,
      labelIndex,
      classId,
      frameIndexModified,
      plane,
    )
  }

  return createMaskAnnotation({
    view: primaryView,
    raster,
    bounds,
    labelIndex,
    classId,
    frameIndex,
  })
}

/**
 * Updates an annotation, calculating and saving its new labelmap.
 */
function updateAnnotation(
  view: View,
  primaryView: View,
  raster: Raster,
  maskAnnotation: Annotation,
  labelIndex: number,
  bounds: Bounds,
  frameIndex?: number,
  plane?: Plane,
): void {
  const frameIndexModified = frameIndex ?? view.currentFrameIndex

  if (plane && plane !== Plane.Z) {
    updateMaskAnnotationOnReformat(
      primaryView,
      assertVoxelRaster(raster),
      maskAnnotation,
      bounds,
      frameIndexModified,
      plane,
    )

    return
  }

  const newBounds = getUpdatedMaskAnnotationBounds(
    raster,
    labelIndex,
    {
      minX: bounds.topLeft.x,
      maxX: bounds.bottomRight.x,
      minY: bounds.topLeft.y,
      maxY: bounds.bottomRight.y,
    },
    raster.width,
    raster.height,
    frameIndexModified,
  )

  updateMaskAnnotation(primaryView, raster, maskAnnotation, newBounds, frameIndexModified)
}

const getVoxelScaling = (view: View): IPoint | undefined => {
  if (!view.fileManager.file) {
    throw new Error('No file for view')
  }
  const { slot_name: slotName, metadata } = view.fileManager.file
  if (!metadata) {
    return
  }

  const voxelRasterDimensions = getVoxelRasterDimensions(view)
  if (!voxelRasterDimensions) {
    return
  }

  return getXYVoxelScaling(slotName, metadata, voxelRasterDimensions)
}

/**
 * As reformats can have unequeal voxels, we need to swap from the image coordinates of the png
 * to the voxel coordinates (i.e. the coordinate frame where each voxel is 1px high and 1px wide).
 *
 * Since reformats always are always stretched across the Z direction of the primary and otherwise
 * equal by construction, we only need to correct on the y axis of the Sagittal or Coronal images,
 * which corresponds to the Z direction of the stack.
 */
const reformatPolygonToVoxelCoordinates = (
  view: View,
  rasterHeight: number,
  polygon: PolygonData,
): PolygonData => {
  const voxelScaling = getVoxelScaling(view)

  if (!voxelScaling) {
    // no scaling, niavely assume its square in this case
    return polygon
  }

  const { x: xScale, y: yScale } = voxelScaling

  // we want to avoid mutating the original polygon
  const clonedPolygon = cloneDeep(polygon)
  const polygonPath = clonedPolygon.path

  for (let i = 0; i < polygonPath.length; i++) {
    polygonPath[i].x = polygonPath[i].x / xScale
    polygonPath[i].y = polygonPath[i].y / yScale
  }

  return clonedPolygon
}

/**
 * Creates or updates a mask annotation using a polygon.
 * @param {View} view The view containing the image to draw to.
 * @param {PolygonData} polygon The polygon.
 * @param {number} classId The Id of the class to paint the polygon as
 * on the raster.
 * @param {number} [frameIndex] The frame index to draw the polygon on.
 * @returns {Promise<void>} A promise resolving when the mask has been created/updated.
 */
export async function addMaskAnnotationUsingPolygon(
  view: View,
  polygon: PolygonData,
  classId: number,
  frameIndex?: number,
): Promise<void> {
  const primaryView = getPrimaryViewFromView(view)

  if (!primaryView) {
    throw new Error('No primary view found for view')
  }

  const raster = getOrCreateRasterForView(primaryView)
  const rasterBufferAccessor = getRasterBufferAccessorForView(view, raster, frameIndex)

  const annotation = getAnnotationForClassIdOnRaster(primaryView, raster, classId)
  const isNewMaskAnnotation = annotation === undefined

  if (shouldApplyRasterScaling(view)) {
    polygon = reformatPolygonToVoxelCoordinates(view, rasterBufferAccessor.height, polygon)
  }

  const labelIndex = getLabelIndex(raster, annotation)
  const { rasterizedPolygon, labelsBeingOverwrittenArray, isRasterBufferModified } =
    await updateRasterMask(
      primaryView,
      raster,
      rasterBufferAccessor,
      polygon,
      labelIndex,
      frameIndex,
    )

  // In case the polygon shape was very tiny it might not even fill a single pixel,
  // also if the polygon was painted outside the raster there is nothing to fill.
  // We should not create/update any annotations in these cases
  // and cleanup the side effects of the polygon creation.
  if (!isRasterBufferModified) {
    cleanupPotentiallyEmptyMask(raster, isNewMaskAnnotation, labelIndex)
    return
  }

  const bounds = rasterizedPolygon.bounds

  const { plane } = rasterBufferAccessor

  if (annotation) {
    updateAnnotation(view, primaryView, raster, annotation, labelIndex, bounds, frameIndex, plane)
  } else {
    await createAnnotation(
      view,
      primaryView,
      raster,
      bounds,
      labelIndex,
      classId,
      frameIndex,
      plane,
    )
  }

  const framesRange = frameIndex !== undefined ? [frameIndex, frameIndex] : undefined
  checkAndRemoveEmptyMasks(view, raster, labelsBeingOverwrittenArray, framesRange)

  if (raster instanceof VoxelRaster) {
    // Invalidate the slice. In milestone 2 of Volumetric Rasters we will
    // use this to invalidate from other planes.
    const { minX, maxX, minY, maxY } = rasterizedPolygon.coords

    invalidate3D(raster, rasterBufferAccessor, view.currentFrameIndex, {
      minX,
      maxX,
      minY,
      maxY,
    })
  } else {
    raster.invalidate(
      rasterizedPolygon.coords.minX,
      rasterizedPolygon.coords.maxX,
      rasterizedPolygon.coords.minY,
      rasterizedPolygon.coords.maxY,
    )
  }
}
