import { FramesLoaderEvents } from '@/modules/Editor/eventBus'

import { FramesLoader } from './framesLoader'
import type { Frame, FramesLoaderConfig } from './types'

/**
 * Dispatcher to the promise worker in charge of loading frames.
 * Provides a public interface to work with the frames loader
 */
export class FramesImageLoader {
  private framesLoader: FramesLoader

  constructor(id: string, framesLoaderConfig?: FramesLoaderConfig) {
    this.framesLoader = new FramesLoader(id)

    if (framesLoaderConfig) {
      this.framesLoader.setConfig(framesLoaderConfig)
    }

    FramesLoaderEvents.frameInvalid.on(this.onFrameInvalid)
  }

  onFrameInvalid = ({ index }: { index: number }) => {
    this.framesLoader.setFrameInvalid(index)
  }

  pushSections(sections: Frame[]): Promise<void> {
    return Promise.resolve(this.framesLoader.pushSections(sections))
  }

  async loadLQFrame(index: number): Promise<string> {
    const res = await this.framesLoader.loadLQFrame(index)

    return res || ''
  }

  async loadHQFrame(index: number): Promise<string> {
    const res = await this.framesLoader.loadHQFrame(index)

    return res || ''
  }

  setFramesToLoad(framesIndexes: number[]): Promise<void> {
    this.framesLoader.setFramesToLoad(framesIndexes)

    return Promise.resolve()
  }

  async setNextFrameToLoad(index: number): Promise<string | undefined> {
    const res = await this.framesLoader.setNextFrameToLoad(index)

    return res || ''
  }

  cleanup(): void {
    this.framesLoader.cleanup()
    FramesLoaderEvents.frameInvalid.off(this.onFrameInvalid)
  }
}
