import { FilterOperator } from '@/modules/AdvancedFilters/FilterOperator'
import { FilterSubject } from '@/modules/AdvancedFilters/FilterSubject'
import { WorkflowStatus } from '@/modules/AdvancedFilters/WorkflowStatus'
import { createAdvancedFilter } from '@/modules/AdvancedFilters/filterFactory'
import type { NeuralModelAction } from '@/store/modules/neuralModel/types'
import { loadV2DatasetGeneralCounts } from '@/backend/darwin/loadV2DatasetGeneralCounts'

/**
 * Fetches dataset item counts for specified new model dataset and selected classes,
 * and pushes into store. Used to determine counts for training validation and
 * test sets.
 */
export const loadNewModelTrainingCounts: NeuralModelAction<void> = async ({ commit, state }) => {
  const { newModelDataset, newModelSelectedClassIds } = state

  if (!newModelDataset) {
    throw new Error('Cannot fetch dataset counts with no dataset selected')
  }

  if (newModelSelectedClassIds.length === 0) {
    throw new Error('Cannot fetch dataset counts with no classes selected')
  }

  // we load counts for the selected dataset, selected classes, completed items only
  const classFilter = {
    subject: FilterSubject.AnnotationClass,
    matcher: {
      name: FilterOperator.AnyOf,
      values: newModelSelectedClassIds,
    },
  }

  const statusFilter = {
    subject: FilterSubject.WorkflowStatus,
    matcher: {
      name: FilterOperator.AnyOf,
      values: [WorkflowStatus.Complete],
    },
  }

  const response = await loadV2DatasetGeneralCounts({
    teamSlug: newModelDataset.team_slug,
    dataset_ids: [newModelDataset.id],
    filter: createAdvancedFilter(classFilter, statusFilter),
  })

  if ('data' in response) {
    commit('SET_NEW_MODEL_TRAINING_COUNTS_V2', {
      counts: response.data,
      datasetId: newModelDataset.id,
    })
  }

  return response
}
