import type { Annotation } from '@/modules/Editor/models/annotation/Annotation'
import type { Bounds, Raster } from '@/modules/Editor/models/raster/Raster'
import { assertImageRaster } from '@/modules/Editor/models/raster/assertImageRaster'
import { assertVideoRaster } from '@/modules/Editor/models/raster/assertVideoRaster'
import { RasterTypes } from '@/modules/Editor/models/raster/rasterTypes'
import type { View } from '@/modules/Editor/views/view'
import { isMaskAnnotationEmpty } from '@/modules/Editor/plugins/mask/utils/shared/isMaskAnnotationEmpty'
import { getAnnotationSegmentFromRasterLabel } from '@/modules/Editor/plugins/mask/utils/shared/getAnnotationSegmentFromRasterLabel'
import { isVideoAnnotationData } from '@/modules/Editor/models/annotation/annotationKindValidator'

/**
 * Updates a mask annotation and the corresponding raster, and
 * publish the change to the annotationManager.
 */
export const updateMaskAnnotation = (
  view: View,
  raster: Raster,
  annotation: Annotation,
  bounds: Bounds,
  frameIndex?: number,
): void => {
  const frameIndexModified = frameIndex ?? view.currentFrameIndex
  const annotationData = {
    rasterId: raster.id,
  }

  const labelIndex = raster.getLabelIndexForAnnotationId(annotation.id)

  if (labelIndex === undefined) {
    throw new Error('label not found on raster')
  }

  if (raster.type === RasterTypes.VIDEO || raster.type === RasterTypes.VOXEL) {
    const videoRaster = assertVideoRaster(raster)

    const videoAnnotationData = annotation.data

    if (!isVideoAnnotationData(videoAnnotationData)) {
      throw new Error('Annotation data for VOXEL or VIDEO raster is invalid')
    }

    const rasterBufferAccessor = videoRaster.getBufferForEdit(frameIndexModified)
    const isEmptyOnFrame = isMaskAnnotationEmpty(labelIndex, rasterBufferAccessor, bounds)

    if (isEmptyOnFrame) {
      // Synchronise the raster layer if the mask is empty on the frame
      videoRaster.deleteLabelOnKeyframe(labelIndex, frameIndexModified)
      videoRaster.deleteVideoBoundsForLabelIndexForFrame(labelIndex, frameIndexModified)
    } else {
      if (!videoRaster.frameBuffers[frameIndexModified]) {
        videoRaster.createNewKeyframe(frameIndexModified)
      }

      // Synchronise the raster layer with the updated label bounds
      videoRaster.setVideoBoundsForLabelIndexForFrame(labelIndex, frameIndexModified, bounds)
      videoRaster.setLabelOnKeyframe(labelIndex, frameIndexModified)

      videoAnnotationData.frames[frameIndexModified] = annotationData
    }

    if (!videoAnnotationData.segments) {
      throw new Error('Annotation segments not found on VOXEL or VIDEO raster')
    }
    // We should not rely on mask annotation segments, but the right-hand sidebar does.
    // TODO: we should update the sidebar so that it checks rasterRange instead
    videoAnnotationData.segments[0] = getAnnotationSegmentFromRasterLabel(videoRaster, labelIndex)
    view.annotationManager.updateAnnotationData(annotation, videoAnnotationData)
  } else {
    view.annotationManager.updateAnnotationData(annotation, annotationData)

    const imageRaster = assertImageRaster(raster)

    imageRaster.setBoundsForLabelIndex(labelIndex, bounds)
  }

  view.annotationManager.updateAnnotation(annotation, {
    updatedFramesIndices: [frameIndexModified],
  })
}
