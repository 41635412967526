// eslint-disable-next-line boundaries/element-types
import type { RenderableImage } from '@/store/modules/workview/types'

import { imageFilterToString } from './imageFilterToString'
import { resolveTransformedImageData } from './utils/resolveTransformedImageData'
import type { View } from './views/view'

export const renderImageOnCanvas = (
  view: View,
  canvas: HTMLCanvasElement,
  image: RenderableImage,
): boolean => {
  // clicker provide tmp canvas so we need to set its size here.
  canvas.width = view.camera.width
  canvas.height = view.camera.height
  const ctx = canvas.getContext('2d')
  if (!ctx) {
    return false
  }

  ctx.clearRect(0, 0, view.camera.width, view.camera.height)
  if (!image.data) {
    return false
  }

  // 'default' color map is hardcoded here, since we no longer apply
  // it this way and instead use a css filter.
  const transformedData = resolveTransformedImageData(
    image,
    view.imageFilter.windowLevels,
    'default',
    null,
  )
  if (!transformedData) {
    return false
  }
  ctx.imageSmoothingEnabled = !!view.imageFilter.isImageSmoothing
  const [dx, dy, dw, dh] = view.camera.drawImageParams(image.data)
  ctx.filter = imageFilterToString(view.imageFilter)
  ctx.drawImage(transformedData, dx, dy, dw, dh)
  ctx.filter = 'none'
  ctx.imageSmoothingEnabled = true

  return true
}
