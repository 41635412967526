// eslint-disable-next-line no-restricted-syntax
import Hls from 'hls.js'
import debounce from 'lodash/debounce'
import range from 'lodash/range'
import throttle from 'lodash/throttle'

import { isRealFPSDisplayOn, setRealFPS } from '@/performance/measure'
import { isSafariBrowser } from '@/core/utils/browser'
import { extractSegmentIndexFromURL } from '@/core/utils/file'
import {
  EditorEvents,
  EditorExceptions,
  FileManagerEvents,
  FrameManagerEvents,
  StreamViewEvents,
  ViewEvents,
  type ViewEvent,
  LayoutEvents,
} from '@/modules/Editor/eventBus'
import type { Editor } from '@/modules/Editor/editor'
import { Object2D } from '@/modules/Editor/models/layers/object2D'
import { renderHTMLVideo } from '@/modules/Editor/renderHTMLVideo'
import { renderImageOnCanvas } from '@/modules/Editor/renderImageOnCanvas'
import { renderMeasureRegion } from '@/modules/Editor/renderMeasureRegion'
import calcAdjustedVideoTimestamp from '@/modules/Editor/utils/calcAdjustedVideoTimestamp'
import { FrameExtractionError } from '@/modules/Editor/utils/frameExtractor'
import { playVideo } from '@/modules/Editor/utils/video'
import { VideoView } from '@/modules/Editor/views/videoView'
import type { FramesLoaderConfig } from '@/modules/Editor/workers/FramesLoaderWorker/types'
import { addBreadcrumb, setContext } from '@/services/sentry'
// eslint-disable-next-line boundaries/element-types
import type { V2DatasetItemPayload } from '@/store/types/V2DatasetItemPayload'
// eslint-disable-next-line boundaries/element-types
import { type V2DatasetItemSlot } from '@/store/types/V2DatasetItemSlot'
import { errorsByCode } from '@/backend/error'
import { getToken } from '@/backend/token'

import { ViewTypes } from './viewTypes'
import { createContextId } from '@/modules/Editor/utils/createContextId'

const FRAME_LOADER_THROTTLE_MS = 500

/**
 * @event video:canplay
 */
export class StreamView extends VideoView {
  readonly type = ViewTypes.STREAM

  private streamHQUrl: string = ''
  private streamLQUrl: string = ''

  private playPromise?: Promise<void>

  private LQVideoEl: HTMLVideoElement | null = null
  private HQVideoEl: HTMLVideoElement | null = null

  private _hlsHQ: Hls | null = null
  private _hlsLQ: Hls | null = null

  protected previewFrameIndex: number | null = null

  private _isVideoLoaded: boolean = false
  public get isVideoLoaded(): boolean {
    return this._isVideoLoaded
  }

  private set isVideoLoaded(val: boolean) {
    this._isVideoLoaded = val

    const ready = this.canplay && this.isVideoLoaded
    StreamViewEvents.readyToPlayStateChanged.emit({ viewId: this.id, slotName: this.name }, ready)
  }

  // Keeps track of start and end time for loaded segments
  // Example: [[0, 6.3], [6.30001, 12], ...]
  private _loadedSegments: Array<number[]> = []

  private addLoadedSegments(segment: number[]): void {
    this._loadedSegments.push(segment)
  }

  private removeLoadedSegments(startOffset: number, endOffset: number): void {
    this._loadedSegments = this._loadedSegments.filter(
      (segment) => segment[0] < startOffset || segment[1] > endOffset,
    )
  }

  private _loadingSegments: Set<string> = new Set()

  private updateLoadingSegments(segment: number[], loading: boolean): void {
    if (loading) {
      this._loadingSegments.add(JSON.stringify(segment))
    } else {
      this._loadingSegments.delete(JSON.stringify(segment))
    }
  }

  private _canplay: boolean = false
  private get canplay(): boolean {
    return this._canplay
  }

  private set canplay(val: boolean) {
    this._canplay = val

    const ready = this.canplay && this.isVideoLoaded
    StreamViewEvents.readyToPlayStateChanged.emit({ viewId: this.id, slotName: this.name }, ready)
  }

  private readonly shouldCreateLQVideoEl: boolean

  constructor(
    public editor: Editor,
    file: V2DatasetItemSlot,
    item: V2DatasetItemPayload,
    initialFrameIndex: number = -1,
    framesLoaderConfig: FramesLoaderConfig = {},
  ) {
    super(editor, file, item, initialFrameIndex, framesLoaderConfig)

    // By default, we create a LQ video element only for long videos
    this.shouldCreateLQVideoEl = this.fileManager.isSectionless

    if (isSafariBrowser()) {
      EditorEvents.message.emit({
        content:
          'Sorry, but Safari does not support Streamed video, you should use the Non-Streamed video version or switch to the Chrome browser instead',
        level: 'warning',
        code: 'FEATURE_NOT_SUPPORTED_BY_BROWSER',
      })
    }

    this.streamHQUrl = this.fileManager.getStreamUrl('high')
    this.streamLQUrl = this.fileManager.getStreamUrl('low')

    if (this.streamLQUrl === this.fileManager.legacyStreamUrl) {
      // Fallback to use only one HQ stream when we don't have any LQ stream preset
      this.shouldCreateLQVideoEl = false
    }

    FrameManagerEvents.firstSegmentLoaded.on(this.onFirstSegmentLoaded)
    FrameManagerEvents.manifestLoaded.on(this.onManifestSegmentLoaded)
    FileManagerEvents.itemFileLoaded.on(this.onItemLoaded)
    FileManagerEvents.itemFileLoadingError.on(this.onItemLoadError)
    EditorEvents.playbackSpeedUpdated.on(this.onPlaybackSpeedUpdated)
    LayoutEvents.activeViewChanged.on(this.onActiveViewChange)

    this.setupHLS()
  }

  private onActiveViewChange = ({ newViewId }: { newViewId: string }): void => {
    if (newViewId !== this.id) {
      return
    }

    // Try to reload the current frame when missing, on activation
    if (!this.currentFrame) {
      this.jumpToFrame(this.currentFrameIndex)
    }
  }

  /**
   * Updates the playback speed of the video.
   * If the video is playing, it will be paused and then resumed to apply the new speed.
   *
   * @param {number} speed - The new playback speed.
   * @returns {void}
   */
  private onPlaybackSpeedUpdated = (speed: number): void => {
    if (!this.HQVideoEl) {
      return
    }
    this.HQVideoEl.playbackRate = speed
    if (!this.isPlaying) {
      return
    }
    this.HQVideoEl.pause()
    this.HQVideoEl.play()
  }

  private onFirstSegmentLoaded = ({ viewId }: ViewEvent): void => {
    if (viewId === this.id) {
      this.updateLoading({ firstSegment: false })
    }
  }
  private onManifestSegmentLoaded = ({ viewId }: ViewEvent): void => {
    if (viewId === this.id) {
      this.updateLoading({ manifest: false })
    }
  }
  private onItemLoaded = ({ viewId }: ViewEvent): void => {
    if (viewId === this.id) {
      this.updateLoading({ itemFile: false })
    }
  }

  private onItemLoadError = ({ viewId }: ViewEvent, { error }: { error: Error }): void => {
    if (viewId === this.id) {
      EditorEvents.message.emit({
        content: error?.message || 'Error loading the item, please try to refresh the page',
        level: 'error',
      })
      this.updateLoading({ itemFile: false, manifest: false })
    }
  }

  private updateCamera(): void {
    this.camera.setImage({
      width: this.HQVideoEl?.videoWidth || 0,
      height: this.HQVideoEl?.videoHeight || 0,
    })
    this.camera.initialScaleToFit()
  }

  private initialised: boolean = false

  /**
   * Represents the pending status of loading for various items.
   * When all are true, the video view is to be considered fully loaded.
   *
   * @type {Object}
   * @property {boolean} manifest - Indicates if the manifest is loading.
   * @property {boolean} firstSegment - Indicates if the first segment is loading.
   * @property {boolean} itemFile - Indicates if the item file is loading.
   */
  private loadingPending = {
    manifest: true,
    firstSegment: true,
    itemFile: true,
  }
  public updateLoading = (update: Partial<typeof this.loadingPending>): void => {
    this.loadingPending = {
      ...this.loadingPending,
      ...update,
    }
    const isLoaded = Object.values(this.loadingPending).every((loading) => loading === false)

    if (!isLoaded) {
      this.loading = true
      return
    }

    this.loading = false

    if (!this.initialised) {
      this.init()
    }
  }

  private frameSeeking: boolean = false
  // This is useful when jumping to a session that is still loading, as it should stop playback
  // The function is debounced as we don't want to show a loader for few ms when segment is
  // actually already loaded (example is moving to next frame)
  private onLQSeek = debounce((seeking: boolean) => {
    this.canplay = this.canplay && !seeking

    if (!seeking) {
      this.seekHQVideoToFrame(this.currentFrameIndex)
    }
  }, 250)

  /**
   * @deprecated
   */
  private onSeek = debounce((seeking: boolean) => {
    this.canplay = this.canplay && !seeking
  }, 250)

  /**
   * Setup Stream connection using HLS lib.
   *
   * @returns
   */
  private setupHLS(): void {
    if (!this.streamHQUrl) {
      throw new Error('No stream url in setupHLS')
    }

    this.HQVideoEl = document.createElement('video')
    this.HQVideoEl.id = `hq-video-element-${this.id}`

    // Hack to force the browser to render the video on the canvas with
    // < 1 fps
    if (this.fileManager.fps < 1) {
      document.body.appendChild(this.HQVideoEl)
      this.HQVideoEl.style.position = 'absolute'
      this.HQVideoEl.style.width = '1px'
      this.HQVideoEl.style.pointerEvents = 'none'
      this.HQVideoEl.controls = true
    } else {
      this.HQVideoEl.style.display = 'none'
    }
    const hls = new Hls({
      backBufferLength: 90,
      // running HLS in a worker does not allow reading the generated segment data
      // (FRAG_LOADED sends an empty buffer)
      enableWorker: false,
      /**
       * We've set `maxFragLookUpTolerance` as it was causing issues loading the correct segment,
       * sometimes loading the one after because of this tolerance (default 0.25s)
       * Although testing didn't surface any issue, we might have a possible delay while playing
       * a video that have heavy segments.
       * Given the lookup was preventing frame extraction, this is deemed ok at time of writing,
       * subject to tweaking in case we see playback behaves poorly
       */
      maxFragLookUpTolerance: 0,
      xhrSetup: (xhr): void => {
        const token = getToken()
        xhr.setRequestHeader('Authorization', `Bearer ${token}`)
      },
    })
    hls.attachMedia(this.HQVideoEl)

    hls.on(Hls.Events.FRAG_LOADING, (_event, data) => {
      this.updateLoadingSegments([data.frag.start, data.frag.end], true)
    })
    hls.on(Hls.Events.FRAG_LOADED, (_event, data) => {
      this.updateLoadingSegments([data.frag.start, data.frag.end], false)

      const segment = new Uint8Array(data.payload)
      const segmentIndex = extractSegmentIndexFromURL(data.frag.url)
      if (segment.length > 0) {
        StreamViewEvents.segmentLoaded.emit(
          { viewId: this.id },
          {
            binaryData: segment,
            index: segmentIndex,
            segmentFileUrl: data.frag.url,
            start: data.frag.startPTS || data.frag.start,
            end: data.frag.endPTS || data.frag.end,
          },
        )
      }
    })
    hls.on(Hls.Events.FRAG_PARSED, (_event, data) => {
      // PTS stands for presentation time stamp
      // maxStartPTS accounts for the end PTS of the previous fragment to avoid missing frames
      // but we need to wait for FRAG_PARSED, as maxStartPTS is not available in FRAG_LOADED
      const fragStart = data.frag.start === 0 ? 0 : data.frag.maxStartPTS || data.frag.start
      this.addLoadedSegments([fragStart, data.frag.end])
    })
    hls.on(Hls.Events.BUFFER_FLUSHING, (_event, data) => {
      if (data.type !== 'video' && data.type !== 'audiovideo') {
        return
      }

      const { startIndex, endIndex } = this.timeRangeToFrames(data.startOffset, data.endOffset)
      FrameManagerEvents.framesFlushed.emit(
        { viewId: this.id, slotName: this.name },
        range(startIndex, endIndex, 1),
      )
      StreamViewEvents.flushRange.emit(
        { viewId: this.id, slotName: this.name },
        { start: data.startOffset, end: data.endOffset },
      )
      this.removeLoadedSegments(data.startOffset, data.endOffset)
    })
    hls.once(Hls.Events.MEDIA_ATTACHED, () => {
      hls.loadSource(this.streamHQUrl)
      let hadMediaError = false
      let shouldTryCodecSwap = false

      // To set the video currentTime to the current frame index
      // when it was initialized with a frame index different than 0
      // NOTE: we need it once when the video is attached and get some data to play
      hls.once(Hls.Events.FRAG_LOADED, () => {
        if (this.currentFrameIndex !== 0) {
          this.jumpToFrame(this.currentFrameIndex)
        }
      })

      hls.on(Hls.Events.ERROR, (event, data) => {
        setContext('HLS error', { data })
        if (data.fatal) {
          if (data.type === Hls.ErrorTypes.MEDIA_ERROR) {
            if (hadMediaError) {
              // hadMediaError is true when we tried to recover and swap codec, but it didn't work
              if (
                data.details === Hls.ErrorDetails.BUFFER_INCOMPATIBLE_CODECS_ERROR &&
                data.error instanceof Error
              ) {
                // We tried to recover and swap codec, nothing has worked
                EditorEvents.message.emit({
                  content: errorsByCode.VIDEO_CODEC_NOT_SUPPORTED,
                  level: 'error',
                })
                this.onFatalError()
                return
              }

              // Return to stop trying to recover
              // at this stage we have tried to recover 2 times and it didn't work
              return
            }

            // When recoverMediaError hasn't solved the problem
            // if another Media Error is raised 'quickly' after this first Media Error
            // first call hls.swapAudioCodec(), then call hls.recoverMediaError().
            // https://github.com/video-dev/hls.js/blob/master/docs/API.md#hlsswapaudiocodec
            if (shouldTryCodecSwap) {
              hls.swapAudioCodec()
              hadMediaError = true
            }

            console.warn('fatal media error encountered, try to recover')
            if (!hadMediaError) {
              // This is the first error, let's set the flag to swap if another error occurs
              shouldTryCodecSwap = true
            }
            hls.recoverMediaError()
          } else if (data.type === Hls.ErrorTypes.NETWORK_ERROR) {
            EditorEvents.message.emit({ content: errorsByCode.NETWORK_ERROR, level: 'warning' })
          } else {
            setContext('HLS error', { event, data })
            console.error('v2 streamView hls load source error')
            hls.destroy()
          }
        }
      })
      hls.on(Hls.Events.MANIFEST_PARSED, () => {
        // the bearer token is only valid for the index not for the actual segments
        hls.config.xhrSetup = (): void => {}
      })

      if (this.shouldCreateLQVideoEl) {
        this.HQVideoEl?.addEventListener('seeked', () => {
          this.clearFrameSeeking()
          this.mainLayer.changed()

          if (this.HQVideoEl) {
            const time = this.getTimeFromFrameIndex(this.HQVideoEl, this.currentFrameIndex)
            if (time !== null && this.isLoadedTimeSegment(time)) {
              this.annotationsAndFrameSyncManager.frameRenderedForKey(
                `${this.name}_${this.currentFrameIndex}`,
              )
            }
          }
        })
      } else {
        this.HQVideoEl?.addEventListener('seeking', () => {
          this.onSeek(true)
        })
        this.HQVideoEl?.addEventListener('seeked', () => {
          if (this.HQVideoEl) {
            const time = this.getTimeFromFrameIndex(this.HQVideoEl, this.currentFrameIndex)
            if (time !== null && this.isLoadedTimeSegment(time)) {
              this.annotationsAndFrameSyncManager.frameRenderedForKey(
                `${this.name}_${this.currentFrameIndex}`,
              )
            }
          }

          this.onSeek(false)
        })
      }
      this.HQVideoEl?.addEventListener('canplay', () => {
        this.canplay = true
      })
      this.HQVideoEl?.addEventListener('loadeddata', () => {
        this.isVideoLoaded = true
        this.updateCamera()
      })
      this.HQVideoEl?.addEventListener(
        'timeupdate',
        throttle(
          () => {
            this.mainLayer.changed()
          },
          100,
          { trailing: true },
        ),
      )
    })
    this._hlsHQ = hls

    if (this.shouldCreateLQVideoEl) {
      this.setupLowQualityHLS()
    }
  }

  private setupLowQualityHLS(): void {
    this.LQVideoEl = document.createElement('video')
    this.LQVideoEl.id = `lq-video-element-${this.id}`
    this.LQVideoEl.style.display = 'none'
    const hlsLow = new Hls({
      backBufferLength: 90,
      enableWorker: true,
      xhrSetup: (xhr): void => {
        const token = getToken()
        xhr.setRequestHeader('Authorization', `Bearer ${token}`)
      },
    })
    hlsLow.attachMedia(this.LQVideoEl)

    hlsLow.once(Hls.Events.MEDIA_ATTACHED, () => {
      hlsLow.loadSource(this.streamLQUrl)

      hlsLow.on(Hls.Events.FRAG_LOADED, (_event, data) => {
        const { startIndex, endIndex } = this.timeRangeToFrames(data.frag.start, data.frag.end)
        FrameManagerEvents.framesReady.emit(
          { viewId: this.id, slotName: this.name },
          range(startIndex, endIndex, 1),
        )
      })

      hlsLow.on(Hls.Events.MANIFEST_PARSED, () => {
        // the bearer token is only valid for the index not for the actual segments
        hlsLow.config.xhrSetup = (): void => {}
      })

      this.LQVideoEl?.addEventListener('seeking', () => {
        // Preview mode don't need to trigger seeking logic
        if (this.previewFrameIndex !== null) {
          return
        }
        this.setFrameSeeking()
        this.onLQSeek(true)
      })
      this.LQVideoEl?.addEventListener('seeked', () => {
        this.onLQSeek(false)
      })
    })
    this._hlsLQ = hlsLow
  }

  private timeRangeToFrames(start: number, end: number): { startIndex: number; endIndex: number } {
    if (!this.HQVideoEl) {
      return { startIndex: 0, endIndex: 0 }
    }

    const duration = end - start

    const startIndex = Math.floor((start / this.HQVideoEl.duration) * this.totalFrames)
    const endIndex = Math.ceil(((start + duration) / this.HQVideoEl.duration) * this.totalFrames)

    return {
      startIndex,
      endIndex,
    }
  }

  protected onFatalError(): void {
    this.loading = false
    this._hlsHQ?.destroy()
    this._hlsLQ?.destroy()
  }

  private setFrameSeeking = throttle(
    () => {
      this.frameSeeking = true
    },
    300,
    { leading: false },
  )

  private clearFrameSeeking = (): void => {
    this.frameSeeking = false
    this.setFrameSeeking.cancel()
  }

  /**
   * Video preview support only low quality video (because of the size)
   */
  private get canPreviewVideoFrame(): boolean {
    return this.previewFrameIndex !== null && !!this.LQVideoEl
  }

  async init(): Promise<void> {
    this.initialised = true
    this.framesIndexes = this.fileManager.framesIndexes
    this.initState()

    this.showFramesTool = this.framesIndexes.length > 1

    this.mainLayer.clear()
    this.mainLayer.add(
      new Object2D('stream-video', (ctx, canvas) => {
        if (
          this.canPreviewVideoFrame ||
          this.frameSeeking ||
          this.isPlaying ||
          !this.currentFrame
        ) {
          const videoEl =
            (this.frameSeeking && this.LQVideoEl) || this.canPreviewVideoFrame
              ? this.LQVideoEl
              : this.HQVideoEl
          if (!videoEl) {
            throw new Error('v2 streamView, init failed at missing videoEl')
          }

          renderHTMLVideo(
            canvas,
            videoEl,
            this.imageFilter,
            this.camera,
            this.width,
            this.height,
            this.windowLevelsRange,
          )

          if (this.previewFrameIndex !== null) {
            return
          }

          // if video is seeking the frame (LQ frame) and the time segment is loaded
          // we mark the frame as rendered
          // NOTE: we don't have a good way to verify the video frame rendered state
          // it depends on the video element
          if (this.frameSeeking && this.isLoadedTimeSegment(videoEl.currentTime)) {
            const frameIndex = this.fileManager.frameManager.getFrameIndexAtTime(
              videoEl.currentTime,
            )
            this.annotationsAndFrameSyncManager.frameRenderedForKey(`${this.name}_${frameIndex}`)
            ViewEvents.currentFrameDisplayed.emit(this.viewEvent, frameIndex)
          }
        } else {
          // if video is not playing, we render a still of the extracted frame
          // closest to the currently paused position of the video
          renderImageOnCanvas(this, canvas, this.currentFrame)

          if (this.currentFrame) {
            this.annotationsAndFrameSyncManager.frameRenderedForKey(
              `${this.name}_${this.currentFrameIndex}`,
            )
            ViewEvents.currentFrameDisplayed.emit(this.viewEvent, this.currentFrameIndex)
          }
        }

        if (this.editor.renderMeasures) {
          renderMeasureRegion(this)
        }
      }),
    )

    // Reset the current image filter's window level
    this.setImageFilter({
      ...this.imageFilter,
      windowLevels: this.defaultWindowLevels,
    })

    if (this.HQVideoEl) {
      this.camera.setImage({
        width: this.fileManager.imageWidth || this.HQVideoEl.videoWidth,
        height: this.fileManager.imageHeight || this.HQVideoEl.videoHeight,
      })
    }

    // Reset tool
    const { currentTool } = this.toolManager
    if (currentTool) {
      currentTool.tool.reset(currentTool.context)
    }

    const contextId = createContextId()
    await this.jumpToFrame(this.currentFrameIndex, contextId)
    this.initCamera()
    this.camera.initialScaleToFit()
  }

  public get readyToPlay(): boolean {
    return this.canplay && this.isVideoLoaded
  }

  private isLoadedTimeSegment(time: number): boolean {
    return this._loadedSegments.some((segment) => segment[0] <= time && segment[1] > time)
  }

  private get shouldExtractFrames(): boolean {
    return this.fileManager.isFrameExtractorEnabled
  }

  private isBuffering: boolean = false

  public play(): void {
    if (!this.canplay && !this.isVideoLoaded) {
      return
    }
    if (!this.HQVideoEl) {
      return
    }
    if (this.isPlaying) {
      return
    }
    if (this.currentFrameIndex === this.totalFrames - 1) {
      // We are starting to play from the last frame. Although we might not be at the end, we should
      // restart from the beginning, regardless of the playback loop config
      this.lqJumpToFrame(0)
    }

    this.HQVideoEl.playbackRate = this.editor.videoPlaybackSpeed
    this.playPromise = playVideo(this.HQVideoEl)
    this.isPlaying = true

    let lastCalledTime = performance.now()
    const interval = 1000 / this.fileManager.fps

    const renderLoop = (now: number): void => {
      const delta = now - lastCalledTime

      if (this.isPlaying) {
        if (delta >= interval) {
          lastCalledTime = now
          this.playVideoCallback()
        }

        requestAnimationFrame(renderLoop)
      }
    }

    requestAnimationFrame(renderLoop)
  }

  /**
   * Restarts the playback of the video, if loop is enabled
   */
  private async onEndOfPlayback(): Promise<void> {
    if (!this.HQVideoEl) {
      return
    }
    if (this.editor.videoPlaybackLoop) {
      this.playPromise = playVideo(this.HQVideoEl)
      this.currentFrameIndex = 0
      return
    }
    this.currentFrameIndex = this.totalFrames - 1
    await this.pause()
  }

  private lastCalledTime = performance.now()

  private _playVideoCallbackStillRunning: boolean = false
  public playVideoCallback = async (): Promise<void> => {
    try {
      if (this._playVideoCallbackStillRunning) {
        // We are still waiting for promises to finish, we can't call another callback
        // or we will have multiple callbacks running at the same time
        return
      }
      if (!this.HQVideoEl) {
        return
      }
      this._playVideoCallbackStillRunning = true
      const { currentTime, duration } = this.HQVideoEl
      const length = this.totalFrames

      if (this.isPlaying && this.HQVideoEl.ended) {
        await this.onEndOfPlayback()
      }

      if (this.annotationsAndFrameSyncManager.shouldPausePlayback) {
        // Video is not ready to be played, show buffering ui and stop the playback
        this.isBuffering = true
        this.HQVideoEl.pause()
        this._playVideoCallbackStillRunning = false
        return
      }

      if (this.isBuffering) {
        // Video was in buffering state but we have the annotation frame data, resume playback
        this.isBuffering = false
        await this.HQVideoEl.play()
      }

      const nextFrameIndex = this.shouldExtractFrames
        ? this.fileManager.frameManager.getFrameIndexAtTime(currentTime) + 1
        : Math.round((currentTime / duration) * length) % length

      if (this.currentFrameIndex !== nextFrameIndex && this.isPlaying) {
        // Sometimes, because of a combination of fps and frame density, we can get to the last
        // frame without the video element having `ended === true`, so we might calculate a
        // `nextFrameIndex` outside ot the total frame range. If that's the case, restart playback
        if (nextFrameIndex >= this.totalFrames) {
          await this.onEndOfPlayback()
        } else if (nextFrameIndex === 0) {
          // If next frame is different from the current one, and value is zero, then we've reached
          // the end of the playback, and we should handle it properly with `onEndOfPlayback`
          await this.onEndOfPlayback()
        } else {
          this.currentFrameIndex = nextFrameIndex
        }

        if (this.isPlaying) {
          // We update the video current time faster than we render frames
          // and we can't verify displayed frame index during the playback
          this.annotationsAndFrameSyncManager.frameRenderedForKey(
            `${this.name}_${this.currentFrameIndex}`,
          )
        }

        if (isRealFPSDisplayOn()) {
          const delta = performance.now() - this.lastCalledTime
          this.lastCalledTime = performance.now()
          setRealFPS(1 / (delta / 1000))
        }

        // jumping between frames changes subAnnotation content so the redraw option is enabled
        this.resetSubannotations()
      }

      this.allLayersChanged()
      this._playVideoCallbackStillRunning = false
    } catch (e) {
      setContext('playVideoCallback_error', {
        description: 'The video playback loop failed.',
        error: e,
      })
      this._playVideoCallbackStillRunning = false
    }
  }

  async pause(): Promise<void> {
    const contextId = createContextId()
    try {
      await this.pauseVideo()
    } catch (e) {
      setContext('pause_error', { error: e })
      console.error('v2 streamView, pause failed at pauseVideo')
    }

    this.videoInterval && window.clearInterval(this.videoInterval)
    this.isPlaying = false

    this.currentFrame = undefined
    this.clearFrameSeeking()

    try {
      await this.jumpToFrame(this.currentFrameIndex, contextId)
    } catch (e) {
      setContext('pause_error', { error: e })
      console.error('v2 streamView, pause failed at jumpToFrame')
    }
  }

  resetSubannotations(): void {
    this.annotationManager.invalidateAnnotationCache()
    this.commentManager.deselectItem()
  }

  public setPreviewFrameIndex(index: number): void {
    this.previewFrameIndex = index
    this.seekLQVideoToFrame(this.previewFrameIndex)
    this.mainLayer.changed()
    this.annotationsLayer.hideAll()
    this.annotationsLayer.changed()
  }

  public clearPreviewFrameIndex(): void {
    this.previewFrameIndex = null
    this.mainLayer.changed()
    this.seekLQVideoToFrame(this.currentFrameIndex)
    this.clearFrameSeeking()
    this.annotationsLayer.showAll()
    this.annotationsLayer.changed()
  }

  /**
   * Fast jumpToFrame using video only
   *
   * @param {number} frameIndex
   * @returns
   */
  async lqJumpToFrame(frameIndex: number): Promise<void> {
    if (this.editor.freezeFrame) {
      EditorExceptions.cannotJumpToFrame.emit(frameIndex)
      return
    }

    if (frameIndex < 0 || frameIndex > this.lastFrameIndex) {
      return
    }

    this.currentFrameIndex = frameIndex

    // Update the render key for next render (optimisedLayer > renderCached)
    this.annotationsLayer.setKeyForNextRender(`${this.name}_${this.currentFrameIndex}`)

    if (this.shouldCreateLQVideoEl) {
      this.seekLQVideoToFrame(this.currentFrameIndex)
    } else {
      this.seekHQVideoToFrame(this.currentFrameIndex)
    }

    const shouldHideLQFrames = this.shouldExtractFrames

    if (this.fileManager.isHQFrameLoaded(frameIndex)) {
      this.loading = false
      let frame
      try {
        frame = await this.fileManager.getHQFrame(this.currentFrameIndex)
      } catch (e) {
        setContext('lqJumpToFrame_error', { error: e })
        console.error('v2 streamView, lqJumpToFrame failed at getHQFrame')
      }

      if (frame) {
        this.currentFrame = frame
      }
    } else if (shouldHideLQFrames) {
      // if currentFrame is undefined, the video will be shown in place of an image
      this.currentFrame = undefined
    } else {
      if (!this.isPlaying) {
        // Adding a delay for the loader, so that using the scrubber doesn't show
        // unnecessary loading
        const loadingInt = setTimeout(() => (this.loading = true), FRAME_LOADER_THROTTLE_MS)
        const lqFrame = await this.fileManager.getLQFrame(this.currentFrameIndex, {
          fallbackHQFrame: !this.fileManager.isSectionless,
        })
        if (this.currentFrameIndex === frameIndex) {
          clearTimeout(loadingInt)
          this.loading = false
          this.currentFrame = lqFrame
        }
      }
    }

    // jumping between frames changes subAnnotation content so the redraw option is enabled
    this.resetSubannotations()
    if (!shouldHideLQFrames) {
      this.fileManager.loadFramesFrom(this.currentFrameIndex)
    }

    this.allLayersChanged()
  }

  /**
   * `contextId` is used, for debug purposes and sentry tracking, to follow through the
   * frame extraction process
   **/
  async jumpToFrame(frameIndex: number, contextId?: string): Promise<void> {
    if (this.editor.freezeFrame) {
      EditorExceptions.cannotJumpToFrame.emit(frameIndex)
      return
    }

    if (frameIndex < 0 || frameIndex > this.lastFrameIndex) {
      return
    }

    this.currentFrameIndex = Math.max(0, Math.min(frameIndex, this.totalFrames - 1))

    // Update the render key for next render (optimisedLayer > renderCached)
    this.annotationsLayer.setKeyForNextRender(`${this.name}_${this.currentFrameIndex}`)

    this.seekHQVideoToFrame(this.currentFrameIndex)

    if (this.fileManager.isHQFrameLoaded(frameIndex)) {
      this.loading = false
      let frame
      try {
        frame = await this.fileManager.getHQFrame(this.currentFrameIndex, contextId)
      } catch (e) {
        setContext('jumpToFrame_error', { error: e })
        console.error('v2 streamView, jumpToFrame failed at getHQFrame')
      }

      if (frame) {
        this.currentFrame = frame
        return
      }
    }

    // If we are extracting frames, we don't want to show the LQ frame loader
    this.loading = !this.shouldExtractFrames
    let lqFrame

    const shouldHideLQFrames = this.shouldExtractFrames
    if (shouldHideLQFrames) {
      // if currentFrame is undefined, the video will be shown in place of an image
      this.currentFrame = undefined
    } else {
      try {
        lqFrame = await this.fileManager.getLQFrame(frameIndex)
      } catch (e) {
        this.loading = false
        setContext('jumpToFrame_error', { error: e })
        console.error('v2 streamView, fileManager failed at getLQFrame')
      }
    }

    if (this.currentFrameIndex === frameIndex) {
      if (lqFrame) {
        this.currentFrame = lqFrame
        this.loading = false
      }

      // jumping between frames changes subAnnotation content so the redraw option is enabled
      this.resetSubannotations()
    }

    this.fileManager
      .getHQFrame(frameIndex, contextId)
      .then((hqFrame) => {
        addBreadcrumb({ message: 'HQ frame loaded', data: { frameIndex } })

        if (this.currentFrameIndex === frameIndex) {
          this.currentFrame = hqFrame

          // jumping between frames changes subAnnotation content so the redraw option is enabled
          this.resetSubannotations()
          this.loading = false
        }
      })
      .catch((e: Error | FrameExtractionError) => {
        this.loading = false
        if (e instanceof FrameExtractionError && 'frameNumber' in e) {
          // Frame extraction error. Are we still trying to extract this frame? If not, this
          // is expected if the segment hasn't loaded yet (usually when jumping between
          // distant frames and segments don't have time to load) and we can skip the error
          if (e.frameNumber !== this.currentFrameIndex) {
            return
          }
        }
        setContext('jumpToFrame error', { error: e })
        console.error('v2 streamView, fileManager failed at getHQFrame')
      })
  }

  private getTimeFromFrameIndex(videoEl: HTMLVideoElement, frameIndex: number): number | null {
    const manifest = this.fileManager.frameManager.manifest
    const frameManifest = manifest[frameIndex]

    // if LONG_VIDEOS is disabled, we don't have a frame manifest, so we use legacy approach
    if (!this.shouldExtractFrames || !frameManifest) {
      return (videoEl.duration / this.totalFrames) * frameIndex
    }

    if (!this.fileManager.file.fps) {
      throw new Error('v2 streamView, seekHQVideoToFrame failed at missing file.fps')
    }

    const newTime = calcAdjustedVideoTimestamp(
      Number(frameManifest.timestamp),
      this.fileManager.file.fps,
    )

    return newTime
  }

  private seekHQVideoToFrame(frameIndex: number): void {
    if (!(this.HQVideoEl && this.HQVideoEl.duration > 0)) {
      return
    }

    const currentTime = this.getTimeFromFrameIndex(this.HQVideoEl, frameIndex)
    if (currentTime === null) {
      return
    }

    if (this.HQVideoEl.currentTime === currentTime) {
      return
    }

    this.HQVideoEl.currentTime = currentTime
  }

  private seekLQVideoToFrame(frameIndex: number): void {
    if (!(this.LQVideoEl && this.LQVideoEl.duration > 0)) {
      return
    }

    const currentTime = this.getTimeFromFrameIndex(this.LQVideoEl, frameIndex)
    if (currentTime === null) {
      return
    }

    if (this.LQVideoEl.currentTime === currentTime) {
      return
    }

    this.LQVideoEl.currentTime = currentTime
  }

  async cleanup(): Promise<void> {
    super.cleanup()

    this.clearFrameSeeking()

    FrameManagerEvents.firstSegmentLoaded.off(this.onFirstSegmentLoaded)
    FrameManagerEvents.manifestLoaded.off(this.onManifestSegmentLoaded)
    FileManagerEvents.itemFileLoaded.off(this.onItemLoaded)
    EditorEvents.playbackSpeedUpdated.off(this.onPlaybackSpeedUpdated)
    FileManagerEvents.itemFileLoadingError.off(this.onItemLoadError)
    LayoutEvents.activeViewChanged.off(this.onActiveViewChange)

    if (this.HQVideoEl) {
      await this.pauseVideo()
      this.HQVideoEl.remove()
      this.HQVideoEl = null
    }
    if (this.LQVideoEl) {
      this.LQVideoEl.remove()
      this.LQVideoEl = null
    }

    if (this._hlsHQ) {
      this._hlsHQ.destroy()
      this._hlsHQ = null
    }
    if (this._hlsLQ) {
      this._hlsLQ.destroy()
      this._hlsLQ = null
    }
  }

  private async pauseVideo(): Promise<void> {
    if (this.HQVideoEl) {
      if (this.playPromise) {
        await this.playPromise
      }

      this.HQVideoEl.pause()
    }
  }
}
