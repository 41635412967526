// eslint-disable-next-line boundaries/element-types
import colors from '@/assets/styles/modules/colors.module.scss'
import type { AnnotationClass } from '@/modules/Editor/AnnotationClass'
import { CallbackStatus } from '@/modules/Editor/callbackHandler'
import type { CameraEvent } from '@/modules/Editor/camera'
import type { CompoundPath } from '@/modules/Editor/compoundPath'
import { toPolyBool } from '@/modules/Editor/compoundPath'
import { EditorCursor, selectCursor } from '@/modules/Editor/editorCursor'
import type { ViewEvent } from '@/modules/Editor/eventBus'
import {
  AnnotationManagerEvents,
  CameraEvents,
  ClassEvents,
  EditorEvents,
  LayoutEvents,
  ToolEvents,
  ToolManagerEvents,
} from '@/modules/Editor/eventBus'
import { hasSegmentContainingIndex } from '@/modules/Editor/helpers/segments'
import type { Action } from '@/modules/Editor/managers/actionManager'
import type { IPoint } from '@/modules/Editor/point'
import type { MultiPolygon, Polygon, Segment } from '@/modules/Editor/polygonOperations'
import {
  combine,
  polygon,
  polygonToGeoJSON,
  segments,
  selectDifference,
  selectUnion,
} from '@/modules/Editor/polygonOperations'
import { MaskBrushDimension, ToolName } from '@/modules/Editor/tools/types'
import type { MaskBrushThreshold } from '@/modules/Editor/tools/types'
import type { PointerEvent } from '@/core/utils/touch'
import { isTouchEvent, resolveEventPoint } from '@/core/utils/touch'
import type { Range } from '@/modules/Editor/types'
import { drawBrush } from '@/modules/Editor/graphics'
import type { AnnotationManager } from '@/modules/Editor/managers/annotationManager'
import type { Tool, ToolContext } from '@/modules/Editor/managers/toolManager'
import type { Annotation } from '@/modules/Editor/models/annotation/Annotation'
import {
  isImageAnnotation,
  isVideoAnnotation,
} from '@/modules/Editor/models/annotation/annotationKindValidator'
import {
  cloneAnnotation,
  shallowCloneAnnotation,
} from '@/modules/Editor/models/annotation/cloneAnnotation'
import { inferVideoData } from '@/modules/Editor/models/annotation/inferVideoData'
import { brushSaveAndExitEditMode } from '@/modules/Editor/plugins/brush/saveAndExitEditMode'
import { BrushTool } from '@/modules/Editor/plugins/brush/toolBase'
import { buildRegularMaskPathIn3D } from '@/modules/Editor/plugins/brush/utils/buildRegularMask3DPath'
import { createPolygonBatchedUpdateAction } from '@/modules/Editor/plugins/brush/utils/createPolygonBatchedUpdateAction'
import { BrushMode, getBrushMode } from '@/modules/Editor/plugins/brush/utils/getBrushMode'
import { getCompoundPathFromSegment } from '@/modules/Editor/plugins/brush/utils/getCompoundPathFromSegment'
import { getMaskPixelCenter } from '@/modules/Editor/plugins/brush/utils/getMaskPixelCenter'
import { getTipShape } from '@/modules/Editor/plugins/brush/utils/getTipShape'
import { isMaskBrush } from '@/modules/Editor/plugins/brush/utils/isMaskBrush'
import { isValidPath } from '@/modules/Editor/plugins/brush/utils/isValidPath'
import { resolveCompundPath } from '@/modules/Editor/plugins/brush/utils/resolveCompoundPath'
import { resolveCurrentClass } from '@/modules/Editor/plugins/brush/utils/resolveCurrentClass'
import { throttleReportActivity } from '@/modules/Editor/plugins/brush/utils/throttleReportActivity'
import type { PixelBrushProperties } from '@/modules/Editor/plugins/mask/utils/BrushPainter'
import { BrushPainter } from '@/modules/Editor/plugins/mask/utils/BrushPainter'
import { setupMouseButtonLoadout } from '@/modules/Editor/plugins/mixins/loadouts'
import { setupToolMouseCallbacks } from '@/modules/Editor/plugins/mixins/setupToolMouseCallbacks'
import { POLYGON_ANNOTATION_TYPE } from '@/modules/Editor/plugins/polygon/types'
import { preselectOrPromptForAnnotationClass } from '@/modules/Editor/utils/preselectOrPromptForAnnotationClass'
import { shouldApplyRasterScaling } from '@/modules/Editor/plugins/mask/utils/shared/shouldApplyRasterScaling'
import { is3dBrushAvailable } from '@/modules/Editor/utils/radiology/is3dBrushAvailable'
import { isReformattedDICOMView } from '@/modules/Editor/utils/radiology/isReformattedDicomView'
import type { View } from '@/modules/Editor/views/view'
import { setContext } from '@/services/sentry'
// eslint-disable-next-line boundaries/element-types
import { hexToRGBA, parseRGBA, rgbaString } from '@/uiKit/colorPalette'

import {
  maskAnnotationType,
  polygonAnnotationType,
  TipShape,
  DEFAULT_BRUSH_THRESHOLD,
} from './consts'
import { buildBrushTip } from './utils/buildBrushTip'
import { buildRegularMaskPath } from './utils/buildRegularMaskPath'
import { buildRegularPolygonPath } from './utils/buildRegularPolygonPath'
import { buildSquareMaskPath } from './utils/buildSquareMaskPath'
import { buildSquarePath } from './utils/buildSquarePath'
import { getIndiciesToMerge } from './utils/getIndiciesToMerge'
import { getNewPath } from './utils/getNewPath'
import { getNewPolygon } from './utils/getNewPolygon'
import { getPaddedRangeOfPath } from './utils/getPaddedRangeOfPath'
import { getRangeOfPolygonRegion } from './utils/getRangeOfPolygonRegion'
import { getSides } from './utils/getSides'
import { getTopLeftOfScaledPixel } from './utils/getTopLeftOfScaledPixel'
import { getVoxelRasterDimensions } from './utils/getVoxelRasterDimensions'
import { interpolate } from './utils/interpolate'
import { interpolateSquare } from './utils/interpolateSquare'
import { scaleReformattedPixelPath } from './utils/scaleReformattedPixelPath'
import { translateMaskPath } from './utils/translateMaskPath'
import { translatePath } from './utils/translatePath'
import { MainAnnotationType } from '@/core/annotationTypes'
import { getPrimaryViewFromView } from '@/modules/Editor/plugins/mask/utils/shared/getPrimaryViewFromView'
import { createUpdateRasterAction } from '@/modules/Editor/plugins/mask/utils/shared/createUpdateRasterAction'
import type { RasterDataSnapshot } from '@/modules/Editor/models/raster/Raster'
import { getOrCreateRasterForView } from '@/modules/Editor/plugins/mask/utils/shared/getOrCreateRasterForView'
import { getToolFrameRange } from '@/modules/Editor/plugins/brush/utils/getToolFrameRange'

export class BrushError extends Error {
  constructor(message: string) {
    super(`Error in brush tool: ${message}`)
  }
}

const squareRadiusFromSize = (size: number): number => size / 2 / Math.SQRT2

const MAX_BRUSH_SIZE = 1000
const BRUSH_SCALING_FACTOR = 1.618

/**
 * Resets on every tool activation.
 * Counts up to SAVE_ACTION_RATE and when reached or surpased, trigers a save
 * of the annotation without leaving edit mode.
 *
 * Once it does the save, it resets back to 0.
 */
let saveActionCounter = 0
const SAVE_ACTION_RATE = 5 // How many brush strokes we can make before saving an edit

/**
 * Used to track whether the current edit mode is for a new or existing annotation.
 * Needs to havea 3rd, uknown state, so we can make sure it only gets set once per edit mode.
 */
let crudState: 'create' | 'edit' | 'unknown' = 'unknown'
let clonedAnnotationForUpdate: Annotation | undefined = undefined

/**
 * Based on current crud state and context, resolves whether we're in edit mode
 * for a new or existing annotation and keeps a record of associated intial data.
 *
 * Should be called after every brush stroke, as well as when exiting edit mode
 * with a confirm, in case no brush strokes happened but only actually does
 * something once per edit mode.
 */
const resolveCrudState = (context: ToolContext): void => {
  if (crudState !== 'unknown') {
    return
  }
  const selectedAnnotation = context.editor.activeView.annotationManager.selectedAnnotation
  if (
    saveActionCounter === 0 &&
    !!selectedAnnotation?.id &&
    clonedAnnotationForUpdate?.id !== selectedAnnotation?.id
  ) {
    // we started updating an existing annotation and this is the very first stroke
    // we have to store a clone of it to restore from on undo when we exit edit mode
    crudState = 'edit'
    clonedAnnotationForUpdate = cloneAnnotation(selectedAnnotation)
    clonedAnnotationForUpdate.id = selectedAnnotation.id
  }

  if (saveActionCounter === 0 && !selectedAnnotation?.id) {
    // we started a brand new annotation and this is the very first stroke
    crudState = 'create'
    clonedAnnotationForUpdate = undefined
  }
}

export class PolygonOrMaskBrushTool extends BrushTool implements Tool {
  painter?: BrushPainter

  /** We cache the view Id when we change view we can remove the brush tip. */
  private cachedActiveViewId: string | undefined

  /** Path of the polygon currently being drawn */
  private currentPolygonPath: Segment = getNewPath()

  /** Whole path previously created. Used for undoing/redoing. */
  private previousPolygonCompoundPath: Segment | null = null

  /** Path of the polygon previously drawn. Used for undoing/redoing. */
  private previousPolygonCurrentPath: Segment = getNewPath()

  /**
   * On start of an edit of a polygon, stores all of the individual paths of the polygon,
   * with holes embedded in each (in the style of geoJSON). These are stored in a cache
   * to avoid heavy combine computations, and only become part of the active edit
   * when the brush is moved close to them.
   */
  private polygonPool: Polygon[] = []
  /**
   * This defines the range of each polygon group above, when the brush tip moves within this
   * range we include them in the polybooljs combine computation.
   */
  private polygonRanges: Range[] = []

  /** An array of segments for each polygon group in the polygonPool. */
  private polygonSegmentPool: Segment[] = []

  /** Wether the brush is 2D or 3D (masks) */
  private maskDimension: MaskBrushDimension = MaskBrushDimension.Paint2D
  public get is3DBrush(): boolean {
    return this.maskDimension === MaskBrushDimension.Paint3D
  }

  /** Mask threshold brush min and max limits */
  private maskThreshold: MaskBrushThreshold = DEFAULT_BRUSH_THRESHOLD
  private isMaskThresholdEnabled: boolean = false

  private polygonDrawing: boolean = false

  private pixelMask: Uint8ClampedArray | null = null
  private pixelMaskIn3D: Uint8ClampedArray[] | null = null
  private pixelIndex: IPoint | undefined = undefined

  /** Optional scaling for the non-square pixels in the X-Y direction. */
  private voxelScaling: IPoint | undefined = undefined

  private polygonAnnotationCreationPromise: Promise<string | undefined> | undefined

  /**
   * For mask classes we need to see if the viewport changed,
   * as we may have a different brush tip per viewport.
   */
  private handleActiveViewChanged: (payload: { newViewId: string }) => void = () => {}

  /** We need to check if the current class is a mask, as that could change the brush type. */
  private handleChangeClassId: (classId: number | null) => void = () => {}

  private handleFrameChanged: (
    _viewEvent: ViewEvent,
    { newIndex, oldIndex }: { newIndex: number; oldIndex?: number },
  ) => void = () => {}

  public onStart(context: ToolContext, event: PointerEvent): CallbackStatus | void {
    if (isMaskBrush(context)) {
      return this.maskOnStart(context, event)
    }

    return this.polygonOnStart(context, event)
  }

  public onMove(context: ToolContext, event: PointerEvent): CallbackStatus | void {
    if (isMaskBrush(context)) {
      return this.maskOnMove(context, event)
    }

    return this.polygonOnMove(context, event)
  }

  public onEnd(context: ToolContext, event: PointerEvent): Promise<CallbackStatus | void> {
    if (isMaskBrush(context)) {
      return this.maskOnEnd(context, event)
    }

    return Promise.resolve(this.polygonOnEnd(context, event))
  }

  public onKeyDown(context: ToolContext, event: KeyboardEvent): void {
    if (isMaskBrush(context)) {
      return this.maskOnKeyDown(context, event)
    }

    return this.polygonOnKeyDown(context, event)
  }

  public onKeyUp(context: ToolContext, event: KeyboardEvent): void {
    if (isMaskBrush(context)) {
      return this.maskOnKeyUp(context, event)
    }

    return this.polygonOnKeyUp(context, event)
  }

  public draw(view: View, context: ToolContext): void {
    if (isMaskBrush(context)) {
      return this.maskDraw(view)
    }

    return this.polygonDraw(view)
  }

  public onRender(view: View, context: ToolContext): void {
    if (isMaskBrush(context)) {
      return this.maskOnRender(view, context)
    }

    return this.polygonOnRender(view, context)
  }

  public async deactivate(context: ToolContext): Promise<void> {
    LayoutEvents.activeViewChanged.off(this.handleActiveViewChanged)
    EditorEvents.preselectedClassIdChanged.off(this.handleChangeClassId)
    ToolManagerEvents.frameChanged.off(this.handleFrameChanged)
    context.handles.forEach((handle) => handle.release())
    context.handles.length = 0

    // Reset the handler to remove editor context
    this.handleActiveViewChanged = (): void => {}
    this.handleFrameChanged = (): void => {}
    this.handleChangeClassId = (): void => {}

    if (this.selectedAnnotation) {
      // Transfer the control back to the Layer
      context.editor.activeView.annotationsLayer.show(this.selectedAnnotation.id)
    }

    // we could start edit, then switch to different tool without making a stroke
    // this ensures we can save the changes
    resolveCrudState(context)
    // NOTE -> Calling .savePolygon() is safe here even in MaskBrush mode,
    // as if we are in MaskBrush mode we should not have this.compoundPath defined.
    // We must await here to avoid a race condition
    // `savePolygon` uses `this.clonedAnnotationForUpdate`
    // which gets cleared in `this.reset()`, so if we do not await,
    // the clear might happen too soon
    await this.savePolygon(context, context.editor.activeView.currentFrameIndex)
    context.editor.activeView.annotationsLayer.clearDrawingCanvas()
    this.reset()
  }

  public exitEditMode(view: View): void {
    this.actionGroup?.remove()
    this.actionGroup = undefined

    this.selectedAnnotation = undefined

    view.annotationManager.deselectAllAnnotations()

    view.annotationsLayer.clearDrawingCanvas()
    this.reset()
  }

  public async confirmCurrentAnnotation(context: ToolContext, frameIndex: number): Promise<void> {
    // we may have hit confirm without making any brush strokes.
    // that means crud state wasn't resolved yet so needs to be done now
    resolveCrudState(context)

    await this.savePolygon(context, frameIndex)
    this.reset()
    // Clear active canvas on confirm
    context.editor.activeView.annotationsLayer.clearDrawingCanvas()
  }

  private clearPolygonPreview(): void {
    this.selectedAnnotation = undefined
    this.polygonDrawing = false
    this.previousMousePoint = undefined
    this.currentPolygonPath = getNewPath()
    this.compoundPath = null
    this.previousPolygonCompoundPath = null
    this.previousPolygonCurrentPath = getNewPath()
  }

  public reset(): void {
    // Mask
    if (this.painter && !this.painter.isEndingStroke) {
      this.painter.endStroke()
      delete this.painter
    }

    this.clearPolygonPreview()

    // Common
    this.touching = false

    saveActionCounter = 0
    clonedAnnotationForUpdate = undefined
    crudState = 'unknown'
  }

  private updatePolygonAnnotation(
    context: ToolContext,
    data: CompoundPath,
    annotationClass: AnnotationClass,
    frameIndex: number,
  ): Annotation | undefined {
    const selectedAnnotation =
      this.selectedAnnotation || context.editor.activeView.annotationManager.selectedAnnotation
    if (!selectedAnnotation) {
      return
    }
    const { activeView } = context.editor
    let updatedAnnotation: Annotation
    if (isVideoAnnotation(selectedAnnotation)) {
      if (!activeView.fileManager.isProcessedAsVideo) {
        throw new BrushError('Expected video/DICOM to be loaded')
      }

      const { subs } = inferVideoData(selectedAnnotation, frameIndex)
      updatedAnnotation = shallowCloneAnnotation(selectedAnnotation, {
        data: {
          ...selectedAnnotation.data,
          frames: {
            ...selectedAnnotation.data.frames,
            [frameIndex]: data,
          },
        },
        annotationClass,
      })

      // inferVideoData fallback to empty array for subs.
      // if no subs are present, we don't want to create a keyframe
      if (isVideoAnnotation(updatedAnnotation) && subs.length) {
        updatedAnnotation.subAnnotations.frames[frameIndex] = subs
      }
    } else {
      updatedAnnotation = shallowCloneAnnotation(selectedAnnotation, {
        data,
        annotationClass,
      })
    }

    const previousAnnotation = selectedAnnotation
    // We used to do this becuase it related a `shouldRender` function that got removed
    // It ensured that once done with the update, the annotation shows up fully rendered,
    // with measures and overlays. This is no longer needed, but it's not clear whether ther
    // would be other side-effects if we remove it.
    this.selectedAnnotation = undefined

    // support view id for undoing purposes
    const sourceViewId = context.editor.activeView.id

    const internalUpdateAction = this.createPolygonInternalUpdateAction(context)
    const action = {
      annotation: updatedAnnotation,
      previousAnnotation,
      do(): boolean {
        internalUpdateAction.do()
        activeView.annotationManager.updateAnnotation(this.annotation, {
          updatedFramesIndices: [frameIndex],
        })
        return true
      },
      undo(): boolean {
        // we don't want to use the active view here, as by the time the user want to undo
        // the view could have changed due to multi-slot;
        // this would result in the annotation not being found and the undo action failing
        const sourceView = context.editor.viewsList.find(({ id }) => id === sourceViewId)
        if (!sourceView) {
          return false
        }

        internalUpdateAction.undo()
        sourceView.annotationManager.updateAnnotation(this.previousAnnotation, {
          updatedFramesIndices: [frameIndex],
        })
        return true
      },
    }
    this.actionGroup = this.actionGroup || context.editor.actionManager.createGroup()
    this.actionGroup.do(action)

    return updatedAnnotation
  }

  private async createPolygonAnnotationWithFirstStroke(
    context: ToolContext,
    data: CompoundPath,
    annotationClass: AnnotationClass,
  ): Promise<string | undefined> {
    const params = { type: POLYGON_ANNOTATION_TYPE, data, annotationClass }

    const { activeView } = context.editor

    if (!activeView) {
      // Note this is typed incorrectly and _can_ be null.
      // This prevents an annotation creation, so throw such an error.
      throw new BrushError('Cannot resolve active view in createAnnotationWithFirstStroke')
    }
    const { annotationManager } = activeView

    const annotation = await annotationManager.prepareAnnotationForCreation(params)

    if (!annotation) {
      AnnotationManagerEvents.annotationError.emit({ viewId: activeView.id })
      throw new BrushError(
        'Cannot create annotation in first stroke. Annotation manager failed to prepare it',
      )
    }

    const annotationId: string | undefined = annotation.id

    // Creates and runs an action which both creates
    // the annotation and stores the first brush stroke.

    this.actionGroup = this.actionGroup || context.editor.actionManager.createGroup()

    const internalUpdateAction = this.createPolygonInternalUpdateAction(context)
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const brush = this

    const action = {
      do(): boolean {
        // ~~~ Brush stroke ~~~
        internalUpdateAction.do()

        // ~~~ Annotation creation ~~~
        // We need to deselect all the annotations
        // because the new annotation will always be selected by default.
        annotationManager.deselectAllAnnotations()

        try {
          annotationManager.createAnnotation(annotation)
          annotationManager.selectAnnotation(annotation.id)
        } catch (e) {
          setContext('error', { error: e })
          console.error('V2 brush tool, annotationManager failed in createWithFirstStroke')
          return false
        }

        // "undo"` deselects so "do" must ensure selection
        if (annotationManager.selectedAnnotation?.id !== annotation.id) {
          annotationManager.selectAnnotation(annotation.id)
          brush.selectedAnnotation = annotationManager.selectedAnnotation
        }

        return true
      },
      undo(): boolean {
        // ~~~ Brush stroke ~~~
        internalUpdateAction.undo()
        saveActionCounter = 0
        crudState = 'unknown'

        // ~~~ Annotation creation ~~~
        annotationManager.deleteAnnotation(annotation.id)
        brush.selectedAnnotation = undefined
        return true
      },
    }

    try {
      await this.actionGroup.do(action)
    } catch (e) {
      setContext('error', { error: e })
      console.error('V2 brush tool, createWithFirstStroke action failed')
    }

    return annotationId
  }

  /**
   *
   * @param context
   * @param data
   * @returns
   */
  private async createOrUpdatePolygonAnnotation(
    context: ToolContext,
    data: CompoundPath,
    annotationClass: AnnotationClass,
    frameIndex: number,
  ): Promise<string | undefined> {
    let annotationId: string | undefined = undefined

    if (this.selectedAnnotation) {
      if (this.polygonAnnotationCreationPromise) {
        // If we have just started a create, make sure it finishes first.
        await this.polygonAnnotationCreationPromise
      }

      const updatedAnnotation = this.updatePolygonAnnotation(
        context,
        data,
        annotationClass,
        frameIndex,
      )
      annotationId = updatedAnnotation?.id
    } else {
      // Set a promise so we have one to await when updating.
      // This prevents us trying to update an annotation which has not yet been created
      this.polygonAnnotationCreationPromise = this.createPolygonAnnotationWithFirstStroke(
        context,
        data,
        annotationClass,
      )

      annotationId = await this.polygonAnnotationCreationPromise

      this.polygonAnnotationCreationPromise = undefined
    }

    return annotationId
  }

  private createPolygonBatchedCreateAction(
    context: ToolContext,
    updatedAnnotation: Annotation,
  ): void {
    const { activeView } = context.editor

    if (!activeView) {
      // Note this is typed incorrectly and _can_ be null.
      // This prevents an annotation creation, so throw such an error.
      throw new BrushError('No active view in batched create')
    }
    const { annotationManager } = activeView

    const annotationId = updatedAnnotation.id

    // Creates an action and stores it, but doesn't run it because we have already updated
    // the state. This is so we can undo the whole creation as a single action, which is
    // the desired UX.

    const viewActionManager = activeView.actionManager

    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const brush = this

    // batches happen outside edit mode, so they should always ensure
    // everything is deselected in both do and undo
    const action = ((): Action => ({
      do(): boolean {
        try {
          annotationManager.createAnnotation(updatedAnnotation)
          annotationManager.selectAnnotation(updatedAnnotation.id)
        } catch (e) {
          setContext('error', { error: e })
          console.error('V2 brush tool, batchedAction, annotationManager failed to create')
          return false
        }

        annotationManager.deselectAllAnnotations()

        return true
      },
      undo(): boolean {
        // ~~~ Annotation creation ~~~
        annotationManager.deleteAnnotation(annotationId)

        brush.clearPolygonPreview()
        annotationManager.deselectAllAnnotations()

        return true
      },
    }))()

    viewActionManager.done(action)
  }

  private async savePolygonDuringEdit(context: ToolContext, frameIndex: number): Promise<void> {
    const data = getCompoundPathFromSegment(
      this.compoundPath,
      context.editor.activeView.camera.scale,
    )
    const annotationClass = resolveCurrentClass(context)

    if (!data || !isValidPath(data)) {
      return
    }

    const annotationId = await this.createOrUpdatePolygonAnnotation(
      context,
      data,
      annotationClass,
      frameIndex,
    )

    if (!annotationId) {
      return
    }

    this.selectedAnnotation =
      context.editor.activeView.annotationManager.getAnnotation(annotationId)
  }

  private async savePolygon(context: ToolContext, frameIndex: number): Promise<void> {
    const data = getCompoundPathFromSegment(
      this.compoundPath,
      context.editor.activeView.camera.scale,
    )

    // not a valid path means we probably deleted the entire path
    if (!data || !isValidPath(data)) {
      if (isMaskBrush(context)) {
        return
      }

      await this.saveErasedPolygon(context.editor.activeView.annotationManager, frameIndex)
      return
    }

    const annotationClass = resolveCurrentClass(context)

    // Update the annotation to the current state.
    const updatedAnnotation = this.updatePolygonAnnotation(
      context,
      data,
      annotationClass,
      frameIndex,
    )

    if (!updatedAnnotation) {
      throw new BrushError('Internal update failed')
    }

    // Create batch actions for this whole "create" or "edit" for the action store.
    if (crudState === 'create') {
      this.createPolygonBatchedCreateAction(context, updatedAnnotation)
    } else {
      createPolygonBatchedUpdateAction(
        context,
        updatedAnnotation,
        clonedAnnotationForUpdate,
        frameIndex,
      )
    }

    this.actionGroup?.remove()
    this.actionGroup = undefined

    context.editor.activeView.annotationManager.deselectAllAnnotations()

    context.editor.activeView.annotationsLayer.changed(updatedAnnotation.id)
    this.reset()
  }

  /** Deletes or restores invalid polygons */
  private async saveErasedPolygon(
    annotationManager: AnnotationManager,
    frameIndex: number,
  ): Promise<void> {
    if (crudState === 'create' && this.selectedAnnotation) {
      // on create we need to always delete
      await annotationManager.deleteAnnotation(this.selectedAnnotation.id)
    } else if (crudState === 'edit' && clonedAnnotationForUpdate) {
      if (isVideoAnnotation(clonedAnnotationForUpdate)) {
        // restore the edited annotation, we don't support deleting for one frame
        await annotationManager.updateAnnotation(clonedAnnotationForUpdate, {
          updatedFramesIndices: [frameIndex],
        })
      } else if (isImageAnnotation(clonedAnnotationForUpdate)) {
        // delete the original annotation in case we deleted the entire polygon
        await annotationManager.deleteAnnotation(clonedAnnotationForUpdate.id)
      }
    }
  }

  /**
   * Updates the polygon for the current edit by:
   * - Combining with any other regions which are close to the mouse cursor.
   * - Combining the current brush stroke with the current region being edited.
   */
  private buildPolygon(context: ToolContext): void {
    if (this.compoundPath === null) {
      // Initialise the compound path
      this.compoundPath = getNewPath()
    }

    // For brush tool squared tip it something failing with error
    try {
      // Merge with nearby regions first
      if (this.polygonPool.length) {
        this.potentiallyMergeWithPolygonsFromPool()
      }

      // Here the compound path is everything touched + merged with so far this interaction.
      const combined = combine(this.compoundPath, this.currentPolygonPath)

      this.compoundPath =
        getBrushMode(context.editor.toolManager) === 'brush'
          ? selectUnion(combined)
          : selectDifference(combined)
    } catch (e) {
      setContext('error', { error: e })
      console.error('V2 brush tool, buildPolygon, failed to combine')

      this.compoundPath = { ...this.currentPolygonPath }
    }

    this.currentPolygonPath = getNewPath()
  }

  /**
   * Checks if one of the polygon's regions is within range of the last edit.
   * If so, add it to the in progress complex polygon region, so that it can be merged properly
   * if it is intersected.
   */
  private potentiallyMergeWithPolygonsFromPool(): void {
    if (!this.compoundPath || !this.polygonPool) {
      throw new Error('compound path should be defined')
    }
    const bufferedCurrentPathRange = getPaddedRangeOfPath(this.currentPolygonPath)

    const indiciesToMerge = getIndiciesToMerge(this.polygonRanges, bufferedCurrentPathRange)

    if (indiciesToMerge.length) {
      // Manually merge the concatenate segments we know don't overlap.
      const concatenatedSegment = getNewPath()

      indiciesToMerge.forEach((index) => {
        const segment = this.polygonSegmentPool[index]
        concatenatedSegment.segments.push(...segment.segments)
      })

      // Merge
      const combined = combine(<Segment>this.compoundPath, concatenatedSegment)

      this.compoundPath = selectUnion(combined)
      this.currentPolygonPath = getNewPath()

      // Remove merged polygons from the pool.
      // Note: go through array backwards to make sure we remove the correct ones with splice.
      for (let i = indiciesToMerge.length - 1; i >= 0; i--) {
        const indexToMerge = indiciesToMerge[i]

        this.polygonPool.splice(indexToMerge, 1)
        this.polygonRanges.splice(indexToMerge, 1)
        this.polygonSegmentPool.splice(indexToMerge, 1)
      }
    }
  }

  private rebuildTipPath(context: ToolContext): void {
    if (isMaskBrush(context)) {
      this.rebuildMaskTipPath(context)
      return
    }

    this.rebuildPolygonTipPath(context)
  }

  private rebuildPolygonTipPath(context: ToolContext): void {
    this.tipPath =
      getTipShape(context) === TipShape.Round
        ? buildRegularPolygonPath(this.size / 2, getSides(TipShape.Round, this.size / 2))
        : buildSquarePath(this.size / 2 + 1)

    /** We set that we are using a polygon class here, so when we switch classes we can check if we
     * need to rebuild the brush tip. */
    this.usingMaskClass = false
  }

  private rebuildMaskTipPath(context: ToolContext): void {
    const pixelBrushProperties: Partial<PixelBrushProperties> = {}

    let tipPath: number[][]
    let pixelMask: Uint8ClampedArray | null
    let pixelMaskIn3D: Uint8ClampedArray[] | null

    if (getTipShape(context) === TipShape.Round) {
      const tipPathAndPixelMask = buildRegularMaskPath(this.size)

      tipPath = tipPathAndPixelMask.tipPath
      pixelMask = !this.is3DBrush ? tipPathAndPixelMask.pixelMask : null
      pixelMaskIn3D = this.is3DBrush ? buildRegularMaskPathIn3D(this.size) : null
      pixelBrushProperties.tipShape = TipShape.Round
    } else {
      tipPath = buildSquareMaskPath(this.size)
      pixelMask = null
      pixelMaskIn3D = null
      pixelBrushProperties.tipShape = TipShape.Squared
    }

    if (shouldApplyRasterScaling(context.editor.activeView)) {
      const file = context.editor.activeView.fileManager.file
      const { metadata, slot_name } = file

      if (metadata) {
        tipPath = scaleReformattedPixelPath(
          tipPath,
          slot_name,
          metadata,
          getVoxelRasterDimensions(context.editor.activeView),
        )
      }
    }

    // We set that we are using a mask class here.
    // This is so that we check when we switch classes we can check if we need
    // to rebuild the brush tip.
    this.usingMaskClass = true

    const { painter } = this

    pixelBrushProperties.pixelMask = this.pixelMask
    pixelBrushProperties.width = this.size
    pixelBrushProperties.pixelMaskIn3D = pixelMaskIn3D

    this.tipPath = tipPath
    this.pixelMask = pixelMask
    this.pixelMaskIn3D = pixelMaskIn3D

    if (painter) {
      painter.setProperties(pixelBrushProperties)
    }
  }

  private setToolOptionPropsForBrush(context: ToolContext): void {
    context.editor.toolManager.setToolOptionProps('brush-size', {
      value: Math.round(this.size),
      commandName: 'brush_tool.set_brush_size',
    })

    // Currently we have 2 separate tool components displaying the same brush size,
    // we need to sync both of them here when the brush size changes.
    context.editor.toolManager.setToolOptionProps('size_and_threshold', {
      size: Math.round(this.size),
      threshold: this.maskThreshold,
      isThresholdEnabled: this.isMaskThresholdEnabled,
    })
  }

  /*
   * This function is used to change the brush dimension between 2D and 3D.
   * It checks if the current view supports 3D brushes and if not, it will
   * default to 2D.
   * If the dimension is not provided, it will switch to the opposite of the current dimension.
   * If the current class isn't a mask, it will default to 2D.
   *
   * @param context - The tool context
   * @param dimension - The dimension to switch to. If not provided, it will switch to the
   * opposite of the current dimension.
   */
  public changeMaskBrushDimension(context: ToolContext, dimension?: MaskBrushDimension): void {
    if (!is3dBrushAvailable(context.editor.activeView)) {
      dimension = MaskBrushDimension.Paint2D
    }

    if (!dimension) {
      dimension =
        this.maskDimension === MaskBrushDimension.Paint2D
          ? MaskBrushDimension.Paint3D
          : MaskBrushDimension.Paint2D
    }

    const preselectedAnnotationClass =
      context.editor.activeView.annotationManager.preselectedAnnotationClass
    if (!preselectedAnnotationClass?.annotation_types.includes('mask')) {
      dimension = MaskBrushDimension.Paint2D
    }

    context.editor.toolManager.setToolOptionProps('dimension_switcher', {
      dimension,
    })
    this.maskDimension = dimension
    this.painter?.setProperties({ dimension })
    this.rebuildMaskTipPath(context)
    ToolEvents.brushDimensionChange.emit(dimension)
  }

  public changeMaskBrushThreshold(context: ToolContext, threshold: MaskBrushThreshold): void {
    this.maskThreshold = threshold
    this.painter?.setProperties({ threshold: this.maskThreshold })

    context.editor.toolManager.setToolOptionProps('size_and_threshold', {
      size: Math.round(this.size),
      threshold: this.maskThreshold,
      isThresholdEnabled: this.isMaskThresholdEnabled,
    })
  }

  public toggleMaskBrushThresholdEnabled(context: ToolContext): void {
    this.isMaskThresholdEnabled = !this.isMaskThresholdEnabled
    this.painter?.setProperties({ isThresholdEnabled: this.isMaskThresholdEnabled })

    context.editor.toolManager.setToolOptionProps('size_and_threshold', {
      size: Math.round(this.size),
      threshold: this.maskThreshold,
      isThresholdEnabled: this.isMaskThresholdEnabled,
    })
  }

  async activate(context: ToolContext): Promise<void> {
    this.cachedActiveViewId = context.editor.activeView.id
    this.changeMaskBrushDimension(context, this.maskDimension)
    this.rebuildPolygonTipPath(context)

    // Define brush tip rebuilder with editor context.
    this.handleActiveViewChanged = ({ newViewId }: { newViewId: string }): void => {
      // If a brush stroke was pending while changing view, let's reset the tool so that we
      // don't try actions on mixed views (old and new)
      if (this.compoundPath) {
        this.reset()
      }

      const activeView = context.editor.layout.views.get(newViewId)
      if (!activeView) {
        return
      }

      const cachedActiveViewId = this.cachedActiveViewId

      if (cachedActiveViewId) {
        // If we had a different view selected, force a render on it to remove the brush tip
        const oldView = context.editor.views.get(cachedActiveViewId)

        if (oldView) {
          // This will be re-initiallised on start or move.
          this.mousePoint = undefined

          // Update brush tip on size change
          this.draw(oldView, context)
        }
      }

      this.cachedActiveViewId = activeView.id

      this.rebuildTipPath(context)
    }
    LayoutEvents.activeViewChanged.on(this.handleActiveViewChanged)

    this.handleFrameChanged = (
      _viewEvent: ViewEvent,
      { newIndex, oldIndex }: { newIndex: number; oldIndex?: number },
    ): void => {
      brushSaveAndExitEditMode(context.editor.activeView, this, context, newIndex, oldIndex)
    }
    ToolManagerEvents.frameChanged.on(this.handleFrameChanged)

    this.handleChangeClassId = (classId: number | null): void => {
      const newClass = classId && context.editor.getClassById(classId)

      // When use selects a mask class, we need to switch to mask brush
      // and show the polygon annotation
      if (
        this.selectedAnnotation &&
        this.selectedAnnotation.type !== MainAnnotationType.Mask &&
        newClass &&
        newClass.annotation_types.includes(MainAnnotationType.Mask)
      ) {
        context.editor.activeView.annotationsLayer.show(this.selectedAnnotation.id)
        context.editor.activeView.annotationManager.deselectAllAnnotations()
        this.reset()
        context.editor.activeView.annotationsLayer.clearDrawingCanvas()
      }

      this.changeMaskBrushDimension(context, this.maskDimension)
    }
    EditorEvents.preselectedClassIdChanged.on(this.handleChangeClassId)

    setupMouseButtonLoadout(context, { middle: true })

    const classSelected = await preselectOrPromptForAnnotationClass(
      context.editor.activeView,
      ToolName.Brush,
      [polygonAnnotationType, maskAnnotationType],
      'You must create or select an existing Mask or Polygon class before using the brush tool',
    )

    if (!classSelected) {
      return
    }

    selectCursor(EditorCursor.Hidden)

    this.setToolOptionPropsForBrush(context)

    context.editor.registerCommand('brush_tool.activate_brush', () => {
      this.setToolOptionPropsForBrush(context)
      context.editor.toolManager.activateToolOption('brush')

      // If in raster mode, let the painter know we are painting.
      this.painter?.setProperties({ isEraser: false })
    })

    context.editor.registerCommand('brush_tool.change_brush_dimension', () => {
      this.changeMaskBrushDimension(context)
    })

    context.editor.registerCommand(
      'brush_tool.change_brush_threshold',
      (threshold: MaskBrushThreshold) => {
        this.changeMaskBrushThreshold(context, threshold)
      },
    )

    context.editor.registerCommand('brush_tool.toggle_mask_threshold_enabled', () => {
      this.toggleMaskBrushThresholdEnabled(context)
    })

    context.editor.registerCommand('brush_tool.activate_eraser', () => {
      this.setToolOptionPropsForBrush(context)
      context.editor.toolManager.activateToolOption('eraser')

      // If in raster mode, let the painter know we are erasing.
      this.painter?.setProperties({ isEraser: true })
    })

    context.editor.registerCommand('brush_tool.activate_round_tip', () => {
      context.editor.toolManager.activateToolOption(TipShape.Round)
      this.rebuildTipPath(context)
    })

    context.editor.registerCommand('brush_tool.activate_squared_tip', () => {
      context.editor.toolManager.activateToolOption(TipShape.Squared)
      this.rebuildTipPath(context)
    })

    context.editor.registerCommand('brush_tool.save', (event: MouseEvent) => {
      if (isMaskBrush(context)) {
        // No annotation to save in mask mode.
        return
      }

      if (this.polygonDrawing) {
        // Trying to save while still drawing, calling `onEnd` to prepare the data before saving
        this.onEnd(context, event || new MouseEvent('mouseup'))
        return
      }

      if (this.selectedAnnotation) {
        // Transfer the control back to the Layer
        context.editor.activeView.annotationsLayer.show(this.selectedAnnotation.id)
      }

      this.confirmCurrentAnnotation(context, context.editor.activeView.currentFrameIndex)
      this.draw(context.editor.activeView, context)
    })

    context.editor.registerCommand('brush_tool.cancel', () => {
      const activeAnnotationId = this.selectedAnnotation?.id

      if (activeAnnotationId) {
        // Transver the controll back to the Layer
        context.editor.activeView.annotationsLayer.show(activeAnnotationId)
      }

      // an annotation gets saved on first stroke, so if we're cancelling out, we need to delete it
      if (crudState === 'create' && this.selectedAnnotation) {
        context.editor.activeView.annotationManager.deleteAnnotation(this.selectedAnnotation.id)

        // without removing the action group, we would end up being able to undo internal strokes
        this.actionGroup?.remove()
        this.actionGroup = undefined
      }

      // cancelling out of an internal update should revert the annotation
      // to the state before the start of edit mode
      if (crudState === 'edit' && clonedAnnotationForUpdate) {
        context.editor.activeView.annotationManager.updateAnnotation(clonedAnnotationForUpdate, {
          updatedFramesIndices: [context.editor.activeView.currentFrameIndex],
        })

        // without removing the action group, we would end up being able to undo internal strokes
        this.actionGroup?.remove()
        this.actionGroup = undefined
      }

      context.editor.activeView.annotationManager.deselectAllAnnotations()
      this.reset()
      context.editor.activeView.annotationsLayer.clearDrawingCanvas()
      if (activeAnnotationId) {
        context.editor.activeView.annotationsLayer.changed(activeAnnotationId)
      }
    })

    context.editor.registerCommand('brush_tool.grow', () => {
      const size = this.size * BRUSH_SCALING_FACTOR
      this.size = size <= MAX_BRUSH_SIZE ? size : MAX_BRUSH_SIZE

      this.rebuildTipPath(context)
      this.setToolOptionPropsForBrush(context)

      // Update brush tip on size change
      this.draw(context.editor.activeView, context)
    })

    context.editor.registerCommand('brush_tool.shrink', () => {
      this.size = this.size > BRUSH_SCALING_FACTOR ? this.size / BRUSH_SCALING_FACTOR : 1

      this.rebuildTipPath(context)
      this.setToolOptionPropsForBrush(context)

      // Update brush tip on size change
      this.draw(context.editor.activeView, context)
    })

    context.editor.registerCommand('brush_tool.set_brush_size', (size: number) => {
      this.size = size

      this.rebuildTipPath(context)
      this.setToolOptionPropsForBrush(context)

      // Update brush tip on size change
      this.draw(context.editor.activeView, context)
    })

    const annotationSelectHandler = (): void => {
      // Annotation selection with LAYER_V2 should transfer the control over annotation rendering
      // to the brush tool render function
      const activeAnnotation = context.editor.activeView.annotationManager.selectedAnnotation
      if (
        !activeAnnotation?.id ||
        (isVideoAnnotation(activeAnnotation) &&
          !hasSegmentContainingIndex(
            activeAnnotation.data?.segments,
            context.editor.activeView.currentFrameIndex,
          ))
      ) {
        context.editor.activeView.annotationManager.deselectAllAnnotations()
        return
      }

      if (this.selectedAnnotation) {
        context.editor.activeView.annotationsLayer.show(this.selectedAnnotation.id)
      }

      // Transfer the render control to the brush tool
      this.selectedAnnotation = undefined

      const newClass =
        context.editor.preselectedAnnotationClassId &&
        context.editor.getClassById(context.editor.preselectedAnnotationClassId)

      // Only polygon annotation can be transferred to the brush tool
      // NOTE: we should ignore preselected mask class here and not hide the polygon annotation
      if (
        activeAnnotation.type === 'polygon' &&
        !(newClass && newClass.annotation_types.includes(MainAnnotationType.Mask))
      ) {
        context.editor.activeView.annotationsLayer.hide(activeAnnotation.id)
        this.draw(context.editor.activeView, context)
      } else {
        this.reset()
        context.editor.activeView.annotationsLayer.clearDrawingCanvas()
      }
    }

    // To cover the case when the annotation is selected before the brush tool is activated
    annotationSelectHandler()

    AnnotationManagerEvents.annotationSelect.on(annotationSelectHandler)

    context.handles.push({
      id: -1,
      release: () => AnnotationManagerEvents.annotationSelect.off(annotationSelectHandler),
    })

    const deselectAnnotationHandler = (): void => {
      // Annotation deselect with LAYER_V2 should transfer the control over annotation rendering
      // back to the annotation layer
      const activeAnnotationId = this.selectedAnnotation?.id
      this.selectedAnnotation = undefined
      this.actionGroup?.clear()
      this.compoundPath = null
      this.reset()
      if (activeAnnotationId) {
        context.editor.activeView.annotationsLayer.show(activeAnnotationId)
        this.draw(context.editor.activeView, context)
      }
    }
    AnnotationManagerEvents.annotationDeselect.on(deselectAnnotationHandler)

    context.handles.push({
      id: -1,
      release: () => AnnotationManagerEvents.annotationDeselect.off(deselectAnnotationHandler),
    })

    setupToolMouseCallbacks(
      context,
      this.onStart.bind(this),
      this.onMove.bind(this),
      this.onEnd.bind(this),
    )

    context.handles.push(...context.editor.onKeyDown((e) => this.onKeyDown(context, e)))
    context.handles.push(...context.editor.onKeyUp((e) => this.onKeyUp(context, e)))

    // Register the camera events to re-render active later
    const viewsOnRender = context.editor.viewsList.map((view) => {
      const handleCameraMove = (cameraEvent: CameraEvent): void => {
        if (cameraEvent.viewId !== view.id) {
          return
        }

        if (this.cachedActiveViewId !== view.id) {
          return
        }

        this.draw(view, context)
      }

      CameraEvents.scaleChanged.on(handleCameraMove)
      CameraEvents.offsetChanged.on(handleCameraMove)

      return {
        id: -1,
        release: (): void => {
          CameraEvents.scaleChanged.off(handleCameraMove)
          CameraEvents.offsetChanged.off(handleCameraMove)
        },
      }
    })

    context.handles.push(...viewsOnRender)
  }

  /** when starting a mask stroke, a snapshot will be taken and stored here **/
  private maskSnapshotBeforeDrawing: RasterDataSnapshot | undefined

  private maskOnStart(context: ToolContext, event: PointerEvent): CallbackStatus | void {
    this.maybeSuppressMouseEvent(event)

    if (context.editor.activeView.fileManager.isTiled) {
      EditorEvents.message.emit({
        content: 'Mask annotations can not be created on tiled images',
        level: 'warning',
      })
      return
    }

    const tipShape: TipShape = getTipShape(context)

    if (isTouchEvent(event) && event.targetTouches.length > 2) {
      this.reset()
      return
    }

    const canvasPoint = resolveEventPoint(event)
    if (!canvasPoint) {
      return
    }

    const imagePoint = context.editor.activeView.camera.canvasViewToImageView(canvasPoint)
    this.mousePoint = imagePoint

    const view = context.editor.activeView

    const preselectedAnnotationClass =
      context.editor.activeView.annotationManager.preselectedAnnotationClass

    if (!preselectedAnnotationClass) {
      throw new Error('Preselected class not found, this should be selected on tool activation')
    }

    const isEraser = getBrushMode(context.editor.toolManager) === BrushMode.Eraser

    // It is important we run this code before we instantiate the BrushPainter, as it
    // does add a new label to the raster, and the snapshot should be taken before that
    const primaryView = getPrimaryViewFromView(context.editor.activeView)
    if (!primaryView) {
      throw new Error('Primary view not found')
    }
    const raster = getOrCreateRasterForView(primaryView)
    const brushDepth = this.maskDimension === MaskBrushDimension.Paint3D ? this.size : 1
    const frameIndexes = getToolFrameRange(raster, isReformattedDICOMView(view), brushDepth)
    this.maskSnapshotBeforeDrawing = raster.takeSnapshot(frameIndexes)

    this.painter = new BrushPainter(view, preselectedAnnotationClass.id, {
      tipShape,
      isEraser,
      pixelMask: this.pixelMask,
      pixelMaskIn3D: this.pixelMaskIn3D,
      width: this.size,
      dimension: this.maskDimension,
      threshold: this.maskThreshold,
      isThresholdEnabled: this.isMaskThresholdEnabled,
    })

    const file = context.editor.activeView.fileManager.file
    const { metadata, slot_name } = file

    const { voxelCoordinate, topLeftOfScaledPixel, voxelScaling } = getTopLeftOfScaledPixel(
      slot_name,
      imagePoint,
      metadata,
      getVoxelRasterDimensions(context.editor.activeView),
    )

    this.pixelIndex = topLeftOfScaledPixel
    this.voxelScaling = voxelScaling

    this.painter.stroke(voxelCoordinate)

    return CallbackStatus.Stop
  }

  /** If the class has changed type (between polygon and mask) rebuilt the brush tip */
  private rebuildTipPathIfClassTypeChanged(context: ToolContext): void {
    if (isMaskBrush(context) && !this.usingMaskClass) {
      this.rebuildTipPath(context)
    }

    if (!isMaskBrush(context) && this.usingMaskClass) {
      this.rebuildTipPath(context)
    }
  }

  private maskOnMove(context: ToolContext, event: PointerEvent): CallbackStatus | void {
    if (!context.editor.activeView.hitTarget(event)) {
      return CallbackStatus.Stop
    }

    this.rebuildTipPathIfClassTypeChanged(context)

    if (this.compoundPath?.segments.length) {
      // Just swapped class whilst creating a polygon tool, clear in progress annotation.
      this.clearPolygonPreview()
    }

    this.maybeSuppressMouseEvent(event)

    const canvasPoint = resolveEventPoint(event)
    if (!canvasPoint) {
      return
    }

    const imagePoint = context.editor.activeView.camera.canvasViewToImageView(canvasPoint)
    this.mousePoint = imagePoint

    const file = context.editor.activeView.fileManager.file
    const { metadata, slot_name } = file

    const { voxelCoordinate, topLeftOfScaledPixel, voxelScaling } = getTopLeftOfScaledPixel(
      slot_name,
      imagePoint,
      metadata,
      getVoxelRasterDimensions(context.editor.activeView),
    )

    this.pixelIndex = topLeftOfScaledPixel
    this.voxelScaling = voxelScaling

    if (this.painter) {
      this.painter.stroke(voxelCoordinate)
    }

    this.draw(context.editor.activeView, context)
  }

  private async maskOnEnd(
    context: ToolContext,
    event: PointerEvent,
  ): Promise<CallbackStatus | void> {
    this.maybeSuppressMouseEvent(event)

    if (context.editor.activeView.fileManager.isTiled) {
      return
    }

    if (!this.painter) {
      // painter might not exist when the stroke was ended by a keyboard shortcut
      this.maskSnapshotBeforeDrawing = undefined
      return
    }

    // It is important to wait for changes inside the endStroke method, as they might change
    // the raster and we need to capture them before we prepare the anction for undo/redo
    await this.painter.endStroke()

    // If `this.maskSnapshotBeforeDrawing` is set, then undo/redo functionality is active
    if (this.maskSnapshotBeforeDrawing) {
      const raster = this.painter.raster
      const brushDepth = this.maskDimension === MaskBrushDimension.Paint3D ? this.size : 1
      const brushFrameRange = getToolFrameRange(
        raster,
        isReformattedDICOMView(context.editor.activeView),
        brushDepth,
      )
      const action = createUpdateRasterAction(
        raster.view,
        raster.takeSnapshot(brushFrameRange),
        this.maskSnapshotBeforeDrawing,
      )
      raster.view.actionManager.done(action)
      raster.clearSnapshot()
      this.maskSnapshotBeforeDrawing = undefined
    }

    delete this.painter

    return CallbackStatus.Stop
  }

  private maskOnKeyDown(context: ToolContext, event: KeyboardEvent): void {
    const shift = 16
    if (event.keyCode !== shift) {
      return
    }

    context.editor.toolManager.activateToolOption('eraser')
  }

  private maskOnKeyUp(context: ToolContext, event: KeyboardEvent): void {
    const shift = 16
    if (event.keyCode !== shift) {
      return
    }

    context.editor.toolManager.activateToolOption('brush')
  }

  private maskDraw(view: View): void {
    view.annotationsLayer.draw((ctx: CanvasRenderingContext2D) => {
      if (!this.mousePoint) {
        return
      }

      const brushTipColor = rgbaString(hexToRGBA(colors.colorAliceShade))
      const mode = getBrushMode(view.editor.toolManager)

      // Draw cursor after the paths, so it looks on top of it
      ctx.beginPath()

      ctx.strokeStyle =
        mode === BrushMode.Eraser
          ? 'rgb(227, 234, 242)' // Alice Shade
          : view.annotationManager.preselectedAnnotationClassColor()

      ctx.fillStyle =
        mode === BrushMode.Eraser
          ? 'rgba(227, 234, 242, 0.15)' // Alice Shade
          : view.annotationManager.preselectedAnnotationClassColor(0.15)

      ctx.lineWidth = 1

      ctx.shadowColor = brushTipColor
      ctx.shadowBlur = 4
      ctx.shadowOffsetX = 0
      ctx.shadowOffsetY = 0

      const center = getMaskPixelCenter(this.size, this.pixelIndex, this.voxelScaling)

      if (!center) {
        return
      }

      const tipPolygon = translateMaskPath(center, this.tipPath)

      const offset = view.camera.getOffset()
      ctx.moveTo(
        tipPolygon[0][0] * view.camera.scale - offset.x,
        tipPolygon[0][1] * view.camera.scale - offset.y,
      )
      for (const [x, y] of tipPolygon) {
        ctx.lineTo(x * view.camera.scale - offset.x, y * view.camera.scale - offset.y)
      }

      ctx.closePath()
      ctx.stroke()
      ctx.fill()
    })
  }

  private maskOnRender(view: View, context: ToolContext): void {
    const ctx = view.annotationsLayer.context
    if (!ctx) {
      return
    }

    if (!this.mousePoint) {
      return
    }

    // Draw cursor after the paths, so it looks on top of it
    ctx.beginPath()

    const brushTipColor = rgbaString(hexToRGBA(colors.colorAliceShade))
    const mode = getBrushMode(context.editor.toolManager)

    ctx.strokeStyle =
      mode === BrushMode.Eraser
        ? brushTipColor
        : context.editor.activeView.annotationManager.preselectedAnnotationClassColor()

    ctx.fillStyle =
      mode === BrushMode.Eraser
        ? rgbaString(parseRGBA(brushTipColor), 0.15)
        : context.editor.activeView.annotationManager.preselectedAnnotationClassColor(0.15)

    ctx.lineWidth = 1

    ctx.shadowColor = brushTipColor
    ctx.shadowBlur = 4
    ctx.shadowOffsetX = 0
    ctx.shadowOffsetY = 0

    const tipPolygon = translateMaskPath(this.mousePoint, this.tipPath)

    const offset = view.camera.getOffset()
    ctx.moveTo(
      tipPolygon[0][0] * view.camera.scale - offset.x,
      tipPolygon[0][1] * view.camera.scale - offset.y,
    )
    for (const [x, y] of tipPolygon) {
      ctx.lineTo(x * view.camera.scale - offset.x, y * view.camera.scale - offset.y)
    }

    ctx.closePath()
    ctx.stroke()
    ctx.fill()
  }

  private polygonOnStart(context: ToolContext, event: PointerEvent): CallbackStatus | void {
    this.maybeSuppressMouseEvent(event)

    if (
      context.editor.featureFlags.MED_2D_VIEWER &&
      isReformattedDICOMView(context.editor.activeView)
    ) {
      EditorEvents.message.emit({
        content: 'Only mask annotations can be created on reformatted views',
        level: 'warning',
      })
      return
    }

    if (isTouchEvent(event) && event.targetTouches.length > 2) {
      this.savePolygon(context, context.editor.activeView.currentFrameIndex)
      this.reset()
      return
    }

    const canvasPoint = resolveEventPoint(event)
    if (!canvasPoint) {
      return
    }

    const imagePoint = context.editor.activeView.camera.canvasViewToImageView(canvasPoint)

    // Save previous path state
    if (this.compoundPath) {
      const path = this.compoundPath
        ? // Forcing recombination of path fixes a problem with the
          // rendering of compound paths with fully intersecting edges, produced by auto-annotate.
          // Without doing this, polygon() on the auto-annotate path will return empty.
          selectUnion(combine(this.compoundPath, { segments: [], inverted: false }))
        : this.currentPolygonPath

      this.previousPolygonCompoundPath = { ...path }

      this.groupExistingPolygonRegions(path)
    } else {
      this.previousPolygonCompoundPath = null
    }

    if (
      this.previousPolygonCompoundPath === null &&
      getBrushMode(context.editor.toolManager) === BrushMode.Eraser
    ) {
      // No path and using eraser, just exit.
      return
    }

    this.previousPolygonCurrentPath = { ...this.currentPolygonPath }

    this.compoundPath = null // No stroke yet
    this.polygonDrawing = true
    this.mousePoint = undefined
    this.previousMousePoint = undefined
    // The current path is just the brush tip.
    this.currentPolygonPath = segments(buildBrushTip(imagePoint, this.tipPath))
    this.buildPolygon(context)

    // Make the first brush stroke. This means people can e.g. erase with
    // A single click, rather than click + drag.
    return this.updatePolygon(context, event)
  }

  /**
   * On start of an edit, we get all of the regions of the complex (or simple)
   * polygon, and group them in GeoJSON style, such that we have each polygon grouped.
   * with its holes. Create a set of segments for each, which will be added to the current editable
   * data only when they are required.
   *
   * @param path The segment for the full complex polygon.
   */
  private groupExistingPolygonRegions(path: Segment): void {
    const polygonPath = polygon(path)

    // Splits the paths into groups with holes for each polygon.
    const geoJSON = polygonToGeoJSON(polygonPath)

    let coordinates: MultiPolygon

    if (geoJSON.type === 'Polygon') {
      // Transform the Polygon type so it has consistent
      // data structure with the polygon
      coordinates = [geoJSON.coordinates]
    } else {
      coordinates = geoJSON.coordinates
    }

    const polygonPool: Polygon[] = []
    const polygonRanges: Range[] = []
    const segmentPool: Segment[] = []

    coordinates.forEach((paths: number[][][]) => {
      const border = paths[0]
      const holes = paths.slice(1)

      if (!border) {
        return
      }
      const range = getRangeOfPolygonRegion(border)
      const polygon: Polygon = getNewPolygon([...paths])

      const borderSegment = segments(getNewPolygon([border]))

      const holeSegments = holes.map((hole) => segments(getNewPolygon([hole])))

      let segmentWithHoles = borderSegment

      holeSegments.forEach((holeSegment) => {
        const combined = combine(segmentWithHoles, holeSegment)

        segmentWithHoles = selectDifference(combined)
      })

      polygonPool.push(polygon)
      polygonRanges.push(range)
      segmentPool.push(segmentWithHoles)
    })

    this.polygonPool = polygonPool
    this.polygonRanges = polygonRanges
    this.polygonSegmentPool = segmentPool
  }

  /** On updating the ploygon move we reduce the number of reportedActivities dispatched */
  private throttledReportActivity = throttleReportActivity()

  /** Updates the polygon, called by mousedown and mousemove events. */
  private updatePolygon(context: ToolContext, event: PointerEvent): CallbackStatus | void {
    if (!context.editor.activeView.hitTarget(event)) {
      return CallbackStatus.Stop
    }

    this.maybeSuppressMouseEvent(event)

    const canvasPoint = resolveEventPoint(event)
    if (!canvasPoint) {
      return
    }

    const imagePoint = context.editor.activeView.camera.canvasViewToImageView(canvasPoint)

    this.previousMousePoint =
      this.polygonDrawing && this.mousePoint
        ? { x: this.mousePoint.x, y: this.mousePoint.y }
        : undefined
    this.mousePoint = imagePoint

    if (
      this.previousPolygonCompoundPath === null &&
      getBrushMode(context.editor.toolManager) === BrushMode.Eraser
    ) {
      // No path and using eraser, just exit.
      // Draw the brush tip using eraser mode
      this.draw(context.editor.activeView, context)
      return CallbackStatus.Stop
    }

    if (!this.polygonDrawing) {
      // Draw the brush tip on the mouse move.
      this.draw(context.editor.activeView, context)
      return
    }

    // throttled report activity for every new draw
    this.throttledReportActivity()

    if (this.previousMousePoint) {
      const interpolated =
        getTipShape(context) === TipShape.Round
          ? interpolate(this.previousMousePoint, this.mousePoint, this.size / 2)
          : interpolateSquare(
              this.previousMousePoint,
              this.mousePoint,
              squareRadiusFromSize(this.size),
            )

      // If PolyBool detects that epsilon is too small or too large,
      // it will throw an error. So need to catch and ignore current path update.
      try {
        const combined = combine(this.currentPolygonPath, interpolated)
        this.currentPolygonPath = selectUnion(combined)
      } catch (e) {
        setContext('error', { error: e })
        console.error('V2 brush tool, updatePolygon, interpolated, failed to combine')
        return
      }
    }

    const currentTip = segments(buildBrushTip(imagePoint, this.tipPath))
    // If PolyBool detects that epsilon is too small or too large,
    // it will throw an error. So need to catch and ignore current path update.
    try {
      const combined = combine(this.currentPolygonPath, currentTip)
      this.currentPolygonPath = selectUnion(combined)
    } catch (e) {
      setContext('error', { error: e })
      console.error('V2 brush tool, updatePolygon, currentTip, failed to combine.')
      return
    }
    this.buildPolygon(context)

    this.previousMousePoint = imagePoint

    context.editor.activeView.unhighlightAllVertices()
    // Draw the polygon
    this.draw(context.editor.activeView, context)
  }

  private polygonOnMove(context: ToolContext, event: PointerEvent): CallbackStatus | void {
    this.rebuildTipPathIfClassTypeChanged(context)

    return this.updatePolygon(context, event)
  }

  private polygonOnEnd(context: ToolContext, event: PointerEvent): CallbackStatus {
    resolveCrudState(context)

    if (
      this.previousPolygonCompoundPath === null &&
      getBrushMode(context.editor.toolManager) === BrushMode.Eraser
    ) {
      // No path and using eraser, just exit.
      this.clearPolygonPreview()
      return CallbackStatus.Stop
    }

    this.maybeSuppressMouseEvent(event)
    this.polygonsMerge()

    if (this.selectedAnnotation) {
      saveActionCounter += 1

      if (saveActionCounter >= SAVE_ACTION_RATE) {
        saveActionCounter = 0

        // Update the polygon
        this.savePolygonDuringEdit(context, context.editor.activeView.currentFrameIndex)
      } else {
        this.doPolygonInternalUpdateAction(context)
      }
    } else {
      // Create the polygon
      this.savePolygonDuringEdit(context, context.editor.activeView.currentFrameIndex)
    }

    this.polygonDrawing = false

    return CallbackStatus.Stop
  }

  private createPolygonInternalUpdateAction(context: ToolContext): Action {
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const brush = this

    const annotationManager = context.editor.activeView.annotationManager
    const annotationId = annotationManager.selectedAnnotation?.id

    // Keep original compoundPath and currentPath to restore it later
    const previousCompoundPath = this.previousPolygonCompoundPath
      ? { ...this.previousPolygonCompoundPath }
      : null
    const previousCurrentPath = this.previousPolygonCurrentPath
    const compoundPath = brush.compoundPath ? { ...brush.compoundPath } : null
    const currentPath = { ...brush.currentPolygonPath }

    return {
      do(): boolean {
        if (annotationId && annotationManager.selectedAnnotation?.id !== annotationId) {
          annotationManager.selectAnnotation(annotationId)
        }

        brush.compoundPath = compoundPath ? { ...compoundPath } : null
        brush.currentPolygonPath = { ...currentPath }

        return true
      },
      undo(): boolean {
        if (annotationId && annotationManager.selectedAnnotation?.id !== annotationId) {
          annotationManager.selectAnnotation(annotationId)
        }

        brush.compoundPath = previousCompoundPath ? { ...previousCompoundPath } : null
        brush.currentPolygonPath = { ...previousCurrentPath }

        return true
      },
    }
  }

  private doPolygonInternalUpdateAction(context: ToolContext): void {
    const action = this.createPolygonInternalUpdateAction(context)
    this.actionGroup = this.actionGroup || context.editor.actionManager.createGroup()
    this.actionGroup.do(action)
  }

  private polygonOnKeyDown(context: ToolContext, event: KeyboardEvent): void {
    const shift = 16
    if (event.keyCode !== shift) {
      return
    }

    this.buildPolygon(context)
    context.editor.toolManager.activateToolOption('eraser')
  }

  private polygonOnKeyUp(context: ToolContext, event: KeyboardEvent): void {
    const shift = 16
    if (event.keyCode !== shift) {
      return
    }

    this.buildPolygon(context)
    context.editor.toolManager.activateToolOption('brush')
  }

  /** OptimisedLayer draw method for the polygon brush tool */
  private polygonDraw(view: View): void {
    const mousePoint = this.mousePoint

    if (!this.polygonDrawing && !this.selectedAnnotation) {
      // If the brush tool is not busy drawing already, see if there's a selected annotation
      // If so, then hide it and show it as current path
      const selectedAnnotation = view.annotationManager.selectedAnnotation
      if (selectedAnnotation && selectedAnnotation.type === 'polygon') {
        const compoundPath = resolveCompundPath(view, selectedAnnotation)

        if (selectedAnnotation.annotationClass) {
          ClassEvents.preselectClassId.emit(selectedAnnotation.annotationClass.id)
        }
        this.compoundPath = segments(toPolyBool(compoundPath))
        this.selectedAnnotation = selectedAnnotation
      }
    }

    const path = this.compoundPath
      ? // Forcing recombination of path fixes a problem with the
        // rendering of compound paths with fully intersecting edges, produced by auto-annotate.
        // Without doing this, polygon() on the auto-annotate path will return empty.
        selectUnion(combine(this.compoundPath, this.currentPolygonPath))
      : this.currentPolygonPath

    const colorHash = parseRGBA(view.annotationManager.preselectedAnnotationClassColor())

    const compoundPathPolygon = polygon(path)

    const polygonToDraw: Polygon = getNewPolygon([...compoundPathPolygon.regions])

    this.polygonPool.forEach((polygon) => {
      polygonToDraw.regions.push(...polygon.regions)
    })

    const filter = view ? view.imageFilter : null

    if (this.selectedAnnotation) {
      // First you need to deactivate all active annotations
      // to clear active layer render pool
      view.annotationsLayer.deactivate(this.selectedAnnotation.id)
    }

    view.annotationsLayer.draw((ctx) => {
      if (!mousePoint) {
        return
      }

      drawBrush(view.camera, ctx, polygonToDraw, colorHash, filter)
      const mode = getBrushMode(view.editor.toolManager)

      // Draw cursor after the paths, so it looks on top of it
      ctx.save()
      ctx.beginPath()

      ctx.strokeStyle =
        mode === BrushMode.Eraser
          ? 'rgb(227, 234, 242)' // Alice Shade
          : view.annotationManager.preselectedAnnotationClassColor()

      ctx.fillStyle =
        mode === BrushMode.Eraser
          ? 'rgba(227, 234, 242, 0.15)' // Alice Shade
          : view.annotationManager.preselectedAnnotationClassColor(0.15)

      ctx.lineWidth = 1

      const tipPolygon = translatePath(mousePoint, this.tipPath)
      const offset = view.camera.getOffset()
      ctx.moveTo(
        tipPolygon[0][0] * view.camera.scale - offset.x,
        tipPolygon[0][1] * view.camera.scale - offset.y,
      )
      for (const [x, y] of tipPolygon) {
        ctx.lineTo(x * view.camera.scale - offset.x, y * view.camera.scale - offset.y)
      }
      ctx.closePath()
      ctx.stroke()
      ctx.fill()
      ctx.restore()
    })
  }

  private polygonOnRender(view: View, context: ToolContext): void {
    const ctx = view.annotationsLayer.context
    if (!ctx) {
      return
    }

    if (!this.mousePoint) {
      return
    }

    // This logic shouldn't be in the renderer
    if (!this.polygonDrawing && !this.selectedAnnotation) {
      // If the brush tool is not busy drawing already, see if there's a selected annotation
      // If so, then hide it and show it as current path
      const selectedAnnotation = view.annotationManager.selectedAnnotation
      if (selectedAnnotation && selectedAnnotation.type === 'polygon') {
        const compoundPath = resolveCompundPath(view, selectedAnnotation)

        if (selectedAnnotation.annotationClass) {
          ClassEvents.preselectClassId.emit(selectedAnnotation.annotationClass.id)
        }
        this.compoundPath = segments(toPolyBool(compoundPath))
        this.selectedAnnotation = selectedAnnotation
      }
    }

    // const path = this.compoundPath ?? this.currentPath

    const path = this.compoundPath
      ? // Forcing recombination of path fixes a problem with the
        // rendering of compound paths with fully intersecting edges, produced by auto-annotate.
        // Without doing this, polygon() on the auto-annotate path will return empty.
        selectUnion(combine(this.compoundPath, this.currentPolygonPath))
      : this.currentPolygonPath

    const colorHash = parseRGBA(
      context.editor.activeView.annotationManager.preselectedAnnotationClassColor(),
    )

    const compoundPathPolygon = polygon(path)

    const polygonToDraw: Polygon = getNewPolygon([...compoundPathPolygon.regions])

    this.polygonPool.forEach((polygon) => {
      polygonToDraw.regions.push(...polygon.regions)
    })

    const filter = context.editor ? context.editor.activeView.imageFilter : null

    drawBrush(view.camera, ctx, polygonToDraw, colorHash, filter)

    // Draw cursor after the paths, so it looks on top of it
    ctx.beginPath()

    const brushTipColor = rgbaString(hexToRGBA(colors.colorAliceShade))
    const mode = getBrushMode(context.editor.toolManager)

    ctx.strokeStyle =
      mode === BrushMode.Eraser
        ? brushTipColor
        : context.editor.activeView.annotationManager.preselectedAnnotationClassColor()

    ctx.fillStyle =
      mode === BrushMode.Eraser
        ? rgbaString(parseRGBA(brushTipColor), 0.15)
        : context.editor.activeView.annotationManager.preselectedAnnotationClassColor(0.15)

    ctx.lineWidth = 1

    ctx.shadowColor = brushTipColor
    ctx.shadowBlur = 4
    ctx.shadowOffsetX = 0
    ctx.shadowOffsetY = 0

    const tipPolygon = translatePath(this.mousePoint, this.tipPath)
    const offset = view.camera.getOffset()
    ctx.moveTo(
      tipPolygon[0][0] * view.camera.scale - offset.x,
      tipPolygon[0][1] * view.camera.scale - offset.y,
    )
    for (const [x, y] of tipPolygon) {
      ctx.lineTo(x * view.camera.scale - offset.x, y * view.camera.scale - offset.y)
    }
    ctx.closePath()
    ctx.stroke()
    ctx.fill()
  }

  private polygonsMerge(): void {
    if (!this.compoundPath || !this.polygonPool.length) {
      return
    }

    const { polygonSegmentPool } = this

    const mergedCompoundPath = getNewPath([...this.compoundPath.segments])

    polygonSegmentPool.forEach((segment) => {
      mergedCompoundPath.segments.push(...segment.segments)
    })

    this.compoundPath = mergedCompoundPath
    this.currentPolygonPath = getNewPath()
    this.polygonPool = []
    this.polygonSegmentPool = []
    this.polygonRanges = []
  }
}

export const brushTool = new PolygonOrMaskBrushTool()
