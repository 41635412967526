import type { AnnotationType } from '@/core/annotationTypes'
import type { WorkflowMutation } from '@/store/modules/workflow/types'
import { StageType } from '@/store/types/StageType'
import type { V2ConsensusTestStagePayload } from '@/store/types/V2WorkflowStagePayload'

export type Payload = {
  testStageId: string
  type: AnnotationType
  threshold: number
}

export const UPDATE_CONSENSUS_TEST_TYPE_IOU: WorkflowMutation<Payload> = (
  state,
  { testStageId, type, threshold },
) => {
  const workflow = state.editedWorkflow

  if (!workflow) {
    return
  }

  const stage = workflow.stages.find(
    (s): s is V2ConsensusTestStagePayload =>
      s.type === StageType.ConsensusTest && s.id === testStageId,
  )
  if (!stage) {
    return
  }

  stage.config.iou_thresholds = {
    ...stage.config.iou_thresholds,
    annotation_types: [
      ...stage.config.iou_thresholds.annotation_types.filter((x) => x.annotation_type !== type),
      {
        annotation_type: type,
        threshold,
      },
    ],
  }
}
