import { ModelType } from '@/core/annotations'
import type {
  NeuralModelState,
  NeuralModelValidationErrors,
} from '@/store/modules/neuralModel/types'

export const validateNewModel = (state: NeuralModelState): NeuralModelValidationErrors => {
  const errors: NeuralModelState['newModelValidationErrors'] = {}

  if (state.newModelSelectedClassIds.length === 0) {
    errors.classes = 'You must select one or more classes to train on.'
  }

  if (
    state.newModelType === ModelType.CLASSIFICATION &&
    state.newModelSelectedClassIds.length < 2
  ) {
    errors.classes = 'You must select at least two classes to train on.'
  }

  if (!state.newModelDataset) {
    errors.dataset = 'You must select a dataset to train on.'
  }

  if (!state.newModelTemplate) {
    errors.template = 'You must select a template for the model.'
  }

  if (!state.newModelName || state.newModelName.length === 0) {
    errors.name = 'You must provide a unique name for the model.'
  }

  if (state.newModelSampleItemsV2.length === 0) {
    errors.items = 'The dataset contains no applicable items to train on.'
  }

  return errors
}

const ELIGIBILITY: Record<ModelType, string[]> = {
  [ModelType.AUTO_ANNOTATE]: ['polygon'],
  [ModelType.AUTO_BOX]: ['bounding_box', 'polygon'],
  [ModelType.AUTO_TRACK]: ['bounding_box', 'polygon'],
  [ModelType.CLASSIFICATION]: ['tag'],
  [ModelType.EMBED]: ['polygon'],
  [ModelType.EXTERNAL]: ['polygon'],
  [ModelType.INSTANCE_SEGMENTATION]: ['polygon'],
  [ModelType.OBJECT_DETECTION]: ['bounding_box', 'polygon'],
  [ModelType.SEMANTIC_SEGMENTATION]: ['polygon'],
  [ModelType.TEXT_SCANNER]: ['bounding_box'],
}

/**
 * Is used when creating a new model to determine if a given annotation type
 * could be created by the model type.
 */
export const isTypeEligible = (annotationType: string, modelType: ModelType): boolean =>
  modelType !== ModelType.EXTERNAL && ELIGIBILITY[modelType].includes(annotationType)
