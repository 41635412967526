import { defineStore } from 'pinia'
import { computed, reactive, ref } from 'vue'

import { useStore } from '@/store/useStore'
import type { PartialRecord } from '@/core/helperTypes'
// we use action and mutation payloads temporarily to ensure type safety when calling vuex
import type { SkippedReason, V2DatasetItemPayload } from '@/store/types'
import { StageType, type MembershipPayload, StageActionType } from '@/store/types'
import type { V2DatasetItemTimeSummaryPayload } from '@/store/types/V2DatasetItemTimeSummaryPayload'
import type { V2WorkflowItemStatePayload } from '@/store/types/V2WorkflowItemStatePayload'
import type { V2WorkflowPayload } from '@/store/types/V2WorkflowPayload'
import type { V2WorkflowStageInstancePayload } from '@/store/types/V2WorkflowStageInstancePayload'
import type {
  V2ConsensusStagePayload,
  V2WorkflowStagePayload,
} from '@/store/types/V2WorkflowStagePayload'
import { loadV2DatasetItemTimeSummary, sendV2Commands } from '@/backend/darwin'
import { loadV2Workflows as loadV2WorkflowsRequest } from '@/backend/darwin/loadV2Workflows'
import type { ErrorWithMessage, ParsedError, ParsedValidationError } from '@/backend/error'

import { useDatasetItemsStore } from '@/modules/Datasets/useDatasetItemsStore'
import { useDatasetStore } from '@/modules/Datasets/useDatasetStore'
import { useUserStore } from '@/modules/Auth/useUserStore'
import type { AxiosResponse } from 'axios'

const standardStageMapping: Record<
  Exclude<StageType, typeof StageType.Dataset>,
  StageActionType
> = {
  [StageType.Archive]: StageActionType.SendToArchive,
  [StageType.Annotate]: StageActionType.SendToAnnotate,
  [StageType.Complete]: StageActionType.MarkAsComplete,
  [StageType.ConsensusEntrypoint]: StageActionType.SendToConsensus,
  [StageType.ConsensusTest]: StageActionType.SendToTest,
  [StageType.Logic]: StageActionType.SendToLogic,
  [StageType.Model]: StageActionType.SendToModel,
  [StageType.Review]: StageActionType.SendToReview,
  [StageType.Discard]: StageActionType.Archive,
  [StageType.Webhook]: StageActionType.SendToWebhook,
  [StageType.Sampling]: StageActionType.SendToSampling,
}

const sentToNextReviewStageMapping: Record<
  Exclude<StageType, typeof StageType.Dataset>,
  StageActionType
> = { ...standardStageMapping, [StageType.Review]: StageActionType.SendToNextReview }

const MAPPING: {
  [k in StageType]: Record<Exclude<StageType, typeof StageType.Dataset>, StageActionType>
} = {
  [StageType.Archive]: { ...standardStageMapping },
  [StageType.Annotate]: { ...standardStageMapping },
  [StageType.Review]: { ...sentToNextReviewStageMapping },
  [StageType.Dataset]: { ...sentToNextReviewStageMapping },
  [StageType.Model]: { ...sentToNextReviewStageMapping },
  [StageType.Complete]: {
    ...standardStageMapping,
    [StageType.Annotate]: StageActionType.MarkAsComplete,
    [StageType.Complete]: StageActionType.MarkAsComplete,
    [StageType.Model]: StageActionType.MarkAsComplete,
    [StageType.Review]: StageActionType.MarkAsComplete,
  },
  [StageType.ConsensusEntrypoint]: { ...standardStageMapping },
  [StageType.ConsensusTest]: { ...sentToNextReviewStageMapping },
  [StageType.Logic]: { ...standardStageMapping },
  [StageType.Discard]: { ...standardStageMapping },
  [StageType.Webhook]: { ...standardStageMapping },
  [StageType.Sampling]: { ...standardStageMapping },
}

/**
 * Return basic mapping when transitioning between two stages of specified types,
 * as for "from" => "to" => "action"
 *
 * This menas that transitioning from "from" type to "to" type implies the
 * returned action is happening.
 *
 * This is mainly used to decide which text to show on the continue button label.
 */
export const mapStageActiontype = (
  from: StageType,
  to: Exclude<StageType, typeof StageType.Dataset>,
): StageActionType => MAPPING[from][to]

/**
 * Intends to hold all things related to the use of workflows within workview.
 *
 * Starts of as a wrapper around pinia actions, getters and mutations, so we can
 * safely move the rest of the app to use it.
 *
 * Once the application IS using it, we convert it to an actual store and remove
 * the vuex things.
 */
export const useWorkflowInWorkviewStore = defineStore('workflowInWorkview', () => {
  const store = useStore()
  const itemsStore = useDatasetItemsStore()

  /**
   * The state of the background engine process for the active dataset item in the workflow.
   *
   * This state receives commands done in workview, processes them, and updates in memory, only
   * saving periodically.
   *
   * We receive updates for it via websockets for the currently selected item in workview.
   *
   * It contains data such as auto-completion of stage instances,
   * what the current stage instance is, dedicated assignees, etc.
   */
  const itemState = ref<V2WorkflowItemStatePayload | null>(null)

  /**
   * Map of all possible states by dataset item id
   */
  const itemStates = ref<PartialRecord<string, V2WorkflowItemStatePayload>>({})

  /**
   * Store the state of the workflow engine for a dataset item,
   * received via websockets into the store.
   */
  const setItemState = (state: V2WorkflowItemStatePayload | null): void => {
    itemState.value = state
  }

  const datasetStore = useDatasetStore()
  const teamSlug = computed(() => datasetStore.dataset?.team_slug)

  /**
   * Add new item state into map of the workflow engine and set current item state
   */
  const addItemState = (itemId: string, state: V2WorkflowItemStatePayload): void => {
    itemState.value = state
    itemStates.value = {
      ...itemStates.value,
      [itemId]: state,
    }
  }

  /**
   * Remove item state into map of the workflow engine and set current item state to null
   */
  const removeItemState = (itemId: string): void => {
    const { [itemId]: workflowItem, ...rest } = itemStates.value

    itemState.value = null
    itemStates.value = { ...rest }
  }

  const stage = ref<V2WorkflowStagePayload | null>(null)

  const setStage = (state: V2WorkflowStagePayload | null): void => {
    stage.value = state
  }

  const stageInstance = ref<V2WorkflowStageInstancePayload | null>(null)

  const setStageInstance = (state: V2WorkflowStageInstancePayload | null): void => {
    stageInstance.value = state
  }

  const workflows = ref<V2WorkflowPayload[]>([])

  const setWorkflows = (state: V2WorkflowPayload[]): void => {
    workflows.value = state
  }

  const itemTimeSummaries = reactive<PartialRecord<string, V2DatasetItemTimeSummaryPayload>>({})

  const setItemTimeSummaries = (state: V2DatasetItemTimeSummaryPayload): void => {
    itemTimeSummaries[state.dataset_item_id] = state
  }

  const assignStage = async ({
    stage,
    member,
  }: {
    stage: V2WorkflowStagePayload
    member: MembershipPayload
  }): Promise<{ error?: ErrorWithMessage | ParsedValidationError; data?: undefined }> => {
    if (!itemsStore.currentItemId) {
      throw new Error('Item not set')
    }

    if (!datasetStore.dataset) {
      throw new Error('Dataset not set')
    }

    if (!itemState.value) {
      throw new Error('Item state not loaded')
    }

    const params: Parameters<typeof sendV2Commands>[0] = {
      datasetItemId: itemsStore.currentItemId,
      teamSlug: datasetStore.dataset.team_slug,
      commands: [
        {
          type: 'assign',
          data: {
            assignee_id: member.user_id,
            stage_id: stage.id,
          },
        },
      ],
    }
    const response = await sendV2Commands(params)

    if ('error' in response) {
      store.commit('workview/SET_ERROR', response.error)
      return { error: response.error }
    }

    return { data: undefined }
  }

  const restartItem = async (): Promise<{
    error?: ErrorWithMessage | ParsedValidationError
    data?: undefined
  }> => {
    if (!itemsStore.currentItemId) {
      throw new Error('Item not set')
    }

    if (!itemState.value) {
      throw new Error('Item state not loaded')
    }

    const stageInstance = itemState.value.current_stage_instances.find(
      (i) => i.stage.type === StageType.Complete,
    )

    if (!stageInstance) {
      throw new Error('Current stage is of wrong type')
    }

    if (!datasetStore.dataset) {
      throw new Error('Dataset not set')
    }

    if (!teamSlug.value) {
      throw new Error('Team slug not set')
    }

    const stage = itemState.value.workflow.stages.find(
      (s) => 'initial' in s.config && s.config.initial,
    )

    if (!stage) {
      throw new Error('Workflow has no initial stage')
    }

    const params: Parameters<typeof sendV2Commands>[0] = {
      datasetItemId: itemsStore.currentItemId,
      teamSlug: teamSlug.value,
      commands: [
        {
          type: 'set_stage',
          data: {
            to_stage_id: stage.id,
          },
        },
      ],
    }

    const response = await sendV2Commands(params)

    if ('error' in response) {
      store.commit('workview/SET_ERROR', response.error)
      return { error: response.error }
    }

    return { data: undefined }
  }

  const getNextStage = (
    instance: V2WorkflowStageInstancePayload,
    itemState: V2WorkflowItemStatePayload,
  ): V2WorkflowStagePayload | undefined =>
    itemState.workflow.stages.find((s) =>
      instance.stage.edges.some(
        // next stage is the implicit default next stage.
        // that means only certain edges are considered
        // - approve for review stage
        // - default for annotate stage (and all other stages)
        (e) => ['default', 'approve'].includes(e.name) && e.target_stage_id === s.id,
      ),
    )

  /**
   * For the specified stage, returns the type of action which is to happen upon stage completion
   *
   * Used to decide what the continue button performs when clicking,
   * and to render what happens when autom-complete runs out.
   */
  const stageCurrentAction = computed<StageActionType | null>(() => {
    const currentInstance = stageInstance.value
    if (!currentInstance) {
      return null
    }

    const currentType = currentInstance.stage.type
    const parallelStages =
      itemState.value?.workflow.stages
        .filter(
          (stage): stage is V2ConsensusStagePayload => stage.type === StageType.ConsensusEntrypoint,
        )
        .flatMap((cs) => [...cs.config.parallel_stage_ids]) ?? []

    const isSubAnnotate =
      currentType === StageType.Annotate && parallelStages.includes(currentInstance.stage.id)

    if (isSubAnnotate) {
      return StageActionType.SendToTest
    }

    if (currentType === StageType.Annotate) {
      if (currentInstance.data.skipped_reason) {
        return StageActionType.Skip
      }
    }

    if (currentType === StageType.Review) {
      if (currentInstance.data.skipped_reason) {
        return StageActionType.Archive
      }
      if (currentInstance.data.active_edge === 'reject') {
        return StageActionType.SendBack
      }
    }

    if (currentType === StageType.Complete) {
      return StageActionType.Completed
    }

    if (!itemState.value) {
      return null
    }

    const nextStage = getNextStage(currentInstance, itemState.value)
    if (!nextStage) {
      return null
    }

    // Dataset can only be a starting stage, so should not happen
    if (nextStage.type === StageType.Dataset) {
      return null
    }

    return mapStageActiontype(currentInstance.stage.type, nextStage.type)
  })

  const loadTimeSummary = async ({
    teamSlug,
    item,
  }: {
    teamSlug: string
    item: V2DatasetItemPayload
  }): Promise<AxiosResponse<V2DatasetItemTimeSummaryPayload, unknown> | ParsedError> => {
    const response = await loadV2DatasetItemTimeSummary({ datasetItemId: item.id, teamSlug })

    if ('data' in response) {
      setItemTimeSummaries(response.data)
    }

    return response
  }

  const loadV2Workflows = async (): Promise<{ data?: undefined } | ParsedError> => {
    if (!datasetStore.dataset) {
      throw new Error('Dataset not set')
    }

    if (!teamSlug.value) {
      throw new Error('Team slug not set')
    }

    const response = await loadV2WorkflowsRequest({
      teamSlug: teamSlug.value,
    })

    if ('data' in response) {
      setWorkflows(response.data)
      return { data: undefined }
    }

    return response
  }

  const userStore = useUserStore()

  const skipSelectedStageInstance = async (
    reason: SkippedReason,
  ): Promise<{
    data?: SkippedReason
    error?: ErrorWithMessage | ParsedValidationError
  }> => {
    const itemsStore = useDatasetItemsStore()
    if (!itemsStore.currentItemId) {
      throw new Error('Item not set')
    }

    if (!datasetStore.dataset) {
      throw new Error('Dataset not set')
    }

    if (!teamSlug.value) {
      throw new Error('Team slug not set')
    }

    if (!stageInstance.value) {
      throw new Error(
        'useWorkflowInWorkviewStore, skipSelectedStageInstance, No stage instance selected',
      )
    }

    const commands: Parameters<typeof sendV2Commands>[0]['commands'] = []

    if (!stageInstance.value.user_id && userStore.currentUser) {
      // we want to assign the stage to the current user if not already assigned
      commands.push({
        type: 'assign',
        data: {
          assignee_id: userStore.currentUserId,
          stage_id: stageInstance.value.stage_id,
        },
      })
    }

    // the primary command we always send is skip
    commands.push({
      type: 'skip',
      data: { reason, stage_id: stageInstance.value.stage_id },
    })

    const response = await sendV2Commands({
      datasetItemId: itemsStore.currentItemId,
      teamSlug: teamSlug.value,
      commands,
    })

    if ('error' in response) {
      store.commit('workview/SET_ERROR', response.error)
      return { error: response.error }
    }

    return { data: undefined }
  }

  const cleanup = (): void => {
    itemState.value = null
    itemStates.value = {}
    stage.value = null
    stageInstance.value = null
    workflows.value = []
    Object.assign(itemTimeSummaries, {})
  }

  return {
    itemState,
    itemStates,
    setItemState,
    addItemState,
    removeItemState,

    stage,
    setStage,

    stageInstance,
    setStageInstance,

    workflows,
    setWorkflows,

    itemTimeSummaries,
    setItemTimeSummaries,

    assignStage,
    restartItem,
    stageCurrentAction,
    loadTimeSummary,
    loadV2Workflows,
    skipSelectedStageInstance,

    cleanup,
  }
})
