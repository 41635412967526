import camelCase from 'lodash/camelCase'
import hasIn from 'lodash/hasIn'
import mapKeys from 'lodash/mapKeys'
import mapValues from 'lodash/mapValues'
import upperFirst from 'lodash/upperFirst'

import type { ValidationError, ValidationErrors } from '@/store/types'

import { DEFAULT_ERROR, errorsByCode, errorMessages } from './error/errors'
import type {
  BackendError,
  ErrorMessageGroup,
  ErrorResponse,
  ErrorWithMessage,
  ParsedError,
  ParsedErrorWithMessage,
  ParsedValidationError,
} from './error/types'

/**
 * Construct a local error using the specified format.
 *
 * The code parameter is required and determines the message.
 *
 * The message parameter is optional and overrides the default message determined by code.
 */
export const constructError = (
  code: keyof typeof errorsByCode,
  message?: string,
): ParsedErrorWithMessage => {
  message = message || errorsByCode[code]

  if (!message) {
    throw new Error(`An unexpected constructError call was made . Code: ${code}`)
  }

  const error: ErrorWithMessage = {
    backendMessage: null,
    code,
    detail: null,
    message,
    status: null,
  }

  return { error }
}

/**
 * Determines if the payload is an error payload returned from a request to the backend
 * made through workview engine actions.
 *
 * Workview engine actions fire requests to the backend using `engine/backend.ts` which is
 * a `window.fetch` wrapper.
 */
const isBackendError = (e: ErrorResponse): boolean => {
  if (e.name === 'SocketError') {
    return true
  }
  if (e.message === 'Network Error') {
    return true
  }
  return !!e.response
}

const isClientError = (e: ErrorResponse): boolean => !isBackendError(e)

const getCode = (error: ErrorResponse): keyof typeof errorsByCode | null => {
  if (error.message && error.message === 'Network Error') {
    return 'NETWORK_ERROR'
  }
  if (error.name === 'SocketError') {
    return 'SOCKET_ERROR'
  }
  if (error.response && error.response.data && error.response.data.errors) {
    return (error.response.data.errors as BackendError).code || null
  }

  return null
}

const getResponseStatus = (error: ErrorResponse): number | null =>
  (error.response && error.response.status) || null

// a validation error will have a status of 422 and a payload.data.errors which is an object
export const isValidationError = (e: ErrorResponse): e is ErrorResponse<ValidationErrors> =>
  e.response?.status === 422 && !hasIn(e.response, 'data.errors.message')

/**
 * In some cases, backend returns a 422 without a list of per-field errors.
 * Instead, it just contains a single message
 * This function tells us if we got that type of resposne
 */
const isValidationErrorWithMessage = ({ response }: ErrorResponse) =>
  response && response.status === 422 && hasIn(response, 'data.errors.message')

// some other backend error will have 'data.errors.message'
const isLoadingNeuralModelError = ({ response }: ErrorResponse) =>
  response && response.status === 404 && hasIn(response, 'data.error.message')

const normalizeErrors = (errors: ValidationError) => {
  const normalizedErrors: ValidationError = mapValues(errors, (value) => {
    if (Array.isArray(value)) {
      if (typeof value[0] === 'object') {
        return value
      }
      return upperFirst(value[0])
    }
    if (typeof value === 'string') {
      return value
    }

    return normalizeErrors(value)
  })

  return mapKeys(normalizedErrors, (value, key) => camelCase(key))
}

const extractValidationErrors = ({ response }: ErrorResponse): ParsedValidationError => {
  const errors = response ? response.data.errors : {}
  return {
    isValidationError: true,
    ...normalizeErrors(errors as ValidationError),
  }
}

const getBackendError = (errorResponse: ErrorResponse): BackendError | null => {
  if (!('response' in errorResponse)) {
    return null
  }
  if (!errorResponse.response) {
    return null
  }
  if (!hasIn(errorResponse.response, 'data.errors.code')) {
    return null
  }
  return errorResponse.response.data.errors
}

/** Extracts frontend-defined message for an error */
const getMessageContent = (
  code: keyof typeof errorsByCode | null,
  status: number | null,
  messages?: ErrorMessageGroup,
  params?: { [key: string]: string | number },
): string => {
  if (params && !messages) {
    throw new Error('A message group needs to be specified when specifying params')
  }

  if (params && messages && messages.parametric) {
    return messages.parametric(params)
  }
  if (code && errorsByCode[code]) {
    return errorsByCode[code]
  }
  if (status && messages && messages[status]) {
    return messages[status] as string
  }
  if (messages) {
    return messages.default
  }
  return DEFAULT_ERROR
}

export const getErrorMessage = (
  error: ParsedError['error'],
  generic: string = 'Something went wrong',
): string[] => {
  if ('isValidationError' in error && error.isValidationError) {
    return Object.values(error)
      .filter((value) => typeof value === 'string')
      .map((value) => value as string)
  }
  if ('message' in error && typeof error.message === 'string') {
    return [error.message]
  }
  return [generic]
}

export const parseError = (
  errorResponse: ErrorResponse,
  messages?: ErrorMessageGroup,
  params?: { [key: string]: string | number },
): ParsedError => {
  if (isClientError(errorResponse)) {
    throw errorResponse
  }

  if (isValidationError(errorResponse)) {
    return { error: extractValidationErrors(errorResponse) }
  }

  if (isValidationErrorWithMessage(errorResponse)) {
    const errorPayload = errorResponse.response?.data.errors

    return {
      error: {
        code: '422',
        detail: null,
        message: (errorPayload?.message as string) || '',
        backendMessage: (errorPayload?.message as string) || null,
        status: 422,
      },
    }
  }

  if (isLoadingNeuralModelError(errorResponse)) {
    return {
      error: {
        code: '404',
        detail: null,
        message: 'Loading model...',
        backendMessage: null,
        status: 404,
      },
    }
  }

  const code = getCode(errorResponse)
  const status = getResponseStatus(errorResponse)
  const backendError = getBackendError(errorResponse)

  const message =
    code === 'SOCKET_ERROR' && errorResponse.message
      ? errorResponse.message
      : getMessageContent(code, status, messages, params)

  const error: ErrorWithMessage = {
    code,
    detail: (backendError && backendError.detail) || null,
    status,
    message,
    backendMessage: (backendError && backendError.message) || null,
  }

  return { error }
}

export * from './error/types'

export { errorMessages, errorsByCode }
