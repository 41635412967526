/**
 * Wrapper around the external library we use for working with times, dates,
 * which is "dayjs", as well as a collection of utility functions directly
 * dealing with timestamps.
 */
// this file is the only one from which dayjs imports are allowed
// eslint-disable-next-line no-restricted-imports
import dayjs from 'dayjs'
// eslint-disable-next-line no-restricted-imports
import advancedFormatPlugin from 'dayjs/plugin/advancedFormat'
// eslint-disable-next-line no-restricted-imports
import durationPlugin from 'dayjs/plugin/duration'
// eslint-disable-next-line no-restricted-imports
import relativeTimePlugin from 'dayjs/plugin/relativeTime'
// eslint-disable-next-line no-restricted-imports
import updateLocalePlugin from 'dayjs/plugin/updateLocale'
// eslint-disable-next-line no-restricted-imports
import utcPlugin from 'dayjs/plugin/utc'

import { addZeros } from './formatter'

export const TimeRange = {
  ALL_TIME: 'all_time',
  TODAY: 'today',
  YESTERDAY: 'yesterday',
  LAST_24_HOURS: 'last_24_hours',
  LAST_7_DAYS: 'last_7_days',
  LAST_14_DAYS: 'last_14_days',
  LAST_30_DAYS: 'last_30_days',
  LAST_90_DAYS: 'last_90_days',
  LAST_180_DAYS: 'last_180_days',
  THIS_MONTH: 'this_month',
  YEAR_TO_DATE: 'year_to_date',
} as const
export type TimeRangeType = (typeof TimeRange)[keyof typeof TimeRange]

dayjs.extend(advancedFormatPlugin) // 'Do' in format, etc
dayjs.extend(durationPlugin) // Using .duration()
dayjs.extend(relativeTimePlugin) // .fromNow()
dayjs.extend(updateLocalePlugin) // .updateLocale()
dayjs.extend(utcPlugin) // .utc()

/**
 * Formats a date specified as ISO timestamp into a 'time ago' string
 */
export const getAgoString = (date: string): string => dayjs.utc(date).fromNow()

/**
 * Formats a duration amount, specifed in seconds, into a HH:MM:SS string.
 *
 * The HH part of the string is discarded if duration is less than an hour.
 */
export const formatDurationAsTimer = (
  durationInSeconds: number,
  separator: string = ':',
): string => {
  const seconds = durationInSeconds % 60
  const minutes = Math.floor((durationInSeconds % 3600) / 60)
  const hours = Math.floor(durationInSeconds / 3600)
  return (hours ? [hours, minutes, seconds] : [minutes, seconds])
    .map((p) => addZeros(p, 2))
    .join(separator)
}

/**
 * Formats a duration amount, specified in seconds into a Xd Xh Xm string.
 *
 * The d and h parts are discarded if duration is less than a day, or an hour, respectively.
 */
export const formatDurationAsSpan = (
  durationInSeconds: number,
  maxUnit: 'd' | 'h' = 'd',
): string => {
  const duration = dayjs.duration(durationInSeconds, 'seconds')
  const parts = []

  if (maxUnit === 'd') {
    // duration.get('d') will not include months
    // duration.asDays() returns a float
    if (durationInSeconds >= 24 * 3600) {
      parts.push(`${Math.floor(duration.asDays())}d`)
    }
    if (durationInSeconds >= 3600) {
      parts.push(`${duration.get('h')}h`)
    }
  } else {
    if (durationInSeconds >= 3600) {
      parts.push(`${Math.floor(duration.asHours())}h`)
    }
  }

  parts.push(`${duration.get('m')}m`)

  return parts.join(' ')
}

export const dateFromUtcISO = (iso: string): Date => dayjs.utc(iso).toDate()

/**
 * Formats a date string in ISO format, not assuming timezone, to the specified format
 */
export const formatDate = (value: string, format: string): string => dayjs(value).format(format)

/**
 * Takes an iso value of a date, assuming it's in UTC, and then reformats it to specified format
 */
export const formatUTCDate = (value: string, format: string): string =>
  dayjs(dateFromUtcISO(value)).format(format)

/**
 * Formats a date in unix timestamp format to the specified format
 */
export const formatUnixDate = (value: number, format: string): string =>
  dayjs.unix(value).format(format)

const utcNow = (): dayjs.Dayjs => dayjs.utc()

export const utcNowISO = (): string => utcNow().toISOString()

export const startOfYear = (format: string): string => utcNow().startOf('year').format(format)

/**
 * Returns ISO string representing the start of the day for the specified ISO string
 */
export const startOfDay = (isoStamp: string = utcNowISO()): string =>
  dayjs.utc(isoStamp).startOf('day').toISOString()

export const startOfHour = (isoStamp: string = utcNowISO()): string =>
  dayjs.utc(isoStamp).startOf('day').toISOString()

export const startOfMinute = (): string => utcNow().startOf('minute').toISOString()

/**
 * Returns current unix timestamp, in milliseconds
 */
export const unixNowMs = (): number => Date.now()

/**
 * Returns current unix timestamp, in seconds
 */
export const unixNowSeconds = (): number => Math.floor(unixNowMs() / 1000)

const dateFromUnixNowMs = (unixNowMs: number): Date => new Date(unixNowMs)

/**
 * Takes unix seconds timestamp and converts it to ISO string
 */
export const unixToIso = (unixNowMs: number): string => dateFromUnixNowMs(unixNowMs).toISOString()

export const unixSecondsFromIso = (iso: string): number =>
  Math.floor(dateFromUtcISO(iso).getTime() / 1000)

export const uniformListOfTimestamps = (
  fromDate: string,
  step: 'hour' | 'day',
  format: string,
): string[] => {
  let from = dayjs.utc(fromDate)
  const now = utcNow()

  const diff = now.diff(from, step)
  const dates: string[] = []

  for (let i = 0; i <= diff; i++) {
    dates.push(from.format(format))
    from = from.add(1, step)
  }

  return dates
}

export const addTime = (isoStamp: string, amount: number, unit: 'day' | 'month' | 'week'): string =>
  dayjs.utc(isoStamp).add(amount, unit).toISOString()

export const timeRangeISOString = (value: string): { start: string; end: string } => {
  const now = dayjs()

  if (value === TimeRange.TODAY) {
    return {
      start: now.startOf('day').toISOString(),
      end: now.endOf('day').toISOString(),
    }
  }
  if (value === TimeRange.YESTERDAY) {
    return {
      start: now.subtract(1, 'day').startOf('day').toISOString(),
      end: now.subtract(1, 'day').endOf('day').toISOString(),
    }
  }
  if (value === TimeRange.LAST_24_HOURS) {
    return {
      start: now.subtract(24, 'hour').toISOString(),
      end: now.toISOString(),
    }
  }
  if (value === TimeRange.LAST_7_DAYS) {
    return {
      start: now.subtract(7, 'day').toISOString(),
      end: now.toISOString(),
    }
  }
  if (value === TimeRange.LAST_14_DAYS) {
    return {
      start: now.subtract(14, 'day').toISOString(),
      end: now.toISOString(),
    }
  }
  if (value === TimeRange.LAST_30_DAYS) {
    return {
      start: now.subtract(30, 'day').toISOString(),
      end: now.toISOString(),
    }
  }
  if (value === TimeRange.LAST_90_DAYS) {
    return {
      start: now.subtract(90, 'day').toISOString(),
      end: now.toISOString(),
    }
  }
  if (value === TimeRange.LAST_180_DAYS) {
    return {
      start: now.subtract(180, 'day').toISOString(),
      end: now.toISOString(),
    }
  }
  if (value === TimeRange.THIS_MONTH) {
    return {
      start: now.startOf('month').toISOString(),
      end: now.toISOString(),
    }
  }
  if (value === TimeRange.YEAR_TO_DATE) {
    return {
      start: now.startOf('year').toISOString(),
      end: now.toISOString(),
    }
  }

  // all time as the darwin platform was release on 2019 december
  const DARWIN_RELEASE_DATE = '2019-12-01T00:00:00Z'
  return {
    start: DARWIN_RELEASE_DATE,
    end: now.toISOString(),
  }
}
