export interface FrameManifest {
  timestamp: string
  originalIndex: number
  segmentIndex: string
}
/**
 * @param manifest BE frame data in `${frameIndex}:${segmentIndex}:${visible}:${timestamp}` format,
 * where each line is a separate frame
 */
const parseFramesManifest = (manifest: string): FrameManifest[] => {
  const result: FrameManifest[] = []
  const manifestLines = manifest.split('\n')
  manifestLines.forEach((line) => {
    if (!line) {
      return
    }

    const [frameIndex, segmentIndex, visible, timestamp] = line.split(':')

    if (visible === '1') {
      result.push({ originalIndex: Number(frameIndex), segmentIndex, timestamp })
    }
  })
  return result
}

export default parseFramesManifest
