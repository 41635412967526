import { defineStore } from 'pinia'
import { computed } from 'vue'

import { useStore } from '@/store/useStore'
import type { DatasetPayload } from '@/store/types'

/**
 * A pinia proxy to vuex, intended to serve routes revolging around a single
 * dataset.
 *
 * Holds state and getters to more easily access that one dataset and detailed
 * data around it.
 *
 * This is intended to replace the part of the vuex dataset module that
 * relates to the current dataset, but not the entire module.
 */
export const useDatasetStore = defineStore('dataset', () => {
  const store = useStore()

  const currentDataset = computed(() => store.state.dataset.currentDataset)

  const dataset = computed(
    () => store.state.dataset.datasets.find((d) => d.id === currentDataset.value?.id) || null,
  )

  const clearCurrentDataset = (): void => {
    store.commit('dataset/SET_CURRENT_DATASET_ID', null)
  }

  const pushDataset = (dataset: DatasetPayload): void => {
    store.commit('dataset/PUSH_DATASET', dataset)
  }

  const setCurrentDataset = (dataset: DatasetPayload): void => {
    pushDataset(dataset)
    store.commit('dataset/SET_CURRENT_DATASET_ID', dataset.id)
  }

  /**
   * This should be moved to a different composable. It doesn't really fit the intent of this one
   */
  const datasetItemFilenames = computed(() => store.state.dataset.datasetItemFilenames)

  const datasets = computed(() => store.state.dataset.datasets)

  return {
    /**
     * Returns data for the currently selected dataset in the store.
     * This is usually the dataset whose route we're on and it can be partially
     * loaded. It consists of an id and count details
     */
    currentDataset,
    /**
     * Returns the proper backend payload for whatever dataset is returned
     * by `currentDataset`.
     */
    dataset,
    datasets,
    datasetItemFilenames,
    clearCurrentDataset,
    /**
     * Insert or replace a dataset in the store
     */
    pushDataset,
    /**
     * Ensures the same dataset is set across all places
     * where we have a concept of a current dataset
     */
    setCurrentDataset,
  }
})
