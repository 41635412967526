import type { PartialRecord } from '@/core/helperTypes'

/**
 * Caches data at the runtime memory for the fastest usage.
 */
export class RuntimeCacher<ValueType> {
  private cacheMap: PartialRecord<string, ValueType> = {}

  setItem(key: string, val: ValueType): void {
    this.cacheMap[key] = val
  }

  hasItem(key: string): boolean {
    return !!this.cacheMap[key]
  }

  getItem(key: string): ValueType | null {
    return this.cacheMap[key] || null
  }

  deleteItem(key: string): void {
    delete this.cacheMap[key]
  }

  clear(): void {
    this.cacheMap = {}
  }
}
