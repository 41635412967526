import { defineStore } from 'pinia'
import { computed } from 'vue'

import { useDatasetItemsStore } from '@/modules/Datasets/useDatasetItemsStore'
import { DatasetItemType, type V2DatasetItemSlot } from '@/store/types'
import { useWorkviewSettingsStore } from '@/pinia/useWorkviewSettingsStore'
import { useSharedActiveView } from '@/composables/useEditorV2/useSharedActiveView'
import { useLayoutStore } from '@/modules/Workview/useLayoutStore'
import type { ExtendedFileMetadata } from '@/modules/Editor/metadata'

type SlotInfo = {
  isDicom: boolean
  isImage: boolean
  isPdf: boolean
  isRadiologicalView: boolean
  isRadiologicalVolumeView: boolean
  isStream: boolean
  isVideo: boolean
  isTiled: boolean
  isVolume: boolean
  isProcessedAsVideo: boolean
  hasFrames: boolean
  framesCount: number
  sectionless: boolean
  metadata?: ExtendedFileMetadata
}

export const useCurrentItemStore = defineStore('currentItem', () => {
  const itemsStore = useDatasetItemsStore()
  const layoutStore = useLayoutStore()
  const workviewSettingsStore = useWorkviewSettingsStore()
  /*
   * Although this line is theoretically useless, it looks like this store being instantiated first
   * is causing issues with the shared view being out of sync.
   * Fixing this issue:
   * https://linear.app/v7labs/issue/DAR-2652/dicom-timeline-broken-when-navigating-between-views
   */
  useSharedActiveView()

  // proxy the currentItem from useDatasetItems to avoid duplicate event listeners
  const currentItem = computed(() => itemsStore.currentItem)

  // proxy the currentItem ID from useDatasetItems to avoid duplicate event listeners
  const currentItemId = computed(() => itemsStore.currentItem?.id)

  const layoutType = computed(() => {
    if (!currentItem.value) {
      return
    }

    if (!('type' in currentItem.value.layout)) {
      return
    }

    return currentItem.value?.layout?.type
  })

  /**
   * LayoutStore is the most reliable source about slots as it takes care of special
   * cases such as channels where items have multi slots but they are merged into 1
   */
  const slots = computed(() => layoutStore.itemSlots)

  /**
   * The amount of available slots
   */
  const slotCount = computed(() => slots.value?.length || 0)

  const multiSlotInfosMap = computed<Record<string, SlotInfo>>(() => {
    const slotMetadata = <Record<string, SlotInfo>>{}
    const isRadiologicalView = !!slots.value?.every(({ type }) => type === DatasetItemType.dicom)
    const areAllSlotsWithAnAffine = !!slots.value?.every(({ metadata }) => !!metadata?.affine)
    const areAllSlotsVolume = !!slots.value?.every(({ metadata }) => !!metadata?.medical?.is_volume)

    slots.value?.map(
      ({
        metadata,
        slot_name,
        sectionless,
        streamable,
        total_sections,
        type,
      }: V2DatasetItemSlot) => {
        let framesCount = total_sections ?? 0
        if (!!(streamable || sectionless) && !!metadata?.frames_manifests?.length) {
          framesCount = metadata.frames_manifests[0].visible_frames ?? 0
        }

        const isDicom = type === DatasetItemType.dicom
        const isImage = type === DatasetItemType.image
        const isPdf = type === DatasetItemType.pdf
        const isVideo = type === DatasetItemType.video
        const isStream = isVideo && metadata?.segment_index !== undefined
        const isTiled = isImage && !!metadata?.levels
        const isVolume = !!metadata?.medical?.is_volume
        const isRadiologicalVolumeView =
          isRadiologicalView &&
          areAllSlotsWithAnAffine &&
          areAllSlotsVolume &&
          layoutType.value === 'horizontal' &&
          slots.value?.length === 3

        slotMetadata[slot_name] = {
          isDicom,
          isImage,
          isPdf,
          isRadiologicalView,
          isRadiologicalVolumeView,
          isStream,
          isTiled,
          isVideo,
          isVolume,
          // Note: the BE logic always need to treat DICOM and PDFs as videos, no matter
          // if they're single frames.
          isProcessedAsVideo: isVideo || isDicom || isPdf,
          hasFrames: framesCount > 1,
          framesCount,
          sectionless: !!sectionless,
          metadata,
        }
      },
    )
    return slotMetadata
  })

  /**
   * Return true if at least 1 slot is uploaded on an external
   */
  const isExternal = computed(() => !!slots.value?.some((s) => s.is_external))

  /**
   * Return true for multi slot items.
   * Note: channels are not taken into consideration for this
   */
  const isMultiSlot = computed(() => slotCount.value > 1)

  /**
   * Check if all slots have a frame manifest
   */
  const allSlotsHaveFrameManifest = computed(() =>
    Object.values(multiSlotInfosMap.value).every(({ metadata }) => !!metadata?.frames_manifests),
  )

  /**
   * Check if all slots contains DICOM views
   */
  const areAllSlotsDicom = computed(() =>
    Object.values(multiSlotInfosMap.value).every(({ isDicom }) => isDicom),
  )

  /**
   * Check if all slots are stream or video
   * Note: `isProcessedAsVideo` could seem a better solution but that would include
   * DICOMs which in some cases are NOT supported (Eg: sync video playback)
   */
  const areAllSlotsStreamOrVideo = computed(() =>
    Object.values(multiSlotInfosMap.value).every(({ isStream, isVideo }) => isStream || isVideo),
  )

  /**
   * Check if all slots sectionless
   */
  const areAllSlotsSectionless = computed(() =>
    Object.values(multiSlotInfosMap.value).every(({ sectionless }) => sectionless),
  )

  /**
   * Check if all slots contains stream views
   */
  const areAllSlotsStream = computed(() =>
    Object.values(multiSlotInfosMap.value).every(({ isStream }) => isStream),
  )

  /**
   * Return true if there is a slot that contains either a video or a multi-frame image
   */
  const hasMultiframeSlot = computed(() =>
    Object.values(multiSlotInfosMap.value).some((slotInfo) => slotInfo.framesCount > 1),
  )

  const activeSlot = computed<SlotInfo | undefined>(
    () => multiSlotInfosMap.value[workviewSettingsStore.activeSlotName],
  )

  const activeSlotFramesCount = computed(() => activeSlot.value?.framesCount || 0)

  const maxFramesCount = computed(() =>
    Math.max(...Object.values(multiSlotInfosMap.value).map((info) => info.framesCount)),
  )

  return {
    currentItem,
    currentItemId,
    isExternal,
    isMultiSlot,
    layoutType,
    allSlotsHaveFrameManifest,
    areAllSlotsDicom,
    areAllSlotsStream,
    areAllSlotsSectionless,
    areAllSlotsStreamOrVideo,
    hasMultiframeSlot,
    multiSlotInfosMap,
    slotCount,
    activeSlot,
    activeSlotFramesCount,
    maxFramesCount,
  }
})
