import { v4 as uuidv4 } from 'uuid'
import { cloneAnnotation } from '@/modules/Editor/models/annotation/cloneAnnotation'
import type { Editor } from '@/modules/Editor/editor'
import type { Annotation } from '@/modules/Editor/models/annotation/Annotation'
import type { View } from '@/modules/Editor/views/view'
import type { Action } from '@/modules/Editor/managers/actionManager'
import { isVideoSubAnnotations } from '@/modules/Editor/models/annotation/annotationKindValidator'
import type {
  AnnotationProperties,
  VideoSubAnnotations,
} from '@/modules/Editor/models/annotation/types'
import { getInitialVideoAnnotationSegments } from '@/modules/Editor/utils/getInitialVideoAnnotationSegments'

const duplicateAnnotationAction = (view: View, newAnnotation: Annotation): Action => ({
  do(): boolean {
    const firstSegment = newAnnotation.data.segments?.[0]
    const firstFrameIndex = firstSegment?.[0] || 0
    // We don't call `duplicateAnnotation` as we need to both create a new annotation
    // AND to update it's content by clearing and eventually shifting its frames.
    // That's achievable by using the `updateAnnotation` method alone, as until we're
    // passing a new annotation ID it will work as a duplicate+update feature
    view.annotationManager.updateAnnotation(newAnnotation, {
      updatedFramesIndices: [firstFrameIndex],
    })
    return true
  },
  undo(): boolean {
    view.annotationManager.deleteAnnotation(newAnnotation.id)
    return true
  },
})

/**
 * Only the annotation first frame frame, sub-annotation and properties are kept
 * when pasting with the shift modifier.
 */
export const getClearedAndShiftedAnnotation = (
  annotation: Annotation,
  currentFrameIndex: number,
  totalFrames: number,
): Annotation => {
  if (!isVideoSubAnnotations(annotation.subAnnotations)) {
    throw new Error('Trying to updated frames on an annotation with no segments')
  }

  const firstSegment = annotation.data.segments?.[0]
  if (!firstSegment) {
    throw new Error('A video annotation must have at least a segment')
  }
  const firstFrameIndex = firstSegment[0] || 0
  const videoAnnotationDuration = 1
  const newSegments = getInitialVideoAnnotationSegments(
    currentFrameIndex,
    totalFrames,
    videoAnnotationDuration,
  )

  const firstFrameProperty = annotation.properties?.[firstFrameIndex]
  const properties: AnnotationProperties = firstFrameProperty
    ? { [currentFrameIndex]: firstFrameProperty }
    : {}

  // When set, add static properties to the annotation
  if (annotation.properties?.global) {
    properties.global = annotation.properties.global
  }

  const firstFrame = annotation.data.frames?.[firstFrameIndex]
  const frames = firstFrame ? { [currentFrameIndex]: firstFrame } : {}

  const firstFrameSubAnnotation = annotation.subAnnotations.frames[firstFrameIndex]
  const subAnnotations: VideoSubAnnotations = {
    frames: firstFrameSubAnnotation ? { [currentFrameIndex]: firstFrameSubAnnotation } : {},
  }

  return {
    ...annotation,
    properties,
    subAnnotations,
    data: {
      ...annotation.data,
      frames,
      hidden_areas: [],
      segments: newSegments,
    },
  }
}

/**
 * Paste the a annotation keeping:
 * - annotation length
 * - the Instance ID
 * - annotation's first frame sub-annotatio/property
 *
 * No further keyframes/sub-keyframes are duplicated AND the
 * annotation duration is set to 1
 * @param context the plugin context
 * @param payload, data from the clipboard memory
 */
export const clipboardCommandAltShiftPaste = (
  editor: Editor,
  payload: {
    clipboardAnnotation: Annotation | undefined
    sourceAnnotationId: string | undefined
  },
): void => {
  const { clipboardAnnotation, sourceAnnotationId } = payload
  if (!clipboardAnnotation || !sourceAnnotationId) {
    return
  }

  if (editor.activeView.isLoading) {
    return
  }

  const newAnnotation = cloneAnnotation(clipboardAnnotation, {
    id: uuidv4(),
    subAnnotations: clipboardAnnotation.subAnnotations,
    properties: clipboardAnnotation.properties,
  })

  // annotation is of type video, we then need to remove
  // frames/sub-annotations/properties from every frame other
  // than the first frame, and shift the annotation starting
  // from the current scrubber position
  if (isVideoSubAnnotations(newAnnotation.subAnnotations)) {
    const clearedShiftedAnnotation = getClearedAndShiftedAnnotation(
      newAnnotation,
      editor.activeView.currentFrameIndex,
      editor.activeView.totalFrames,
    )
    editor.actionManager.do(duplicateAnnotationAction(editor.activeView, clearedShiftedAnnotation))
    return
  }

  // annotation is of type image, this case's easier as we don't need to
  // worry about frames
  editor.actionManager.do(duplicateAnnotationAction(editor.activeView, newAnnotation))
}
