import { ToolName } from '@/modules/Editor/tools/types'
import type { SubAnnotationTool, ToolContext } from '@/modules/Editor/managers/toolManager'
import type { Annotation } from '@/modules/Editor/models/annotation/Annotation'
import { setupMouseButtonLoadout } from '@/modules/Editor/plugins/mixins/loadouts'

import type { ClickerTool } from './ClickerTool'
import { isPreselectedModelAutoAnnotate, retrieveAutoAnnotateData } from './utils'

const resolveClickerTool = (context: ToolContext): ClickerTool | undefined => {
  const { currentTool } = context.editor.toolManager
  if (!currentTool) {
    return
  }

  if (currentTool.name !== ToolName.Clicker) {
    return
  }

  return currentTool.tool as ClickerTool
}

export const clickerSubTool: SubAnnotationTool = {
  masterAnnotation: null,
  selectMasterAnnotation(context: ToolContext, annotation: Annotation) {
    this.masterAnnotation = annotation

    const autoAnnotateData = retrieveAutoAnnotateData(annotation.id, context.editor.activeView)
    if (!autoAnnotateData) {
      return
    }

    context.editor.toolManager.activateTool('clicker_tool')
    context.editor.callCommand('clicker_tool.init', annotation, autoAnnotateData)

    const tool = resolveClickerTool(context)
    if (!tool) {
      return
    }

    if (isPreselectedModelAutoAnnotate(context.editor)) {
      return
    }

    const { trainedModels } = context.editor.store.state.neuralModel
    const trainedAutoAnnotateIds = trainedModels.map((tm) => tm.id)

    const autoAnnotateModel = context.editor.autoAnnotateModels.find((m) =>
      trainedAutoAnnotateIds.includes(m.id),
    )

    if (autoAnnotateModel) {
      context.editor.store.commit(
        'workview/SET_CURRENT_TOOL_PRESELECTED_MODEL_ID',
        autoAnnotateModel.id,
      )
    }
  },

  activate(context: ToolContext) {
    setupMouseButtonLoadout(context, { primary: true })
  },

  deactivate(context: ToolContext) {
    context.editor.toolManager.activatePreviousToolEntry()
  },

  reset() {},
}
