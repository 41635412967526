import { markRaw } from 'vue'
import type { Store } from 'vuex'

import type { AnnotationType } from '@/core/annotationTypes'
import type { CallbackFunctionVariadic, PartialRecord } from '@/core/helperTypes'
import type { AnnotationClass } from '@/modules/Editor/AnnotationClass'
import type { CallbackHandle } from '@/modules/Editor/callbackHandler'
import { AnnotationManagerEvents, EditorEvents, LayoutEvents } from '@/modules/Editor/eventBus'
import type { ImageManipulationFilter } from '@/modules/Editor/imageManipulation'
import { ActionManager } from '@/modules/Editor/managers/actionManager'
import ClassDialog from '@/modules/Editor/ClassDialog'
import type { LayoutConfig } from '@/modules/Editor/layout'
import { Layout } from '@/modules/Editor/layout'
import { AutoAnnotateManager } from '@/modules/Editor/managers/autoAnnotateManager'
import { HotkeyManager } from '@/modules/Editor/managers/hotkeyManager'
import { PluginManager } from '@/modules/Editor/managers/pluginManager'
import { ToolManager } from '@/modules/Editor/managers/toolManager'
import type { AutoAnnotateModel } from '@/modules/Editor/types'
import type { FeatureFlags } from '@/modules/Editor/types/FeatureFlags'
import type { View } from '@/modules/Editor/views/view'

// eslint-disable-next-line boundaries/element-types
import { type DatasetItemType, type RootState } from '@/store/types'

import type { ICommentsProvider } from './iproviders/types'
import type { Providers } from './iproviders/types'
import { PolygonTool } from './plugins/polygon/tool'
import { VideoView } from './views/videoView'
import type { VtkContextManager } from './managers/vtkContextManager'
import type { Section } from './managers/fileManager'
import type { AutoAnnotateInferencePayload, InferenceData, InferenceResult } from './backend'

/**
 * Number of maximum simultaneous requests.
 */
const HARDWARE_CONCURRENCY = 2

export type EditorConfig = {
  featureFlags?: Partial<FeatureFlags>
  /**
   * A set of interfaces used to CRUD comments, for now, later annotations, etc.
   * Long term this is how Edtior saves data without relying on Vuex or Pinia.
   * @see https://www.notion.so/v7labs/Providers-6b7ca33689bc4b1581dbb36af7411702
   * @see https://www.notion.so/v7labs/Road-to-embeddable-editor-9ba58d785db34bafb96386ab436a071d
   */
  providers: Providers
  autoAnnotateModels: AutoAnnotateModel[]
  preselectedAnnotationClassId: number | null
  preselectedClassIdPerTool: PartialRecord<string, number>
  hardwareConcurrency?: number
  freezeFrame?: boolean
  renderSubAnnotations?: boolean
  renderMeasures?: boolean
  getBaseStreamUrl: (itemId: string, fileSlotName: string) => string
  getClassById: (id: number) => AnnotationClass | undefined
  getCurrentUserId: () => number | undefined
  getFirstClassForType: (
    type: AnnotationType,
    extraCondition?: (c: AnnotationClass) => boolean,
  ) => AnnotationClass | undefined
  selectPreset: (key: string) => void
  loadTiles: (
    itemId: string,
    slotName: string,
    tiles: { x: number; y: number; z: number }[],
  ) => Promise<{ [k in string]: string } | null>
  loadSlotSections: (
    itemId: string,
    slotName: string,
    offset: number,
    size: number,
  ) => Promise<Section[] | null>
  runInference: (
    modelId: string,
    data: InferenceData | AutoAnnotateInferencePayload,
  ) => Promise<InferenceResult | InferenceResult[] | null>
  videoPlaybackSpeed: number
  videoPlaybackLoop: boolean
  syncVideoPlayback: boolean
  framesPreloadSize: number
}

export class Editor {
  public readonly version = '2.0'
  protected onCleanup: (() => void)[] = []

  public layout: Layout

  protected static RERENDER_LIMIT = 50

  public actionManager: ActionManager
  public hotkeyManager: HotkeyManager
  public pluginManager: PluginManager
  public autoAnnotateManager: AutoAnnotateManager
  public toolManager: ToolManager
  public classDialog: ClassDialog
  public framesPreloadSize: number
  public readonly featureFlags: FeatureFlags
  public readonly hardwareConcurrency
  public readonly autoAnnotateModels

  public embedded: boolean = false

  // eslint-disable-next-line @typescript-eslint/no-unsafe-function-type
  private commands = new Map<string, Function>()
  protected renderAnimationFrameID: number = -1

  public store: Store<RootState>

  public readonly commentsProvider: ICommentsProvider

  public get videoAnnotationDuration(): number {
    return this.store.state.workview.videoAnnotationDuration
  }

  private _slotTypes: (DatasetItemType | null)[] = []
  public get slotTypes(): typeof this._slotTypes {
    return this._slotTypes
  }

  public set slotTypes(slotTypes: (DatasetItemType | null)[]) {
    this._slotTypes = slotTypes
  }

  private _freezeFrame: boolean = false
  public get freezeFrame(): boolean {
    return this._freezeFrame
  }

  private _preselectedAnnotationClassId: number | null = null
  public get preselectedAnnotationClassId(): number | null {
    return this._preselectedAnnotationClassId
  }

  private set preselectedAnnotationClassId(val: number | null) {
    this._preselectedAnnotationClassId = val
  }

  private _renderMeasures: boolean = false
  public get renderMeasures(): boolean {
    return this._renderMeasures
  }

  private _renderSubAnnotations: boolean = true
  public get renderSubAnnotations(): boolean {
    return this._renderSubAnnotations
  }

  /**
   * Keeps the preselected class id per tool.
   * Used by the preselectOrPromptForAnnotationClass.ts to preselect class for the tool.
   * Provided with Editor's config and can be refreshed with watcher in the useEditorV2.ts.
   *
   * @deprecated Do not overuse this since we're planning to move out classes from the Editor.
   */
  public preselectedClassIdPerTool: PartialRecord<string, number> = {}

  /**
   * Temporary workaround to get the base stream URL of an item's
   * slot/file without tangling the app store to deep into the editor
   *
   * @deprecated Do not overuse this since we're planning to move the
   * file manager out of the editor
   */
  readonly getBaseStreamUrl: (itemId: string, fileSlotName: string) => string

  /**
   * Allow us to remove classes from the Editor.AnnotationManager
   * and not create dependency to the Vue store.
   * We use it:
   * - to get set class connection to the Annotation. AnnotationMangaer
   * - to select class using hot key HotkeyManager
   *
   * @deprecated Do not overuse this since we're planning to move out classes from the Editor.
   */
  readonly getClassById: (id: number) => AnnotationClass | undefined

  /**
   * Get current user id from the store.
   * @deprecated Do not overuse this since we're planning to move out classes from the Editor.
   */
  readonly getCurrentUserId: () => number | undefined

  /**
   * Temporary function passed in via config to load tiles for a tiled file.
   * @deprecated Should be removed as we moved file loading out of the editor. Do not expand use if you can avoid it.
   */
  readonly loadTiles: (
    itemId: string,
    slotName: string,
    tiles: { x: number; y: number; z: number }[],
  ) => Promise<{ [k in string]: string } | null>

  /**
   * Temporary function passed in via config to load slot sections for a file (slot).
   * @deprecated Should be removed as we moved file loading out of the editor. Do not expand use if you can avoid it.
   */
  readonly loadSlotSections: (
    itemId: string,
    slotName: string,
    offset: number,
    size: number,
  ) => Promise<Section[] | null>

  readonly runInference: (
    modelId: string,
    data: InferenceData | AutoAnnotateInferencePayload,
  ) => Promise<InferenceResult | InferenceResult[] | null>

  /**
   * Select a preset by keyboard event key
   * @deprecated Temporary until we are able to hoist it out of the editor
   */
  readonly selectPreset: (key: string) => void

  private _syncVideoPlayback: boolean = false
  public get syncVideoPlayback(): boolean {
    return this._syncVideoPlayback && this.viewsList.length > 1
  }

  /**
   * To preselect class we need the entire list of classes to match type and first available class.
   * To avoid copying the classes list from the Pinia store to the Editor
   * preselectOrPromptForAnnotationClass.ts uses this function to get it.
   *
   * @deprecated Do not overuse this since we're planning to move out classes from the Editor.
   */
  readonly getFirstClassForType: (
    type: AnnotationType,
    extraCondition?: (c: AnnotationClass) => boolean,
  ) => AnnotationClass | undefined

  private _videoPlaybackSpeed: number
  public get videoPlaybackSpeed(): number {
    return this._videoPlaybackSpeed
  }

  private _videoPlaybackLoop: boolean
  public get videoPlaybackLoop(): boolean {
    return this._videoPlaybackLoop
  }

  constructor(store: Store<RootState>, layout: LayoutConfig, config: EditorConfig) {
    this.store = store

    const configFeatureFlags = config.featureFlags || {}

    this.featureFlags = {
      ANNOTATIONS_PAGINATION: configFeatureFlags.ANNOTATIONS_PAGINATION || false,
      CHANNELS: configFeatureFlags.CHANNELS || false,
      LOSSLESS_FRAME_EXTRACTION: configFeatureFlags.LOSSLESS_FRAME_EXTRACTION || false,
      LEGACY_DICOM: configFeatureFlags.LEGACY_DICOM || false,
      IMAGE_TAG_LOADER: configFeatureFlags.IMAGE_TAG_LOADER || false,
      MED_2D_VIEWER: configFeatureFlags.MED_2D_VIEWER || false,
      MED_LIGHT_MODE: configFeatureFlags.MED_LIGHT_MODE || false,
      OBLIQUE_PLANES: configFeatureFlags.OBLIQUE_PLANES || false,
      SENTRY_REPLAY: configFeatureFlags.SENTRY_REPLAY || false,
      SYNC_VIDEO_PLAYBACK: configFeatureFlags.SYNC_VIDEO_PLAYBACK || false,
      THRESHOLD_BRUSH: configFeatureFlags.THRESHOLD_BRUSH || false,
      USE_IMG_RENDERING: configFeatureFlags.USE_IMG_RENDERING || false,
      WORKLOG_V3_ENABLED: configFeatureFlags.WORKLOG_V3_ENABLED || false,
      FRAMES_MANIFEST_WITH_FRAMES_EXTRACTION:
        configFeatureFlags.FRAMES_MANIFEST_WITH_FRAMES_EXTRACTION || false,
      ANNOTATIONS_PACKAGE: configFeatureFlags.ANNOTATIONS_PACKAGE || false,
      VIDEO_TIMECODE: configFeatureFlags.VIDEO_TIMECODE || false,
    }

    this.autoAnnotateModels = config.autoAnnotateModels
    this.preselectedAnnotationClassId = config.preselectedAnnotationClassId
    this.preselectedClassIdPerTool = config.preselectedClassIdPerTool
    this._renderSubAnnotations = config.renderSubAnnotations || true
    this._videoPlaybackSpeed = config.videoPlaybackSpeed
    this._videoPlaybackLoop = config.videoPlaybackLoop
    this.hardwareConcurrency = config.hardwareConcurrency || HARDWARE_CONCURRENCY
    this.framesPreloadSize = config.framesPreloadSize

    this._freezeFrame = !!config.freezeFrame
    this.commentsProvider = config.providers.commentsProvider
    this.getBaseStreamUrl = config.getBaseStreamUrl
    this.getClassById = config.getClassById
    this.getFirstClassForType = config.getFirstClassForType
    this.getCurrentUserId = config.getCurrentUserId
    this.selectPreset = config.selectPreset
    this.loadTiles = config.loadTiles
    this.loadSlotSections = config.loadSlotSections
    this.runInference = config.runInference
    this._syncVideoPlayback = config.syncVideoPlayback

    this.hotkeyManager = new HotkeyManager(this)
    this.autoAnnotateManager = new AutoAnnotateManager(this)
    this.actionManager = new ActionManager()
    this.classDialog = new ClassDialog()
    this.toolManager = new ToolManager(this)

    this.layout = markRaw(new Layout(this, layout))
    this.pluginManager = new PluginManager(this) // needs to go after layout. solved in ARC-877
  }

  setFramesPreloadSize(val: number): void {
    this.framesPreloadSize = val
  }

  setSyncVideoPlayback(val: boolean): void {
    this._syncVideoPlayback = val
  }

  setVideoPlaybackLoop(val: boolean): void {
    this._videoPlaybackLoop = val
  }

  setFreezeFrame(state: boolean): void {
    this._freezeFrame = state
  }

  setPreselectedAnnotationClassId(val: number | null): void {
    this.preselectedAnnotationClassId = val
    EditorEvents.preselectedClassIdChanged.emit(val)
  }

  setPreselectedClassIdPerTool(val: PartialRecord<string, number>): void {
    this.preselectedClassIdPerTool = val
  }

  setRenderSubAnnotations(val: boolean): void {
    this._renderSubAnnotations = val
  }

  setRenderMeasures(val: boolean): void {
    this._renderMeasures = val
  }

  setVideoPlaybackSpeed(speed: number): void {
    this._videoPlaybackSpeed = speed
    EditorEvents.playbackSpeedUpdated.emit(speed)
  }

  public init(layout: LayoutConfig, embedded: boolean = false): void {
    this.cleanup()

    const handleAnnotationDelete = (): void => {
      if (this.toolManager.currentTool?.tool instanceof PolygonTool) {
        this.toolManager.currentTool.tool.reset(this.toolManager.currentTool.context)
      }
    }
    AnnotationManagerEvents.annotationDelete.on(handleAnnotationDelete)
    this.onCleanup.push(() => AnnotationManagerEvents.annotationDelete.off(handleAnnotationDelete))

    this.layout = markRaw(new Layout(this, layout))
    LayoutEvents.initialised.emit({ layout: this.layout })

    this.pluginManager = new PluginManager(this) // needs to go after layout. solved in ARC-877

    this.hotkeyManager.registerDefaultHotkeyListeners()
    this.hotkeyManager.registerAnnotationClassOrPresetHotkeys()

    this.embedded = embedded

    this.render()
  }

  get activeView(): View {
    return this.layout.activeView
  }

  private _vtkContextManagerPromise?: Promise<VtkContextManager>

  private async createContextManager(): Promise<VtkContextManager> {
    const { VtkContextManager } = await import('@/modules/Editor/managers/vtkContextManager')
    return new VtkContextManager()
  }

  get vtkContextManager(): Promise<VtkContextManager> {
    if (!this._vtkContextManagerPromise) {
      this._vtkContextManagerPromise = this.createContextManager()
    }
    return this._vtkContextManagerPromise
  }

  /**
   * The view that is visible at any given time.
   * This is normally the `activeView`, but in cases like channels, it can be the active channel view
   */
  get visibleView(): View {
    return this.layout.visibleView
  }

  get views(): Map<string, View> {
    return this.layout.views
  }

  public get viewsList(): View[] {
    return this.layout.viewsList
  }

  public setPreviewFrameIndex(index: number): void {
    if (!(this.activeView instanceof VideoView)) {
      return
    }

    this.activeView.setPreviewFrameIndex(index)
  }

  public clearPreviewFrameIndex(): void {
    if (!(this.activeView instanceof VideoView)) {
      return
    }

    this.activeView.clearPreviewFrameIndex()
  }

  public togglePlayPause(): void {
    if (this.activeView instanceof VideoView) {
      this.activeView.togglePlayPause()
    }
  }

  /**
   * toggle play/pause for the sync video playback feature.
   */
  public async togglePlayPauseForAllViews(): Promise<void> {
    if (!(this.activeView instanceof VideoView)) {
      return
    }

    const activeViewIsPlaying = this.activeView.isPlaying

    await this.layout.viewsList.forEach(async (view) => {
      if (!(view instanceof VideoView)) {
        return
      }
      activeViewIsPlaying ? await view.pause() : await view.play()
    })
  }

  public render(): void {
    this.layout.viewsList.forEach((view) => {
      view.render()
    })

    this.renderAnimationFrameID = requestAnimationFrame(() => {
      this.render()
    })
  }

  public enterFullScreen(slotName: string): void {
    this.layout.enterFullScreen(slotName)
    LayoutEvents.enterFullscreen.emit({ slotName, layout: this.layout })
  }

  public exitFullScreen(): void {
    this.layout.exitFullScreen()
    LayoutEvents.exitFullscreen.emit({ layout: this.layout })
  }

  public enterMultiPlanarMode(): void {
    this.layout.enterMultiPlanarMode()
    LayoutEvents.enterMultiPlanarMode.emit({ layout: this.layout })
  }

  public exitMultiPlanarMode(): void {
    this.layout.exitMultiPlanarMode()
    LayoutEvents.exitMultiPlanarMode.emit({ layout: this.layout })
  }

  public setLayout(columns: number, rows: number): void {
    this.layout.setLayout(columns, rows)
    LayoutEvents.changed.emit({ layout: this.layout })
  }

  public setViewport(slot_name: string, column: number, row: number): void {
    this.layout.setViewport(slot_name, column, row)
    LayoutEvents.setViewport.emit({ slotName: slot_name, layout: this.layout })
  }

  public setAndActivateViewport(slot_name: string, column: number, row: number): void {
    this.setViewport(slot_name, column, row)
  }

  setImageFilter(filter: ImageManipulationFilter): void {
    this.viewsList.forEach((view) => {
      view.setImageFilter(filter)
    })
  }

  // we cannot use ...args: unknown[] here.
  // Variadic function declarations only work with any this way

  public registerCommand<T extends unknown[]>(
    name: string,
    action: CallbackFunctionVariadic<T>,
  ): void {
    this.commands.set(name, action)
  }

  public unregisterCommand(name: string): void {
    this.commands.delete(name)
  }

  public callCommand(name: string, ...args: unknown[]): void {
    const command = this.commands.get(name)
    if (!command) {
      return
    }
    command(...args)
  }

  // START::Callbacks
  public onDoubleClick(cb: (event: MouseEvent) => void): CallbackHandle[] {
    return this.viewsList.map((view) => view.onDoubleClick(cb))
  }

  public onMouseDown(cb: (event: MouseEvent) => void): CallbackHandle[] {
    return this.viewsList.map((view) => view.onMouseDown(cb))
  }

  public onMouseUp(cb: (event: MouseEvent) => void): CallbackHandle[] {
    return this.viewsList.map((view) => view.onMouseUp(cb))
  }

  public onMouseMove(cb: (event: MouseEvent) => void): CallbackHandle[] {
    return this.viewsList.map((view) => view.onMouseMove(cb))
  }

  public onMouseLeave(cb: (event: MouseEvent) => void): CallbackHandle[] {
    return this.viewsList.map((view) => view.onMouseLeave(cb))
  }

  public onGestureStart(cb: (event: Event) => void): CallbackHandle[] {
    return this.viewsList.map((view) => view.onGestureStart(cb))
  }

  public onGestureChange(cb: (event: Event) => void): CallbackHandle[] {
    return this.viewsList.map((view) => view.onGestureChange(cb))
  }

  public onGestureEnd(cb: (event: Event) => void): CallbackHandle[] {
    return this.viewsList.map((view) => view.onGestureEnd(cb))
  }

  public onWheel(cb: (event: WheelEvent) => void): CallbackHandle[] {
    return this.viewsList.map((view) => view.onWheel(cb))
  }

  public onTouchStart(cb: (event: TouchEvent) => void): CallbackHandle[] {
    return this.viewsList.map((view) => view.onTouchStart(cb))
  }

  public onTouchEnd(cb: (event: TouchEvent) => void): CallbackHandle[] {
    return this.viewsList.map((view) => view.onTouchEnd(cb))
  }

  public onTouchMove(cb: (event: TouchEvent) => void): CallbackHandle[] {
    return this.viewsList.map((view) => view.onTouchMove(cb))
  }

  public onKeyDown(cb: (event: KeyboardEvent) => void): CallbackHandle[] {
    return this.viewsList.map((view) => view.onKeyDown(cb))
  }

  public onKeyPress(cb: (event: KeyboardEvent) => void): CallbackHandle[] {
    return this.viewsList.map((view) => view.onKeyPress(cb))
  }

  public onKeyUp(cb: (event: KeyboardEvent) => void): CallbackHandle[] {
    return this.viewsList.map((view) => view.onKeyUp(cb))
  }
  // END::Callbacks

  public cleanup(): void {
    this.commands.clear()
    this.layout.cleanup()
    this.pluginManager.clearPlugins()
    this.hotkeyManager.cleanup()
    this.onCleanup.forEach((callback) => callback())
    window.cancelAnimationFrame(this.renderAnimationFrameID)
    EditorEvents.cleanup.emit()
  }

  public destroy(): void {
    this.cleanup()
    this._vtkContextManagerPromise?.then((contextManager) => contextManager.delete())
  }
}
