import { v4 as uuidv4 } from 'uuid'
import type { AnnotationType } from '@/core/annotationTypes'
import type {
  AnnotationData,
  SubAnnotationData,
  VideoAnnotationData,
} from '@/modules/Editor/AnnotationData'
import { enableInterpolateByDefault } from '@/modules/Editor/annotationInterpolation'
import type { Action } from '@/modules/Editor/managers/actionManager'
import type { Editor } from '@/modules/Editor/editor'
import type { Annotation } from '@/modules/Editor/models/annotation/Annotation'
import {
  isVideoAnnotationData,
  isVideoSubAnnotations,
} from '@/modules/Editor/models/annotation/annotationKindValidator'
import { cloneAnnotation } from '@/modules/Editor/models/annotation/cloneAnnotation'
import type { VideoAnnotationDataPayload } from '@/modules/Editor/types'
import { getInitialVideoAnnotationSegments } from '@/modules/Editor/utils/getInitialVideoAnnotationSegments'
import type { View } from '@/modules/Editor/views/view'
import { getAnnotationRange } from '@/core/utils/frames'
import type { PartialRecord } from '@/core/helperTypes'
import type { PropertiesData } from '@/core/annotations'

/**
 * The original annotation start keyframe might not match the
 * new one so we calculate a shifted keyframe
 */
const shiftFrames = (
  frames: { [frame: string]: unknown },
  newSegmentStart: number,
  oldSegmentStart: number,
): { [frame: string]: AnnotationData | SubAnnotationData } | undefined => {
  const newFrames: { [frame: string]: AnnotationData | SubAnnotationData } = {}

  Object.keys(frames).forEach((keyframe: string) => {
    const segmentsDelta = newSegmentStart - oldSegmentStart
    const shiftedKeyframe = Number(keyframe) + segmentsDelta
    newFrames[shiftedKeyframe] = frames[keyframe]
  })

  return newFrames
}

/**
 * The original annotation start keyframe might not match the
 * new one so we calculate a shifted keyframe
 */
const shiftProperties = (
  properties: PartialRecord<number | 'global', PropertiesData | null>,
  newSegmentStart: number,
  oldSegmentStart: number,
): PartialRecord<number, PropertiesData | null> => {
  const newProperties: PartialRecord<number | 'global', PropertiesData | null> = {}

  Object.keys(properties).forEach((keyframe: string) => {
    const segmentsDelta = newSegmentStart - oldSegmentStart
    if (keyframe === 'global') {
      newProperties[keyframe] = properties[keyframe]
      return
    }
    const shiftedKeyframe = Number(keyframe) + segmentsDelta
    newProperties[shiftedKeyframe] = properties[Number(keyframe)]
  })

  return newProperties
}

const shiftHiddenAreas = (
  hiddenAreas: [number, number][] | undefined,
  newSegmentStart: number,
  oldSegmentStart: number,
): [number, number][] | undefined =>
  hiddenAreas?.map(([start, end]) => [
    start + (newSegmentStart - oldSegmentStart),
    end + (newSegmentStart - oldSegmentStart),
  ])

/**
 * We shift the annotation frames based on the new annotation start frame
 */
const getShiftedAnnotationData = (
  oldAnnotationData: VideoAnnotationData,
  newAnnotationData: VideoAnnotationDataPayload,
  oldAnnotationType: AnnotationType,
  view: View,
): VideoAnnotationData => {
  const { frames, sub_frames, hidden_areas, segments } = oldAnnotationData

  if (segments === undefined) {
    throw new Error('Trying to shift an annotation with no segments')
  }

  const { startFrame: originalStartFrame, endFrame: originalEndFrame } = getAnnotationRange(
    segments,
    view.totalFrames,
  )

  const videoAnnotationDuration = originalEndFrame - originalStartFrame

  const newSegments = getInitialVideoAnnotationSegments(
    view.currentFrameIndex,
    view.totalFrames,
    videoAnnotationDuration,
  )
  const newSegmentStart = newSegments[0][0]
  const oldSegmentStart = segments[0][0]

  const newFrames = shiftFrames(frames, newSegmentStart, oldSegmentStart) ?? frames
  const newSubFrames = shiftFrames(sub_frames, newSegmentStart, oldSegmentStart) ?? sub_frames
  const newHiddenAreas = shiftHiddenAreas(hidden_areas, newSegmentStart, oldSegmentStart)

  return {
    frames: newFrames,
    sub_frames: newSubFrames,
    segments: newSegments,
    hidden_areas: newHiddenAreas,
    interpolated: enableInterpolateByDefault(oldAnnotationType),
    interpolate_algorithm: newAnnotationData.interpolateAlgorithm,
  }
}

const getShiftedProperties = (annotation: Annotation, view: View): Annotation['properties'] => {
  const { segments } = annotation.data

  if (segments === undefined) {
    throw new Error('Trying to shift an annotation with no segments')
  }

  const { startFrame: originalStartFrame, endFrame: originalEndFrame } = getAnnotationRange(
    segments,
    view.totalFrames,
  )

  const videoAnnotationDuration = originalEndFrame - originalStartFrame

  const newSegments = getInitialVideoAnnotationSegments(
    view.currentFrameIndex,
    view.totalFrames,
    videoAnnotationDuration,
  )

  const newSegmentStart = newSegments[0][0]
  const oldSegmentStart = segments[0][0]

  return annotation.properties
    ? shiftProperties(annotation.properties, newSegmentStart, oldSegmentStart)
    : undefined
}

const duplicateAnnotationAction = (
  view: View,
  ann: Annotation,
  options?: { isVideo: boolean; segmentShiftAmount: number },
): Action => ({
  do(): boolean {
    const firstSegment = ann.data.segments?.[0]
    const firstFrameIndex = firstSegment?.[0] || 0
    view.annotationManager.updateAnnotation(
      ann,
      options?.isVideo
        ? {
            updatedFramesIndices: [firstFrameIndex],
            segmentShiftAmount: options?.segmentShiftAmount,
          }
        : undefined,
    )
    return true
  },
  undo(): boolean {
    view.annotationManager.deleteAnnotation(ann.id)
    return true
  },
})

/**
 * Create a new annotation based on the on stored in clipboard memory
 * @param context the plugin context
 * @param payload, data from the clipboard memory
 */
export const clipboardCommandPaste = (
  editor: Editor,
  payload: {
    clipboardAnnotation: Annotation | undefined
    sourceAnnotationId: string | undefined
    videoAnnotationData?: VideoAnnotationDataPayload
  },
): void => {
  const { clipboardAnnotation, videoAnnotationData, sourceAnnotationId } = payload
  if (!clipboardAnnotation || !sourceAnnotationId) {
    return
  }

  if (editor.activeView.isLoading) {
    return
  }

  const newAnnotation = cloneAnnotation(clipboardAnnotation, {
    id: uuidv4(),
  })

  // annotation is of type video, we then need to shift the annotation starting
  // frames, sub-frames, properties and hidden-areas from the current scrubber position
  if (
    clipboardAnnotation.data &&
    videoAnnotationData &&
    isVideoAnnotationData(clipboardAnnotation.data) &&
    isVideoSubAnnotations(newAnnotation.subAnnotations)
  ) {
    const shiftedAnnotationData = getShiftedAnnotationData(
      clipboardAnnotation.data,
      videoAnnotationData,
      clipboardAnnotation.type,
      editor.activeView,
    )
    const shiftedProperties = getShiftedProperties(newAnnotation, editor.activeView)
    const shiftedAnnotation = editor.activeView.annotationManager.initializeAnnotation({
      type: clipboardAnnotation.type,
      annotationClass: clipboardAnnotation.annotationClass,
      data: shiftedAnnotationData,
      properties: shiftedProperties,
    })
    if (!shiftedAnnotation || !('frames' in shiftedAnnotation.subAnnotations)) {
      return
    }

    // Make sure that the subAnnotations frames have the new subAnnotations correctly
    // shifted and that we pass a 2D Array in case the Annotation has multiple subAnnotations
    shiftedAnnotation.subAnnotations.frames = videoAnnotationData.subs.reduce<{
      [frame: string]: Annotation[]
    }>(
      (acc, s, idx) => ({
        ...acc,
        [Object.keys(shiftedAnnotationData.sub_frames)[idx]]: Array.isArray(s) ? s : [s],
      }),
      {},
    )

    const clipboardAnnotationFirstSegment = clipboardAnnotation.data.segments?.[0][0] || 0
    const options = {
      isVideo: true,
      segmentShiftAmount: editor.activeView.currentFrameIndex - clipboardAnnotationFirstSegment,
    }
    editor.actionManager.do(
      duplicateAnnotationAction(editor.activeView, shiftedAnnotation, options),
    )
    return
  }

  editor.actionManager.do(duplicateAnnotationAction(editor.activeView, newAnnotation))
}
