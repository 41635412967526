import type { WorkflowMutation } from '@/store/modules/workflow/types'
import { StageType } from '@/store/types/StageType'
import type {
  V2ConsensusStagePayload,
  V2WorkflowStagePayload,
} from '@/store/types/V2WorkflowStagePayload'

const findParentConsensusStage = (
  stages: V2WorkflowStagePayload[],
  stageId: string,
): V2ConsensusStagePayload | undefined =>
  stages.find(
    (s): s is V2ConsensusStagePayload =>
      s.type === StageType.ConsensusEntrypoint && s.config.parallel_stage_ids.includes(stageId),
  )

export const REMOVE_STAGE: WorkflowMutation<string> = (state, stageId) => {
  const stageIdsToRemove = [stageId]
  const draft = state.editedWorkflow

  if (!draft) {
    return
  }

  const stage = draft.stages.find(({ id }) => id === stageId)
  if (stage?.type === StageType.ConsensusEntrypoint) {
    stageIdsToRemove.push(stage.config.test_stage_id, ...stage.config.parallel_stage_ids)
  }

  const isSubStage = stage?.type === StageType.Annotate || stage?.type === StageType.Model
  const parentConsensusStage = findParentConsensusStage(draft.stages, stageId)
  const firstValidParallelStageIdIfSubStage =
    isSubStage && parentConsensusStage ? parentConsensusStage.config.parallel_stage_ids[1] : null

  draft.stages = draft.stages
    .filter(({ id }) => !stageIdsToRemove.includes(id))
    .map((s) =>
      s.type === StageType.ConsensusEntrypoint
        ? // delete the stage id to be removed from any existing consensus entrypoint configs
          {
            ...s,
            config: {
              ...s.config,
              parallel_stage_ids: s.config.parallel_stage_ids.filter((id) => id !== stageId),
            },
          }
        : s.type === StageType.ConsensusTest
          ? {
              // remove the stage id from the test config if set as champion_stage_id
              // and set it back to the first parallel stage
              ...s,
              config: {
                ...s.config,
                champion_stage_id:
                  s.config.champion_stage_id === stageId
                    ? firstValidParallelStageIdIfSubStage
                    : s.config.champion_stage_id,
              },
            }
          : s,
    )
}
