import type { ApiResponse, V2WorkflowPayload } from '@/store/types'
import { get } from '@/backend/api'
import type { ParsedError } from '@/backend/error'
import { errorMessages, isErrorResponse, parseError } from '@/backend/error'

type Params = {
  teamSlug: string
  workflowId: string
}

type Response = Promise<ApiResponse<V2WorkflowPayload> | ParsedError>

/**
 * Loads specific workflow via id and team slug
 */
export const loadV2Workflow = async (params: Params): Response => {
  const { teamSlug, workflowId } = params
  const path = `v2/teams/${teamSlug}/workflows/${workflowId}`

  try {
    const response = await get<V2WorkflowPayload>(path)
    return response
  } catch (error) {
    if (!isErrorResponse(error)) {
      throw error
    }
    return parseError(error, errorMessages.WORKFLOWS_LOAD)
  }
}
