import { EventEmitter } from 'events'

import type { PartialRecord } from '@/core/helperTypes'
import {
  AnnotationManagerEvents,
  RasterManagerEvents,
  type ViewEvent,
} from '@/modules/Editor/eventBus'
import type { Raster } from '@/modules/Editor/models/raster/Raster'
import { Events as VideoRasterEvents } from '@/modules/Editor/models/raster/VideoRaster'
import type { View } from '@/modules/Editor/views/view'

export enum Events {
  RASTERS_CREATE = 'rasters:create',
  RASTERS_UPDATE = 'rasters:update',
  RASTERS_DELETE = 'rasters:delete',
  RASTERS_CHANGED = 'rasters:changed',
  RASTERS_ERROR = 'rasters:error',
  RASTERS_VIDEO_RASTER_RANGE_UPDATED = 'rasters.videorasterrangeupdated',
  RASTERS_VIDEO_RASTER_KEYFRAME_ADDED = 'rasters.videorasterkeyframeadded',
}

/**
 * Here we use a set of listeners which bubble each raster's events up so that the raster manager
 * can be used as the single source of truth for all layers above.
 */
type RasterListeners = {
  videoRasterRangeUpdatedHandler?: (rasterId: string) => void
  videoRasterKeyframeAddedHandler?: (args: { rasterId: string; keyframe: number }) => void
  videoRasterKeyframeDeletedHandler?: (args: { rasterId: string; keyframe: number }) => void
}

/**
 * The `RasterManager` is instantiated by a `View` and manages the rasters
 * associated with images/videos displayed in this view.
 */
export class RasterManager extends EventEmitter {
  protected readonly view: View
  private memoGetRasters: { key: string; result: Raster[] } = {
    key: '',
    result: [],
  }

  /**
   * A map of all current image rasters on this view.
   */
  private rastersMap: { [key: Raster['id']]: Raster } = {}
  private rasterListeners: PartialRecord<string, RasterListeners> = {}

  /**
   * Keeps raster keys as an Array to support reactivity.
   */
  private rasterIds: Raster['id'][] = []

  constructor(view: View) {
    super()
    this.view = view

    AnnotationManagerEvents.annotationsDelete.on(this.removeAnnotationsFromRaster)
    AnnotationManagerEvents.annotationDelete.on(this.removeAnnotationFromRaster)
  }

  // ~~~~~~ Create ~~~~~~

  /**
   * Adds the given raster to the raster manager.
   */
  public addRaster(raster: Raster): Raster {
    if (this.hasRaster(raster.id)) {
      this.emitError()
      throw new Error(`Raster with id ${raster.id} already exists!`)
    }

    this.pushRaster(raster)
    this.emit(Events.RASTERS_CREATE, raster)
    RasterManagerEvents.rasterCreated.emit({ viewId: this.view.id }, raster)
    this.emit(Events.RASTERS_CHANGED, this.rasters)
    RasterManagerEvents.rastersChanged.emit({ viewId: this.view.id }, this.rasters)

    this.view.rasterAnnotationLayer.changed()
    this.addRasterEventListeners(raster)

    return raster
  }

  private pushRaster(payload: Raster): void {
    if (this.rasterIds.includes(payload.id)) {
      return
    }
    this.rasterIds.push(payload.id)
    this.rastersMap[payload.id] = payload
    this.clearMemo()
  }

  // ~~~~~~ Read ~~~~~~

  /**
   * Returns true if a raster with a given `id` is present in the
   * `rasterManager`.
   */
  public hasRaster(id: Raster['id']): boolean {
    return this.rasterIds.includes(id)
  }

  /**
   * Returns true if the `RasterManager`'s `View` contains a
   * raster already.
   */
  public hasRasterForFileInView(): boolean {
    return !!this.getRasterForFileInView()
  }

  /**
   * Returns the `Raster` on the manager with the given `id`, if present.
   */
  public getRaster(id: Raster['id']): Raster | undefined {
    const raster = this.rastersMap[id]

    if (!raster) {
      console.warn(`raster with id ${id} not found`)
      return
    }

    return raster
  }

  /**
   * If there is a `Raster` associated with the given `file`
   * displayed the view, returns this `Raster`.
   */
  public getRasterForFileInView(): Raster | undefined {
    const rasters = this.rasters
    const currentFile = this.view.fileManager.file

    if (currentFile.metadata === undefined) {
      console.warn('Need file in view to find associated raster')
      return
    }

    const fileId = currentFile.id
    const raster = rasters.find((raster) => raster.fileId === fileId)

    return raster
  }

  /**
   * Returns all the rasters on the manager.
   */
  get rasters(): Raster[] {
    const stringifyArr = JSON.stringify(this.rasterIds)

    if (!stringifyArr || stringifyArr !== this.memoGetRasters.key) {
      this.memoGetRasters.key = stringifyArr
      this.memoGetRasters.result = this.rasterIds.map((id) => this.getRaster(id) as Raster)
    }

    return this.memoGetRasters.result
  }

  // ~~~~~~  Update ~~~~~~

  /**
   * Triggers events for a raster update. Ideally tools want to
   * update the `raster.activeBuffer` directly, and call this to propogate updates.
   */
  public updateRaster(raster: Raster): void {
    if (this.hasRaster(raster.id)) {
      this.emit(Events.RASTERS_UPDATE, raster)
      RasterManagerEvents.rasterUpdated.emit({ viewId: this.view.id }, raster)
      this.emit(Events.RASTERS_CHANGED, this.rasters)
      RasterManagerEvents.rastersChanged.emit({ viewId: this.view.id }, this.rasters)
    } else {
      this.addRaster(raster)
    }
  }

  // ~~~~~~ Delete ~~~~~~

  /**
   * Removes the given `Raster` from the `RasterManager`, if present.
   */
  public deleteRaster(rasterId: string): string | null {
    if (!this.hasRaster(rasterId)) {
      return null
    }

    this.deleteRasterFromManager(rasterId)
    this.emit(Events.RASTERS_DELETE, rasterId)
    RasterManagerEvents.rasterDeleted.emit({ viewId: this.view.id }, rasterId)

    this.resetState()

    this.emit(Events.RASTERS_CHANGED, this.rasters)
    RasterManagerEvents.rastersChanged.emit({ viewId: this.view.id }, this.rasters)

    return rasterId
  }

  /**
   * Removes all `Raster`s stored on the `RasterManager`.
   */
  public deleteRasters(): void {
    this.rasters.forEach((raster) => {
      this.deleteRasterFromManager(raster.id)
    })

    this.resetState()
    this.emit(Events.RASTERS_CHANGED, this.rasters)
    RasterManagerEvents.rastersChanged.emit({ viewId: this.view.id }, this.rasters)
  }

  /**
   * Internal deletion of rasters from the manager.
   */
  private deleteRasterFromManager(rasterId: string): void {
    this.removeRasterEventListeners(rasterId)

    const index = this.rasterIds.indexOf(rasterId)

    if (index === -1) {
      // Already deleted.
      return
    }

    this.rasterIds.splice(index, 1)

    const raster = this.rastersMap[rasterId]

    raster.cleanup()

    delete this.rastersMap[rasterId]

    this.clearMemo()
  }

  /**
   * Cleanup of individual mask labels on the raster layer when a mask annotation is deleted.
   * If the raster becomes empty, then delete the raster itself.
   */
  private removeAnnotationFromRaster = ({ viewId }: ViewEvent, annotationId: string): void => {
    if (viewId !== this.view.id) {
      return
    }

    for (const raster of this.rasters) {
      const labelIndex = raster.getLabelIndexForAnnotationId(annotationId)

      if (labelIndex !== undefined) {
        raster.deleteLabelFromRaster(labelIndex)
      }

      if (raster.labelsOnRaster.length === 0) {
        this.deleteRaster(raster.id)
      }
    }
  }

  private removeAnnotationsFromRaster = ({ viewId }: ViewEvent, annotationIds: string[]): void => {
    if (viewId !== this.view.id) {
      return
    }
    annotationIds.forEach((id) => this.removeAnnotationFromRaster({ viewId }, id))
  }

  // ~~~ Common private methods ~~~

  private addRasterEventListeners(raster: Raster): void {
    // Add handlers for internal raster updates to propogate through manager.
    const videoRasterRangeUpdatedHandler = (rasterId: string): void => {
      this.emit(Events.RASTERS_VIDEO_RASTER_RANGE_UPDATED, rasterId)
      RasterManagerEvents.videoRasterRangeUpdated.emit({ viewId: this.view.id }, rasterId)
    }

    const videoRasterKeyframeAddedHandler = (args: {
      rasterId: string
      keyframe: number
    }): void => {
      this.emit(Events.RASTERS_VIDEO_RASTER_KEYFRAME_ADDED, args)
      RasterManagerEvents.videoRasterKeyframeAdded.emit({ viewId: this.view.id }, args)
    }

    const videoRasterKeyframeDeletedHandler = (args: {
      rasterId: string
      keyframe: number
    }): void => {
      RasterManagerEvents.videoRasterKeyframeDeleted.emit({ viewId: this.view.id }, args)
    }

    raster.on(VideoRasterEvents.VIDEO_RASTER_RANGE_UPDATED, videoRasterRangeUpdatedHandler)
    raster.on(VideoRasterEvents.VIDEO_RASTER_KEYFRAME_CREATED, videoRasterKeyframeAddedHandler)
    raster.on(VideoRasterEvents.VIDEO_RASTER_KEYFRAME_DELETED, videoRasterKeyframeDeletedHandler)

    this.rasterListeners[raster.id] = {
      videoRasterRangeUpdatedHandler,
      videoRasterKeyframeAddedHandler,
      videoRasterKeyframeDeletedHandler,
    }
  }

  private removeRasterEventListeners(rasterId: string): void {
    const raster = this.getRaster(rasterId)
    const rasterListeners = this.rasterListeners[rasterId]

    if (raster && rasterListeners) {
      const {
        videoRasterRangeUpdatedHandler,
        videoRasterKeyframeAddedHandler,
        videoRasterKeyframeDeletedHandler,
      } = rasterListeners

      if (videoRasterRangeUpdatedHandler) {
        raster.off(VideoRasterEvents.VIDEO_RASTER_RANGE_UPDATED, videoRasterRangeUpdatedHandler)
      }

      if (videoRasterKeyframeAddedHandler) {
        raster.off(VideoRasterEvents.VIDEO_RASTER_KEYFRAME_CREATED, videoRasterKeyframeAddedHandler)
      }

      if (videoRasterKeyframeDeletedHandler) {
        raster.off(
          VideoRasterEvents.VIDEO_RASTER_KEYFRAME_DELETED,
          videoRasterKeyframeDeletedHandler,
        )
      }
    }
  }

  private emitError(): void {
    this.emit(Events.RASTERS_ERROR)
    RasterManagerEvents.rasterError.emit({ viewId: this.view.id })
  }

  private resetState(): void {
    this.clearMemo()
  }

  private clearMemo(): void {
    this.memoGetRasters = {
      key: '',
      result: [],
    }
  }

  public cleanup(): void {
    AnnotationManagerEvents.annotationsDelete.off(this.removeAnnotationsFromRaster)
    AnnotationManagerEvents.annotationDelete.off(this.removeAnnotationFromRaster)
    this.rastersMap = {}
    this.rasterListeners = {}
    this.rasterIds = []
    this.resetState()
  }
}
