import type { WorkflowMutation } from '@/store/modules/workflow/types'
import type { V2WorkflowPayload } from '@/store/types/V2WorkflowPayload'

export const SET_WORKFLOW: WorkflowMutation<V2WorkflowPayload> = (state, payload) => {
  const index = state.workflows.findIndex((w) => w.id === payload.id)

  if (index > -1) {
    state.workflows.splice(index, 1, payload)
  } else {
    state.workflows.push(payload)
  }
}
