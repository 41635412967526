import type { DatasetItemReportPayload } from '@/store/modules/datasetItemReports/types'
import { get } from '@/backend/api'
import { errorMessages, parseError, isErrorResponse } from '@/backend/error'

type Params = {
  teamSlug: string
  datasetSlug: string
}

/**
 * Fetch reports from the API
 */
export const loadDatasetItemReports = async (params: Params) => {
  const path = `teams/${params.teamSlug}/${params.datasetSlug}/item_reports`

  try {
    return await get<DatasetItemReportPayload[]>(path)
  } catch (err) {
    if (!isErrorResponse(err)) {
      throw err
    }
    return parseError(err, errorMessages.DATASET_ITEM_REPORT_LOAD)
  }
}
