import EventEmitter from 'events'

import { FileManagerEvents, FramesLoaderEvents } from '@/modules/Editor/eventBus'
import type { ExtendedFileMetadata } from '@/modules/Editor/metadata'
import type { View } from '@/modules/Editor/views/view'
import type { Frame, FramesLoaderConfig } from '@/modules/Editor/workers/FramesLoaderWorker/types'
import { resolvePixdims } from '@/modules/Editor/utils/radiology/resolvePixdims'
import { doesNeedIsotropicTransformation } from '@/modules/Editor/utils/radiology/doesNeedIsotropicTransformation'
import { getIndicesPerSlotName } from '@/modules/Editor/utils/radiology/getIndicesPerSlotName'
import { MedicalVolumePlane } from '@/modules/Editor/MedicalMetadata'

// eslint-disable-next-line boundaries/element-types
import { VIRTUAL_MPR_SLOT_ID_PREFIX } from '@/modules/Workview/injectVirtualSlotsForDicomMPR'

// eslint-disable-next-line boundaries/element-types
import { DatasetItemType } from '@/store/types/DatasetItemType'
// eslint-disable-next-line boundaries/element-types
import type { V2DatasetItemPayload } from '@/store/types/V2DatasetItemPayload'
// eslint-disable-next-line boundaries/element-types
import type { V2DatasetItemSlot } from '@/store/types/V2DatasetItemSlot'

import { FrameManager } from './frameManager'

type GetLQOptions = {
  fallbackHQFrame: boolean
}

const DEFAULT_SECTIONS_SIZE = 500

/**
 * To render 3 planes of the DICOM item (axial, coronal, sagittal)
 * vtk.js needs to load all the frames to reconstruct the 3D volume.
 *
 * For DICOM files we expand the sections size to 1000 to guarantee
 * support for larger volumes, they're rather rare but they exist.
 */
const DICOM_SECTIONS_SIZE = 1000

export enum Events {
  FILE_LOADING = 'file:loading',
  FILE_LOADED = 'file:loaded',
}

export type Section = Frame & {
  width: number | null
  height: number | null
  type: 'frame' | 'simple_image' | 'page' | 'slice' | 'tiled_image'
}

export class FileManager extends EventEmitter {
  private view: View
  private slotSections: Map<number, Section> = new Map()

  public frameManager: FrameManager

  private _file: V2DatasetItemSlot
  public get file(): V2DatasetItemSlot {
    return this._file
  }

  private set file(val: V2DatasetItemSlot) {
    this._file = val
  }

  private _item: V2DatasetItemPayload
  public get item(): V2DatasetItemPayload {
    return this._item
  }

  private set item(val: V2DatasetItemPayload) {
    this._item = val
  }

  private shouldEnableFrameExtractor: boolean
  public get isFrameExtractorEnabled(): boolean {
    return this.shouldEnableFrameExtractor
  }

  public get imageWidth(): number | null {
    return this.slotSections.get(0)?.width || null
  }

  public get imageHeight(): number | null {
    return this.slotSections.get(0)?.height || null
  }

  // when `FRAMES_MANIFEST_WITH_FRAMES_EXTRACTION` is enabled we return the frame manifest
  // for shortVideos to support the Sync Video Playback (SVP) feature. but we still want to
  // retrieve frames from the BE rather than the frame extractor
  private shouldUseFrameManifestForFrameExtraction = (): boolean =>
    !this.view.editor.featureFlags.FRAMES_MANIFEST_WITH_FRAMES_EXTRACTION &&
    !!this.file.metadata?.frames_manifests?.length

  constructor(
    view: View,
    file: V2DatasetItemSlot,
    item: V2DatasetItemPayload,
    framesLoaderConfig?: FramesLoaderConfig,
  ) {
    super()

    this.view = view
    this._file = file
    this._item = item

    this.recomputePixelDimensionsForDicom(item)

    this.shouldEnableFrameExtractor =
      // file.streamable - when the video file is long enough to be streamed
      // file.sectionless - when the video file's duration is less than 1 second
      !!(file.streamable || file.sectionless) && this.shouldUseFrameManifestForFrameExtraction()

    const lossless = view.editor.featureFlags.LOSSLESS_FRAME_EXTRACTION

    this.frameManager = new FrameManager(view.id, file, {
      shouldExtractFrames: this.shouldEnableFrameExtractor,
      lossless,
      framesLoaderConfig,
      IMAGE_TAG_LOADER: !!this.view.editor.featureFlags.IMAGE_TAG_LOADER,
    })

    FramesLoaderEvents.getSection.on(this.loadSection)

    this.loadFileResources()
  }

  private recomputePixelDimensionsForDicom(item: V2DatasetItemPayload): void {
    if (!this.view.editor.featureFlags.MED_2D_VIEWER) {
      return
    }

    // Width and height are sent in pixels, we save them in new attributes
    // The width and height of the slot metadata are converted to millimeters if possible
    for (const slot of item.slots) {
      const { metadata } = slot
      const shape = metadata?.shape
      const medicalMetadata = metadata?.medical

      if (
        !shape ||
        !medicalMetadata ||
        slot.type !== 'dicom' ||
        !doesNeedIsotropicTransformation(metadata)
      ) {
        continue
      }

      // Size of a pixel in [Sagittal, Coronal, Axial] order
      const spacing = resolvePixdims(medicalMetadata)

      // Number of pixels in [Sagittal, Coronal, Axial] order
      const dimensions = [shape[1], shape[2], shape[3]]

      // Size in millimeters in [Sagittal, Coronal, Axial] order
      const size = [
        dimensions[0] * spacing[0],
        dimensions[1] * spacing[1],
        dimensions[2] * spacing[2],
      ]

      if (!medicalMetadata.plane_map[slot.slot_name]) {
        // For multi-slot DICOM uploads the plane_map object always contain
        // default mapping {'0': 'AXIAL/CORONAL/SAGITTAL'} which is unfortunately
        // incorrect as slot name '0' does not exist in this case.
        // We need to inject the correct plane_map object for missing slot.
        medicalMetadata.plane_map = {
          [slot.slot_name]: metadata.primary_plane || MedicalVolumePlane.AXIAL,
        }
      }

      const indicesPerSlotName = getIndicesPerSlotName(medicalMetadata)
      const indices = indicesPerSlotName[slot.slot_name]

      metadata.width = size[indices.width]
      metadata.height = size[indices.height]
      metadata.pixelWidth = dimensions[indices.width]
      metadata.pixelHeight = dimensions[indices.height]
    }
  }

  /**
   * Loads file resources, including the file item and the manifest.
   * It emits an event when the file is loaded.
   *
   * @returns {Promise<void>}
   */
  private async loadFileResources(): Promise<void> {
    this.emit(Events.FILE_LOADING)

    try {
      await this.loadFilePage()
      await this.frameManager.loadManifest()

      FileManagerEvents.itemFileLoaded.emit({ viewId: this.view.id })
      this.emit(Events.FILE_LOADED, this.slotSections)
    } catch (error) {
      FileManagerEvents.itemFileLoadingError.emit(
        { viewId: this.view.id },
        { error: error as Error },
      )
    }
  }

  public loadFrames(): void {
    if (this.isTiled) {
      throw new Error("Can't run frames loader for tiled image file!")
    }

    if (this.view.editor.framesPreloadSize) {
      this.frameManager.loadFrames(this.framesIndexes.slice(0, this.view.editor.framesPreloadSize))
      return
    }

    this.frameManager.loadFrames(this.framesIndexes)
  }

  public loadFramesFrom(index: number): ReturnType<typeof this.frameManager.loadFramesFrom> {
    if (this.view.editor.framesPreloadSize) {
      this.frameManager.loadFrames(
        this.framesIndexes.slice(index, index + this.view.editor.framesPreloadSize),
      )
    }

    return this.frameManager.loadFramesFrom(index)
  }

  public isFrameLoaded(index: number): boolean {
    return this.frameManager.isFrameLoaded(index)
  }

  public isHQFrameLoaded(index: number): boolean {
    return this.frameManager.isHQFrameLoaded(index)
  }

  public get framesIndexes(): number[] {
    return [...new Array(this.totalSections)].map((_, i) => i)
  }

  /**
   * LONG_VIDEOS feature flag is replaced by sectionless flags
   */
  get isSectionless(): boolean {
    return !!this.file.sectionless
  }

  get isFileStreamable(): boolean {
    // Sectionless is when we don't extract frames or create sections on the BE, we create a manifest.
    // Streamable - is false, then the video is shorter the 1 second, and the single segment has 0 duration.
    return !!(this.file.streamable || this.isSectionless)
  }

  get totalSections(): number {
    if (
      this.isSectionless &&
      this.isFileStreamable &&
      this.shouldUseFrameManifestForFrameExtraction() &&
      this.file.metadata?.frames_manifests
    ) {
      return this.file.metadata.frames_manifests[0].visible_frames
    }
    return this.file.total_sections
  }

  get fileId(): string {
    return this.file.id
  }

  get filename(): string {
    return this.file.file_name
  }

  get slotName(): string {
    return this.file.slot_name
  }

  get metadata(): ExtendedFileMetadata | null {
    return this.file.metadata || null
  }

  get fps(): number {
    return this.metadata?.native_fps || 24
  }

  get isImage(): boolean {
    return this.file.type === DatasetItemType.image
  }

  get isVideo(): boolean {
    return this.file.type === DatasetItemType.video
  }

  get isDicom(): boolean {
    return this.file.type === DatasetItemType.dicom
  }

  get isPdf(): boolean {
    return this.file.type === DatasetItemType.pdf
  }

  get isTiled(): boolean {
    return this.file.type === DatasetItemType.image && !!this.file.metadata?.levels
  }

  get isProcessedAsVideo(): boolean {
    return this.isPdf || this.isVideo || this.isDicom
  }

  get sectionsSize(): number {
    return this.isDicom ? DICOM_SECTIONS_SIZE : DEFAULT_SECTIONS_SIZE
  }

  async getSection(index: number): Promise<Section | undefined> {
    if (this.slotSections.has(index)) {
      return Promise.resolve(this.slotSections.get(index))
    }

    await this.loadFilePage(this.resolveSectionsOffset(index))

    return this.getSection(index)
  }

  /**
   * Returns cached LQ image from the browser memory.
   * Optionally fallback to HQ image.
   *
   * NOTE: Slow to play the video using this method.
   */
  async getLQFrame(frameIndex: number = 0, options?: GetLQOptions, contextId?: string) {
    if (frameIndex >= this.totalSections) {
      return
    }

    const renderableImage = await this.frameManager.loadLQFrame(frameIndex)

    if (renderableImage) {
      return renderableImage
    }

    if (options?.fallbackHQFrame) {
      return this.frameManager.loadHQFrame(frameIndex, contextId)
    }
  }

  async getHQFrame(frameIndex: number = 0, contextId?: string) {
    if (frameIndex > this.totalSections) {
      return
    }

    const renderableImage = await this.frameManager.loadHQFrame(frameIndex, contextId)

    return renderableImage
  }

  /**
   * Get information about a specific frame index, namely the segment file URL and the
   * index within that segment.
   *
   * @param frameIndex The frame index
   * @returns A frame spec containing the segment file URL and index within that segment
   */
  public async getFrameSpec(frameIndex: number = 0) {
    return await this.frameManager.getFrameSpec(frameIndex)
  }

  public get legacyStreamUrl(): string {
    return `${this.view.editor.getBaseStreamUrl(this.item.id, this.file.slot_name)}/sign`
  }

  public getStreamUrl(quality: 'high' | 'low' = 'high'): string {
    const hasQualityPreset = this.file.available_quality_presets?.find(
      (preset) => preset.name === quality,
    )

    if (!hasQualityPreset) {
      return this.legacyStreamUrl
    }

    return `${this.view.editor.getBaseStreamUrl(this.item.id, this.file.slot_name)}/${quality}/sign`
  }

  private loadFileRequest: Promise<Section[] | null> | null = null

  private async loadFilePage(offset: number = 0): Promise<Section[] | null> {
    if (this.file.id.includes(VIRTUAL_MPR_SLOT_ID_PREFIX)) {
      // Virtual slots are non-existing views that are created for the purpose of
      // the dynamic MPR support. There is no point to load the file for them.
      return null
    }

    if (this.loadFileRequest) {
      return this.loadFileRequest
    }

    this.loadFileRequest = this.view.editor.loadSlotSections(
      this.item.id,
      this.file.slot_name,
      offset,
      this.sectionsSize,
    )

    const response = await this.loadFileRequest

    this.loadFileRequest = null

    if (!response) {
      return null
    }

    if (!this.shouldEnableFrameExtractor) {
      this.frameManager.addFrameURLs(response)
    }

    response.forEach((section) => this.slotSections.set(section.section_index, section))

    return response
  }

  private resolveSectionsOffset(index: number): number {
    return Math.floor(index / this.sectionsSize) * this.sectionsSize
  }

  private loadSection = async ({ index }: { index: number }): Promise<void> => {
    await this.loadFilePage(this.resolveSectionsOffset(index))
  }

  cleanup(): void {
    this.frameManager.cleanup()
    this.slotSections.clear()
    FramesLoaderEvents.getSection.off(this.loadSection)
  }
}
