import type { NeuralModelAction } from '@/store/modules/neuralModel/types'
import { loadTrainingSessions as windRequest } from '@/backend/wind'
import type { TrainingSessionPayload } from '@/backend/wind/types'

export type Action = NeuralModelAction<number, TrainingSessionPayload[]>

export const loadTrainingSessions: Action = async ({ commit }, teamId) => {
  const response = await windRequest({ teamId })
  if ('data' in response) {
    commit('SET_TRAINING_SESSIONS', response.data)
  }
  return response
}
