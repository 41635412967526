import type { WorkflowMutation } from '@/store/modules/workflow/types'
import type { WebhookTestItemPayload } from '@/store/types'

type Payload = WebhookTestItemPayload

/**
 * Push webhook response from api response
 *
 * @param state Current V2 workflows state (mutable).
 * @param webhookResponseItem Webhook response payload returned by the API.
 */
export const PUSH_WEBHOOK_RESPONSE: WorkflowMutation<Payload> = (state, webhookResponseItem) => {
  if (webhookResponseItem.status === 'success') {
    state.webhookSuccessResponses.push(webhookResponseItem)
  } else {
    state.webhookFailResponses.push(webhookResponseItem)
  }
}
