import type { IPoint } from '@/modules/Editor/point'

/**
 * Given two points that form a line,
 * will project a continuation of that line to the border defined by
 * leftBorder, topBorder, rightBorder, bottomBorder
 * and return the point on the border.
 *
 * Logic taken from here: https://stackoverflow.com/a/45056039
 */
const projectPointToBorder = (
  point1: IPoint,
  point2: IPoint,
  leftBorder: number,
  topBorder: number,
  rightBorder: number,
  bottomBorder: number,
) => {
  const x1 = point1.x
  const x2 = point2.x
  const y1 = point1.y
  const y2 = point2.y
  const vx = x2 - x1
  const vy = y2 - y1
  let dx: number, dy: number, py: number

  dx = vx < 0 ? leftBorder : rightBorder
  dy = py = vy < 0 ? topBorder : bottomBorder
  if (vx === 0) {
    dx = x1
  } else if (vy === 0) {
    dy = y1
  } else {
    dy = y1 + (vy / vx) * (dx - x1)
    if (dy < topBorder || dy > bottomBorder) {
      dx = x1 + (vx / vy) * (py - y1)
      dy = py
    }
  }
  return {
    x: dx,
    y: dy,
  }
}

export default projectPointToBorder
