import { defineStore } from 'pinia'
import { computed, ref } from 'vue'

import { useStore } from '@/store/useStore'
import { useToast } from '@/uiKit/Toast/useToast'
import type { PartialRecord } from '@/core/helperTypes'
import type { AutoAnnotateModel } from '@/pinia/useAutoAnnotateStore'
import { useTeamStore } from '@/pinia/useTeamStore'
import type { LoadInferenceRequests } from '@/store/modules/neuralModel/actions/loadInferenceRequests'
import type { LoadRunningSessionInstanceCountsPayload } from '@/store/modules/neuralModel/actions/loadRunningSessionInstanceCounts'
import type { Action as LoadRunningSessions } from '@/store/modules/neuralModel/actions/loadRunningSessions'
import type { LoadTrainedModels } from '@/store/modules/neuralModel/actions/loadTrainedModels'
import type { Action as LoadTrainingSessions } from '@/store/modules/neuralModel/actions/loadTrainingSessions'
import type { RunInference } from '@/store/modules/neuralModel/actions/runInference'
import type { StopTrainingSession } from '@/store/modules/neuralModel/actions/stopTrainingSession'
import type { UpdateModel } from '@/store/modules/neuralModel/actions/updateModel'
import type { StoreActionPayload, StoreActionResponse } from '@/store/types'
import {
  loadExternalModels as loadExternalModelsFromWind,
  deleteExternalModel as deleteExternalModelFromWind,
  loadAvailableModels as loadAvailableModelsFromWind,
} from '@/backend/wind'
import type {
  ExternalModelResponsePayload,
  TrainedModelPayload,
  RunningSessionPayload,
  MetricPayload,
  TrainingSessionPayload,
} from '@/backend/wind/types'

import type { ModelItem } from './types'
/**
 * This Pinia store acts as the frontend's interface to Wind. It is meant to
 * eventually fully replace the Vuex neuralModel module. If, while migrating
 * there is a clear set of responsibilities that can be moved to a separate
 * store, that should be done.
 *
 * This store's responsibilities should include:
 * - loading and storing models
 * - sending requests to update models
 * - sending requests to start/stop models
 * - sending requests to run inferences
 *
 * To enable the incremental migration from Vuex to Pinia, to begin with
 * this store will act as a proxy to the Vuex store.
 *
 */
export const useModelsStore = defineStore('models', () => {
  const vuexStore = useStore()
  const toast = useToast()

  /**
   * All 'new style' external models, in the format returned by Wind.
   */
  const externalModelPayloads = ref<ExternalModelResponsePayload[]>([])

  /**
   * Both Running Sessions and External Models being fetched from the same endpoint.
   * No need for client mapping also
   */
  const availableModels = ref<AutoAnnotateModel[]>([])
  /**
   * All 'new style' external models, using the frontend's `ModelItem` type.
   */
  const externalModelItems = computed<ModelItem[]>(() =>
    externalModelPayloads.value.map((m) => ({
      id: m.id,
      name: m.name,
      teamId: m.team_id,
      insertedAt: m.inserted_at,
      datasetSlug: null,
      visible: m.visible,
    })),
  )
  const teamStore = useTeamStore()

  /**
   * Load all external models from Wind and populate in the store.
   */
  const loadExternalModels = async (): Promise<void> => {
    if (!teamStore.currentTeam) {
      return
    }

    const response = await loadExternalModelsFromWind(teamStore.currentTeam.id)
    if ('data' in response) {
      externalModelPayloads.value = response.data
    }
  }

  const loadAvailableModels = async (): Promise<void> => {
    if (!teamStore.currentTeam) {
      return
    }

    const response = await loadAvailableModelsFromWind(teamStore.currentTeam.id)
    if ('data' in response) {
      availableModels.value = response.data
    }
  }

  /**
   * Adds a new external model payload to the store. If a model with the same
   * ID already exists, it will be replaced.
   */
  const addExternalModelPayload = (payload: ExternalModelResponsePayload): void => {
    const otherModels = externalModelPayloads.value.filter((m) => m.id !== payload.id)

    externalModelPayloads.value = [...otherModels, payload]
  }

  const deleteExternalModel = async (modelId: string): Promise<void> => {
    if (!teamStore.currentTeam) {
      return
    }

    const response = await deleteExternalModelFromWind(modelId, teamStore.currentTeam.id)
    if ('error' in response) {
      toast.error({ meta: { title: 'Error deleting model. Please try again.' } })
      return
    }

    externalModelPayloads.value = externalModelPayloads.value.filter((m) => m.id !== modelId)
  }

  const reset = (): void => {
    externalModelPayloads.value = []
    vuexStore.commit('neuralModel/RESET_ALL', null)
  }

  // Use this store as a proxy to access models from the Vuex store to make it easier
  // to eventually migrate fully to Pinia.
  /**
   * All Wind trained models
   */
  const trainedModels = computed(() => vuexStore.state.neuralModel.trainedModels)
  /**
   * All Wind training sessions
   */
  const trainingSessions = computed(() => vuexStore.state.neuralModel.trainingSessions)
  /**
   * All Wind running sessions
   */
  const runningSessions = computed(() => vuexStore.state.neuralModel.runningSessions)

  const selectedRunningSession = computed(() => vuexStore.state.neuralModel.selectedRunningSession)

  const loadRunningSessions = (teamId: number): ReturnType<LoadRunningSessions> =>
    vuexStore.dispatch('neuralModel/loadRunningSessions', teamId)

  const loadTrainedModels = (teamId: number): ReturnType<LoadTrainedModels> =>
    vuexStore.dispatch('neuralModel/loadTrainedModels', teamId)

  const loadTrainingSessions = (teamId: number): ReturnType<LoadTrainingSessions> =>
    vuexStore.dispatch('neuralModel/loadTrainingSessions', teamId)

  const runningSessionInstanceCounts = computed(
    () => vuexStore.state.neuralModel.runningSessionInstanceCounts,
  )
  const loadRunningSessionInstanceCounts = (
    payload: StoreActionPayload<LoadRunningSessionInstanceCountsPayload>,
  ): ReturnType<LoadRunningSessionInstanceCountsPayload> =>
    vuexStore.dispatch('neuralModel/loadRunningSessionInstanceCounts', payload)

  const runningSessionRequestCounts = computed(
    () => vuexStore.state.neuralModel.runningSessionRequestCounts,
  )

  const loadInferenceRequests = (
    payload: StoreActionPayload<LoadInferenceRequests>,
  ): ReturnType<LoadInferenceRequests> =>
    vuexStore.dispatch('neuralModel/loadInferenceRequests', payload)

  const runInference = (
    payload: StoreActionPayload<RunInference>,
  ): Promise<StoreActionResponse<RunInference>> =>
    vuexStore.dispatch('neuralModel/runInference', payload)

  const stopTrainingSession = (
    payload: StoreActionPayload<StopTrainingSession>,
  ): Promise<StoreActionResponse<StopTrainingSession>> =>
    vuexStore.dispatch('neuralModel/stopTrainingSession', payload)

  const updateModel = (
    payload: StoreActionPayload<UpdateModel>,
  ): Promise<StoreActionResponse<UpdateModel>> =>
    vuexStore.dispatch('neuralModel/updateModel', payload)

  const selectedTrainedModel = ref<TrainedModelPayload | null>(null)
  const selectTrainedModel = (model: TrainedModelPayload | null): void => {
    selectedTrainedModel.value = model
  }

  const selectRunningSession = (model: RunningSessionPayload | null): void => {
    vuexStore.commit('neuralModel/SELECT_RUNNING_SESSION', model)
  }

  const metrics = ref<PartialRecord<string, MetricPayload[]>>({})

  const setMetricsForTrainingSession = (
    trainingSessionId: string,
    sessionMetrics: MetricPayload[],
  ): void => {
    metrics.value = {
      ...metrics.value,
      [trainingSessionId]: sessionMetrics,
    }
  }

  const pushTrainedModel = (model: TrainedModelPayload): void =>
    vuexStore.commit('neuralModel/PUSH_TRAINED_MODEL', model)

  const pushRunningSession = (model: RunningSessionPayload): void =>
    vuexStore.commit('neuralModel/PUSH_RUNNING_SESSION', model)

  const pushTraininigSession = (model: TrainingSessionPayload): void =>
    vuexStore.commit('neuralModel/PUSH_TRAINING_SESSION', model)

  return {
    // Lists of models and functions to load them
    externalModelPayloads,
    externalModelItems,
    loadExternalModels,
    addExternalModelPayload,

    // new API using the Available models endpoint
    availableModels,
    loadAvailableModels,

    trainedModels,
    loadTrainedModels,

    trainingSessions,
    loadTrainingSessions,

    runningSessions,
    loadRunningSessions,

    selectedRunningSession,
    selectRunningSession,

    // Stats on usage of models
    loadInferenceRequests,
    loadRunningSessionInstanceCounts,
    runningSessionRequestCounts,
    runningSessionInstanceCounts,

    metrics,
    setMetricsForTrainingSession,

    // Start/stop/delete models
    deleteExternalModel,
    stopTrainingSession,
    updateModel,
    selectTrainedModel,
    selectedTrainedModel,

    // Inference
    runInference,

    // Reset
    reset,

    // model data
    pushTrainedModel,
    pushRunningSession,
    pushTraininigSession,
  }
})
