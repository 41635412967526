/**
 * Notion Hotkeys documentation available at
 * https://www.notion.so/v7labs/Annotation-Workview-e8d26f8dd268433ab97d5860e476d04f
 *
 * HotkeyInfo/hotkeys.ts
 * - Describes all possible hotkeys with a short description to display in UI
 *
 * managers/hotkeyManager.ts
 * - Binds event listeners for all keyboard events (keydown, keypress, keyup)
 * - Define hotkeys and events that the editor should trigger on it
 *
 * plugins/mixins/setupPanning.ts
 * - defines utility functions to enable panning in workview
 */
import isEqual from 'lodash/isEqual'

import { onMacOS } from '@/core/utils/browser'
import type { AnnotationClass } from '@/modules/Editor/AnnotationClass'
import { ClassEvents, HotkeyManagerEvents, WorkviewTrackerEvents } from '@/modules/Editor/eventBus'
import { SubToolName } from '@/modules/Editor/tools/types'
import type { Editor } from '@/modules/Editor/editor'
import { handleEditorKeybindings } from '@/modules/Editor/editorKeybindings'
import type { Annotation } from '@/modules/Editor/models/annotation/Annotation'
import { hasAttributesSubAnnotation } from '@/modules/Editor/utils'
// eslint-disable-next-line boundaries/element-types
import type { AnnotationHotkeysPayload } from '@/store/types/AnnotationHotkeysPayload'
import type { Action } from '@/modules/Editor/managers/actionManager'
import type { View } from '@/modules/Editor/views/view'
import { trackHotkey } from '@/services/sentry'

export type HotkeyListener = {
  listener: (event: KeyboardEvent, payload?: unknown) => void
  key?: string | string[]
  code?: string | string[]
  metaKey?: boolean
  shiftKey?: boolean
  type?: 'keydown' | 'keypress' | 'keyup'
  target?: EventTarget
  payload?: unknown
}

export type HotkeyListenerOptions = Pick<HotkeyListener, 'type' | 'payload' | 'target'>
/**
 * This is a list of hotkeys that should be ignored by the hotkey manager
 * The ignored keys can be duplicated.
 * The `payload` attribute can be useful to identify the source of the freeze and to support
 * the same key being frozen/unfrozen in different contexts at the same time
 */
export type IgnoredHotkey = Pick<HotkeyListener, 'key' | 'code' | 'payload'>

const createAnnotationAction = (view: View, ann: Annotation): Action => ({
  do(): boolean {
    view.annotationManager.createAnnotation(ann)
    return true
  },
  undo(): boolean {
    view.annotationManager.deleteAnnotation(ann.id)
    return true
  },
})

const deleteAnnotationAction = (view: View, ann: Annotation): Action => ({
  do(): boolean {
    view.annotationManager.deleteAnnotation(ann.id)
    return true
  },
  undo(): boolean {
    view.annotationManager.createAnnotation(ann)
    return true
  },
})

export class HotkeyManager {
  public listeners: HotkeyListener[] = []
  private isTabActive: boolean = false
  private isTabHotkeyTemporarilyDisabled: boolean = false
  public tabIndex: number = 0
  private editor: Editor
  public ignoredHotkeys: IgnoredHotkey[] = []

  constructor(editor: Editor) {
    this.editor = editor
    this.listeners = []
    this.registerKeyEvents()
    HotkeyManagerEvents.ready.emit()
  }

  private datasetHotkeys: AnnotationHotkeysPayload = {}

  private trackKeyEvent = (event: KeyboardEvent): void => {
    if (this.editor.featureFlags.WORKLOG_V3_ENABLED) {
      WorkviewTrackerEvents.reportActivity.emit()
    }

    if (this.editor.featureFlags.SENTRY_REPLAY) {
      trackHotkey(event, 'hotkeyManager')
      return
    }
  }

  public setHotkeys(hotkeys: AnnotationHotkeysPayload): void {
    this.datasetHotkeys = hotkeys
  }

  public setIgnoredHotkeys(hotkeys: IgnoredHotkey[]): void {
    this.ignoredHotkeys = hotkeys
  }

  /**
   * Register default editor hotkey listeners
   */
  public registerDefaultHotkeyListeners(): void {
    this.unregisterKeyEvents()
    this.registerKeyEvents()

    const { editor } = this

    // Keydown handlers
    this.$on({}, (event) => HotkeyManagerEvents.key.emit(event))

    // CAUTION: On some keyboard layouts,
    // hitting the key 'z' results in key: 'z', code: 'KeyY'
    // Using `key` is safer than code
    this.$on({ key: 'z', metaKey: true }, (event) => {
      event.preventDefault()
      event.shiftKey ? editor.actionManager.redo() : editor.actionManager.undo()
    })

    this.registerTabBehavior()

    this.$on({ code: 'ArrowUp' }, (event) => {
      event.preventDefault()
      editor.activeView.annotationManager.moveSelectedAnnotation({
        x: 0,
        y: event.shiftKey ? -5 : -1,
      })
    })

    this.$on({ code: 'ArrowDown' }, (event) => {
      event.preventDefault()
      editor.activeView.annotationManager.moveSelectedAnnotation({
        x: 0,
        y: event.shiftKey ? 5 : 1,
      })
    })

    this.$on({ code: 'ArrowLeft' }, (event) => {
      event.preventDefault()
      editor.activeView.annotationManager.moveSelectedAnnotation({
        x: event.shiftKey ? -5 : -1,
        y: 0,
      })
    })

    this.$on({ code: 'ArrowRight' }, (event) => {
      event.preventDefault()
      editor.activeView.annotationManager.moveSelectedAnnotation({
        x: event.shiftKey ? 5 : 1,
        y: 0,
      })
    })

    this.$on({ key: '`' }, (event) => {
      event.preventDefault()
      editor.activeView.annotationManager.selectNextVertex()
    })

    this.$on({ key: '~' }, (event) => {
      event.preventDefault()
      editor.activeView.annotationManager.selectPreviousVertex()
    })

    this.$on({ code: 'Escape' }, (event) => {
      event.preventDefault()
      editor.activeView.annotationManager.deselectAllAnnotations()
    })

    this.$on({ key: 'a', metaKey: true }, (event) => {
      event.preventDefault()
      const { selectedAnnotation } = editor.activeView.annotationManager
      if (selectedAnnotation && hasAttributesSubAnnotation(selectedAnnotation)) {
        editor.toolManager.activateToolWithStore(SubToolName.Attributes, {
          sub: { master: selectedAnnotation },
        })
      }
    })

    this.$on({}, (event) => handleEditorKeybindings(editor, event, 'keydown'))
    this.$on({}, (event) => editor.toolManager.handleKeybindings(event))
    this.$on({}, (event) => editor.activeView.onOnKeyDownCallbacks.call(event))

    // Keypress handlers
    this.$on({}, (event) => editor.activeView.onOnKeyPressCallbacks.call(event), {
      type: 'keypress',
    })

    // Keyup handlers
    this.$on(
      { code: ['ArrowUp', 'ArrowDown', 'ArrowLeft', 'ArrowRight'] },
      () => editor.activeView.annotationManager.performMoveAction(),
      { type: 'keyup' },
    )
    this.$on({}, (event) => handleEditorKeybindings(editor, event, 'keyup'), { type: 'keyup' })
    this.$on({}, (event) => editor.activeView.onOnKeyUpCallbacks.call(event), { type: 'keyup' })
  }

  registerTabBehavior(): void {
    let isTabHeldDown: boolean = false

    const { editor } = this

    this.$on(
      { code: 'Tab' },
      (event) => {
        if (this.isTabHotkeyTemporarilyDisabled) {
          return
        }

        event.preventDefault()
        // short keydown-key-ups of the tab key should cycle selection through visible annotations
        // if this is set to true, it was a long press, so on keyup, we can skip that behavior
        isTabHeldDown = event.repeat
      },
      { type: 'keydown' },
    )

    this.$on(
      { code: 'Tab' },
      (event) => {
        if (this.isTabHotkeyTemporarilyDisabled) {
          return
        }

        // this means it was a long keypress, so we reset the flag and early return
        if (isTabHeldDown) {
          isTabHeldDown = false
          return
        }

        // if we got here, it was a short keydown -> keyup,
        // so we want to select next visible annotation

        const { annotationManager } = editor.activeView
        if (annotationManager.annotations.length === 0) {
          return
        }

        let annotation = annotationManager.findAnnotationAtIndex(this.tabIndex)

        // If there's already a selected annotation, then increment or decrement
        // the index until you hit a visible annotation
        if (annotationManager.selectedAnnotation) {
          for (let i = 0; i < annotationManager.frameAnnotations.length; i++) {
            this.tabIndex = event.shiftKey ? this.tabIndex - 1 : this.tabIndex + 1
            annotation = annotationManager.findAnnotationAtIndex(this.tabIndex)
            if (annotation && !annotationManager.isHidden(annotation.id)) {
              break
            }
          }
        }

        if (annotation) {
          annotationManager.selectAnnotation(annotation.id)
          this.editor.activeView.zoomToAnnotation(annotation)
        }
      },
      { type: 'keyup' },
    )
  }

  /**
   * Register annotation class hotkeys
   */
  public registerAnnotationClassOrPresetHotkeys(): void {
    this.$on({ key: ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'] }, (e) =>
      this.isTabActive ? this.selectPresets(e) : this.selectAnnotationClass(e),
    )
  }

  /**
   * Select annotation class from the key event
   */
  private selectAnnotationClass(event: KeyboardEvent): void {
    const { toolManager } = this.editor
    const datasetHotkey = this.datasetHotkeys[event.key]
    if (typeof datasetHotkey !== 'string') {
      return
    }
    const classId = parseInt(datasetHotkey.split(':')[1])
    if (!classId || isNaN(classId)) {
      return
    }
    const annotationClass = this.editor.getClassById(classId)
    if (!annotationClass) {
      return
    }
    if (
      this.editor.activeView.annotationManager.maybeChanceClassOfSelectedAnnotation(annotationClass)
    ) {
      return
    }

    const typeName =
      this.editor.activeView.annotationManager.getMainAnnotationTypeForClass(annotationClass)

    if (typeName === 'tag') {
      this.handleTagAnnotationClass(annotationClass)
      return
    }

    const tool = toolManager.findByMainAnnotationTypeName(typeName)

    if (!tool) {
      return
    }

    // If I am using brush/clicker and press a key for a polygon,
    // it should still keep me on the brush tool but switch the class
    if (
      !(
        toolManager.currentTool &&
        ['auto_annotate_tool', 'brush_tool', 'clicker_tool', 'empty_tool'].includes(
          toolManager.currentTool.name,
        ) &&
        tool.name === 'polygon_tool'
      )
    ) {
      this.editor.toolManager.activateToolWithStore(tool.name)
    }

    ClassEvents.preselectClassId.emit(annotationClass.id)
  }

  /**
   * Select image manipulation preset
   */
  private selectPresets(event: KeyboardEvent): void {
    this.editor.selectPreset(event.key)

    // Avoid the tag annotation from being fired immediately after
    // a select preset shortcut is issued
    this.isTabHotkeyTemporarilyDisabled = true
    setTimeout(() => {
      this.isTabHotkeyTemporarilyDisabled = false
    }, 600)
  }

  public handleTagAnnotationClass(tagAnnotationClass: AnnotationClass): void {
    const { activeView } = this.editor
    const { annotations } = activeView.annotationManager

    const existingTagAnnotation = annotations.find(
      (annotation) => annotation.classId === tagAnnotationClass.id,
    )

    // If annotation for this tag annotation class exists, remove it.
    // If not, add a new annotation.
    if (existingTagAnnotation) {
      this.editor.actionManager.do(deleteAnnotationAction(activeView, existingTagAnnotation))
      return
    }

    const fileManager = activeView.fileManager
    let annotation: Annotation | null
    if (fileManager.isProcessedAsVideo) {
      annotation = this.editor.activeView.annotationManager.initializeAnnotation({
        type: 'tag',
        annotationClass: tagAnnotationClass,
        data: {
          frames: { [activeView.currentFrameIndex]: { tag: {}, keyframe: true } },
          segments: [
            [
              activeView.currentFrameIndex,
              Math.min(
                activeView.currentFrameIndex + activeView.editor.videoAnnotationDuration,
                activeView.totalFrames,
              ),
            ],
          ],
          interpolated: false,
        },
      })
    } else {
      annotation = this.editor.activeView.annotationManager.initializeAnnotation({
        type: 'tag',
        annotationClass: tagAnnotationClass,
        data: { tag: {} },
      })
    }

    if (!annotation) {
      return
    }
    this.editor.actionManager.do(createAnnotationAction(activeView, annotation))
  }

  /**
   * Clean up all the hotkey listeners
   */
  public cleanup(): void {
    this.unregisterKeyEvents()
    this.listeners = []
    HotkeyManagerEvents.cleanup.emit()
  }

  /**
   * Register a new hotkey listener
   */
  public $on(
    filter: {
      key?: string | string[]
      code?: string | string[]
      metaKey?: boolean
      shiftKey?: boolean
    },
    listener: HotkeyListener['listener'],
    options?: HotkeyListenerOptions,
  ): void {
    this.listeners.push({ listener, ...filter, ...options })
  }

  /**
   * Deregister an existing hotkey listener
   */
  public $off(
    filter: {
      key?: string | string[]
      code?: string | string[]
      metaKey?: boolean
      shiftKey?: boolean
    },
    listener: HotkeyListener['listener'],
  ): void {
    const idx = this.listeners.findIndex(
      (hl) =>
        hl.listener === listener && isEqual(hl.key, filter.key) && isEqual(hl.code, filter.code),
    )
    if (idx < 0) {
      return
    }

    this.listeners.splice(idx, 1)
  }

  private get keydownListeners(): HotkeyListener[] {
    // When type is undefined, it is keydown by default
    return this.listeners.filter((hl) => !hl.type || hl.type === 'keydown')
  }

  private get keypressListeners(): HotkeyListener[] {
    return this.listeners.filter((hl) => hl.type === 'keypress')
  }

  private get keyupListeners(): HotkeyListener[] {
    return this.listeners.filter((hl) => hl.type === 'keyup')
  }

  private isMetaKey(event: KeyboardEvent): boolean {
    return onMacOS() ? event.metaKey : event.ctrlKey
  }

  private isIgnoredHotkey(event: KeyboardEvent): boolean {
    return this.ignoredHotkeys.some(
      (ignored) =>
        (ignored.key && ignored.key === event.key) || (ignored.code && ignored.code === event.code),
    )
  }

  /**
   * Check if the current keyboard event matches the hotkey listener conditions
   */
  private conditionMatches(event: KeyboardEvent, hotkeyListener: HotkeyListener): boolean {
    const metaKey = this.isMetaKey(event)

    if (hotkeyListener.target && hotkeyListener.target !== event.target) {
      return false
    }

    if (hotkeyListener.metaKey !== undefined && hotkeyListener.metaKey !== metaKey) {
      return false
    }

    if (hotkeyListener.shiftKey !== undefined && hotkeyListener.shiftKey !== event.shiftKey) {
      return false
    }

    if (!hotkeyListener.key && !hotkeyListener.code) {
      return true
    }

    if (hotkeyListener.key) {
      if (hotkeyListener.key instanceof Array) {
        return hotkeyListener.key.includes(event.key)
      }

      return hotkeyListener.key === event.key
    }

    if (hotkeyListener.code) {
      if (hotkeyListener.code instanceof Array && hotkeyListener.code.includes(event.code)) {
        return true
      }

      return hotkeyListener.code === event.code
    }

    return false
  }

  private onKeyDown = (event: KeyboardEvent): void => {
    if (event.target) {
      // This has been added to make the input work on workview
      // For now, we have search field on the class selection dropdown
      // In case of dropdown, we just ignore other handlers so that
      // Input can handle it on its own
      const elem = event.target as HTMLElement
      if (elem.tagName === 'INPUT' || elem.tagName === 'TEXTAREA') {
        return
      }
    }

    // Check if the Tab key is pressed
    if (event.key === 'Tab') {
      this.isTabActive = true
    }

    this.keydownListeners.forEach((hl) => {
      if (this.isIgnoredHotkey(event)) {
        return
      }
      if (!this.conditionMatches(event, hl)) {
        return
      }
      this.trackKeyEvent(event)
      hl.listener(event, hl.payload)
    })
  }

  private onKeyPress = (event: KeyboardEvent): void => {
    if (event.target) {
      // This has been added to make the input work on workview
      // For now, we have search field on the class selection dropdown
      // In case of dropdown, we just ignore other handlers so that
      // Input can handle it on its own
      const elem = event.target as HTMLElement
      if (elem.tagName === 'INPUT' || elem.tagName === 'TEXTAREA') {
        return
      }
    }

    // Check if the Tab key is pressed
    if (event.key === 'Tab') {
      this.isTabActive = true
    }

    this.keypressListeners.forEach((hl) => {
      if (this.isIgnoredHotkey(event)) {
        return
      }
      if (!this.conditionMatches(event, hl)) {
        return
      }
      this.trackKeyEvent(event)
      hl.listener(event, hl.payload)
    })
  }

  private onKeyUp = (event: KeyboardEvent): void => {
    if (event.target) {
      const elem = event.target as HTMLElement
      if (elem.tagName === 'INPUT' || elem.tagName === 'TEXTAREA') {
        return
      }
    }

    // Check if the Tab key is pressed
    if (event.key === 'Tab') {
      this.isTabActive = false
    }

    this.keyupListeners.forEach((hl) => {
      if (this.isIgnoredHotkey(event)) {
        return
      }
      if (!this.conditionMatches(event, hl)) {
        return
      }
      this.trackKeyEvent(event)
      hl.listener(event, hl.payload)
    })
  }

  private registerKeyEvents(): void {
    document.addEventListener('keydown', this.onKeyDown)
    document.addEventListener('keypress', this.onKeyPress)
    document.addEventListener('keyup', this.onKeyUp)
  }

  private unregisterKeyEvents(): void {
    document.removeEventListener('keydown', this.onKeyDown)
    document.removeEventListener('keypress', this.onKeyPress)
    document.removeEventListener('keyup', this.onKeyUp)
  }
}
