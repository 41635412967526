import Vue from 'vue'
import type { ActionTree, MutationTree } from 'vuex'

import { setContext } from '@/services/sentry'
import type {
  NotificationMessagePayload,
  NotificationPayload,
  RootState,
  UserPayload,
} from '@/store/types'
import { copyAttributes } from '@/utils/array'
import { parseError, isErrorResponse } from '@/backend/error'
import type { Channel } from '@/backend/socket'
import { Socket } from '@/backend/socket'
import { useUserStore } from '@/modules/Auth/useUserStore'

export type NotificationState = {
  notifications: Map<number, NotificationPayload>
  sortedNotifications: NotificationPayload[]
  byTeamUnreadCount: { [teamId: number]: number }
  socketRef: null | number
}

export const getInitialState = (): NotificationState => ({
  notifications: new Map(),
  sortedNotifications: [],
  byTeamUnreadCount: {},
  socketRef: null,
})

const state: NotificationState = getInitialState()

const compareInsertedAt = (a: NotificationPayload, b: NotificationPayload): number => {
  if (a.inserted_at === b.inserted_at) {
    return 0
  }
  if (a.inserted_at < b.inserted_at) {
    return 1
  }
  return -1
}

const topicName = (user: UserPayload | null): string | null =>
  user ? `notifications:${user.id}` : null

const getChannel = (): Promise<{ channel: Channel }> => {
  const userStore = useUserStore()
  const topic = topicName(userStore.currentUser)
  if (!topic) {
    throw new Error('Unable to resolve notifications channel')
  }
  return Socket.connectAndJoin(topic)
}

const actions: ActionTree<NotificationState, RootState> = {
  async joinNotificationsChannel({ commit }) {
    let response

    try {
      response = await getChannel()
    } catch (error) {
      if (!isErrorResponse(error)) {
        throw error
      }
      return parseError(error)
    }

    const { channel } = response

    if (state.socketRef) {
      channel.off('notifications', state.socketRef)
      commit('UNSET_SOCKET_REF')
    }

    const socketRef = channel.on('notifications', (payload: NotificationMessagePayload) => {
      const {
        notifications,
        deleted_notifications: deletedNotifications,
        by_team_unread_count: byTeamUnreadCount,
      } = payload

      if (notifications) {
        commit('ADD_NOTIFICATIONS', notifications)
      }

      if (deletedNotifications) {
        commit('DELETE_NOTIFICATIONS', deletedNotifications)
      }

      if (byTeamUnreadCount) {
        commit('BY_TEAM_UNREAD_COUNT', byTeamUnreadCount)
      }
    })

    commit('SET_SOCKET_REF', socketRef)
  },

  async moreNotifications({ state }) {
    try {
      const sortedRead = state.sortedNotifications.filter((n) => n.is_read)
      const oldestRead = sortedRead[sortedRead.length - 1]
      const sortedUnread = state.sortedNotifications.filter((n) => !n.is_read)
      const oldestUnread = sortedUnread[sortedUnread.length - 1]

      const payload = {
        before_read_id: oldestRead ? oldestRead.id : null,
        before_unread_id: oldestUnread ? oldestUnread.id : null,
      }

      if (!oldestRead && !oldestUnread) {
        return
      }

      const { channel } = await getChannel()
      if (channel) {
        channel.push('notifications', payload)
      } else {
        console.error('moreNotifications', 'channel not found')
      }
    } catch (e) {
      setContext('error', { error: e })
      console.error('moreNotifications')
    }
  },

  async markNotificationRead({ commit }, id) {
    try {
      const { channel } = await getChannel()
      if (channel) {
        channel.push('notifications:read', { id: id })
        commit('MARK_READ', id)
      } else {
        console.error('markNotificationRead', 'channel not found')
      }
    } catch (e) {
      setContext('channelErrorDetails', { error: e })
      console.error('markNotificationRead')
    }
  },

  async leaveNotificationsChannel({ commit }) {
    const userStore = useUserStore()
    const topic = topicName(userStore.currentUser)
    if (!topic) {
      return
    }
    await Socket.leave(topic)
    commit('CLEAR_NOTIFICATIONS')
  },
}

const resort = (notifications: NotificationState['notifications']): NotificationPayload[] =>
  Array.from(notifications.values()).sort(compareInsertedAt)

const mutations: MutationTree<NotificationState> = {
  ADD_NOTIFICATIONS(state, notifications: NotificationPayload[]) {
    notifications.forEach((n) => state.notifications.set(n.id, n))
    const sorted = resort(state.notifications)
    Vue.set(state, 'notifications', state.notifications)
    Vue.set(state, 'sortedNotifications', sorted)
  },

  DELETE_NOTIFICATIONS(state, notifications: NotificationPayload[]) {
    const toDelete = notifications.filter((n) => state.notifications.has(n.id))
    if (toDelete.length > 0) {
      toDelete.forEach((n) => state.notifications.delete(n.id))
      const sorted = resort(state.notifications)
      Vue.set(state, 'notifications', state.notifications)
      Vue.set(state, 'sortedNotifications', sorted)
    }
  },

  CLEAR_NOTIFICATIONS(state) {
    Vue.set(state, 'notifications', new Map())
    Vue.set(state, 'sortedNotifications', [])
    Vue.set(state, 'byTeamUnreadCount', {})
  },

  BY_TEAM_UNREAD_COUNT(state, list: [number, number][]) {
    const map: NotificationState['byTeamUnreadCount'] = {}
    list.forEach(([teamId, count]) => (map[teamId] = count))
    Vue.set(state, 'byTeamUnreadCount', map)
  },

  MARK_READ(state, id: number) {
    const notification = state.notifications.get(id)
    if (!notification) {
      return
    }

    notification.is_read = true
    state.notifications.set(id, notification)
    const sorted = resort(state.notifications)
    Vue.set(state, 'notifications', state.notifications)
    Vue.set(state, 'sortedNotifications', sorted)
  },

  SET_SOCKET_REF(state, ref) {
    state.socketRef = ref
  },

  UNSET_SOCKET_REF(state) {
    state.socketRef = null
  },

  RESET_ALL(state: NotificationState) {
    copyAttributes(state, getInitialState())
  },
}

export default {
  namespaced: true,
  state,
  actions,
  mutations,
}
