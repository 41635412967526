import { FilterOperator } from '@/modules/AdvancedFilters/FilterOperator'
import { FilterSubject } from '@/modules/AdvancedFilters/FilterSubject'
import { WorkflowStatus } from '@/modules/AdvancedFilters/WorkflowStatus'
import { createAdvancedFilter } from '@/modules/AdvancedFilters/filterFactory'
import type { NeuralModelAction } from '@/store/modules/neuralModel/types'
import { loadV2DatasetItems as requestV2 } from '@/backend/darwin'

/**
 * Fetches a sampling of dataset items from the store.
 *
 * Can be called repeatedly to fetch more items.
 */
export const loadSampleDatasetItems: NeuralModelAction<void> = async ({ commit, state }) => {
  const { newModelDataset } = state

  if (!newModelDataset) {
    throw new Error('Cannot fetch sample items with no dataset selected')
  }

  const statusFilter = {
    subject: FilterSubject.WorkflowStatus,
    matcher: {
      name: FilterOperator.AnyOf,
      values: [WorkflowStatus.Complete],
    },
  }

  const response = await requestV2({
    include_thumbnails: true,
    page: {
      from: state.newModelSampleItemsCursor || undefined,
      size: 20,
    },
    filter: createAdvancedFilter(statusFilter),
    dataset_ids: [newModelDataset.id],
    teamSlug: newModelDataset.team_slug,
  })

  if ('data' in response && 'items' in response.data) {
    commit('PUSH_NEW_MODEL_SAMPLE_ITEMS_V2', response.data.items)
    commit('SET_NEW_MODEL_SAMPLE_ITEMS_CURSOR', response.data.page.next)
  }

  return response
}
