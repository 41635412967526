import type { MutationTree } from 'vuex'

import type {
  DatasetDetailPayload,
  DatasetItemCountsPayload,
  DatasetPayload,
  V2DatasetItemPayload,
} from '@/store/types'
import { copyAttributes } from '@/utils/array'

import { PUSH_DATASET } from './mutations/PUSH_DATASET'
import { SET_DATASET_ITEM_FILENAMES } from './mutations/SET_DATASET_ITEM_FILENAMES'
import { SET_EXPORT_COMPLETE_COUNT } from './mutations/SET_EXPORT_COMPLETE_COUNT'
import { SET_SELECTED_ALL_ITEMS } from './mutations/SET_SELECTED_ALL_ITEMS'
import { SET_SELECTED_ITEMS } from './mutations/SET_SELECTED_ITEMS'
import { UPDATE_V2_ITEM_SELECTION } from './mutations/UPDATE_V2_ITEM_SELECTION'
import { getInitialState } from './state'
import type { DatasetState } from './state'

// mutations
export const mutations: MutationTree<DatasetState> = {
  PUSH_DATASET,
  SET_DATASET_ITEM_FILENAMES,
  SET_EXPORT_COMPLETE_COUNT,
  SET_SELECTED_ALL_ITEMS,
  SET_SELECTED_ITEMS,
  UPDATE_V2_ITEM_SELECTION,

  SET_CURRENT_DATASET_ID(state, datasetId: number | null) {
    state.currentDataset.id = datasetId
  },

  SET_V2_DATASET_ITEMS(state, items: V2DatasetItemPayload[]) {
    const res: { [id: string]: V2DatasetItemPayload } = {}

    items.forEach((item) => {
      res[item.id] = item
    })

    state.datasetItemsV2 = {
      ...state.datasetItemsV2,
      ...res,
    }
  },

  CLEAR_V2_DATASET_ITEMS(state) {
    state.datasetItemsV2 = {}
  },

  SET_DATASETS(state, datasets: DatasetPayload[]) {
    state.datasets = datasets.map((dataset) => {
      const oldDataset = state.datasets.find((d) => d.id === dataset.id)
      return {
        ...(oldDataset || {}),
        ...dataset,
      }
    })
  },

  SET_DATASET_EXPORTS(state, datasetExports) {
    state.datasetExports = datasetExports
  },

  DELETE_EXPORT(state, datasetExportName) {
    const idx = state.datasetExports.findIndex((d) => d.name === datasetExportName)
    if (idx < 0) {
      return
    }
    state.datasetExports.splice(idx, 1)
  },

  PUSH_DATASET_DETAILS(
    state,
    details: DatasetDetailPayload | (DatasetItemCountsPayload & { id: number }),
  ) {
    const index = state.datasetDetails.findIndex((d) => d.id === details.id)

    if (index > -1) {
      state.datasetDetails.splice(index, 1, details)
    } else {
      state.datasetDetails.push(details)
    }
  },

  REMOVE_DATASET(state, datasetId: DatasetPayload['id']) {
    const { datasets } = state
    const index = datasets.findIndex((p) => p.id === datasetId)
    if (index > -1) {
      state.datasets.splice(index, 1)
    }
  },

  // selection

  DESELECT_ALL_ITEMS(state) {
    state.selectedV2ItemIds = []
    state.selectedAll = false
  },

  // reset

  RESET_ALL(state) {
    copyAttributes(state, getInitialState())
  },

  SET_DATA_TAB_COLUMN_COUNT(state, columnCount: number) {
    state.dataTabColumnCount = columnCount
  },
}
