import type { NeuralModelAction } from '@/store/modules/neuralModel/types'
import { stopTrainingSession as windStopTrainingSession } from '@/backend/wind'
import type { TrainingSessionPayload } from '@/backend/wind/types'

type Payload = {
  trainingSessionId: TrainingSessionPayload['id']
  teamId: number
}

export type StopTrainingSession = NeuralModelAction<Payload, TrainingSessionPayload>

export const stopTrainingSession: StopTrainingSession = async ({ commit }, payload) => {
  const { trainingSessionId, teamId } = payload

  const response = await windStopTrainingSession({
    teamId,
    trainingSessionId,
  })

  if ('data' in response) {
    commit('DELETE_TRAINING_SESSION', trainingSessionId)
  }

  return response
}
