/**
 * Array related helper functions
 */
import difference from 'lodash/difference'
import intersection from 'lodash/intersection'
import sortBy from 'lodash/sortBy'

import type { PartialRecord } from '@/core/helperTypes'

/**
 * * includes - attribute names to include when copying, if undefined, it will not considered
 * * excludes - attribute names to exclude when copying, if undefined, it will not considered
 */
type COPY_ATTRIBUTE_OPTIONS = {
  includes?: string[]
  excludes?: string[]
}

export const copyAttributes = (
  dest: PartialRecord<string, unknown>,
  src: PartialRecord<string, unknown>,
  options: COPY_ATTRIBUTE_OPTIONS = {},
): void => {
  for (const key of Object.keys(src)) {
    if (options.includes && !options.includes.includes(key)) {
      continue
    }
    if (options.excludes && options.excludes.includes(key)) {
      continue
    }
    dest[key] = src[key]
  }
}

/**
 * Update the current array with the new values
 * It just does the following 3 things
 * * Removes the old values that is not existing in the new array
 * * Updates the existing object values with the new value in the new array
 * * Add new values from the new array
 * @param currentEntries Current entries to update
 * @param newEntries New entries that has updated values
 * @param idFunc Function that will determine unique id from an entry
 */
export const updateArray = <IdType extends string | number, T extends { [k in IdType]: unknown }>(
  currentEntries: T[],
  newEntries: T[],
  idFunc: (entry: T) => IdType,
  copyOptions: COPY_ATTRIBUTE_OPTIONS = {},
): void => {
  if (!currentEntries || !newEntries) {
    return
  }

  const oldIds = currentEntries.map(idFunc)
  const newIds = newEntries.map(idFunc)

  const getIdIndexMap = (entries: T[]): { [k in IdType]?: number } => {
    const init: { [k in IdType]?: number } = {}
    return entries.reduce((accu, entry, index) => {
      const id = idFunc(entry)
      accu[id] = index
      return accu
    }, init)
  }

  const oldIdIndexMap = getIdIndexMap(currentEntries)
  const newIdIndexMap = getIdIndexMap(newEntries)

  // Copy entry details from new to old one
  const idsToCopy = intersection(oldIds, newIds)
  idsToCopy.forEach((id) => {
    const oldIdx = oldIdIndexMap[id]
    const newIdx = newIdIndexMap[id]
    if (oldIdx === undefined || newIdx === undefined) {
      return
    }
    copyAttributes(currentEntries[oldIdx], newEntries[newIdx], copyOptions)
  })

  // Remove old entries that doesn't exist in the new entries
  const idsToRemove = difference(oldIds, newIds)
  idsToRemove.reverse().forEach((id) => {
    const idx = oldIdIndexMap[id]
    idx !== undefined && currentEntries.splice(idx, 1)
  })

  // Add new entries from the new entries
  const idsToAdd = difference(newIds, oldIds)
  idsToAdd.forEach((id) => {
    const newIdx = newIdIndexMap[id]
    newIdx !== undefined && currentEntries.push(newEntries[newIdx])
  })

  sortBy(currentEntries, (entry) => newIdIndexMap[idFunc(entry)])
}

export const getIdNext = (current: number, length: number, next: boolean): number =>
  next ? (current + 1) % length : current - 1 < 0 ? length - 1 : current - 1

/**
 * When given 2 numeric ranges, will return true if and only if:
 * - Every number in the subset is also in the superset
 * - The subset is not equal to the superset
 *
 * 'proper subset' is a term that means something in mathematics,
 * see https://mathinsight.org/definition/proper_subset
 *
 * @param subset - A numeric range for the subset candidate
 * @param superset - A numeric range for the superset candidate
 *
 * @returns True if the subset is a proper subset of the superset, false otherwise
 */
export const isProperSubset = (subset: [number, number], superset: [number, number]): boolean =>
  (superset[0] <= subset[0] && superset[1] > subset[1]) ||
  (superset[0] < subset[0] && superset[1] >= subset[1])
