import type { DatasetItemFilter, DatasetReportPayload } from '@/store/types'
import { get } from '@/backend/api'
import { errorMessages, isErrorResponse, parseError } from '@/backend/error'
import type { ApiResult } from './types'

type Params = DatasetItemFilter & { datasetId: number }

export const loadDatasetReport = async (
  params: Params,
): Promise<ApiResult<DatasetReportPayload>> => {
  const { datasetId, ...rest } = params
  const path = `datasets/${datasetId}/report`

  try {
    const response = await get<DatasetReportPayload>(path, rest)
    return { ok: true, data: response.data }
  } catch (error) {
    if (!isErrorResponse(error)) {
      throw error
    }
    return { ...parseError(error, errorMessages.DATASET_LOAD), ok: false }
  }
}
