import type { components } from '@/backend/darwin/api'

import type { StageType } from './StageType'

/**
 * Current status of an item record.
 *
 * Items start as 'uploading, go through 'processing', then end up in 'error' or 'new'
 *
 * Once in new, their status is updated by the backend as they go through a workflow
 *
 * The workflow stage type maps to status, based on the following rules
 *
 * - complete stage type -> complete status
 * - discard stage type -> complete status
 * - archive stage type -> complete status
 * - review stage type -> review status
 * - any other stage type -> annotate
 *
 * Additionally, titems in a discard or archive stage will be rendered as 'archived'
 * by the backend, in spite of the above, so the practical mapping is more like
 *
 * - complete stage type -> complete status
 * - discard and archive stage type -> archived status
 * - review stage type -> review status
 * - any other stage type -> annotate status
 *
 * If this goes out of date, look for `stage_type_to_status` in our around the file
 * `lib/graphotate/workflows_v2/workflows_v2.ex` in the backend repository.
 *
 * In practice, this means that when adding new stages, this enum should generally NOT be expanded.
 */
export type DatasetItemStatus = components['schemas']['DatasetsV2.Common.GeneralStatus']
export const DatasetItemStatus: { [T in DatasetItemStatus]: T } = {
  annotate: 'annotate',
  complete: 'complete',
  error: 'error',
  archived: 'archived',
  new: 'new',
  processing: 'processing',
  review: 'review',
  uploading: 'uploading',
} as const

export const ITEM_STATUSES: Record<DatasetItemStatus | StageType, string> = {
  archive: 'Being archived',
  annotate: 'Being annotated',
  archived: 'Archived',
  complete: 'Complete',
  consensus_entrypoint: 'Consensus',
  consensus_test: 'Consensus',
  logic: 'Logic',
  dataset: 'New',
  discard: 'Archive',
  error: 'Error',
  model: 'Model',
  new: 'New',
  processing: 'Processing',
  review: 'In Review',
  uploading: 'Uploading',
  webhook: 'Webhook',
  sampling: 'Sampling',
}

export const getDatasetItemStatusLabel = (status: DatasetItemStatus | StageType): string =>
  ITEM_STATUSES[status]

export const isDatasetItemStatus = (status: string): status is DatasetItemStatus =>
  Object.values(DatasetItemStatus).some((s) => s === status)
