import type { ModelDevice } from '@/store/modules/neuralModel/types'
import { WindAuthAction } from '@/backend/darwin/WindAuthAction'
import type { ApiResult } from '@/backend/darwin/types'
import { errorMessages } from '@/backend/error'

import { withAuth } from './api'
import type { TrainingClass, TrainingSessionPayload } from './types'

type Params = {
  classes: Omit<TrainingClass, 'id'>[]
  datasetId: number
  datasetSlug: string
  device: ModelDevice
  modelTemplateId: string
  name: string
  teamId: number
  teamSlug: string
}

export const trainModel = (params: Params): Promise<ApiResult<TrainingSessionPayload>> => {
  const { classes, datasetId, datasetSlug, device, modelTemplateId, name, teamId, teamSlug } =
    params
  const authParams = { action: WindAuthAction.TrainModels, teamId }

  const path = `model_templates/${modelTemplateId}/training_sessions`

  const body = {
    classes,
    dataset_id: datasetId,
    dataset_identifier: `${teamSlug}/${datasetSlug}`,
    dataset_version: 2,
    device,
    name,
    team_id: teamId,
  }

  return withAuth<TrainingSessionPayload>(
    authParams,
    (client) => client.post<TrainingSessionPayload>(path, body),
    errorMessages.NEURAL_MODEL_TRAIN,
  )
}
