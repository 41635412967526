import type { WorkflowAction } from '@/store/modules/workflow/types'
import type { V2WorkflowPayload } from '@/store/types'
import { deleteV2Workflow } from '@/backend/darwin'

export type Payload = {
  teamSlug: string
  workflowId: string
}

/**
 * Delete specific workflow
 *
 */
export const deleteWorkflow: WorkflowAction<Payload, V2WorkflowPayload> = async (
  { commit },
  { teamSlug, workflowId },
) => {
  const response = await deleteV2Workflow({ teamSlug, workflowId })

  if ('data' in response) {
    commit('REMOVE_WORKFLOW', response.data)
  }

  return response
}
