import type { Action } from '@/modules/Editor/managers/actionManager'
import { RasterTypes } from '@/modules/Editor/models/raster/rasterTypes'
import { assertImageRaster } from '@/modules/Editor/models/raster/assertImageRaster'
import type { Bounds, Raster, RasterDataSnapshot } from '@/modules/Editor/models/raster/Raster'
import difference from 'lodash/difference'
import { createMaskAnnotation } from '@/modules/Editor/plugins/mask/utils/shared/createMaskAnnotation'
import without from 'lodash/without'
import { updateMaskAnnotation } from '@/modules/Editor/plugins/mask/utils/shared/updateMaskAnnotation'
import {
  assertImageRasterSnapshot,
  assertVideoOrVoxelRasterSnapshot,
} from '@/modules/Editor/models/raster/assertRasterSnapshot'
import { assertVideoRaster } from '@/modules/Editor/models/raster/assertVideoRaster'
import type { View } from '@/modules/Editor/views/view'
import { deserializeImageRaster } from '@/modules/Editor/models/raster/deserializeImageRaster'
import { deserializeVideoOrVoxelRaster } from '@/modules/Editor/models/raster/deserializeVideoOrVoxelRaster'
import { getOrCreateRasterForView } from '@/modules/Editor/plugins/mask/utils/shared/getOrCreateRasterForView'

type MaskDiff = {
  add: number[]
  remove: number[]
  change: number[]
}

const SKIP_LABEL_INDEX_CORRECTION = false

const getLabelBounds = (raster: Raster, labelIndex: number, frameIndex: number): Bounds => {
  if (raster.type === RasterTypes.IMAGE) {
    const imageRaster = assertImageRaster(raster)
    return imageRaster.getBoundsForLabelIndex(labelIndex)
  }

  if (raster.type === RasterTypes.VIDEO) {
    const videoRaster = assertVideoRaster(raster)
    const frameBounds = videoRaster.getVideoBoundsForLabelIndexForFrame(labelIndex, frameIndex)
    if (frameBounds) {
      return frameBounds
    }
  }

  return {
    topLeft: { x: 0, y: 0 },
    bottomRight: { x: raster.width, y: raster.height },
  }
}

/**
 * Finds the difference between the old and new annotation ID mappings.
 * When changedLabels is provided, it will only consider those labels for the diff. This is
 * useful for videos, because the mappings include all lavels in the item, but the single frame
 * might have a subset of those labels.
 */
const findLabelDiff = (
  oldMapping: Record<string, number>,
  newMapping: Record<string, number>,
  changedLabels: number[] | undefined,
): MaskDiff => {
  const add = difference(Object.values(newMapping), Object.values(oldMapping))
  const remove = difference(Object.values(oldMapping), Object.values(newMapping))
  const change = without(changedLabels || Object.values(newMapping), ...add, ...remove)
  return { add, remove, change }
}

const applyDiff = async (
  diff: MaskDiff,
  raster: Raster,
  rasterData: RasterDataSnapshot,
  rasterDataBefore: RasterDataSnapshot,
  frameIndex: number,
): Promise<void> => {
  for (const labelIndex of diff.add) {
    const annotationClassId = rasterData.labelIndexToClassIdMapping.get(labelIndex)
    if (!annotationClassId) {
      throw new Error('Annotation class id not found for label index')
    }
    const annotationClass = raster.view.editor.getClassById(annotationClassId)

    const bounds = getLabelBounds(raster, labelIndex, frameIndex)
    // Assume the annotation ID mapping is available
    await createMaskAnnotation({
      view: raster.view,
      raster,
      bounds,
      labelIndex,
      classId: annotationClassId,
      annotationClass,
      frameIndex,
      annotationId: rasterData.labelIndexToAnnotationIdMapping.get(labelIndex),
    })
  }

  diff.remove.forEach((labelIndex) => {
    // remove is firing an operation on an annotation that has been removed already, so we need
    // to use the `rasterDataBefore` to get the mapping
    const annotationId = rasterDataBefore.labelIndexToAnnotationIdMapping.get(labelIndex)
    if (annotationId) {
      const annotation = raster.view.annotationManager.getAnnotation(annotationId)
      if (annotation) {
        raster.view.annotationManager.deleteAnnotation(annotation.id)
      }
    }
  })

  diff.change.forEach((labelIndex) => {
    const annotationId = raster.getAnnotationMapping(labelIndex)
    if (!annotationId) {
      throw new Error('Annotation to update not found for label index')
    }
    const annotation = raster.view.annotationManager.getAnnotation(annotationId)
    if (!annotation) {
      throw new Error('Annotation to update not found for label index')
    }
    const bounds = getLabelBounds(raster, labelIndex, frameIndex)
    updateMaskAnnotation(raster.view, raster, annotation, bounds)
  })
}

/**
 * Restores the raster data from an action snapshot.
 * The `actionData` is the data we want to restore, while `currentRasterData` is the current state
 * of the raster that we need to change.
 */
export const restoreRasterActionData = async (
  raster: Raster,
  actionData: RasterRestoreActionData,
  currentRasterData: RasterRestoreActionData,
): Promise<void> => {
  const annotationIdMapping = Object.fromEntries(currentRasterData.data.maskAnnotationIdsMapping)
  const newAnnotationIdMapping = Object.fromEntries(actionData.data.maskAnnotationIdsMapping)
  let frameIndex = 0

  if (raster.type === RasterTypes.IMAGE) {
    const imageRaster = assertImageRaster(raster)
    const imageRasterSnapshot = assertImageRasterSnapshot(actionData.data)

    deserializeImageRaster(
      imageRaster,
      {
        mask_annotation_ids_mapping: newAnnotationIdMapping,
        dense_rle: imageRasterSnapshot.denseRLE,
        total_pixels: raster.height * raster.width,
      },
      actionData.annotationIdToClassId,
    )
  }

  if (raster.type === RasterTypes.VIDEO || raster.type === RasterTypes.VOXEL) {
    const videoRaster = assertVideoRaster(raster)
    const videoRasterSnapshot = assertVideoOrVoxelRasterSnapshot(actionData.data)
    // We are not supporting actions on multiple frames - yet - so taking the first frame as
    // I know there will be exactly one frame in the snapshot
    Object.keys(videoRasterSnapshot.denseRLE).forEach((frameIndexString) => {
      frameIndex = Number(frameIndexString)
      const frameDenseRLE = videoRasterSnapshot.denseRLE[frameIndex]
      if (!frameDenseRLE || frameDenseRLE[0] === undefined) {
        return
      }

      deserializeVideoOrVoxelRaster(
        videoRaster,
        {
          [frameIndex]: {
            raster_layer: {
              mask_annotation_ids_mapping: newAnnotationIdMapping,
              dense_rle: frameDenseRLE,
              total_pixels: raster.height * raster.width,
            },
          },
        },
        actionData.annotationIdToClassId,
        SKIP_LABEL_INDEX_CORRECTION,
      )
    })
  }

  // If labelsInSnapshots are found (video/voxel) check both snapshots to be sure to capture the difference
  let labelsInSnapshots: number[] | undefined
  if (actionData.data.labelsInSnapshot) {
    const labelsSet = new Set([
      ...actionData.data.labelsInSnapshot,
      ...(currentRasterData.data.labelsInSnapshot || []),
    ])
    labelsInSnapshots = Array.from(labelsSet)
  }

  // Find the difference between the old and new annotation ID mappings
  const labelDiff = findLabelDiff(annotationIdMapping, newAnnotationIdMapping, labelsInSnapshots)

  // Create/Delete/Update annotations according to the diff
  await applyDiff(labelDiff, raster, actionData.data, currentRasterData.data, frameIndex)
  // In a next iteration, we should get the bounds of the changes and only invalidate those
  // TODO: should be improved part of this ticket:
  // https://linear.app/v7labs/issue/DAR-1885/performance-improvement-for-undoredo-action
  raster.invalidateAll()
}

type RasterRestoreActionData = {
  data: RasterDataSnapshot
  annotationIdToClassId: Record<string, number>
}

/**
 * Creates an action that handles undo/redo for raster updates, and can be added to the action
 * manager.
 * It requires a `view` rather than a `raster` because the raster could be undefined before or
 * after the action, so the view allows us the get the current raster and perform actions to it.
 */
export const createUpdateRasterAction = (
  view: View,
  rasterDataCurrent: RasterDataSnapshot,
  rasterDataBefore: RasterDataSnapshot,
): Action => {
  const rasterLayerDataForUndo: RasterRestoreActionData = {
    data: { ...rasterDataBefore },
    annotationIdToClassId: Object.fromEntries(rasterDataBefore.maskAnnotationClassIdsMapping),
  }
  const rasterLayerDataForRedo: RasterRestoreActionData = {
    data: { ...rasterDataCurrent },
    annotationIdToClassId: Object.fromEntries(rasterDataCurrent.maskAnnotationClassIdsMapping),
  }

  const action: Action = {
    do: async (): Promise<boolean> => {
      const raster = getOrCreateRasterForView(view)
      await restoreRasterActionData(raster, rasterLayerDataForRedo, rasterLayerDataForUndo)
      return true
    },
    undo: async (): Promise<boolean> => {
      const raster = getOrCreateRasterForView(view)
      await restoreRasterActionData(raster, rasterLayerDataForUndo, rasterLayerDataForRedo)
      return true
    },
  }
  return action
}
