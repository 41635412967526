import type { V2Workflows } from '@/store/types'

export const getInitialState = (): V2Workflows => ({
  workflows: [],
  workflowsLoading: false,
  editedWorkflow: null,
  webhookSuccessResponses: [],
  webhookFailResponses: [],
})

export const state: V2Workflows = getInitialState()
