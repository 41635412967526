import type { NeuralModelAction } from '@/store/modules/neuralModel/types'
import { loadRunningSessionInstanceCounts as windRequest } from '@/backend/wind'
import type {
  RunningSessionInstanceCountPayload,
  RunningSessionPayload,
} from '@/backend/wind/types'

type RequestParams = Parameters<typeof windRequest>[0]
type Payload = Pick<RequestParams, 'from' | 'granularity'> & {
  runningSession: RunningSessionPayload
  teamId: number
}

export type LoadRunningSessionInstanceCountsPayload = NeuralModelAction<
  Payload,
  RunningSessionInstanceCountPayload[]
>

export const loadRunningSessionInstanceCounts: LoadRunningSessionInstanceCountsPayload = async (
  { commit },
  payload,
) => {
  const { from, granularity, runningSession, teamId } = payload

  const response = await windRequest({
    from,
    granularity,
    runningSessionId: runningSession.id,
    teamId,
  })

  if ('data' in response) {
    commit('SET_RUNNING_SESSION_INSTANCE_COUNTS', {
      runningSession,
      data: response.data,
    })
  }

  return response
}
