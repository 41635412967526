import type { WorkflowMutation } from '@/store/modules/workflow/types'
import type { BaseStageConfig, V2WorkflowStagePayload } from '@/store/types/V2WorkflowStagePayload'

type Payload = {
  stageId: string
  config: Partial<V2WorkflowStagePayload['config']>
}

const onlyStageCoords = (
  config: Partial<V2WorkflowStagePayload['config']>,
): config is BaseStageConfig =>
  config.x !== undefined && config.y !== undefined && Object.keys(config).length === 2

export const UPDATE_STAGE_CONFIG: WorkflowMutation<Payload> = (state, { stageId, config }) => {
  const workflow = state.editedWorkflow

  if (!workflow) {
    return
  }

  const stage = workflow.stages.find(({ id }) => id === stageId)
  if (!stage) {
    return
  }

  // if only the stage coords are provided, update them directly
  // to avoid unnecessary reactive updates
  if (onlyStageCoords(config)) {
    stage.config.x = config.x
    stage.config.y = config.y
  } else {
    stage.config = {
      ...stage.config,
      ...config,
    }
  }
}
