import { createSharedComposable } from '@vueuse/core'
import { v4 as uuid4 } from 'uuid'
import type { ComputedRef, Ref } from 'vue'
import { computed, ref } from 'vue'

import { createRequestEngine } from '@/pinia/utils/annotationEngine'
import type { sendV2Commands } from '@/backend/darwin'

type UseAnnotationRequestCounterReturn = {
  /**
   * Function that is used to dispatch an annotation workflow call on the backend.
   */
  dispatchAnnotationCommand: (
    annotationId: string,
    payload: Parameters<typeof sendV2Commands>[0],
  ) => ReturnType<typeof sendV2Commands>
  /**
   * The number of unique annotations that have an inflight or queued request.
   */
  unsavedAnnotationCount: ComputedRef<number>
  /**
   * Will be true if there are any annotations with an inflight or queued request.
   */
  hasUnsavedAnnotation: ComputedRef<boolean>
  /**
   * A list of requests that are currently in-flight or queued.
   */
  unsavedAnnotationRequests: Ref<{ requestId: string; annotationId: string }[]>
}

/**
 * Composable to handle tracking and counting of annotation requests.
 *
 */
const useAnnotationRequestCounter = (): UseAnnotationRequestCounterReturn => {
  const { dispatchRequest } = createRequestEngine()

  /**
   * A list of requests with a unique request id and a non-unique annotation id
   * We need a unique request ID, as every annotation might have both an inflight and a queued
   * request, so not having some unique identifier would make it impossible to count correctly.
   */
  const unsavedAnnotationRequests = ref<{ requestId: string; annotationId: string }[]>([])

  const unsavedAnnotationCount = computed(() => {
    const annotationIds = unsavedAnnotationRequests.value.map((r) => r.annotationId)
    return new Set(annotationIds).size
  })

  const hasUnsavedAnnotation = computed(() => unsavedAnnotationCount.value > 0)

  const dispatchAnnotationCommand = async (
    annotationId: string,
    payload: Parameters<typeof sendV2Commands>[0],
  ): ReturnType<typeof sendV2Commands> => {
    const requestId = uuid4()

    unsavedAnnotationRequests.value.push({ requestId, annotationId })

    const result = await dispatchRequest(payload)

    // Due to the async nature of these calls, it's extremely impotant we remove the request
    // by finding index first, then splicing out that index alone.
    // Performing a filter by excluding a specific id might overwrite other elements of the array.
    const index = unsavedAnnotationRequests.value.findIndex((v) => v.requestId === requestId)
    if (index >= 0) {
      unsavedAnnotationRequests.value.splice(index, 1)
    }

    return result
  }

  return {
    dispatchAnnotationCommand,
    unsavedAnnotationCount,
    hasUnsavedAnnotation,
    unsavedAnnotationRequests,
  }
}

export const useSharedAnnotationRequestCounter = createSharedComposable(useAnnotationRequestCounter)
