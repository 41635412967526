export const DarwinKind = {
  TRANSPARENT: 'transparent',
  PRIMARY: 'primary',
  SECONDARY: 'secondary',
  WARNING: 'warning',
  NEGATIVE: 'negative',
  POSITIVE: 'positive',
  OUTLINE: 'outline',
  SURFACE: 'surface',
  STAGE_COMPLETE: 'stage-complete',
  STAGE_NEW: 'stage-new',
  STAGE_REVIEW: 'stage-review',
} as const

export const DarwinFlair = {
  SQUARED: 'squared',
  SOFT: 'soft',
  ROUNDED: 'rounded',
  CIRCLE: 'circle',
} as const

export const DarwinSize = {
  AUTO: 'auto',
  ICON: 'icon',
  XS: 'xs',
  SM: 'sm',
  MD: 'md',
  LG: 'lg',
} as const

export const DarwinTag = {
  BUTTON: 'button',
  ROUTER: 'router-link',
  A: 'a',
  DIV: 'div',
} as const

export const SortDirection = {
  DESCENDING: 'desc',
  ASCENDING: 'asc',
} as const

export type DarwinKindType = (typeof DarwinKind)[keyof typeof DarwinKind]
export type DarwinFlairType = (typeof DarwinFlair)[keyof typeof DarwinFlair]
export type DarwinSizeType = (typeof DarwinSize)[keyof typeof DarwinSize]
export type DarwinTagType = (typeof DarwinTag)[keyof typeof DarwinTag]
export type SortDirectionType = (typeof SortDirection)[keyof typeof SortDirection]

export interface OptionType {
  id: string
  text?: string
  height?: number
  width?: number
  separator?: boolean
  disabled?: boolean
  group?: string
  [key: string]: unknown
  // additional invisible tags by which one can filter
  alsoFilterableBy?: string[]
}

export interface TabType {
  id: string
  text: string
  path?: string
  active?: boolean
  badge?: string | number | boolean | null
  badgeSize?: DarwinSizeType
  attributes?: Record<string, string>
}
