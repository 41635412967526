import type { WorkflowAction } from '@/store/modules/workflow/types'
import type { V2WorkflowPayload } from '@/store/types'
import { createV2Workflow } from '@/backend/darwin/createV2Workflow'
import { updateV2Workflow } from '@/backend/darwin/updateV2Workflow'

/**
 * Action validates if the user is saving a new workflow or updating an existing one.
 * */
export const submitWorkflow: WorkflowAction<string, V2WorkflowPayload> = async (
  { commit, state },
  teamSlug,
) => {
  if (!state.editedWorkflow) {
    return
  }

  const response =
    state.editedWorkflow.id === 'new-workflow'
      ? await createV2Workflow({ teamSlug, ...state.editedWorkflow })
      : await updateV2Workflow({
          teamSlug,
          ...state.editedWorkflow,
          workflowId: state.editedWorkflow.id,
        })

  if ('data' in response) {
    commit('SET_WORKFLOW', response.data)
    commit('SET_EDITED_WORKFLOW', response.data)
  }

  return response
}
