import { computed } from 'vue'

import { useWorkflowInWorkviewStore } from '@/modules/Workview/useWorkflowInWorkviewStore'
import { StageType } from '@/store/types/StageType'
import { getDatasetClasses } from '@/modules/Classes/getDatasetClasses'
import { useClasses } from '@/modules/Classes/useClasses'
import { useDatasetStore } from '@/modules/Datasets/useDatasetStore'

/**
 * This composable is only meant to be used in those components where
 * the classes shown might be restricted by their stage

 * It is not intended to become the classes pinia store.
 * Instead, once we have a classes pinia store, this component will talk to it
 * instead of to vuex
 */

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export const useStageSpecificClasses = () => {
  const workflowInworkview = useWorkflowInWorkviewStore()

  const selectedStage = computed(() => workflowInworkview.stage)
  const datasetStore = useDatasetStore()
  const datasetId = computed(() => datasetStore.dataset?.id)
  const classStore = useClasses()
  const datasetClasses = computed(() =>
    datasetId.value ? getDatasetClasses(classStore.teamClasses, datasetId.value) : [],
  )

  /**
   * Only the classes defined here will be visible to the user
   * If the array is empty, then all classes are allowed
   */
  const allowedClassIds = computed<number[]>(() => {
    if (selectedStage.value?.type !== StageType.Annotate) {
      return []
    }

    const selectedClasses = new Set(selectedStage.value?.config.allowed_class_ids ?? [])

    if (selectedClasses.size === 0) {
      return []
    }

    return datasetClasses.value.filter((x) => selectedClasses.has(x.id)).map((x) => x.id)
  })

  const allowedClassIdsMap = computed(() =>
    Object.fromEntries(allowedClassIds.value.map((id) => [id, true])),
  )

  const isClassFilterApplied = computed<boolean>(() => !!allowedClassIds.value.length)

  // for milestone 2 we should also check the stage config
  // since users will be able to opt in/out of this
  const forbiddenClassCreation = computed<boolean>(() => isClassFilterApplied.value)

  const stageClasses = computed(() => {
    if (isClassFilterApplied.value) {
      return datasetClasses.value.filter(({ id }) => allowedClassIds.value.includes(id))
    }

    return datasetClasses.value
  })

  return {
    isClassFilterApplied,
    stageClasses,
    forbiddenClassCreation,
    selectedStage,
    allowedClassIds,
    allowedClassIdsMap,
  }
}
