<template>
  <div
    id="app"
    class="darwin-theme"
  >
    <router-view />

    <portal-target
      name="modal"
      multiple
    />

    <CriticalErrorDialog v-if="hasFatalError" />
  </div>
</template>

<script lang="ts" setup>
import { watch, computed, onMounted } from 'vue'

import { useInitialAuthLoader } from '@/modules/Auth/useInitialAuthLoader'
import { useAnnotationTypeLoader } from '@/modules/Classes/useAnnotationTypeLoader'
import { useClassLoader } from '@/modules/Classes/useClassLoader'
import { useIntercomLoader } from '@/composables/useIntercomLoader'
import { useIntercomMessaging } from '@/composables/useIntercomMessaging'
import { useSentryWatchers } from '@/composables/useSentryWatchers'
import CriticalErrorDialog from '@/modules/Workview/CriticalErrorDialog.vue'
import { useFatalError } from '@/modules/Workview/useFatalError'
import { useTeamStore } from '@/pinia/useTeamStore'

import { useModelsStore } from '@/modules/Models/useModelsStore'
import { usePlanLoader } from './modules/Billing/usePlanLoader'

useInitialAuthLoader()
useSentryWatchers()
useIntercomLoader()

const typeLoader = useAnnotationTypeLoader()
onMounted(() => typeLoader.load())

useClassLoader()
useIntercomMessaging()

const planLoader = usePlanLoader()
planLoader.autoLoadPlanOnTeamChange()

const teamStore = useTeamStore()
const modelsStore = useModelsStore()

const fatalError = useFatalError()

const hasFatalError = computed(() => fatalError.hasFatalError)

watch(() => teamStore.currentTeam, modelsStore.reset)
</script>
